import {fromEvent, merge, Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DdtListFilter, DdtListTableService} from '../../services/ddt-list-table.service';



@Component({
    selector: 'app-ddt-list',
    templateUrl: './ddt-list.component.html',
    styleUrls: ['./ddt-list.component.scss']
})
export class DdtListComponent implements OnInit, OnDestroy {

    @Input() reloadDataSubject: Observable<void>;
    @ViewChild('ddtNrF', {static: true}) ddtNrF: ElementRef;
    @ViewChild('vendorF', {static: true}) vendorF: ElementRef;
    @ViewChild('noteF', {static: true}) noteF: ElementRef;
    public resultsLength = 0;
    public operationInExecution = false;
    public selectedTab = 0;
    public slowListElements: number = 0;
    public performingQuickSerch$: Observable<boolean>;
    public performingSlowSearch$: Observable<boolean>;
    private unsubscribe$ = new Subject();

    constructor(
        private ddtListTableService: DdtListTableService
    ) {
        this.performingQuickSerch$ = this.ddtListTableService.isLoading$('quick');
        this.performingSlowSearch$ = this.ddtListTableService.isLoading$('slow');
    }

    ngOnInit() {
        this.restoreFilters();

        const ddtNrFevent$ = fromEvent(this.ddtNrF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const vendorFevent$ = fromEvent(this.vendorF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const noteFevent$ = fromEvent(this.noteF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );

        merge(ddtNrFevent$, vendorFevent$, noteFevent$).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => this.storeFilters());
    }

    restoreFilters() {
        const filter = this.ddtListTableService.retrieveFiltersFromStorage();

        this.ddtNrF.nativeElement.value = filter.ddtNr;
        this.noteF.nativeElement.value = filter.note;
        this.vendorF.nativeElement.value = filter.vendor;
    }

    storeFilters() {
        const filter: DdtListFilter = {
            ddtNr: this.ddtNrF.nativeElement.value.trim(),
            note: this.noteF.nativeElement.value.trim(),
            vendor: this.vendorF.nativeElement.value.trim()
        };

        this.ddtListTableService.storeFilters(filter);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

