<div class="example-header mt-1">
    <mat-form-field class="filterMargin">
        <input #ddtNrF [disabled]="operationInExecution" matInput placeholder="Ddt Nr">
    </mat-form-field>
    <mat-form-field class="filterMargin">
        <input #vendorF [disabled]="operationInExecution" matInput placeholder="Vendor">
    </mat-form-field>
    <mat-form-field class="filterMargin">
        <input #noteF [disabled]="operationInExecution" matInput placeholder="Description">
    </mat-form-field>
</div>

<div>
    <mat-tab-group (selectedIndexChange)="selectedTab = $event" [selectedIndex]="selectedTab">

        <mat-tab>
            <ng-template mat-tab-label>
                <span matTooltip="Lista veloce">Delivery notes list</span>
            </ng-template>

            <app-ddt-list-table [reloadDataSubject]="reloadDataSubject" searchMode='quick'></app-ddt-list-table>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div *ngIf="performingSlowSearch$ | async; else loaded" class="tab-label-loading">
                    <mat-icon matTooltip="Searching...">cloud_sync</mat-icon>
                </div>
                <ng-template #loaded>
                    <div class="tab-label-loaded">
                        <mat-icon matTooltip="Lista esaustiva ({{slowListElements}} delivery notes)">cloud_done
                        </mat-icon>
                        <span>({{ slowListElements }})</span>
                    </div>
                </ng-template>
            </ng-template>

            <app-ddt-list-table (updateTotalElements)="slowListElements = $event" [reloadDataSubject]="reloadDataSubject"
                                searchMode='slow'
            ></app-ddt-list-table>
        </mat-tab>
    </mat-tab-group>
</div>
