<mat-card>
    <form (ngSubmit)="submit()" [formGroup]="ddtForm">
        <mat-card-header>
            <mat-card-title>
                <h4>
                    <div *ngIf="editMode; else newDdt">
                        <span i18n>Delivery Note</span>&nbsp;
                        <span>{{ currentDdtNr }}</span>
                    </div>
                    <ng-template #newDdt>
                        <span i18n>New Delivery Note</span>&nbsp;
                        <span>{{ ddtNr }}</span>
                    </ng-template>
                </h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="ddt-container">
                <div class="ddt-meta">
                    <div class="ddt-meta-form">
                        <div>
                            <mat-form-field>
                                <input formControlName="ddtNr" i18n-placeholder matInput placeholder="Delivery Note Nr"
                                       type="text">
                            </mat-form-field>
                            <mat-form-field class="mat-form-field-lg">
                                <input (focus)="loadOrders()" [matAutocomplete]="autoOrder" formControlName="order" matInput
                                       placeholder="Order"
                                       type="text">
                                <mat-autocomplete #autoOrder="matAutocomplete" (optionSelected)="getItemsByOrder($event)"
                                                  [displayWith]="orderDisplay">
                                    <mat-option *ngFor="let order of filteredOrders | async" [value]="order">
                                        {{ order.orderNr }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field>
                                <input [matDatepicker]="ddtDate" formControlName="ddtDate" matInput
                                       placeholder="Delivery Note Date">
                                <mat-datepicker-toggle [for]="ddtDate" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #ddtDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field style="width:570px !important">
                                <textarea class="form-control" formControlName="note" i18n-placeholder matAutosizeMaxRows="120"
                                          matAutosizeMinRows="3" matInput matTextareaAutosize
                                          placeholder="Description"></textarea>
                            </mat-form-field>
                        </div>
                        <div>
                            <app-file-upload (uploadProcess)="getUploadProcess($event)"
                                             (url)="getDownloadUrl($event)"></app-file-upload>
                            <a *ngIf="ddtForm.get('downloadUrl').value" [href]="ddtForm.get('downloadUrl').value"
                               class="ddtFile" mat-button target="_blank">
                                <mat-icon matListIcon>attach_file</mat-icon>
                                <span class="menu-label" i18n="Ddt file">See file</span>
                            </a>
                        </div>
                        <mat-card-actions>
                            <button [disabled]="ddtForm.invalid || ddtAdding || disabledSubmit || numberItemSelected == 0" mat-raised-button
                                    type="submit">
                                <span *ngIf="editMode; else elseBlock" i18n>Update</span>
                                <ng-template #elseBlock>
                                    <span i18n>Submit</span>
                                </ng-template>
                            </button>
                            <a mat-raised-button routerLink="/ddt">
                                <span i18n>Cancel</span>
                            </a>
                        </mat-card-actions>
                    </div>

                    <div class="ddt-meta-form">
                        <div class="ddt-entry-array">
                            <h5>Materials: </h5>

                            <div class="example-container mat-elevation-z8">
                                <mat-table [dataSource]="dataSourceItems" matSort>

                                    <ng-container class="descriptionItem" matColumnDef="description">
                                        <mat-header-cell *matHeaderCellDef i18n>Description</mat-header-cell>
                                        <mat-cell *matCellDef="let row"
                                                  class="descriptionItem"> {{ row.description | ellipsis:100 }}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="quantity">
                                        <mat-header-cell *matHeaderCellDef i18n>Original Quantity</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.quantity }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="selected">
                                        <mat-header-cell *matHeaderCellDef i18n>In this delivery note?</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <mat-checkbox (change)="changeSelectedItem($event, row.quantity, i)"
                                                          [checked]="row.selected"></mat-checkbox>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="deliveryQuantity">
                                        <mat-header-cell *matHeaderCellDef i18n>Delivery Quantity</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <input (change)="changeQuantityValue($event, i)" (keyup)="changeQuantityValue($event, i)"
                                                   [disabled]="!row.selected" [value]="row.deliveryQuantity"
                                                   class="no-spinner" i18n-placeholder matInput min="1"
                                                   type="number">
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns">
                                    </mat-row>
                                </mat-table>

                                <mat-paginator [length]="dataSourceItems.resultsLength"
                                               [pageSizeOptions]="[100]"></mat-paginator>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </form>
</mat-card>
