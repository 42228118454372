<div class="example-container mat-elevation-z8 table-container">

    <mat-progress-bar *ngIf="tableIsLoading$ | async" mode="indeterminate" style="width:100%"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc" matSortDisableClear>

        <ng-container matColumnDef="sent">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Sent</mat-header-cell>
            <mat-cell *matCellDef="let row">
        <span *ngIf="row.sent" [matTooltip]="row.sentOn">
          <mat-icon>mail</mat-icon>
        </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="budgetId">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Budget</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="row.budget.project">{{ row.budget | projectNr }}-</span>{{ row.budget.budgetNr }}
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="subBudgetId">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Sub Budget</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.subBudget.description }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="activityId">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Activity</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.activity.activity }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="hours">
            <mat-header-cell *matHeaderCellDef i18n>Hours</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.hours }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Date (Europe/Rome Time)</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.date | timestamp | date:'mediumDate':'+0200' }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="userId">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>User</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.user.displayName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef i18n>Description</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div [innerHtml]="row.description"></div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef i18n>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <ng-template [ngIf]="canChangeUser || !row.sent">
                    <button (click)="editTimereport($event,row)" [disabled]="disableAllActions" mat-icon-button>
                        <mat-icon>mode_edit</mat-icon>
                    </button>
                    <button (click)="deleteTimereport($event,row)" [disabled]="disableAllActions" mat-icon-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-template>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="expandedElement = row" *matRowDef="let row; columns: displayedColumns;" [class.expanded]="expandedElement == row"
                 class="element-row" matRipple>
        </mat-row>
    </mat-table>

    <mat-paginator [length]="totalElements" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>