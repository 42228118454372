import {BehaviorSubject, fromEvent, Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../services/data.service';
import {Budget} from './interfaces';
import {AuthService} from '../services/auth.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import {BudgetListFilter, BudgetListTableService} from '../services/budget-list-table.service';



@Component({
    selector: 'app-budget-list',
    templateUrl: './budget-list.component.html',
    styleUrls: ['./budget-list.component.css']
})
export class BudgetListComponent implements OnInit, OnDestroy {

    loading = false;

    iconFilter: string | null = null;

    showExport = false;

    @ViewChild('reportFromDate', {
        read: MatInput
    }) reportFromDate;

    @ViewChild('reportToDate', {
        read: MatInput
    }) reportToDate;

    @ViewChild('filterF', {
        static: true
    }) filterF: ElementRef;

    // Set our default values
    public budgets: Budget[] = [];
    public _canDownloadReport: boolean;
    public reportDateFromSubject = new BehaviorSubject<Date>(moment().subtract(1, 'year').toDate());
    public reportDateToSubject = new BehaviorSubject<Date>(moment().toDate());
    slowListElements = 0;
    selectedTab = 0;
    queryParamFilter = false;
    public performingQuickSerch$: Observable<boolean>;
    public performingSlowSearch$: Observable<boolean>;
    private dataSubscription;
    private unsubscribe$ = new Subject();

    // TypeScript public modifiers
    constructor(
        private authService: AuthService,
        private dataService: DataService,
        private route: ActivatedRoute,
        private budgetListTableService: BudgetListTableService
    ) {
        this.performingQuickSerch$ = this.budgetListTableService.isLoading$('quick');
        this.performingSlowSearch$ = this.budgetListTableService.isLoading$('slow');
    }

    setIconFilter(iconFilter) {
        this.iconFilter = (iconFilter === this.iconFilter) ? null : iconFilter;
        this.storeFilters();
    }

    public ngOnInit() {

        this.authService.userHasPermission('app.budget.report.download')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val: boolean) => {
                this._canDownloadReport = val;
            });

        this.route.queryParamMap.pipe(takeUntil(this.unsubscribe$)).subscribe((queryParamMap) => {
            setTimeout(function () {
                window.scrollTo(0, Number(sessionStorage.getItem('budgetListScrollPosition')));
                window.addEventListener('scroll', this.scrollHandler, true);
            }.bind(this), 500);
            this.queryParamFilter = queryParamMap.has('new');
        });

        this.restoreFilters();

        fromEvent(this.filterF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$),
            debounceTime(500)
        ).subscribe(v => this.storeFilters());
    }

    restoreFilters() {
        const filter = this.budgetListTableService.retrieveFiltersFromStorage();

        this.filterF.nativeElement.value = filter.filter;
        this.showExport = filter.showExport;
        this.iconFilter = filter.status;
    }

    storeFilters() {
        const filter: BudgetListFilter = {
            filter: this.filterF.nativeElement.value.trim(),
            showExport: this.showExport,
            status: this.iconFilter
        };

        this.budgetListTableService.storeFilters(filter);
    }

    changeShowExports() {
        this.showExport = !this.showExport;
        this.storeFilters();
    }

    public ngOnDestroy() {
        window.removeEventListener('scroll', this.scrollHandler, true);
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        console.log('On destroy budgetlist. Freeing memory.');
    }

    async downloadHourReport() {

        const from = this.reportFromDate.value.toDate();
        const to = this.reportToDate.value.toDate();
        console.log('Creating excel with dates: ', from, to);

        try {
            this.loading = true;
            const reportData = await this.dataService.getHoursReport(from, to).toPromise();
            console.log('Got data: ', reportData);
            XLSX.writeFile(reportData.wb, reportData.fileName);

            this.loading = false;
        } catch (err) {
            console.error(err);
            this.loading = false;
        }

    }

    private scrollHandler(event) {
        const doc = document.documentElement;
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

        sessionStorage.setItem('budgetListScrollPosition', top.toFixed(0));

    }

}
