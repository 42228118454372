import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import {UsersAddComponent} from './users-add/users-add.component';
import {UsersListComponent} from './users-list/users-list.component';
import {SharedModule} from '../shared/shared.module';
import {UsersGuard} from './guards/users.guard';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [UsersAddComponent, UsersListComponent],
    providers: [UsersGuard]
})
export class UsersModule {
}
