export interface Activity {
    id?: string;
    activity: string;
    amount?: number;
    cost: number;
    hours?: number;
    weight?: number;
    category: string;
    name?: string;
    finalHours?: number;
}

export interface ActivitiesTemplate {
    activities: Activity[];
    latest: boolean;
    validFrom: Date;
    validTp: Date;
}
