import {BehaviorSubject, merge, Observable} from 'rxjs';

import {debounceTime, map} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../interfaces/users';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {DataService} from '../../services/data.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../private/private.component';



@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {

    // For the mat-table
    displayedColumns = ['displayName', 'email', 'firstName', 'lastName', 'googleUid', 'initials', 'roles', 'active', 'external', 'referent', 'jobClassification', 'actions'];
    dataSource: OrderDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private userStream$;
    private dataSubscription;

    constructor(
        private dataService: DataService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.userStream$ = this.dataService.getUserList().pipe(
            map((userList) => userList.map((user: any) => {
                const data = user.payload.doc.data() as User;
                const id = user.payload.doc.id;
                return {id: id, ...data};
            })));

        // Assign the data to the data source for the table to render
        this.dataSource = new OrderDataSource(
            this.userStream$,
            this.paginator, this.sort);
    }

    public getUserListFilter() {
        let userListFilter = localStorage.getItem('userListFilter') ? localStorage.getItem('userListFilter') : '';
        this.applyFilter(userListFilter);
        return userListFilter;
    }

    public setUserListFilter(filterName, filterValue) {
        localStorage.setItem(filterName, filterValue);
    }

    public ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    public deleteUser(user: User) {
        console.log('Delete user', user);
        const dialogMessage = {confirmMessage: `Are you sure you want to delete user: ${user.displayName}?`};
        const dialogData = {data: dialogMessage, height: '230px', width: '450px', disableClose: true};
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogData);

        dialogRef.afterClosed().subscribe(choice => {
            if (choice.confirm) {
                this.dataService.deleteUser(user)
                    .then(() => {
                        console.log('User deleted');
                    })
                    .catch((err) => {
                        console.error('Error while removing user', err.message);
                    });
            }
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        // set cookie
        this.setUserListFilter('userListFilter', filterValue);
    }

}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class OrderDataSource extends DataSource<User> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable
            // .do((data) => console.log(data))
            .subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<User[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('usersListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('usersListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('usersListSortId');
        const sortDirection = localStorage.getItem('usersListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            const data = this._dataChange.value;

            // Filter
            const fData = data.filter((item: User) => {
                let srcStr;
                try {
                    srcStr = item.type.map(entry => entry).join('-');
                } catch (err) {
                    srcStr = '';
                }

                const searchStr = (srcStr + item.displayName).toLowerCase() + (srcStr + item.firstName).toLowerCase() + (srcStr + item.lastName).toLowerCase() + (srcStr + item.email).toLowerCase();
                return (searchStr.indexOf(this.filter.toLowerCase()) !== -1);
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: User[]) => {
        localStorage.setItem('usersListSortId', this._sort.active);
        localStorage.setItem('usersListSortDirection', this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: User, b: User) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'displayName':
                    [propertyA, propertyB] = [a.displayName, b.displayName];
                    break;
                case 'email':
                    [propertyA, propertyB] = [a.email, b.email];
                    break;
            }

            let valueA;
            let valueB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
