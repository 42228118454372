import * as firebase from 'firebase';
import * as moment from 'moment';



/**
 * Utiliy function to convert a firebase datetime type to a js date
 * @param input a value that can be either Timestamp or FiledValue
 */
export function firestoreDateToJsDate(input: firebase.firestore.Timestamp | firebase.firestore.FieldValue | Date | moment.Moment | string): Date {
    if (input instanceof firebase.firestore.Timestamp) {
        return input.toDate();
    } else if (input instanceof firebase.firestore.FieldValue) {
        throw new Error(`Filed Value cannot be converted to date`);
    } else if (input instanceof Date) {
        return input;
    } else if (moment.isMoment(input)) { // Workaround sometimes the use can pass moment obj...
        return (<moment.Moment>input).toDate();
    } else if (typeof input === 'string') { // Workaround sometimes the use can pass a string (sob) obj...
        return moment(<string>input).toDate();
    } else if ((<any>input)._seconds) { // Workaround sometimes you just have an obj like { _seconds: xxx, _milliseconds: xxxx }
        return new Date((<any>input)._seconds * 1000);
    } else {
        throw new Error(`Cannot convert ${typeof input} to date`);
    }
}
