import {switchMap, filter} from 'rxjs/operators';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {AppStateService} from '../../services/app-state.service';
import {DataService} from '../../services/data.service';



@Component({
    selector: 'app-cosmo-invoices',
    templateUrl: './invoices-cosmo.component.html',
    styleUrls: ['./invoices-cosmo.component.scss']
})
export class InvoicesCosmoComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService,
        private dataService: DataService
    ) {
    }

    private iId;
    private invoicesSubscription;
    public invoiceCosmo;

    ngOnInit() {
        this.invoicesSubscription = this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('id')),
            switchMap((params: ParamMap) => {
                this.iId = params.get('id');
                console.log('INVOICE ID', this.iId);
                return this.dataService.getInvoicesCosmo(this.iId);
            })).subscribe((invoice) => {
            if (!invoice) {
                this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this invoice id'});
                this.router.navigate(['/invoices-cosmo']);
            } else {
                invoice.id = this.iId;
                this.invoiceCosmo = invoice;
                console.log('Invoice set: ', invoice);
            }
        });
    }

    ngOnDestroy() {
        if (this.invoicesSubscription) {
            this.invoicesSubscription.unsubscribe();
        }
    }

}
