import {merge} from 'rxjs';

import {map, debounceTime} from 'rxjs/operators';
import {
    Component, ViewChild,
    OnInit,
    OnDestroy,
    AfterViewInit
} from '@angular/core';
import {Invoices} from '../../interfaces/invoices';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatInput} from '@angular/material/input';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {DataService} from '../../../services/data.service';
import {Invoice} from '@wondersys/wonderbudget-lib';
import {firestoreDateToJsDate} from '../../../shared/utils/utils';



@Component({
    selector: 'app-invoices-cosmo-list',
    templateUrl: './invoices-cosmo-list.component.html',
    styleUrls: ['./invoices-cosmo-list.component.scss']
})
export class InvoicesCosmoListComponent implements OnInit, OnDestroy, AfterViewInit {

    // For the mat-table
    displayedColumns = ['invoiceNr', 'vendor', 'downloadUrl', 'orderNr',
        'invoiceDate', 'amount', 'registeredToCosmo', 'cosmoErrors', 'actions'];
    dataSource: InvoicesCosmoDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @ViewChild('filter', {
        read: MatInput
    }) filter: MatInput;

    private invoicesCosmoStream$;
    private dataSubscription;

    // Flags
    public invoicesCosmoPrepare = new Map<string, boolean>();

    constructor(
        private dataService: DataService
    ) {
    }

    ngOnInit() {

        this.invoicesCosmoStream$ = this.dataService.getInvoicesCosmoList();

        // Assign the data to the data source for the table to render
        this.dataSource = new InvoicesCosmoDataSource(
            this.invoicesCosmoStream$,
            this.paginator, this.sort);
    }

    ngAfterViewInit() {
        this.filter.value = this.getListFilter();
    }

    public ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    public getListFilter() {
        let listFilter = localStorage.getItem('invoicesCosmoListFilter') ? localStorage.getItem('invoicesCosmoListFilter') : '';
        this.applyFilter(listFilter);
        return listFilter;
    }

    public setListFilter(filterName, filterValue) {
        localStorage.setItem(filterName, filterValue);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.setListFilter('invoicesCosmoListFilter', filterValue);
    }

}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class InvoicesCosmoDataSource extends DataSource<Invoices> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable
            // .do((data) => console.log(data))
            .subscribe(this._dataChange);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Invoices[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('invoiceCosmoListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('invoiceCosmoListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('invoiceCosmoListSortId');
        const sortDirection = localStorage.getItem('invoiceCosmoListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            const data = this._dataChange.value;

            // Filter
            let searchStr;
            const fData = data.filter((item: Invoices) => {
                searchStr = item.order ? (item.order.orderNr).toLowerCase() : '';
                searchStr += item.invoiceNr;
                if (item.order && item.order.vendor && item.order.vendor.name) {
                    searchStr += item.order.vendor.name.toLowerCase();
                }
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (invoices: Invoice[]) => {

        localStorage.setItem('invoicesCosmoListSortId', this._sort.active);
        localStorage.setItem('invoicesCosmoListSortDirection', this._sort.direction);

        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        invoices.sort((a: Invoice, b: Invoice) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'invoiceNr':
                    [propertyA, propertyB] = [a.invoiceNr, b.invoiceNr];
                    break;
                case 'orderNr':
                    [propertyA, propertyB] = [a.order.orderNr, b.order.orderNr];
                    break;
                case 'vendor':
                    [propertyA, propertyB] = [a.order.vendor.name, b.order.vendor.name];
                    break;
                case 'registeredToCosmo':
                    [propertyA, propertyB] = [a.registeredToCosmo, b.registeredToCosmo];
                    break;
                case 'invoiceDate':
                    [propertyA, propertyB] = [firestoreDateToJsDate(a.invoiceDate), firestoreDateToJsDate(b.invoiceDate)];
                    break;
            }

            return (propertyA < propertyB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
