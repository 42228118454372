import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DdtListFilter, DdtListResponse, DdtListTableService} from '../../services/ddt-list-table.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Ddt} from '../interfaces/ddt';
import {filter, take, takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-ddt-list-table',
    templateUrl: './ddt-list-table.component.html',
    styleUrls: ['./ddt-list-table.component.scss']
})
export class DdtListTableComponent implements OnInit {

    @Input() searchMode: 'quick' | 'slow';
    @Input() reloadDataSubject: Observable<void>;
    appliedFilter: DdtListFilter;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    dataSource = new MatTableDataSource<Ddt>();
    displayedColumns = [
        'ddtNr',
        'downloadUrl',
        'note',
        'vendor',
        'orderNr',
        'ddtDate',
        'actions'
    ];
    totalElements = 0;
    @Output() updateTotalElements: EventEmitter<number> = new EventEmitter<number>();
    private unsubscribe$: Subject<void> = new Subject<void>();
    private firstLoad = true;
    private isLoadingData: Subscription;

    constructor(
        private ddtListTableService: DdtListTableService
    ) {
    }

    get tableIsLoading$() {
        return this.ddtListTableService.isLoading$(this.searchMode);
    }

    ngOnInit(): void {
        this.paginator.pageSize = 50;

        this.ddtListTableService.appliedFilterAndSorting$.pipe(
            filter(newFilter => this.ddtListTableService.compareFilters(newFilter, this.appliedFilter)),
            takeUntil(this.unsubscribe$)
        ).subscribe(inputFilter => this.handleFilterChange(inputFilter));

        this.reloadDataSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.firstLoad = true;
            this.handleFilterChange(this.appliedFilter);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {
        this.sort.sortChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.paginator.pageIndex = 0;
                this.ddtListTableService.storeSorting(this.sort.active, this.sort.direction);
            });

        if (this.searchMode === 'slow') {
            this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
        } else {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.quickTableFilterPredicate();
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = this.quickTableSort();
        }
    }

    loadData() {
        console.log(this.searchMode, ' loading data...');

        if (this.searchMode === 'slow') {
            // empty the slow table while waiting for a new response
            this.dataSource = null;
            if (this.isLoadingData) {
                // delete pending requests caused by quick change of the filter/sort
                this.isLoadingData.unsubscribe();
            }
        }

        this.isLoadingData = this.ddtListTableService.loadData(this.searchMode, this.paginator.pageIndex, this.paginator.pageSize)
            .pipe(take(1))
            .subscribe(result => {
                if (!result?.data) {
                    return;
                }

                for (const ddt of result.data) {
                    ddt.ddtDate = ddt.ddtDate ? (ddt.ddtDate['seconds']) ?
                        new Date(ddt.ddtDate['seconds'] * 1000) : new Date(ddt.ddtDate['_seconds'] * 1000) : null;
                }
                console.log(this.searchMode, ' received data:', result);
                this.setDataSource(result);
            });
    }

    quickTableFilterPredicate(): (ddt: Ddt, filter: string) => boolean {
        return (ddt: Ddt, inputFilter: string) => {
            let ddtNrF = true;
            let vendorF = true;
            let noteF = true;

            if (this.appliedFilter.ddtNr) {
                ddtNrF = ddt.ddtNr && ddt.ddtNr.toLowerCase().includes(this.appliedFilter.ddtNr.toLowerCase());
            }
            if (this.appliedFilter.vendor) {
                vendorF = ddt.order.vendor && ddt.order.vendor.name && ddt.order.vendor.name.toLowerCase().includes(this.appliedFilter.vendor.toLowerCase());
            }
            if (this.appliedFilter.note) {
                noteF = ddt.note && ddt.note.toLowerCase().includes(this.appliedFilter.note.toLowerCase());
            }

            return ddtNrF && vendorF && noteF;
        };
    }

    quickTableSort(): (items: Ddt[], sort: MatSort) => Ddt[] {
        return (items: Ddt[], sort: MatSort) => {
            const sortDirection = (sort.direction === 'asc') ? 1 : -1;

            switch (sort.active) {
                case 'ddtNr':
                    return items.sort((a, b) => a.ddtNr.localeCompare(b.ddtNr) * sortDirection);
                case 'vendor':
                    return items.sort((a, b) => a.order.vendor.name.localeCompare(b.order.vendor.name) * sortDirection);
                case 'note':
                    return items.sort((a, b) => a.note?.localeCompare(b.note) * sortDirection);
                case 'ddtDate':
                    return items.sort((a, b) => {
                        const ddtDateA = a.ddtDate ? (a.ddtDate as Date).getTime() : 0;
                        const ddtDateB = b.ddtDate ? (b.ddtDate as Date).getTime() : 0;
                        return (ddtDateA - ddtDateB) * sortDirection;
                    });
                case 'orderNr':
                    return items.sort((a, b) => a.order.orderNr.localeCompare(b.order.orderNr) * sortDirection);
                default:
                    return items;
            }
        };
    }

    private handleFilterChange(inputFilter: DdtListFilter) {
        this.restoreSorting(inputFilter);
        this.appliedFilter = {...inputFilter};
        this.paginator.pageIndex = 0;

        // for the slow table dataSource.filter is not meaningful
        if (this.searchMode === 'quick') {
            this.dataSource.filter = JSON.stringify(inputFilter);
        }

        // for the quick table load data only once
        if (this.searchMode === 'slow' || this.firstLoad) {
            this.loadData();
        }
    }

    private setDataSource(response: DdtListResponse) {
        if (this.searchMode === 'quick') {
            this.dataSource.data = response.data as any;
        } else {
            this.dataSource = response.data as any;
        }

        this.totalElements = response.totalSize;
        this.paginator.length = response.totalSize;

        if (this.firstLoad) {
            this.firstLoad = false;
            // this will force the table to apply the initial filter and update the paginator values
            setTimeout(() => this.dataSource.filter = JSON.stringify(this.appliedFilter));
        }

        this.updateTotalElements.emit(this.totalElements);
    }

    private restoreSorting(inputFilter: DdtListFilter) {
        const needRefresh = this.sort.active !== inputFilter.sortActive || this.sort.direction !== inputFilter.sortDirection;
        this.sort.active = (inputFilter.sortActive) ? inputFilter.sortActive : 'ddtDate';
        this.sort.direction = (inputFilter.sortDirection) ? inputFilter.sortDirection as SortDirection : 'desc';
        if (needRefresh) {
            this.sort.sortChange.emit();
        }
    }
}
