import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';

import {OrderAddComponent} from './order-add/order-add.component';
import {OrderListComponent} from './order-list/order-list.component';
import {OrderViewComponent} from './order-view/order-view.component';
import {OrderEntryComponent} from './components/order-entry.component';
import {OrderEntryArrayComponent} from './components/order-entry-array.component';
import {SharedModule} from '../shared/shared.module';
import {DeleteOrderDialogComponent} from './components/delete-order-dialog/delete-order-dialog.component';
import {BudgetModule} from '../budget';
import {MaterialDialogComponent} from '../budget/sub-budget.component';
import {OrderGuard} from './guards/order.guard';
import {OrderListTableComponent} from './order-list-table/order-list-table.component';
import {MarkOrderAsDeliveredDialogComponent} from './mark-order-as-delivered-dialog/mark-order-as-delivered-dialog.component';



@NgModule({
    entryComponents: [DeleteOrderDialogComponent, MaterialDialogComponent],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        BudgetModule
    ],
    declarations: [OrderAddComponent,
        OrderListComponent,
        OrderViewComponent,
        OrderEntryComponent,
        OrderEntryArrayComponent,
        DeleteOrderDialogComponent,
        OrderListTableComponent,
        MarkOrderAsDeliveredDialogComponent
    ],
    providers: [OrderGuard]
})

export class OrderModule {
}
