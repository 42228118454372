import {combineLatest, Observable, ReplaySubject, Subscription} from 'rxjs';

import {map, tap} from 'rxjs/operators';
import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {DataService} from '../services/data.service';


import {Activity, Budget, GeneratedBy, Material, SubBudget, SubBudgetTotals} from './interfaces';
import {ActivitiesValueModel} from './models/activities-value';
import {AuthService} from '../services/auth.service';
import {AppStateService} from '../services/app-state.service';
import {MaterialModel} from './models/material';
import {budgetSummary} from '@wondersys/wonderbudget-lib';
import {NgForm} from '@angular/forms';



@Component({
    selector: 'app-sub-budget',
    styleUrls: ['./sub-budget.component.css'],
    templateUrl: './sub-budget.component.html'
})
export class SubBudgetComponent implements OnInit, OnDestroy {

    @Input() public subBudgetId: string;
    @Input() public budgetTotal: number;
    @Input() public resume: budgetSummary;
    @Output() public update = new EventEmitter<SubBudgetTotals>();
    @Output() public delete = new EventEmitter<string>();
    @Output() public tagsUpdated = new EventEmitter<string[]>();
    // Set our default values
    public localState = {value: ''};
    public separatorKeyCodes = [ENTER, COMMA];
    public subBudgetTags: string[];
    public budgets = [];
    public activitiesDataSource;
    public materialDataSource;
    public displayedColumns = ['name', 'category', 'cost', 'hoursNew', 'importoCalc', 'weight']; // L'ordine conta!!!
    public matDisplayedColumns = ['description', 'category', 'unit', 'quantity', 'costEach', 'amount', 'weight', 'actions']; // L'ordine conta!!!
    public avm: {};
    public subBudgetTotals = new SubBudgetTotals();
    public subBudgetName;
    public vacancyBtn = false;
    public vacancyBtnDisabled = false;
    public calculate; // This is bound to the disable toggle.
    public calculateToggleEnabled = true;
    public categoryMap = new Map<string, string>();
    public canNotDelete = false;
    private budget$ = new ReplaySubject<Budget>(1);
    private subBudget$;
    private subBudgetActivities$;
    private subBudgetMaterials$;
    private activityValMap: ActivitiesValueModel;
    private subBudgetSubscription;

    constructor(
        private dataService: DataService,
        public dialog: MatDialog,
        private auth: AuthService,
        private appState: AppStateService
    ) {
    }

    private _budget: Budget;

    public get budget() {
        return this._budget;
    }

    @Input()
    public set budget(b: Budget) {

        this.budget$.next(b);
        this._budget = b;
    }

    public _authorizedToModify = false;

    public get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input()
    public set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
    }

    private _canEdit = false;

    get canEdit(): boolean {
        return this._canEdit;
    }

    public ngOnInit() {

        // FIX import assumption
        if (!this.subBudgetId) {
            this.subBudgetId = 'budget0';
        }

        this.subBudgetActivities$ = this.dataService
            .getSBActivities(this._budget.id, this.subBudgetId).pipe(
                map((activity) => {
                    return activity.map((m: any) => {
                        const data = m.payload.doc.data() as Activity;
                        const id = m.payload.doc.id;
                        if (data.finalHours && data.finalHours > 0) {
                            this.canNotDelete = true;
                        }
                        return {id, ...data};
                    });
                }),
                tap((activity) => {
                    this.activityValMap = new ActivitiesValueModel(activity);
                    this.avm = this.activityValMap.activityValueMap;
                    this.subBudgetTotals.activities = activity.filter((a) => a.cost && a.hours)
                        .map((a) => a.cost * a.hours).reduce((v1, v2) => v1 + v2, 0);
                    this.subBudgetTotals.calculate = this.calculate;
                    this.update.emit(this.subBudgetTotals);
                }));

        this.subBudgetMaterials$ = this.dataService.getSBMaterials(this._budget.id, this.subBudgetId).pipe(
            map((materials) => {
                return materials.map((m: any) => {
                    const data = m.payload.doc.data() as Material;
                    const id = m.payload.doc.id;

                    // set hasOrder based on resume materials
                    if (!this.resume) {
                        return {id, ...data};
                    }
                    this.resume.materials.map(rm => {
                        const sameDescr = rm.description ? rm.description.trim().toLowerCase() === data.description.trim().toLowerCase() : true;
                        const sameCategory = rm.category ? rm.category.trim().toLowerCase() === data.category.trim().toLowerCase() : true;
                        const sameCostEach = rm.costEach ? rm.costEach === data.costEach : true;
                        if (sameDescr && sameCategory && sameCostEach) {
                            if (rm.finalQuantity && rm.finalQuantity > 0) {
                                data.hasOrders = true;
                                this.canNotDelete = true;
                            } else {
                                data.hasOrders = false;
                            }
                        }
                    });

                    return {id, ...data};
                });
            }),
            tap((materials) => {
                this.subBudgetTotals.materials = materials.filter((m) => m.costEach && m.quantity)
                    .map((m) => m.costEach * m.quantity).reduce((v1, v2) => v1 + v2, 0);
                this.subBudgetTotals.calculate = this.calculate;
                this.update.emit(this.subBudgetTotals);
            }));

        this.subBudget$ = this.dataService
            .getSubBudget(this._budget.id, this.subBudgetId).pipe(
                tap((sb) => {
                    if (!sb) {
                        return;
                    }
                    this.subBudgetTags = sb.tags;
                    this.subBudgetName = sb.description;
                    this.calculateToggleEnabled = (!sb.generatedBy || sb.generatedBy === GeneratedBy.User);
                    // WORKAROUND to FIX OLD stuff
                    if (!sb.generatedBy) {
                        if (['ASSEMBLY', 'AUTO_BUDGET', 'IMPORT'].indexOf(sb.id) !== -1) {
                            const gb = sb.id === 'ASSEMBLY' ? GeneratedBy.Assembly : GeneratedBy.Export;
                            this.dataService.updateSubBudgetField(this._budget.id, this.subBudgetId, {
                                generatedBy: gb,
                                calculate: true
                            }).then(() => {
                                console.log('FIX HAS BEEN APPLIED Once');
                            }).catch((err) => {
                                console.error(err);
                            });
                        } else {
                            this.dataService.updateSubBudgetField(this._budget.id, this.subBudgetId, {
                                generatedBy: GeneratedBy.User,
                                calculate: true
                            }).then(() => {
                                console.log('FIX HAS BEEN APPLIED Once');
                            }).catch((err) => {
                                console.error(err);
                            });
                        }
                    }
                    if (this.calculate !== sb.calculate) {

                        this.calculate = sb.calculate;
                        this.subBudgetTotals.calculate = this.calculate;
                        this.update.emit(this.subBudgetTotals);
                    }
                }));

        this.subBudgetSubscription = new Subscription();
        this.activitiesDataSource = new ExampleDataSource(this.subBudgetActivities$);
        this.materialDataSource = new ExampleDataSource(this.subBudgetMaterials$);

        const sb01 = this.dataService.getItemCategories()
            .subscribe((cats) => {
                this.categoryMap.clear();
                cats.forEach((c) => {
                    this.categoryMap.set(c.name, c.description);
                });
            });
        this.subBudgetSubscription.add(sb01);

        const sb02 = this.dataService.getSBActivity(this._budget.id, this.subBudgetId, 'VACANCY')
            .subscribe((snap) => {
                if (snap) {
                    this.vacancyBtn = false;
                }
            }, (err) => {
                console.error('err', err);
            });
        this.subBudgetSubscription.add(sb02);

        const sb03 = combineLatest([
            this.budget$,
            this.subBudget$,
            this.auth.userHasPermissions(['app.budget.figures.read', 'app.budget.final.read', 'app.budget.write', 'app.iproject.write'])
        ], (budget, subBudget, userPerm) => [budget, subBudget, userPerm])
            .subscribe(([budget, subBudget, userPerm]: [Budget, any, any[]]) => {

                this.vacancyBtn = budget.project && budget.project.internal && userPerm[3];

                this._canEdit = this.userCanEditSubBudget(budget, subBudget, userPerm);
                const hourCol = this._canEdit ? 'hoursNew' : 'hours';
                this.displayedColumns = ['name', 'category', 'cost', hourCol, 'importoCalc', 'weight'];
                this.matDisplayedColumns = ['description', 'category', 'unit', 'quantity', 'costEach', 'amount', 'weight'];
                if (this._canEdit) {
                    this.matDisplayedColumns.push('actions');
                }
                // --------
            });
        this.subBudgetSubscription.add(sb03);

    }

    public addTag(tag) {

        const input = tag.input;
        const value = tag.value;

        // Add our fruit
        if ((value || '').trim()) {
            if (this.subBudgetTags) {
                this.subBudgetTags.push(value.trim());
            } else {
                this.subBudgetTags = [value.trim()];
            }
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.updateTags();

    }

    public removeTag(tag) {
        this.subBudgetTags = this.subBudgetTags.filter(t => t !== tag);
        this.updateTags();
    }

    public toggleCalculate = ($event: MatSlideToggleChange) => {
        this.dataService.updateSubBudgetField(this._budget.id, this.subBudgetId, {
            calculate: $event.checked
        }).then(() => {
        }).catch((err) => {
            console.error('Sub Budget Toggle', err);
        });
    }

    public generateVacancyActivity = () => {
        this.vacancyBtnDisabled = true;
        this.dataService.setSBActivity(this._budget.id, this.subBudgetId, 'VACANCY', {
            activity: 'Assenza',
            category: 'ASS',
            cost: 46,
            name: 'Assenza'
        }).then((data) => {
            this.vacancyBtnDisabled = false;
        }).catch((err) => {
            console.error('Activity ASSENZA error', err);
            this.vacancyBtnDisabled = false;
        });
    }

    public deleteSubBudget = () => {
        if (!this._canEdit) {
            return false;
        }
        this.appState.confirmDialog({confirmMessage: 'SubBudget will be deleted'})
            .then((result) => {
                this.delete.emit(this.subBudgetId);
                console.log('SubBudget deleting: ', this.subBudgetId, this.subBudgetName);
                if (result && result.confirm) {
                    return this.dataService.deleteSubBudget(this._budget.id, this.subBudgetId);
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
            });
        return true;
    }

    public async copySubBudget() {
        const result = await this.dataService.copySubBudget(this._budget.id, this.subBudgetId, GeneratedBy.User);
    }

    public updateName(name: string) {
        if (name.length > 2 && (name !== this.subBudgetName)) {
            this.dataService.patchSubBudget(this._budget.id, this.subBudgetId, {
                description: name
            });
        }
    }

    public ngOnDestroy() {
        // this.budgetEditable$.complete();
        this.budget$.complete();
        if (this.subBudgetSubscription) {
            this.subBudgetSubscription.unsubscribe();
        }
    }

    public openMaterialDialog($event, type, m?: Material) {
        const dialogRef = this.dialog.open(MaterialDialogComponent, {
            width: '350px',
            data: {
                budgetId: this._budget.id,
                subBudgetId: this.subBudgetId,
                type: type,
                material: m
            }
        });
    }

    public deleteMaterial($event, row: Material) {
        this.dataService.deleteMaterial(this._budget.id, this.subBudgetId, row.id)
            .subscribe(() => {
                console.log('Deleted material');
            });
    }

    public commitActivities = (f: NgForm) => {
        if (f.value) {
            this.dataService.commitActivities(this._budget.id, this.subBudgetId, this.avm)
                .subscribe((x) => {
                    console.log('Activities committed');
                });
        }
    }

    private updateTags() {
        this.dataService.updateSubBudgetField(this._budget.id, this.subBudgetId, {
            tags: this.subBudgetTags
        }).then(data => {
            this.tagsUpdated.emit(this.subBudgetTags);
        }).catch((err) => {
            console.error('Sub Budget Tags error ', err);
        });
    }

    private userCanEditSubBudget(budget: Budget, subBudget: SubBudget, userPerm: boolean[]): boolean {

        const budgetStatus = budget.status;
        const overrideEdit = subBudget && subBudget.overrideEdit;
        const overrideLock = subBudget && subBudget.overrideLock;
        if (subBudget && subBudget.description === 'Installation') {
            return false;
        }

        if (this.authorizedToModify) {
            return true;
        }

        switch (budgetStatus) {
            case 'archived':
            case 'new':
                return false;
            case 'assigned':
                if (overrideLock) {
                    return false;
                }
                /* user canEditBudget */
                if (userPerm[2]) {
                    return true;
                }
                break;
            case 'closed':
                if (overrideEdit) {
                    /* projec internal*/
                    if (budget.project && budget.project.internal) {
                        /* user canEditInternalBudget */
                        if (userPerm[3]) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if (userPerm[2]) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
        }

        return false;
    }

}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class ExampleDataSource extends DataSource<any> {

    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<any[]> {
        return this.observable.pipe(map((data: any) => {
            this.resultsLength = data.length;
            return data;
        }));

    }

    disconnect() {
    }

}


@Component({
    selector: 'app-material-dialog',
    template: `
        <div>
            <app-item-material-form (onSubmit)="closeDialog($event)" [forOrderBuild]="data.forOrderBuild"
                                    [budgetId]="data.budgetId" [subBudgetId]="data.subBudgetId" [type]="data.type"
                                    [material]="data.material"
                                    [assignPossibilities]="data.assignPossibilities"></app-item-material-form>
        </div>`
})
export class MaterialDialogComponent implements OnInit {

    public forOrderBuild;

    constructor(
        public dialogRef: MatDialogRef<MaterialDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

    }

    closeDialog(mat: MaterialModel) {
        console.log('closing choose material dialog with: ', mat);
        const returned = mat.toPlainObj();
        if (mat.referencedMaterialId) {
            returned['referencedMaterialId'] = mat.referencedMaterialId;
        }
        this.dialogRef.close(returned);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }


}
