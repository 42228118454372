import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {
    AppErrorDialogComponent,
    AppInfoDialogComponent,
    ConfirmDialogComponent,
    PrivateComponent
} from './private.component';
import {PrivateRoutingModule} from './private-routing.module';
import {MaterialModule} from '../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {BudgetModule} from '../budget';
import {TimereportModule} from '../timereport';
import {AuthGuard} from '../services/auth-guard.service';
import {OrderModule} from '../order/order.module';
import {InvoicesModule} from '../invoices/invoices.module';
import {DdtModule} from '../ddt/ddt.module';
import {UsersModule} from '../users/users.module';
import {StoreModule} from '../store/store.module';
import {HeaderComponent, NotificationsComponent, SidebarListComponent} from '../components';
import {ProjectModule} from '../project/project.module';
import {SharedModule} from '../shared/shared.module';
import {InternalProjectModule} from '../internal-project/internal-project.module';
import {LaserCutModule} from '../laser-cut/laser-cut.module';
import {BalancesModule} from '../balances/balances.module';
import {StatcollectorComponent} from '../statcollector/statcollector.component';
import {StatcollectorListComponent} from '../statcollector/statcollector-list/statcollector-list.component';
import {StatcollectorViewComponent} from '../statcollector/statcollector-view/statcollector-view.component';
import {WorkersSummaryComponent} from '../workers-summary/workers-summary.component';
import {WorkersSummaryListComponent} from '../workers-summary-list/workers-summary-list.component';
import {StatCollectorGuard} from '../statcollector/guards/statcollector.guard';
import {WorkSummaryGuard} from '../workers-summary/guards/worksummary.guard';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        BudgetModule,
        TimereportModule,
        OrderModule,
        InvoicesModule,
        DdtModule,
        UsersModule,
        PrivateRoutingModule,
        ProjectModule,
        InternalProjectModule,
        LaserCutModule,
        BalancesModule,
        StoreModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        PrivateComponent,
        SidebarListComponent,
        HeaderComponent,
        NotificationsComponent,
        AppErrorDialogComponent,
        AppInfoDialogComponent,
        ConfirmDialogComponent,
        StatcollectorComponent,
        StatcollectorListComponent,
        StatcollectorViewComponent,
        WorkersSummaryComponent,
        WorkersSummaryListComponent
    ],
    providers: [
        AuthGuard,
        DatePipe,
        StatCollectorGuard,
        WorkSummaryGuard
    ],
    entryComponents: [
        NotificationsComponent,
        AppErrorDialogComponent,
        AppInfoDialogComponent,
        ConfirmDialogComponent
    ]
})
export class PrivateModule {
}
