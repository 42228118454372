<div class="example-header">
    <mat-form-field>
        <input (keyup)="applyFilter($event.target.value)" [value]="getListFilter()" matInput placeholder="Filter">
    </mat-form-field>
</div>

<div class="example-container mat-elevation-z8">

    <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="projectNr">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Project</mat-header-cell>
            <mat-cell
                    *matCellDef="let row">{{ row.project.promo ? 'p' : '' }}{{ row.project.internal ? 'i' : '' }}{{ row.project.projectNr }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div>{{ row.description }}</div>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="total">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Est. Total Cost</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.project.cost | currency:'EUR' }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="final">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Final Cost</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.costSummary?.finalCostTotal | currency:'EUR' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Date</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.created | timestamp | date:'shortDate' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button (click)="deleteProject(row.id)" mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="goToBudget(row)" *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>

    <mat-paginator [length]="dataSource.resultsLength" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>