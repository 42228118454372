<!-- Budget Details -->
<div *ngIf="budget" class="fxlayout-row-stretch">

    <!-- Description card -->
    <mat-card class="children-equal-3">
        <mat-card-title>
            <ng-container *ngIf="budget.project && budget.project.internal && !budget.project.promo">
                <span class="budget_number">Internal</span>
                <span class="pipe-separator"></span>
            </ng-container>
            <ng-container *ngIf="budget.project && budget.project.promo">
                <span class="budget_number">Promo</span>
                <span class="pipe-separator"></span>
            </ng-container>
            <ng-container *ngIf="budget.project">
                <span class="budget_number">{{ budget.project.projectNr }}</span>
                <span class="pipe-separator"></span>
            </ng-container>
            <span class="budget_number">{{ budget.budgetNr }}</span>
            <span class="pipe-separator"></span>
            <span class="opp_name">{{ budget.prosperworks?.name }}</span>
            <span class="pipe-separator"></span>
            <span class="opp_company_name">{{ budget.prosperworks?.company_name }}</span>
            <span class="pipe-separator"></span>
            <a [href]="budgetFolderLink" class="driveFolderLink" i18n target="_blank">Go to Folder</a>
        </mat-card-title>
        <mat-card-subtitle>
            <span class="opp_detail">{{ budget.description }}</span>
            <span class="opp_detail">{{ budget.prosperworks?.detail }}</span>
            <span></span>
        </mat-card-subtitle>
        <mat-card-content>
            <div class="definition-list-container">
                <dl>
                    <ng-container *ngIf="budget.assignee && budget.assignee.on">
                        <dt i18n>Assigned on</dt>
                        <dd>{{ budget.assignee?.on | timestamp | date }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.deliveryThr">
                        <dt i18n>To be delivered</dt>
                        <dd>{{ budget.deliveryThr | timestamp | date }}</dd>
                    </ng-container>
                    <ng-container *ngIf="canOpenProject">
                        <dt i18n>CosmoId</dt>
                        <dd>{{ budget.cosmoId?.cosmoId }}</dd>
                    </ng-container>
                </dl>
            </div>
            <div class="definition-list-container">
                <dl>
                    <ng-container *ngIf="budget.author">
                        <dt i18n>Author</dt>
                        <dd>{{ budget.author?.displayName }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.creation">
                        <dt i18n>Created on</dt>
                        <dd>{{ budget.creation|timestamp | date }}</dd>
                    </ng-container>
                </dl>
            </div>
            <div class="definition-list-container">
                <dl>
                    <ng-container *ngIf="budget.offerDate">
                        <dt i18n>Offer Date</dt>
                        <dd>{{ budget.offerDate |timestamp | date }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.budgetCostTotal">
                        <dt i18n>Cost tot.</dt>
                        <dd>{{ budget.budgetCostTotal| currency:budget.export ? 'USD' : 'EUR' }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.budgetHoursTotal">
                        <dt i18n>Hours tot.</dt>
                        <dd>{{ budget.budgetHoursTotal| currency:budget.export ? 'USD' : 'EUR' }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.budgetMaterialsTotal">
                        <dt i18n>Material tot.</dt>
                        <dd>{{ budget.budgetMaterialsTotal| currency:budget.export ? 'USD' : 'EUR' }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.project?.projectManager">
                        <dt i18n>Project Manager</dt>
                        <dd>{{ budget.project?.projectManager.displayName }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.ref">
                        <dt i18n>Ref</dt>
                        <dd>{{ budget.ref }}</dd>
                    </ng-container>
                    <ng-container *ngIf="budget.salesManager">
                        <dt i18n>Sales Manager</dt>
                        <dd>{{ budget.salesManager }}</dd>
                    </ng-container>
                    <ng-container *ngIf="exportBudget">
                        <dt i18n>Export</dt>
                        <dd><a [routerLink]="['/budget',exportBudget.id ]">{{ exportBudget.budgetNr }}</a></dd>
                    </ng-container>
                    <ng-container *ngIf="budget.export && budget.export.from">
                        <dt i18n>Import</dt>
                        <dd>
                            <a [routerLink]="['/budget',budget.export.from.id ]">{{ budget.export.from.budgetNr }}</a>
                        </dd>
                    </ng-container>
                </dl>

            </div>
            <div class="offer-block">
                <div *ngIf="this.poCreating">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div *ngIf="this.offerUrl">
                    <span class="inline-label" i18n>Offer</span>
                    <span>
            <a [href]="this.offerUrl" target="_blank">{{ this.offerUrl }}</a>
          </span>
                </div>
                <div *ngIf="exportBudget && exportBudget.offerUrl">
                    <span class="inline-label" i18n>Export Offer</span>
                    <span>
            <a [href]="exportBudget.offerUrl" target="_blank">{{ exportBudget.offerUrl }}</a>
          </span>
                </div>
            </div>
            <div class="button-row">
                <button (click)="deleteBudget()"
                        *ngIf="canDeleteBudget && budget.status && ['new', 'assigned'].includes(budget.status)"
                        color="warn" i18n mat-raised-button>
                    Delete budget
                </button>
                <button (click)="openAssignDialog()" *ngIf="canAssignBudget && budget.status && budget.status === 'new'"
                        i18n mat-raised-button>
                    Assign
                </button>
                <button (click)="reopenAssignDialog()"
                        *ngIf="canAssignBudget && budget.status && !['new', 'archived'].includes(budget.status) " i18n mat-raised-button>
                    Re-Assign
                </button>
                <button *ngIf="isEditable && canPrepareOffer && !budget.export" [disabled]="this.poCreating"
                        [matMenuTriggerFor]="offerMenu" i18n mat-raised-button>
                    Prepare Offer
                </button>
                <mat-menu #offerMenu="matMenu">
                    <button (click)="prepareOffer(t)" *ngFor="let t of offerTemplates" mat-menu-item>{{ t.name }}
                    </button>
                </mat-menu>
                <button (click)="getReportFile()" *ngIf="canReceiveReport && budget.status && budget.status === 'archived'"
                        i18n mat-raised-button>
                    Send me Report file
                </button>
                <button (click)="openOfferWizard()" *ngIf="false" [disabled]="this.poCreating" i18n mat-raised-button>
                    Create offer document
                </button>
                <button *ngIf="exportBudget && exportBudget.status != 'closed' && offerExportTemplates"
                        [disabled]="this.poCreating"
                        [matMenuTriggerFor]="offerExportMenu" i18n mat-raised-button>
                    Export Offer
                </button>
                <mat-menu #offerExportMenu="matMenu">
                    <button (click)="prepareExportOffer(t)" *ngFor="let t of offerExportTemplates"
                            mat-menu-item>
                        {{ t.name }}
                    </button>
                </mat-menu>
                <button (click)="openProject()"
                        *ngIf="budget.status !== 'new' && budget.status !== 'archived' && canOpenProject && isEditable" [disabled]="this.poCreating" i18n mat-raised-button>
                    Open Project
                </button>
                <button (click)="reOpenProject()" *ngIf="budget.status === 'archived' && canReOpenProject"
                        [disabled]="this.poCreating"
                        i18n mat-raised-button>
                    Reopen Project
                </button>
                <button (click)="archive()" *ngIf="budget.status !== 'archived' && canArchiveProject" [disabled]="this.poCreating"
                        i18n mat-raised-button>
                    Archive Project
                </button>
                <button (click)="importFromSheet()" *ngIf="false" [disabled]="importingBoM" i18n mat-raised-button>
                    Import BoM
                </button>
                <button (click)="createExportBudget()" *ngIf="createExportBudgetBtn" i18n mat-raised-button>
                    Generate Export
                </button>
                <button (click)="removeExportLink()" *ngIf="removeExportLinkBtn" i18n mat-raised-button>
                    Remove export link
                </button>

                <mat-form-field style="margin-left:10px;">
                    <mat-select (selectionChange)="filterChange()" [(ngModel)]="tagFilter" multiple
                                placeholder="Filter">
                        <mat-option *ngFor="let tag of tags$ | async" [value]="tag">{{ tag }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="!budget.status">
                <p class="warn-message" i18n>
                    This budget cannot be edited because his status is unknown. Please assign a status to this budget
                    using the
                    "Change Status" button
                </p>
            </div>

            <!-- buttons for modify open project -->
            <div *ngIf="budget.status === 'closed'">

                <div *ngIf="canGrantProjectAuth">
                    <a (click)="ackBudgetModifyAuth(true)" *ngIf="budget.authorizedModify === 'waiting'"
                       mat-raised-button>
                        <span i18n>Authorize Modifications</span>
                    </a>
                    <a (click)="ackBudgetModifyAuth(false)"
                       *ngIf="budget.authorizedModify === 'waiting' || budget.authorizedModify === 'yes'"
                       mat-raised-button>
                        <span i18n>Deny Modifications</span>
                    </a>
                    <span *ngIf="budget.authorizedModify === 'yes'" class="modify-warning">
            Modifications have been authorized
          </span>
                </div>

                <div *ngIf="canAskProjectAuth">
                    <a (click)="askBudgetModifyAuth()" *ngIf="!budget.authorizedModify" mat-raised-button>
                        <span i18n>Ask modify permission</span>
                    </a>
                    <a (click)="doneModifications()" *ngIf="budget.authorizedModify === 'yes'" mat-raised-button>
                        <span i18n>Reset modification permissions</span>
                    </a>
                    <span *ngIf="budget.authorizedModify === 'yes'" class="modify-warning">
            Modifications have been authorized
          </span>
                    <span *ngIf="budget.authorizedModify === 'waiting'" class="custom-inline-icon">
            <mat-icon title="waiting for authorization">access_time</mat-icon>
            Auth for modifications to this budget have been requested.
          </span>
                    <span *ngIf="budget.authorizedModify === 'no'">
            Auth for modifications to this budget have been denied.
          </span>
                </div>

            </div>


        </mat-card-content>
    </mat-card>


    <!-- Costs card -->
    <mat-card *ngIf="canSeeFigures && !budget.export" class="children-equal-3 total-summary-card pt-0">

        <div>
            <h4>Costs:</h4>
            <table [dataSource]="costsTableData" class="mat-elevation-z0 full-width" mat-table>
                <ng-container matColumnDef="label">
                    <td *matCellDef="let element" mat-cell>
                        {{ element.label }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="value">
                    <td *matCellDef="let element" mat-cell>
                        {{ element.value | currency:element.currency:'symbol':'1.0-0' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <td *matCellDef="let element" mat-cell>
                        <mat-icon class="info-icon" matTooltip="{{element.description}}">info</mat-icon>

                        <!-- For changing finalOffer on the budget -->
                        <mat-icon (click)="changeFinalOffer(false)" *ngIf="(canAskProjectAuth || canGrantProjectAuth) && budget.status === 'closed' && element.label ===
              'Final Offer ITA'" class="icons" mat-raised-button>
                            edit
                        </mat-icon>

                    </td>
                </ng-container>
                <tr *matRowDef="let row; columns: costsTablesColumns;" mat-row></tr>
            </table>
        </div>

        <div *ngIf="showExport && exportBudget">
            <h4>USA costs:</h4>
            <table [dataSource]="costsTableDataExport" class="mat-elevation-z0 full-width" mat-table>
                <ng-container matColumnDef="label">
                    <td *matCellDef="let element" mat-cell>
                        {{ element.label }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="value">
                    <td *matCellDef="let element" mat-cell>
                        {{ element.value | currency:element.currency:'symbol':'1.0-0' }}
                        <span *ngIf="element.valueCurrency">
              &nbsp;&nbsp;({{ element.valueCurrency | currency:'EUR':'symbol':'1.0-0' }})
            </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <td *matCellDef="let element" mat-cell>
                        <mat-icon class="info-icon" matTooltip="{{element.description}}">info
                        </mat-icon>

                        <!-- For changing finalOffer on the budget -->
                        <mat-icon (click)="changeFinalOffer(true)"
                                  *ngIf="(canAskProjectAuth || canGrantProjectAuth) && exportBudget.status === 'closed' && element.label === 'Final Offer USA'"
                                  class="icons" mat-raised-button>
                            edit
                        </mat-icon>
                    </td>
                </ng-container>
                <tr *matRowDef="let row; columns: costsTablesColumns;" mat-row></tr>
            </table>
        </div>

        <!-- payment status  & button -->
        <div class="row-distance">
            <div class="row-centered">
                <h4>Payment status:&nbsp;</h4>
                <span *ngIf="!budget.paymentStatus">
<!--          <mat-progress-spinner mode="indeterminate" [diameter]="30" class="spinner-progress"></mat-progress-spinner>-->
        </span>
                <span *ngIf="budget.paymentStatus">&nbsp;&nbsp;{{ budget.paymentStatus?.completion }}
                    %&nbsp;&nbsp;</span>
                <span *ngIf="budget.project && budget.prosperworks && !budget.project.internal && budget.paymentStatus && budget.paymentStatus.errors.length > 0 && cosmoEnabled" class="errorMessage"
                      i18n>
          {{ budget.paymentStatus.errors }}
        </span>
            </div>
            <mat-slide-toggle (change)="applyShowExport($event.checked)" *ngIf="exportBudget" [checked]="showExport">
                Show/hide
                USA
            </mat-slide-toggle>
        </div>


        <button (click)="refreshCostSummary()" [disabled]="refreshingCostSummary" class="refresh-button" mat-mini-fab>
            <mat-icon>refresh</mat-icon>
        </button>
    </mat-card>


    <!-- graph card -->
    <mat-card class="children-equal-3 chart-summary-card">
        <app-summary-chart [data]="subBudgetValuesForChart"></app-summary-chart>
    </mat-card>

</div>


<!-- Tabs for resumes and sub-budgets -->
<mat-tab-group #subBudgetsTabs *ngIf="budget" [selectedIndex]="selectedTab">
    <mat-tab *ngIf="canSeeFigures && budget" label="Summary">
        <ng-template mat-tab-label>
            <b i18n>Summary</b>
        </ng-template>
        <div class="fxlayout-row">
            <mat-card style="flex:1 1 15%">
                <app-margin-form [authorizedToModify]="canAskProjectAuth && canModifyProject" [budget]="budget"
                                 [kind]="importBudget ? 'USA' : 'ITA'">
                </app-margin-form>
            </mat-card>
            <mat-card *ngIf="!budget.export" style="flex:1 1 15%">
                <app-assemblyCosts-form
                        (updateCostSummary)="refreshCostSummary()"
                        [authorizedToModify]="canAskProjectAuth && canModifyProject"
                        [budget]="budget">
                </app-assemblyCosts-form>
            </mat-card>
            <mat-card style="flex:3 1 55%">
                <app-splitted-costs-table [isExportBudget]="!!importBudget"
                                          [summary]="importBudget ? splittedCostsTableDataExport : splittedCostsTableDataIta">
                </app-splitted-costs-table>
            </mat-card>
        </div>
    </mat-tab>
    <mat-tab *ngIf="showExport && exportBudget && canSeeFigures  && budget" label="Summary USA">
        <ng-template mat-tab-label>
            <b i18n style="color: rgb(237, 49, 154);">Summary USA</b>
        </ng-template>
        <div class="fxlayout-row">
            <mat-card class="children-small-3">
                <app-margin-form [authorizedToModify]="canAskProjectAuth && canModifyProject" [budget]="exportBudget"
                                 [kind]="'USA'">
                </app-margin-form>
            </mat-card>
            <mat-card class="children-small-3">
                <app-assemblyCosts-form (updateCostSummary)="refreshCostSummary()"
                                        [authorizedToModify]="canAskProjectAuth && canModifyProject"
                                        [budget]="exportBudget">
                </app-assemblyCosts-form>
            </mat-card>
            <mat-card class="children-small-3">
                <app-export-form (changeSelectedTab)="changeTab($event)" (updateCostSummary)="refreshCostSummary()"
                                 [authorizedToModify]="canAskProjectAuth && canModifyProject"
                                 [budget]="exportBudget"
                                 [splittedCostsIta]="splittedCostsTableDataIta">
                </app-export-form>
            </mat-card>
            <mat-card class="children-usa-4">
                <app-splitted-costs-table [isExportBudget]="true"
                                          [summary]="splittedCostsTableDataExport"></app-splitted-costs-table>
            </mat-card>
        </div>
    </mat-tab>
    <!-- subBudget resume -->
    <mat-tab [label]="'Resume'">
        <ng-template mat-tab-label>
      <span [class]="'custom-inline-icon'">
        Resume&nbsp;
        <mat-icon>timer</mat-icon>
      </span>
        </ng-template>
        <app-sub-budget-resume [budgetId]="this.budget.id" [budgetTotal]="budget?.costSummary?.total" [budget]="budget"
                               [resume]="resume" [subBudgetSummary$]="subBudgetSummary$"
                               [subBudgets]="sbCollNameMap"
                               [tabSelector]="selectedTab"></app-sub-budget-resume>
    </mat-tab>

    <!-- subbudgets -->

    <ng-container *ngFor="let sb of sbColl | async; index as i">
        <mat-tab *ngIf="checkFilters(sb.tags)" [label]="sb.description">
            <ng-template mat-tab-label>
        <span [class]="sb.calculate? 'custom-inline-icon' :'custom-inline-icon mat-label-disabled'">
          {{ sb.description }}&nbsp;
          <mat-icon *ngIf="sb.overrideEdit">whatshot</mat-icon>
        </span>
            </ng-template>
            <app-sub-budget (delete)="deleteSubBudget($event)" (tagsUpdated)="toggleCalculate($event,sb)" (update)="updateSubBudgetValues($event, sb.id)"
                            [authorizedToModify]="canAskProjectAuth && canModifyProject" [budgetTotal]="budget?.costSummary?.total"
                            [budget]="budget" [resume]="resume"
                            [subBudgetId]="sb.id"></app-sub-budget>
        </mat-tab>
    </ng-container>

    <!-- end subbudgets -->

    <!-- export subbudgets -->

    <ng-container *ngIf="showExport && exportBudget">
    <span *ngFor="let sb of sbCollExport | async; index as i">
      <mat-tab *ngIf="checkFilters(sb.tags)" [label]="sb.description">
        <ng-template mat-tab-label>
          <span [class]="sb.calculate? 'custom-inline-icon' :'custom-inline-icon mat-label-disabled'">
            <b style="color: rgb(237, 49, 154);">{{ sb.description }}</b>&nbsp;
            <mat-icon *ngIf="sb.overrideEdit">whatshot</mat-icon>
          </span>
        </ng-template>
        <app-sub-budget (delete)="deleteSubBudget($event)" (tagsUpdated)="toggleCalculate($event,sb)" [authorizedToModify]="canAskProjectAuth && canModifyProject"
                        [budgetTotal]="exportBudget?.costSummary?.total" [budget]="exportBudget"
                        [subBudgetId]="sb.id"></app-sub-budget>
      </mat-tab>
    </span>
    </ng-container>

    <!-- end export subbudgets -->

    <!-- Add a new subBudget -->

    <mat-tab *ngIf=" !budget.export && canAddSubBudget && this.budget.status !=='archived'" label=" Add New...">
        <mat-card>
            <app-sub-budget-form (onSubmit)="addSubBudget($event)"></app-sub-budget-form>
        </mat-card>
    </mat-tab>

    <!-- end Add a new subBudget -->

</mat-tab-group>


<div *ngIf="!budget" class="fxlayout-column">
    <mat-progress-spinner class="fxlayout-center-column"></mat-progress-spinner>
</div>
