import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Budget, GeneratedBy} from '../../interfaces';
import {BudgetModel} from '../../models/budget-model';
import {AuthService} from '../../../services/auth.service';
import * as firebase from 'firebase';



@Component({
    selector: 'app-assemblyCosts-form',
    templateUrl: './assemblyCosts-form.component.html',
    styleUrls: ['./assemblyCosts-form.component.scss']
})
export class AssemblyCostsFormComponent implements OnInit {

    @Output() updateCostSummary = new EventEmitter<boolean>();
    public assemblyCostsForm: FormGroup;

    constructor(private dataService: DataService, private fb: FormBuilder, private auth: AuthService) {
    }

    private _budget: Budget;

    @Input() set budget(b: Budget) {
        this._budget = b;
        if (this.assemblyCostsForm) {
            if (!BudgetModel.isEditable(this._budget, this.auth.currentUser) && !this.authorizedToModify) {
                this.assemblyCostsForm.disable();
            } else {
                this.assemblyCostsForm.enable();
            }
        }
    }

    public _authorizedToModify = false;

    public get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input()
    public set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
    }

    ngOnInit() {

        // Definition of the assemblyCosts Form
        // build the form model
        this.assemblyCostsForm = this.fb.group({
            employee: [this._budget.assemblyCosts ? this._budget.assemblyCosts.employee : 0, [Validators.min(0), this.isInteger]],
            projectManager: [this._budget.assemblyCosts ? this._budget.assemblyCosts.projectManager : 0, [Validators.min(0), this.isInteger]],
            employeeDays: [this._budget.assemblyCosts ? this._budget.assemblyCosts.employeeDays : 0, Validators.min(0)],
            projectManagerDays: [this._budget.assemblyCosts ? this._budget.assemblyCosts.projectManagerDays : 0, Validators.min(0)],
            employeeCost: [this._budget.assemblyCosts ? this._budget.assemblyCosts.employeeCost : 0, Validators.min(0)],
            projectMangerCost: [this._budget.assemblyCosts ? this._budget.assemblyCosts.projectMangerCost : 0, Validators.min(0)],
            travelExpensesAccomodation:
                [this._budget.assemblyCosts ? this._budget.assemblyCosts.travelExpensesAccomodation : 0, Validators.min(0)],
            travelExpensesFlights: [this._budget.assemblyCosts ? this._budget.assemblyCosts.travelExpensesFlights : 0, Validators.min(0)],
            renting: [this._budget.assemblyCosts ? this._budget.assemblyCosts.renting : 0, Validators.min(0)],
            externalManpower: [this._budget.assemblyCosts ? this._budget.assemblyCosts.externalManpower : 0, Validators.min(0)],
            other: [this._budget.assemblyCosts ? this._budget.assemblyCosts.other : 0, Validators.min(0)]
        });

        if (!BudgetModel.isEditable(this._budget, this.auth.currentUser) && !this.authorizedToModify) {
            this.assemblyCostsForm.disable();
        } else {
            this.assemblyCostsForm.enable();
        }

    }

    isInteger = (control: FormControl) => {
        const val = control.value;
        if (Number.isInteger(val)) {
            return null;
        } else {
            return {
                isNotInteger: {value: val}
            };
        }
    }

    submit() {
        const ac = {
            employee: this.assemblyCostsForm.value.employee,
            projectManager: this.assemblyCostsForm.value.projectManager,
            employeeDays: this.assemblyCostsForm.value.employeeDays,
            projectManagerDays: this.assemblyCostsForm.value.projectManagerDays,
            employeeCost: this.assemblyCostsForm.value.employeeCost,
            projectMangerCost: this.assemblyCostsForm.value.projectMangerCost,
            travelExpensesAccomodation: this.assemblyCostsForm.value.travelExpensesAccomodation,
            travelExpensesFlights: this.assemblyCostsForm.value.travelExpensesFlights,
            renting: this.assemblyCostsForm.value.renting,
            externalManpower: this.assemblyCostsForm.value.externalManpower,
            other: this.assemblyCostsForm.value.other
        };

        this.dataService.patchBudget(this._budget.id, {
            assemblyCosts: ac
        })
            .then(() => {
                // GENERATE NEW ACTIVITY OR UPDATE
                return this.dataService.updateSubBudget(this._budget.id, 'ASSEMBLY', {
                    description: 'Installation',
                    id: 'ASSEMBLY',
                    overrideLock: true,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    deleted: null,
                    active: true,
                    createdBy: undefined, // This will be filled by dataservice
                    generatedBy: GeneratedBy.Assembly,
                    calculate: true
                });
            })
            .then(() => {
                return this.dataService.setSBActivity(this._budget.id, 'ASSEMBLY', 'projectManager', {
                    activity: 'Project Manager Installation',
                    category: 'INS',
                    hours: ac.projectManager * ac.projectManagerDays * 8,
                    cost: ac.projectMangerCost / 8
                });
            })
            .then(() => {
                return this.dataService.setSBActivity(this._budget.id, 'ASSEMBLY', 'employee', {
                    activity: 'Employee Installation',
                    category: 'INS',
                    hours: ac.employee * ac.employeeDays * 8,
                    cost: ac.employeeCost / 8
                });
            })
            .then(() => {
                return this.dataService.setSBMaterial(this._budget.id, 'ASSEMBLY', 'travelExpenses', {
                    description: 'Travel Expenses Accomodation',
                    category: 'INS',
                    costEach: ac.travelExpensesAccomodation,
                    quantity: 1,
                    unit: 'pieces'
                });
            })
            .then(() => {
                return this.dataService.setSBMaterial(this._budget.id, 'ASSEMBLY', 'flights', {
                    description: 'Travel Expenses Flights',
                    category: 'INS',
                    costEach: ac.travelExpensesFlights,
                    quantity: 1,
                    unit: 'pieces'
                });
            })
            .then(() => {
                return this.dataService.setSBMaterial(this._budget.id, 'ASSEMBLY', 'externalManpower', {
                    description: 'External Manpower',
                    category: 'INS',
                    costEach: ac.externalManpower,
                    quantity: 1,
                    unit: 'pieces'
                });
            })
            .then(() => {
                return this.dataService.setSBMaterial(this._budget.id, 'ASSEMBLY', 'renting', {
                    description: 'Renting',
                    category: 'INS',
                    costEach: ac.renting,
                    quantity: 1,
                    unit: 'pieces'
                });
            })
            .then(() => {
                return this.dataService.setSBMaterial(this._budget.id, 'ASSEMBLY', 'other', {
                    description: 'Other',
                    category: 'INS',
                    costEach: ac.other,
                    quantity: 1,
                    unit: 'pieces'
                });
            })
            .then(() => {
                console.log('Assembly costs - All updated', this.assemblyCostsForm);
                this.updateCostSummary.emit(true);
            });
    }

}

