import {combineLatest, interval, Observable, Subject} from 'rxjs';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {DataService} from '../services/data.service';
import {Budget} from '../budget/interfaces';



@Component({
    selector: 'app-workers-summary-list',
    templateUrl: './workers-summary-list.component.html',
    styleUrls: ['./workers-summary-list.component.scss']
})
export class WorkersSummaryListComponent implements OnInit {

    @Input()
    set timeInterval(value: number) {
        if (value) {
            this._timeInterval = value;
        }
    }

    constructor(private dataService: DataService, private _changeDetectionRef: ChangeDetectorRef) {
    }

    public projects$: Observable<Partial<Budget>[]>;

    public resumes: any[] = [];

    public displayedResumes: any[] = [];

    public displayedColumns = ['ppm', 'comm', 'ces', 'form', 'pieg', 'laser', 'asm', 'mon', 'trasp', 'mic', 'pul', 'contr'];

    private unsubscribeInterval$ = new Subject();
    private unsubscribe$ = new Subject();

    @Input()
    public canChange = false;

    private _timeInterval = 5000;

    private fullScreenActive = false;

    ngOnInit() {

        this.projects$ = this.dataService.getBudgetsForWorkersList();
        this.projects$.pipe(takeUntil(this.unsubscribe$)).subscribe((projects: any[]) => {

            const obs = [];

            for (const project of projects) {

                obs.push(this.dataService.getSubBudgetResume(project.id).pipe(
                    map((summary: any) => {
                        console.log('returned summary: ', project.id, project.description, summary);

                        const budgetReport = {};
                        const finalReport = {};
                        for (const act of summary.activities) {
                            const key = act.name ? act.name : act.activity;
                            budgetReport[key] = {value: act.hours, cssClass: 'budget-hour'};
                            finalReport[key] = {
                                value: act.finalHours,
                                cssClass: act.finalHours > act.hours ? 'red' : 'green'
                            };
                        }
                        summary.budgetId = project.id;
                        summary.budgetReport = budgetReport;
                        summary.finalReport = finalReport;
                        summary.report = [summary.budgetReport, summary.finalReport];
                        summary.projectNr = project.project.projectNr;
                        summary.budgetNr = project.budgetNr;
                        summary.description = project.description ? project.description : project.prosperworks ? project.prosperworks.name : '';
                        summary.client = project.prosperworks ? project.prosperworks.company_name : '';

                        return summary;
                    })));
            }

            combineLatest(obs).pipe(takeUntil(this.unsubscribe$)).subscribe((resumes) => {
                console.log('got resumes:', resumes);
                this.resumes = resumes;
                this.displayedResumes = this.resumes.slice();
            }, err => {
                console.error(err);
            });

            return projects;
        });

        document.addEventListener('fullscreenchange', this.fullScreenHandler, false);
        document.addEventListener('mozfullscreenchange', this.fullScreenHandler, false);
        document.addEventListener('MSFullscreenChange', this.fullScreenHandler, false);
        document.addEventListener('webkitfullscreenchange', this.fullScreenHandler, false);
    }

    public fullScreenHandler = () => {
        console.log('fullScreenHandler!! ', this.fullScreenActive);
        this.fullScreenActive = !this.fullScreenActive;

        if (!this.fullScreenActive) {
            console.log('We are not in full screen, removing interval if exists.. ');
            this.unsubscribeInterval$.next(true);
            this.displayedResumes = this.resumes.slice();
        }
    }

    rotate() {

        if (this.displayedResumes.length > 3) {
            this.displayedResumes.push(this.displayedResumes.shift());
            this.displayedResumes.push(this.displayedResumes.shift());
            this._changeDetectionRef.detectChanges();
        }

    }

    goFullscreen() {

        interval(this._timeInterval).pipe(takeUntil(this.unsubscribeInterval$)).subscribe(() => {
            this.rotate();
        });

        const element = document.getElementById('container');
        if (element && document.fullscreenEnabled) {
            element.requestFullscreen();
        }
    }

    removeItem(id: string) {
        console.log('removing budget:', id);
        this.dataService.deselectForWorkersList(id).then(res => {
            this.resumes = [];
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.unsubscribeInterval$.next();
        this.unsubscribeInterval$.complete();
    }
}
