<div [formGroup]="item" class="order-entry-container">
    <div class="order-entry-form">
        <div class="oder-entry-form-line" class="fxlayout-row-order-item">
            <input formControlName="id" type="hidden">
            <mat-form-field class="children-equal-1">
                <input [matAutocomplete]="autoBudget" formControlName="budget" matInput placeholder="Project"
                       type="text">
                <mat-autocomplete #autoBudget="matAutocomplete" (optionSelected)="budgetChange($event)"
                                  [displayWith]="budgetDisplayFn">
                    <mat-option *ngFor="let budget of filteredBudgets | async" [value]="budget">
                        <span *ngIf="budget.project">{{ budget | projectNr }}-</span>{{ budget.budgetNr }}
                        <small *ngIf="budget.companyName">{{ budget.companyName }}</small>
                        <small
                                *ngIf="budget.prosperworks && budget.prosperworks.company_name">{{ budget.prosperworks.company_name }}</small>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="oder-entry-form-line" class="fxlayout-row-order-item">
            <mat-icon *ngIf="item.get('material').value?.isOnlyFinal" style="color:#19b059"
                      title="Custom defined material">
                new_releases
            </mat-icon>

            <mat-form-field style="flex-grow:5; flex-basis: 50%;">
                <mat-select (selectionChange)="onMaterialSelected($event.value)" [compareWith]="compareMaterial" formControlName="material"
                            i18n-placeholder placeholder="Choose Material">
                    <mat-option *ngFor="let material of materials$ | async" [value]="material">
                        {{ material.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <span style="margin-left:10px; margin-right: 5px;">
        or create new material:
      </span>

            <div>
                <button (click)="addNewMaterial()" mat-mini-fab style="background-color: cadetblue;" title="Include new material"
                        type="button">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>


        <div class="oder-entry-form-line" class="fxlayout-row-order-item-2">
            <mat-form-field>
                <input [disabled]="true" [value]="item.get('material').value ? item.get('material').value.description : ''" class="form-control" i18n-placeholder
                       matInput
                       placeholder="Ordered material"
                       type="text">
            </mat-form-field>
            <mat-form-field>
                <input [disabled]="true" [value]="item.get('material').value ? getRefItemDescr(item.get('material').value.referencedMaterialId) : ''" class="form-control" i18n-placeholder
                       matInput
                       placeholder="Referenced material" type="text">
            </mat-form-field>
            <mat-form-field>
                <input [attr.id]="'description'+index" class="form-control" formControlName="code" i18n-placeholder matInput
                       placeholder="Code" type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-select formControlName="unit" i18n-placeholder name="unit" placeholder="Unit">
                    <mat-option *ngFor="let u of unities" [value]="u">
                        {{ u }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input [attr.id]="'quantity'+index" class="form-control" formControlName="quantity" i18n-placeholder matInput
                       placeholder="Quantity" type="number">
            </mat-form-field>
            <mat-form-field>
                <input [attr.id]="'costEach'+index" class="form-control" formControlName="costEach" i18n-placeholder
                       matInput
                       placeholder="Price per unit" type="number">
            </mat-form-field>
            <mat-form-field>
                <input [attr.id]="'discount'+index" class="form-control" formControlName="discount" i18n-placeholder
                       matInput
                       placeholder="Discount (%)" type="number">
            </mat-form-field>
            <div>
                <span class="entry-total-value">{{ total | currency:currency }}</span>
            </div>
        </div>


        <div class="oder-entry-form-line" class="fxlayout-row-order-item">
            <mat-form-field appearance="outline" class="children-equal-1">
        <textarea [attr.id]="'description'+index" class="form-control" formControlName="description" i18n-placeholder
                  matAutosizeMaxRows="10" matAutosizeMinRows="3" matInput
                  matTextareaAutosize placeholder="Item description"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="order-entry-remove-button">
        <button (click)="removed.emit(index)" [disabled]="deletionBlocked" class="btn" mat-button type="button">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
