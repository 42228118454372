import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, Validators} from '@angular/forms';
import {OrderEntryComponent} from './order-entry.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatAccordion} from '@angular/material/expansion';



@Component({
    selector: 'app-order-entry-array',
    templateUrl: './order-entry-array.component.html',
    styleUrls: ['./order-entry-array.component.scss']
})
export class OrderEntryArrayComponent implements OnInit, OnDestroy {


    @ViewChild('accordion', {static: true}) accordion: MatAccordion;
    public total$: Observable<number>;
    @Input()
    public currency: string;
    @Input()
    public itemsFormArray: FormArray;

    constructor() {
    }

    static buildItems(items?) {
        if (!items) {
            return new FormArray([
                OrderEntryComponent.buildItem()
            ], Validators.required);
        } else {
            const entryItems = [];
            items.forEach(element => {
                entryItems.push(OrderEntryComponent.buildItem(element));
            });
            return new FormArray(entryItems, Validators.required);
        }
    }

    ngOnInit() {
        this.total$ = this.itemsFormArray.valueChanges.pipe(
            map((entryArray) => {
                return entryArray
                    .map((item) => OrderEntryComponent.calculateEntryValue(item.costEach, item.quantity, item.discount))
                    .reduce((a, b) => a + b, 0);
            }));
        if (this.accordion) this.accordion.openAll();
    }

    ngOnDestroy() {

    }

    addItem(item?) {
        this.itemsFormArray.push(OrderEntryComponent.buildItem(item));
    }

    removeItem(item) {
        console.log('removing item:', item)
        this.itemsFormArray.removeAt(item)
    }


}


