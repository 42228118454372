import {first, map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {combineLatest} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {Invoice} from '@wondersys/wonderbudget-lib';
import * as moment from 'moment';



const guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

@Component({
    selector: 'app-superadmin',
    templateUrl: './superadmin.component.html',
    styleUrls: ['./superadmin.component.scss']
})
export class SuperadminComponent implements OnInit {

    public updatedInvoices = [];
    public ordersToFix = [];
    public invoiceList = [];
    public userRole;
    public invoiceItemsWithoutIds = [];
    public invoiceWithIds: Invoice[] = [];

    disableInvoiceFix = false;
    public unmatchedInvoices = [];

    constructor(
        private dataService: DataService,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.userRole = this.authService.userStream$.pipe(map(user => user.role));
    }

    /**
     * Find all the invoices that are bound to an order where there is no reference for this invocie in the order
     */

    // FATTURA -> RICAVARE ORDINE
    // PRENDI ORDINE E SCORRI TUTTI GLI ITEM DENTRO L'ORDINE
    // IN ALMENO UN ITEM DELL'ORDINE DEVE COMPARIRE LA FATTURA
    public async matchBrokenInvoices() {
        this.unmatchedInvoices = [];

        const invoicesRef = await this.dataService.getInvoicesListOnce().pipe(first()).toPromise()
        const invoices = invoicesRef.docs.map(doc => {
            const data = doc.data();
            const id = doc.id;
            return {id, ...data};
        });
        console.log('total invoices:', invoices.length);

        const ordersRef = await this.dataService.getOrderListOnce().pipe(first()).toPromise()
        const orders = ordersRef.docs.map(doc => {
            const data = doc.data();
            const id = doc.id;
            return {id, ...data};
        });
        console.log('total orders:', orders.length);

        for (const invDoc of invoices) {

            if (invDoc.order.id) {
                const order = orders.find(o => o.id === invDoc.order.id)
                if (!order) return // skip it

                const noItemHasThisInvoice = order.items === null || order.items === undefined || order.items.every(
                    it => it.invoices === null || it.invoices === undefined || it.invoices.every(i => i.invoiceId !== invDoc.id)
                );

                if (noItemHasThisInvoice) {
                    // Add the DB id to the invoice (is it useful?)
                    invDoc.id = invDoc.id;
                    const matchingItems = this.getMatchingItems(invDoc.items, order.items);
                    if (matchingItems.length > 0) {
                        this.unmatchedInvoices.push({
                            invoice: {
                                id: invDoc.id,
                                items: invDoc.items,
                                invoiceDate: invDoc.invoiceDate,
                                invoiceDateText: moment((<any>invDoc.invoiceDate).toDate()).format('YYYY-MM-DD'),
                                invoiceNr: invDoc.invoiceNr,
                                materialAmount: invDoc.totalAmount
                            },
                            order: {
                                id: invDoc.order.id,
                                items: order.items
                            },
                            matchingItems: matchingItems
                        })

                        console.log(`-------------(${this.unmatchedInvoices.length}) UNMATCHED INVOICES`);
                        this.unmatchedInvoices.sort((a, b) => a.invoice.invoiceDate > b.invoice.invoiceDate ? -1 : 1);
                        console.log(this.unmatchedInvoices);
                    }
                }
            }

        }

        console.log('end!')
    }

    public fixInvoices() {
        this.disableInvoiceFix = true;
        console.log('fixing all:', this.unmatchedInvoices);

        const batchOrders = this.unmatchedInvoices.map(unI => this.fixInvoice(unI));

        return Promise.all(batchOrders).then(data => {
            console.log('All done', data)
            this.disableInvoiceFix = false;
        }).catch(err => {
            console.error('error in promise all', err)
        })
    }

    /**
     * Takes output from matchBrokenInvoices and fixes the relative orders (adds the "invoice" into the "invoices" array)
     * @param unI
     */
    async fixInvoice(unI: any) {

        const newItems = unI.order.items;

        unI.matchingItems.forEach(it => {
            const itemToUpdate = newItems.find(ni => ni.id === it.id);

            if (itemToUpdate) {
                const newItemInvoices = itemToUpdate.invoices ? itemToUpdate.invoices : [];
                const invoiceItem = unI.invoice.items.find(ii => ii.id === itemToUpdate.id);

                if (invoiceItem) {
                    console.log('saving:', unI.order.id, unI.invoice.id, unI.invoice.invoiceNr, unI.invoice.invoiceDate, invoiceItem.amount);
                    newItemInvoices.push({
                        invoiceDate: unI.invoice.invoiceDate,
                        invoiceId: unI.invoice.id,
                        invoiceNr: unI.invoice.invoiceNr,
                        materialAmount: invoiceItem.amount ? invoiceItem.amount : 0
                    })
                }

                itemToUpdate.invoices = newItemInvoices;

            }

        });

        console.log(unI.matchingItems.length, newItems);

        try {
            const data = await this.dataService.patchOrder(unI.order.id, {
                items: newItems
            });
            console.log('order patched', data);
        } catch (err) {
            console.error('error while updating order', err);
        }
    }

    /**
     * Run after getNewInvoices
     * Save "updatedInvoices" to the database
     */
    public fixItemsAndInvoices() {
        console.log('updated invoices', this.updatedInvoices)
        const updatePromises = this.updatedInvoices.map(inv => {

            return this.dataService.updateInvoices(inv.id, inv)
                .then(data => console.log(`${inv.id} updated`))
                .catch(err => console.error(`Error updating ${inv.id}`, inv, err));
        })

        return Promise.all(updatePromises)
            .then(data => console.log('All done', data))
            .catch(error => console.error(error))
    }

    getOrdersWithUndefinedItemId() {
        this.dataService.getOrderList().pipe(map(orders => orders.filter(ord => {

                return ord.items.some(it => typeof it.id === 'undefined')
            })),
            // .map(filteredOrders => filteredOrders.map(fo => fo.payload.doc.data()))
            first()).subscribe(data => {
            console.log('Orders to fix', data)
            this.ordersToFix = data;

        });
    }

    fixOrders() {
        const orderPromises = this.ordersToFix.map(order => {
            const newOrder = order.payload.doc.data()

            newOrder.items = newOrder.items.map(it => {
                const newItem = it;

                if (typeof it.id === 'undefined') {
                    newItem.id = guid();
                }

                return newItem;
            })
            return order.payload.doc.ref.update(newOrder).then(result => {
                console.log(`${order.payload.doc.id} order updated`, result)
            }).catch(error => {
                console.error(`${order.payload.doc.id} order NOT updated`, error)
            })
        })

        return Promise.all(orderPromises).then(result => {
            console.log('all done', result);
        }).catch(err => {
            console.error(`error on promise all`, err);
        })
    }

    getCurrentInvoices() {
        this.dataService.getInvoicesList().pipe(first()).subscribe(invoices => {
            this.invoiceList = invoices;
        })
    }

    /**
     * Loop on invoices IN MEMORY
     * - for each
     * -- find the order on DB
     * -- loop on invoice item
     * --- for each
     * ---- try to find the corresponding item in the order
     * ---- if match -> update the invoice item with the order item ID
     * ---- if NOT match -> DO NOTHING
     */
    getNewInvoices() {
        const invObservables = this.invoiceList.map(invSnap => {

            const inv = invSnap.payload.doc.data()
            const invId = invSnap.payload.doc.id;
            const orderId = inv.orderId ? inv.orderId : inv.order.id;

            if (typeof inv.order === 'string') {
                console.log('this invoice has bad order: ', inv)
            }

            return this.dataService.getOrder(orderId).pipe(map(order => {

                const newInv = inv;
                newInv.id = invId;

                // Do this in other function

                // newInv.order = {
                //   id: orderId,
                //   orderNr: inv.order.orderNr ? inv.order.orderNr : '',
                //   date: inv.order.date ? inv.order.date : '' ,
                //   totalValue: inv.order.totalValue ? inv.order.totalValue : '',
                //   vat: inv.order.vat ? inv.order.vat : '',
                //   vendor: inv.order.vendor ? inv.order.vendor : '',
                //   primaryContact: inv.order.primaryContact ? inv.order.primaryContact : ''
                // };

                if (typeof inv.order !== 'string') {
                    newInv.order.id = orderId;
                }

                order.items.forEach(oit => {

                    const itemsFilter = newInv.items.filter(iit => iit.description === oit.description);

                    let itemToUpdate;

                    if (itemsFilter.length > 1) {
                        itemToUpdate = itemsFilter.find(iit => {
                            let fieldCheck;
                            if (iit.costEach === undefined) {
                                fieldCheck = ('' + iit.quantity) === ('' + oit.quantity);
                            } else {
                                fieldCheck = ('' + iit.costEach) === ('' + oit.costEach);
                            }
                            return iit.id === undefined && iit.description === oit.description && fieldCheck;
                        });
                    } else {
                        itemToUpdate = itemsFilter[0];
                    }

                    if (itemToUpdate) {
                        if (oit.id) {
                            itemToUpdate.id = oit.id;
                        } else {
                            console.log(`Please add ID to this item in order ${orderId} : `, oit, 'suggested ID: ', guid());
                        }

                    }
                })
                return newInv;
            }), first())
        })
        console.log(invObservables);
        combineLatest(invObservables).pipe(first()).subscribe(data => {
            console.log('overall reuslt', data);
            this.updatedInvoices = data
        })
    } // After this one complete go to fixItemsAndInvoices

    getInvoiceItemsWithoutIds() {
        this.dataService.getInvoiceItemsWithoutIds().then(result => {
            this.invoiceItemsWithoutIds = result;
        });
    }

    createNewItemsInOrders() {
    }

    exportHours() {

    }

    /*
      createNewItemsInOrders(){
        const orderProm = this.invoiceItemsWithoutIds.map(inv => {
          return this.dataService.getOrder(inv.orderId).pipe(switchMap (order => {
            console.log('Got this order:', order.id);
            inv.items.forEach(iit => {

              if (iit.id === undefined && iit.selected){

                const itemsFilter = inv.order.items.filter(ioItem => ioItem.description === iit.description);

                let invoiceOrderItem: OrderEntry;

                if (itemsFilter.length > 1) {
                  invoiceOrderItem = itemsFilter.find(ioItem => {
                    let fieldCheck;
                    if (ioItem.costEach === undefined) {
                      fieldCheck = ('' + ioItem.quantity) === ('' + iit.quantity);
                    } else {
                      fieldCheck = ('' + ioItem.costEach) === ('' + iit.costEach);
                    }
                    return ioItem.id === undefined && ioItem.description === iit.description && fieldCheck;
                  });
                } else {
                  invoiceOrderItem = itemsFilter[0];
                }

                if(invoiceOrderItem){
                  iit.id = guid();
                  iit.unmatched = true;
                  order.items.push({
                    id: iit.id,
                    costEach: invoiceOrderItem.costEach,
                    quantity: 0,
                    description: invoiceOrderItem.description,
                    discount: invoiceOrderItem.discount,
                    unit: invoiceOrderItem.unit,
                    code: invoiceOrderItem.code,
                    material: invoiceOrderItem.material,
                    subBudget: invoiceOrderItem.subBudget,
                    budget: invoiceOrderItem.budget,
                    total: invoiceOrderItem.total ? invoiceOrderItem.total : 0,
                    itemDeliveryCompletion: invoiceOrderItem.itemDeliveryCompletion ? invoiceOrderItem.itemDeliveryCompletion : null,
                    itemInvoiceCompletion: invoiceOrderItem.itemInvoiceCompletion ? invoiceOrderItem.itemInvoiceCompletion : null,
                    invoices: invoiceOrderItem.invoices ? invoiceOrderItem.invoices.concat([{
                      invoiceDate: inv.invoiceDate,
                      invoiceId: inv.id,
                      invoiceNr: inv.invoiceNr,
                      materialAmount: 0
                    }]) : [{
                      invoiceDate: inv.invoiceDate,
                      invoiceId: inv.id,
                      invoiceNr: inv.invoiceNr,
                      materialAmount: 0
                    }],
                    ddt: invoiceOrderItem.ddt ? invoiceOrderItem.ddt : [],
                    outOfBudget: invoiceOrderItem.outOfBudget ? invoiceOrderItem.outOfBudget : null

                  });
                }else{
                  console.log("Invoice orderItem is undefined");
                }

                console.log("Invoice orderItem", invoiceOrderItem);



              }
            });
            console.log('updated order: ', order);
            console.log('updated inv: ', inv);

            return Promise.all([
              this.dataService.updateOrder(order.id, {items: order.items}).then(() => {console.log('updated order ' + order.id);}),
              this.dataService.updateInvoices(inv.id, {unmatchedItems: true, items: inv.items }).then(() => { console.log('updated invoice ' + inv.id); })
            ]);

          })).toPromise();
        })


        Promise.all(orderProm).then(() => {
          console.log('All order items created');
        });

      }
    */

    private getMatchingItems(invItems, orderItems) {

        return orderItems.filter(oi => invItems.some(ii => {
            return ii.id === oi.id && ii.selected === true
        }));
    }
}
