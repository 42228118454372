import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';



@Component({
    selector: 'app-statcollector-view',
    templateUrl: './statcollector-view.component.html',
    styleUrls: ['./statcollector-view.component.scss']
})
export class StatcollectorViewComponent implements OnInit {

    public iframeLink: SafeResourceUrl;

    public dashboardSub;
    public linkSelection = [];

    constructor(private configService: ConfigService,
                private authService: AuthService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        console.log('Getting dashboard link for user:', this.authService.currentUser.email);
        this.dashboardSub = this.configService.getUserDashboardLink(this.authService.currentUser.email)
            .subscribe(res => {
                if (res.docs.length === 0) {
                    console.log('Link for current user has not been found.. add it in the database!');
                    return;
                }
                const obj = res.docs[0].data();
                console.log('Setting link:', obj);
                this.linkSelection = obj.otherLinks;
                if (obj.link) {
                    this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(obj.link);
                    this.linkSelection.push({description: '- Personale -', link: obj.link, active: true});
                } else {
                    this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.linkSelection[0]);
                }
                this.linkSelection.sort((a, b) => a.description > b.description ? 1 : -1);
            }, err => {
                console.error(err);
            });
    }

    public ngOnDestroy() {
        if (this.dashboardSub) this.dashboardSub.unsubscribe();
    }

    public switchDashboard(link: string) {
        const found = this.linkSelection.find(obj => obj.link === link);
        if (found.active) return;
        console.log('switch to link:', link);
        this.linkSelection.map(obj => obj.active = false);
        this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
        found.active = true;
    }

}
