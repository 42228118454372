import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../services/data.service';
import {AuthService} from '../../../services/auth.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {DataSource} from '@angular/cdk/collections';
import {PaymentMethods} from '../../../interfaces/payment-methods';
import {Invoice, Order} from '@wondersys/wonderbudget-lib';
import {Invoices} from '../../interfaces/invoices';

import * as moment from 'moment-timezone';
import {ConfigService} from '../../../services/config.service';
import {firestoreDateToJsDate} from '../../../shared/utils/utils';



@Component({
    selector: 'app-invoices-cosmo-add',
    templateUrl: './invoices-cosmo-add.component.html',
    styleUrls: ['./invoices-cosmo-add.component.scss']
})
export class InvoicesCosmoAddComponent implements OnInit, OnDestroy, AfterViewChecked {

    // For the mat-table
    displayedColumns = ['description', 'invoiceDescription', 'quantity', 'costEach', 'amount'];
    dataSourceItems: ItemsDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public filteredOrders: Observable<Partial<Order>[]>;
    public unsubscribe$ = new Subject();
    public invoicesForm: FormGroup;
    public _invoice;
    public invoicesNr: string;
    public currentInvoicesNr: string;
    public invoicesAdding = false;
    public isItems = false;
    public orders = [];
    public orderSubscription;
    public paymentMethodsSubscription;
    public iOrderId;
    public invoiceRegisteredToCosmo;
    public invoiceOperations;
    public cosmoEnabled;
    public repositoryReasonOptions = [
        {id: 'FF', value: 'FATTURA DA FORNITORE'},
        {id: 'FFUEBL', value: 'FATTURA FORNITORE UE e BL'},
        {id: 'FFESTI', value: 'FATTURA FORNITORE UE/EST iva'},
        {id: 'FFCH', value: 'FATTURA FORNITORE CH'},
        {id: 'FFRC', value: 'FATTURA FORNITORE RC'}
    ];
    public paymentMethodOptions = [];
    public userRole = 'user';
    private itemsStream$ = new BehaviorSubject([]);

    constructor(
        private _changeDetectionRef: ChangeDetectorRef,
        private dataService: DataService,
        private authService: AuthService,
        private configService: ConfigService) {
    }

    get invoicesCosmo() {
        return this._invoice;
    }

    @Input() set invoicesCosmo(inputInvoice: Invoices) {
        console.log('Invoices input', inputInvoice);
        this._invoice = inputInvoice;
        if (inputInvoice) {
            this.invoicesForm.enable();
            this.emptyItems();

            const patchInvoices: any = {
                invoiceNr: inputInvoice.invoiceNr,
                invoiceNr2: inputInvoice.invoiceNr2 ? inputInvoice.invoiceNr2 : '',
                order: inputInvoice.order,
                items: inputInvoice.items,
                invoiceDate: firestoreDateToJsDate(inputInvoice.invoiceDate),
                totalAmount: inputInvoice.totalAmount ? inputInvoice.totalAmount : 0,
                note: inputInvoice.note,
                downloadUrl: inputInvoice.downloadUrl,
                paymentMethod: inputInvoice.paymentMethod ? inputInvoice.paymentMethod : inputInvoice.order.paymentMethod,
                paymentPurpose: inputInvoice.paymentPurpose,
                reasonCode: inputInvoice.reasonCode ? inputInvoice.reasonCode : '',
                sentToAccounting: inputInvoice.sentToAccounting,
                registeredToCosmo: inputInvoice.registeredToCosmo,
                cosmoErrors: inputInvoice.cosmoErrors ? inputInvoice.cosmoErrors : ''
            };

            if (inputInvoice.fromImport) {
                patchInvoices.fromImport = inputInvoice.fromImport;
            }

            if (inputInvoice.paymentMethodCode || inputInvoice.order.paymentMethodCode) {
                patchInvoices.paymentMethodCode = inputInvoice.paymentMethodCode ? inputInvoice.paymentMethodCode : inputInvoice.order.paymentMethodCode;
            }

            this.invoicesForm.patchValue(patchInvoices);
            this.setItemsToInvoice(inputInvoice.items);


            this.invoiceRegisteredToCosmo = (inputInvoice.registeredToCosmo === 'done');
            this.invoiceOperations = (inputInvoice.registeredToCosmo == 'ready' && inputInvoice.sentToAccounting);
            if (inputInvoice.registeredToCosmo != 'ready') {
                this.invoicesForm.disable();
            }

            this.isItems = (inputInvoice.items.length > 0);
        }
    }

    get invoiceFormArray(): FormArray {
        return (this.invoicesForm.get('invoices') as FormArray);
    }

    ngAfterViewChecked(): void {
        // your code
        this._changeDetectionRef.detectChanges();
    }

    ngOnInit() {

        this.authService.userStream$.subscribe((val) => {
            if (val) this.userRole = val.role;
        })


        this.invoicesForm = new FormGroup({
            invoiceNr: new FormControl('', [
                Validators.required
            ]),
            invoiceNr2: new FormControl(''),
            order: new FormControl('', [
                Validators.required
            ]),
            items: new FormArray([]),
            invoiceDate: new FormControl(),
            note: new FormControl(),
            downloadUrl: new FormControl(''),
            paymentMethod: new FormControl('', [
                Validators.required
            ]),
            paymentMethodCode: new FormControl(''),
            paymentPurpose: new FormControl('', [
                Validators.required
            ]),
            reasonCode: new FormControl(''),
            fromImport: new FormControl(),
            sentToAccounting: new FormControl(),
            registeredToCosmo: new FormControl(),
            cosmoErrors: new FormControl('')
        });

        this.paymentMethodsSubscription = this.dataService.getPaymentMethods().pipe(
            map((paymentMethodsList) => paymentMethodsList.map((paymentMethod) => {
                const data = paymentMethod.payload.doc.data() as PaymentMethods;
                return data;
            })),
            takeUntil(this.unsubscribe$))
            .subscribe((paymentMethods) => {
                this.paymentMethodOptions = paymentMethods;
            });

        // Assign the data to the data source for the table to render
        this.dataSourceItems = new ItemsDataSource(
            this.itemsStream$,
            this.paginator, this.sort);

        this.configService.settings.subscribe((sett) => {
            if (sett && sett.value && sett.value.cosmo) {
                this.cosmoEnabled = sett.value.cosmo.enabled;
            }
        });
    }

    // private filterOrder(val: string): Partial<Order>[] {
    //   return this.orders
    //     .filter(option => {
    //       let searchString = option.orderNr;
    //       return searchString.toLowerCase().indexOf(val.toLowerCase()) !== -1;
    //     });
    // }

    public getItemsByOrder($event) {
        this.emptyItems();
        if (!$event.option.value) {
            return;
        }

        var itemsToDisplay = [];
        var itemsFromOrder = $event.option.value.items;
        this.setItemsToInvoice(itemsFromOrder);

        this.isItems = true;
    }

    public orderDisplay = (order: Order): string => {
        if (order) {
            let ret = order.orderNr;
            return ret;
        } else {
            return null;
        }
    }

    public changeSelectedItem($event, index) {
        var itemSelected = $event.checked;
        (<FormArray>this.invoicesForm.controls['items']).value[index].selected = itemSelected;
    }

    public getDownloadUrl(evt) {
        return evt.subscribe(url => {
            this.invoicesForm.patchValue({downloadUrl: url});
        });
    }

    public sendToCosmo() {
        const invoice = <Invoice>this.invoicesForm.value;

        const selectedPaymentMethod: any = this.paymentMethodOptions.filter(pm => {
            return pm.methodValue === invoice.paymentMethod;
        });

        console.log(this.paymentMethodOptions, invoice.paymentMethod, selectedPaymentMethod);

        this.updateInvoice({
            registeredToCosmo: 'processing',
            paymentMethod: invoice.paymentMethod,
            paymentMethodCode: selectedPaymentMethod[0].methodCode
        }, this._invoice.id);
    }

    public resendToCosmo() {
        this.updateInvoice({
            registeredToCosmo: 'ready'
        }, this._invoice.id).then(res => {
            this.sendToCosmo();
        });
    }

    public sendBackToSales() {
        const invoice = <Invoice>this.invoicesForm.value;
        invoice.sentToAccounting = false;
        this.dataService.updateInvoices(this._invoice.id, {
            sentToAccounting: false
        })
            .then((addedInvoice) => {
                console.log('Invoice sent back to Sales');
            })
            .catch((err) => {
                console.log('Error while updating invoice', err);
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }

    submit() {
        console.log('Reactive Form submitted: ', this.invoicesForm);
        if (this.invoicesForm.valid) {
            // Disable submit button
            this.invoicesAdding = true;
            const invoices = <Invoices>this.invoicesForm.value;

            // MOMENT DATEPICKER
            if (invoices.invoiceDate) {
                invoices.invoiceDate = moment(invoices.invoiceDate).toDate();
            }

            // UPDATE
            invoices.order.paymentMethod = invoices.paymentMethod;
            const selectedPaymentMethod: any = this.paymentMethodOptions.filter(pm => {
                return pm.methodValue === invoices.order.paymentMethod;
            });

            invoices.order.paymentMethodCode = selectedPaymentMethod[0].methodCode;
            invoices.paymentMethodCode = selectedPaymentMethod[0].methodCode;

            this.updateInvoice({
                paymentMethodCode: selectedPaymentMethod[0].methodCode,
                paymentMethod: invoices.paymentMethod
            }, this._invoice.id);
        } else {
            console.log('Invoices invalid');
        }
    }

    private emptyItems() {
        while ((<FormArray>this.invoicesForm.get('items')).value.length) {
            (<FormArray>this.invoicesForm.get('items')).removeAt(0);
        }
    }

    private setItemsToInvoice(items) {
        const itemsControl = <FormArray>this.invoicesForm.controls['items'];

        items.forEach(item => {
            if (item.selected) {
                var orderItem = {
                    description: item.description,
                    invoiceDescription: item.invoiceDescription,
                    quantity: item.quantity,
                    costEach: item.costEach,
                    amount: item.amount,
                    selected: item.selected ? item.selected : false
                }

                itemsControl.value.push(orderItem);
            }
        });

        this.itemsStream$.next(itemsControl.value);
    }

    private updateInvoice(invoice, invoiceId) {
        return this.dataService.updateInvoices(invoiceId, invoice)
            .then((updatedInvoice) => {
                this.invoicesAdding = false;
                console.log('invoice updated: ', invoiceId)
            }).catch((err) => {
                console.log('Error while updating invoices', err);
                this.invoicesAdding = false;
            });
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object)) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }

}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class ItemsDataSource extends DataSource<Order> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable
            // .do((data) => console.log(data))
            .subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Order[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('invoiceCosmoAddListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('invoiceCosmoAddListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('invoiceCosmoAddListSortId');
        const sortDirection = localStorage.getItem('invoiceCosmoAddListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            const data = this._dataChange.value;

            // Filter
            const fData = data.filter((item) => {
                const searchStr = (item.description);
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: Order[]) => {
        // console.log('sort ', this._sort.active, this._sort.direction);
        localStorage.setItem('invoicesCosmoAddListSortId', this._sort.active);
        localStorage.setItem('invoicesCosmoAddListSortDirection', this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: Order, b: Order) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';


            let valueA;
            let valueB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}
