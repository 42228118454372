<div class="detail-card-container">
    <mat-toolbar class="sub-budget-toolbar">
        <div class="sub-budget-toolbar__left">
            <mat-form-field *ngIf="canEdit">
                <input #box (blur)="updateName(box.value)" (keyup.enter)="updateName(box.value)" [ngModel]="subBudgetName"
                       matInput minlength="3" required>
            </mat-form-field>
            <div *ngIf="!canEdit">
                {{ subBudgetName }}
            </div>

            <mat-form-field class="sub-budget-tag-list">
                <mat-chip-list #chipList>
                    <mat-chip (removed)="removeTag(tag)" *ngFor="let tag of subBudgetTags" [removable]="true"
                              [selectable]="true">
                        {{ tag }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input (matChipInputTokenEnd)="addTag($event)" [matChipInputAddOnBlur]="true"
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeyCodes" placeholder="Tags"/>
                </mat-chip-list>
            </mat-form-field>
        </div>

        <div class="sub-budget-toolbar__right">

            <div *ngIf="subBudgetTotals">
                <span i18n>Total</span>:&nbsp;
                <span>{{ subBudgetTotals.total | currency:budget && budget.export ? 'USD' : 'EUR' }}</span>&nbsp;|&nbsp;
                <span>{{ (calculate ? subBudgetTotals.total / budgetTotal * 100 : 0)| number:'1.0-2' }} %</span>
            </div>
            <!-- Toggle button to disable budget -->
            <mat-slide-toggle (change)="toggleCalculate($event)" *ngIf="calculateToggleEnabled && canEdit"
                              [checked]="calculate">Disable
            </mat-slide-toggle>
            <button *ngIf="vacancyBtn || canEdit" [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button (click)="generateVacancyActivity()" *ngIf="vacancyBtn" mat-menu-item>
                    <mat-icon>directions_walk</mat-icon>
                    <span i18n>Generate Vacancy Activity</span>
                </button>
                <button (click)="deleteSubBudget()" *ngIf="canEdit" [disabled]="canNotDelete" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span i18n>Delete Sub Budget</span>
                </button>
                <button (click)="copySubBudget()" *ngIf="canEdit" mat-menu-item>
                    <mat-icon>file_copy</mat-icon>
                    <span i18n>Duplicate Sub Budget</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title i18n>Activities</mat-panel-title>
            <mat-panel-description>
                <span>Total</span>:&nbsp;
                <span>{{ subBudgetTotals?.activities | currency:budget && budget.export ? 'USD' : 'EUR' }}</span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form #f="ngForm" (ngSubmit)="commitActivities(f)" novalidate>
            <mat-table #table [dataSource]="activitiesDataSource">
                <!-- Nome Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef i18n>Activity</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.activity }}</mat-cell>
                </ng-container>

                <!-- Category Column -->
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef i18n>Category</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ categoryMap.get(row.category) }}</mat-cell>
                </ng-container>

                <!-- Costo Column -->
                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef i18n>Cost</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.cost | currency:budget && budget.export ? 'USD' : 'EUR' }}
                    </mat-cell>
                </ng-container>

                <!-- Ore Column -->
                <ng-container matColumnDef="hours">
                    <mat-header-cell *matHeaderCellDef i18n>Hours</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.hours }}</mat-cell>
                </ng-container>

                <!-- Ore Column -->
                <ng-container matColumnDef="hoursNew">
                    <mat-header-cell *matHeaderCellDef i18n>Hours</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-form-field class="hours-input-field">
                            <input #hours [(ngModel)]="avm[row.id]" [name]="row.id" matInput min="0" type="number">
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <!-- Importo Column -->
                <ng-container matColumnDef="importoCalc">
                    <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.cost * avm[row.id] | number:'1.0-2' }}</mat-cell>
                </ng-container>

                <!-- Importo Column -->
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.amount | number:'1.0-2' }}</mat-cell>
                </ng-container>

                <!-- Color Column -->
                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef i18n>Weight</mat-header-cell>
                    <mat-cell *matCellDef="let row" [style.color]="row.color">
                        {{ 100 * row.cost * avm[row.id] / budgetTotal | number:'1.0-2' }} %
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-action-row *ngIf="canEdit">
                <button [disabled]="f.invalid" mat-button type="submit">Save</button>
            </mat-action-row>
        </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title i18n="Title of the list of external goods to be purchased">
                Materials
            </mat-panel-title>
            <mat-panel-description>
                <span i18n>Total</span>:&nbsp;
                <span>{{ subBudgetTotals?.materials | currency:budget && budget.export ? 'USD' : 'EUR' }}</span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-table #table [dataSource]="materialDataSource">

            <!-- Nome Column -->
            <!-- <ng-container matColumnDef="matrixCode">
                      <mat-header-cell *matHeaderCellDef i18n>Matrix Code</mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{ row.matrixCode }} </mat-cell>
                  </ng-container> -->

            <!-- Nome Column -->
            <!-- <ng-container matColumnDef="marking">
                      <mat-header-cell *matHeaderCellDef i18n>Marking</mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{ row.marking }} </mat-cell>
                  </ng-container> -->

            <!-- Nome Column -->
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef i18n>Material</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.description }}</mat-cell>
            </ng-container>

            <!-- Costo Column -->
            <ng-container matColumnDef="costEach">
                <mat-header-cell *matHeaderCellDef i18n>Cost Each</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.costEach | number:'1.0-2' }}</mat-cell>
            </ng-container>

            <!-- Category Column -->
            <ng-container matColumnDef="category">
                <mat-header-cell *matHeaderCellDef i18n>Category</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ categoryMap.get(row.category) }}</mat-cell>
            </ng-container>

            <!-- Costo Column -->
            <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef i18n>Unit</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.unit }}</mat-cell>
            </ng-container>

            <!-- Ore Column -->
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef i18n>Quantity</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{ row.quantity }}
                    <!--
                              <mat-form-field>
                                  <input type="number" matInput #quantity [value]="row.quantity">
                              </mat-form-field>
                          -->
                </mat-cell>
            </ng-container>

            <!-- Importo Column -->
            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.quantity * row.costEach | number:'1.0-2' }}</mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="weight">
                <mat-header-cell *matHeaderCellDef i18n>Weight</mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color">
                    {{ 100 * row.quantity * row.costEach / budgetTotal | number:'1.0-2' }} %
                </mat-cell>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef i18n>Actions</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button mat-icon-button title="Edit Material Entry">
                        <mat-icon (click)="openMaterialDialog($event, 'edit', row)">edit</mat-icon>
                    </button>
                    <button mat-icon-button title="Copy Material Entry">
                        <mat-icon (click)="openMaterialDialog($event, 'copy', row)">file_copy</mat-icon>
                    </button>
                    <button [disabled]="row.hasOrders" mat-icon-button title="Delete Material Entry">
                        <mat-icon (click)="deleteMaterial($event,row)">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="matDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: matDisplayedColumns;"></mat-row>
        </mat-table>
        <mat-action-row align="end">
            <button (click)="openMaterialDialog($event)" *ngIf="canEdit" color="accent" mat-fab>
                <mat-icon>add</mat-icon>
            </button>
        </mat-action-row>
    </mat-expansion-panel>
</div>
