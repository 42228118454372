import GoogleUser = gapi.auth2.GoogleUser;
import * as firebase from 'firebase/app';

// This is what we have in our database
export interface AclUser {
    id?: string;
    role: string;
    email: string;
    googleUid: string;
    displayName: string;
    personalName: string;
    initials: string;
    type?: string[];
    external?: boolean;
    deleted?: boolean;
}

// This is the full rappresentations of the user with all
// data merged togheters
export interface AppUser extends AclUser {
    googleUser: GoogleUser;
    firebaseUser: firebase.User;
    permissions?: Set<string>;
}

export const AppUserUtil = {
    getUserPartial: (appUser: AppUser): Partial<AppUser> => {
        const partial: Partial<AppUser> = {
            email: appUser.email,
            googleUid: appUser.googleUid,
            displayName: appUser.displayName
        };
        if (appUser.id !== undefined) {
            partial.id = appUser.id;
        }
        return partial;
    }
};
