<div class="example-header">
    <mat-form-field>
        <input #filter (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter">
    </mat-form-field>
</div>

<div class="example-container mat-elevation-z8">

    <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="invoiceNr">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Invoice Nr.</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="row.invoiceNr2">{{ row.invoiceNr }}/{{ row.invoiceNr2 }}</span>
                <span *ngIf="!row.invoiceNr2">{{ row.invoiceNr }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vendor">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Vendor</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.order?.vendor?.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="downloadUrl">
            <mat-header-cell *matHeaderCellDef i18n>Document</mat-header-cell>
            <mat-cell *matCellDef="let row">
          <span *ngIf="row.downloadUrl">
            <a [href]="row.downloadUrl" target="_blank">
              <mat-icon matListIcon>attach_file</mat-icon>
            </a>
          </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderNr">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Order Nr.</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.order.orderNr }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Invoice Date</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.invoiceDate | timestamp | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
            <mat-cell *matCellDef="let row"><span
                    *ngIf="row.totalAmount"> {{ row.totalAmount | currency:'EUR' }} </span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="registeredToCosmo">
            <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Send to Cosmo?</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.registeredToCosmo }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="cosmoErrors">
            <mat-header-cell *matHeaderCellDef i18n>Errors from Cosmo</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.cosmoErrors">
                    <div *ngFor="let cosmoError of row.cosmoErrors.split('\\r')">{{ cosmoError }}</div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef i18n>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <a [routerLink]="['/invoices-cosmo',row.id]" mat-icon-button>
                    <mat-icon *ngIf="!row.registeredToCosmo">mode_edit</mat-icon>
                    <mat-icon *ngIf="row.registeredToCosmo">visibility</mat-icon>
                </a>
                <!--<button mat-icon-button [disabled]="row.sentToAccounting" (click)="deleteInvoices(row)">
                  <mat-icon>delete</mat-icon>
                </button>-->
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>

    <mat-paginator [length]="dataSource.resultsLength" [pageSizeOptions]="[5, 10, 50, 25, 100]"
                   pageSize="100"></mat-paginator>
</div>