import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppStateService} from '../../services/app-state.service';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {Invoice, Order, OrderEntry} from '@wondersys/wonderbudget-lib';
import {firestoreDateToJsDate} from '../../shared/utils/utils';
import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {ConfigService} from '../../services/config.service';



@Component({
    selector: 'app-invoices-add',
    templateUrl: './invoices-add.component.html',
    styleUrls: ['./invoices-add.component.scss']
})
export class InvoicesAddComponent implements OnInit, OnDestroy {

    // For the mat-table
    displayedColumns = ['selected', 'description', 'descriptionCopy', 'invoiceDescription', 'orderQuantity', 'quantity', 'costEach', 'orderAmount', 'toBeInvoiced', 'amount'];

    invoiceLog: Observable<any>;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Output() invoiceAdded = new EventEmitter<Invoice>();
    public operationInExecution = false;
    public itemSelection = new BehaviorSubject([]);
    public displayedItems = [];
    public filteredOrders$: Observable<Partial<Order>[]>;
    public invoicesForm: FormGroup;
    public invoiceTotalAmount: Observable<Number>;
    public currentInvoicesNr: string;
    public invoicesAdding = false;
    public isItems = false;
    public editMode = false;
    public orders = [];
    public disabledSubmit;
    public numberItemSelected;
    public order;
    public invoiceSentToAccounting;
    public cosmoEnabled;
    public vendorNdaFieldIsSet = false;
    public vendorHasNda = false;
    public ndaFile;
    public userIsInvoicesAdmin: Observable<boolean>;
    public isInvoiceAdmin;
    public userIsInvoicesAdminSubscription: Subscription;
    reloadSubscription: Subscription;
    private unsubscribe$ = new Subject();

    constructor(
        private _changeDetectionRef: ChangeDetectorRef,
        private dataService: DataService,
        private authService: AuthService,
        private configService: ConfigService,
        private router: Router,
        private appState: AppStateService) {

        // Handle component refresh
        // https://medium.com/@rakshitshah/refresh-angular-component-without-navigation-148a87c2de3f

        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //   return false;
        // };
        //
        // this.reloadSubscription = this.router.events.subscribe((event) => {
        //   if (event instanceof NavigationEnd) {
        //     // Trick the Router into believing it's last link wasn't previously loaded
        //     this.router.navigated = false;
        //   }
        // });

    }

    private _invoice;

    get invoice() {
        return this._invoice;
    }

    @Input() set invoice(invoice: Invoice) {
        this._invoice = invoice;

        if (invoice) {
            this.editMode = true;
            this.invoicesForm.enable();

            const patchInvoices: any = {
                invoiceNr: invoice.invoiceNr,
                invoiceNr2: '',
                orderId: invoice.orderId,
                order: invoice.order,
                items: invoice.items,
                invoiceDate: firestoreDateToJsDate(invoice.invoiceDate),
                note: invoice.note,
                downloadUrl: invoice.downloadUrl
            };

            this.invoicesForm.patchValue(patchInvoices);

            this.invoiceLog = this.dataService.getInvoiceLog(invoice.id);

            this.itemsInitialization();

            this.dataService.getOrder(invoice.orderId).pipe(
                first()).subscribe((order) => {
                if (!order) {
                    this.appState.showError({errorCode: -1, errorMessage: 'Unable to find the related invoice order'});
                    this.router.navigate(['/invoices']);
                } else {
                    this.checkNda(order.vendor.id);
                }

            });

            this.invoiceSentToAccounting = invoice.sentToAccounting;
            this.isItems = (invoice.items.length > 0);
        }
    }

    // ngAfterViewChecked(): void {
    //   this._changeDetectionRef.detectChanges();
    // }

    loadOrders() {
        if (this.orders.length === 0 && !this.operationInExecution) {
            this.invoicesForm.get('order').disable();
            this.operationInExecution = true;
            this.dataService.getLimitedOrderListOnce().subscribe((orders) => {
                console.log('loaded orders list', orders.docs.length);
                this.operationInExecution = false;
                this.invoicesForm.get('order').enable();
                this.orders = orders.docs.map(d => {
                    return {...d.data(), id: d.id};
                });
            }, error => {
                console.error(error);
                this.operationInExecution = false;
                this.invoicesForm.get('order').enable();
            });
        }
    }

    ngOnInit() {

        this.userIsInvoicesAdmin = this.authService.userHasPermission('app.invoices.admin');

        this.userIsInvoicesAdminSubscription = this.userIsInvoicesAdmin.pipe(tap((isInvoiceAdmin) => {
            this.isInvoiceAdmin = isInvoiceAdmin;
            if (this.invoiceSentToAccounting && !isInvoiceAdmin) {
                this.invoicesForm.disable();
            }
        })).subscribe();

        this.invoicesForm = new FormGroup({
            invoiceNr: new FormControl('', [
                Validators.required
            ]),
            invoiceNr2: new FormControl(''),
            order: new FormControl('', [
                Validators.required
            ]),
            orderId: new FormControl('', [
                Validators.required
            ]),
            invoiceDate: new FormControl(Date, [
                Validators.required
            ]),
            note: new FormControl(),
            downloadUrl: new FormControl('')
        });

        this.filteredOrders$ = this.invoicesForm.get('order').valueChanges
            .pipe(
                startWith({} as Order),
                debounceTime(200),
                distinctUntilChanged(),
                map(order => order && typeof order === 'object' ? order.orderNr : String(order)),
                map(orderNr => this.filterOrder(orderNr)),
                tap(res => this._changeDetectionRef.detectChanges())
            );


        this.configService.settings.subscribe((sett) => {
            if (sett) {
                try {
                    this.cosmoEnabled = sett.value.cosmo.enabled;
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }

    public onOrderSelected(event) {
        this.invoicesForm.patchValue({orderId: event.option.value.id});
        this.itemsInitialization();
        this.checkNda(event.option.value.vendor.id);
    }

    checkNda(vendorId) {
        const component = this;
        this.vendorHasNda = false;

        if (!vendorId) {
            console.log('can not check nda, no vendorId passed.');
            return;
        }

        this.dataService.getCompanyById(vendorId).pipe(first())
            .subscribe((company) => {

                if (!this.configService.settings || !this.configService.settings.value) {
                    console.log('can not check nda, no settings loaded.');
                    return;
                }

                const cfNdaId = this.configService.settings.value.value.prosperworks.cfNdaId;
                if (!cfNdaId) {
                    throw Error(`nda field id is not defined in settings`);
                }
                console.log('settings.value.value.prosperworks.cfNdaId: ', cfNdaId);

                const vendorNdaFile = company.custom_fields.find(cf => cf.custom_field_definition_id.toString() === cfNdaId);
                if (vendorNdaFile && vendorNdaFile.value) {
                    this.vendorNdaFieldIsSet = true;
                } else {
                    this.vendorNdaFieldIsSet = false;
                }
                const vendorNdaObs = this.dataService.getVendorNda(company);
                if (vendorNdaObs) {
                    vendorNdaObs.pipe(first()).subscribe(driveFilePromise => {

                        driveFilePromise.then(driveFileResponse => {
                            console.log('found this NDA drive file: ', driveFileResponse);
                            if (driveFileResponse && driveFileResponse.result) console.log('webViewLink: ', driveFileResponse.result.webViewLink);
                            this.ndaFile = driveFileResponse;
                            component.ndaFile = driveFileResponse;
                            this.vendorHasNda = true;
                            component.vendorHasNda = true;
                            component._changeDetectionRef.detectChanges();
                        });

                    }, error => console.error(error));
                } else {
                    console.log('drive file observable not initialized');
                    this.vendorHasNda = false;
                    component.vendorHasNda = false;
                }
            });

    }

    public orderDisplay = (order: Order): string => {
        if (order) {
            const ret = order.orderNr;
            return ret;
        } else {
            return null;
        }
    }

    public copySelectedDescription($event, itemId) {
        const itemSelected = $event.checked;

        const itemsSel = this.itemSelection.value;

        const matchingItem = this.displayedItems.find(it => it.id === itemId)

        if (itemsSel[itemId]) {
            itemsSel[itemId] = {
                ...itemsSel[itemId],
                invoiceDescription: itemSelected ? matchingItem.description : '',
                copyDesc: itemSelected
            };
        } else {
            itemsSel[itemId] = {
                invoiceDescription: itemSelected ? matchingItem.description : '',
                copyDesc: itemSelected
            };
        }

        this.itemSelection.next(itemsSel);
    }

    public getDownloadUrl(evt) {
        return evt.subscribe(url => {
            this.invoicesForm.patchValue({downloadUrl: url});
        });
    }

    public getUploadProcess(process) {
        this.disabledSubmit = process;
    }

    public sendToAccounting() {

        const newInvoice = this.getNewInvoice();

        newInvoice.sentToAccounting = true;
        newInvoice.registeredToCosmo = 'ready';
        newInvoice.totalAmount = this.setTotalInvoiceAmount(newInvoice.items);
        newInvoice.paymentPurpose = this.setPaymentPurpose(newInvoice.items);

        this.dataService.updateInvoices(this._invoice.id, newInvoice)
            .then(() => {
                this.invoiceSentToAccounting = true;
                console.log('Invoice sent to accounting');
            })
            .catch(err => {
                console.error('Error while updating invoice', err);
            });
    }

    ngOnDestroy() {
        this.userIsInvoicesAdminSubscription.unsubscribe();
        if (this.reloadSubscription) {
            this.reloadSubscription.unsubscribe();
        }
    }

    public itemsInitialization() {
        console.log('init items: ', this.invoicesForm.value);


        const invoiceItemsObs = new BehaviorSubject([]);
        const orderId = this.invoicesForm.value.orderId;
        this.itemSelection.next([]);
        this.numberItemSelected = 0;

        if (this.editMode) {
            invoiceItemsObs.next(this.invoice.items);

            const itemSel = this.itemSelection.value;
            this.invoice.items.forEach(item => {
                if (item.selected) {
                    itemSel[item.id] = {selected: item.selected, amount: item.amount};
                    this.numberItemSelected += 1;
                }
            });
            this.itemSelection.next(itemSel);
        }

        this.invoiceTotalAmount = this.itemSelection.pipe(
            map(items => Object.values(items)),
            map(items => items.map(i => i.selected ? i.amount : 0).reduce((a1, a2) => a1 + a2, 0))
        );

        const orderItemsObs = orderId ? this.dataService.getOrder(orderId).pipe(map(order => order.items)) : of([]);

        combineLatest([orderItemsObs.pipe(take(1)), invoiceItemsObs, this.itemSelection])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([orderItems, invoiceItems, itemSelection]) => {
                const items = orderItems.map(oi => {

                    const matchingItem = invoiceItems.find(ii => oi.id === ii.id && ii.selected);

                    const invoiceCompletion = oi.invoices ? oi.invoices.map(i => i.materialAmount).reduce((a, b) => a + b, 0) : 0;

                    const orderAmount = this.getTotalAmountPerMaterial(oi);

                    const toBeInvoiced = orderAmount - invoiceCompletion;

                    let itemDisabled = toBeInvoiced === 0;

                    if (this.invoice) {
                        itemDisabled = itemDisabled && oi.invoices ? oi.invoices.findIndex(i => i.invoiceId === this.invoice.id) === -1 : false;
                    }

                    let invoiceDescription = itemSelection[oi.id] && itemSelection[oi.id].invoiceDescription ? itemSelection[oi.id].invoiceDescription : '';

                    if (matchingItem && matchingItem.invoiceDescription) {
                        invoiceDescription = matchingItem.invoiceDescription;
                    }

                    return {
                        id: oi.id,
                        amount: itemSelection[oi.id] ? itemSelection[oi.id].amount : 0,
                        orderAmount: orderAmount,
                        toBeInvoiced: toBeInvoiced,
                        itemDisabled: itemDisabled,
                        costEach: oi.costEach,
                        description: matchingItem ? matchingItem.description : oi.description,
                        invoiceDescription: invoiceDescription,
                        invoiceQuantity: matchingItem ? matchingItem.quantity : 0,
                        quantity: oi.quantity,
                        selected: itemSelection[oi.id] ? itemSelection[oi.id].selected === true : false,
                        projectNr: oi.budget && oi.budget.project && oi.budget.project.projectNr ? oi.budget.project.projectNr : '',
                        copyDesc: itemSelection[oi.id] ? itemSelection[oi.id].copyDesc === true : false
                    };
                });

                this.displayedItems = items;
            });

    }

    public transferAmount(item) {
        if (this.isInvoiceAdmin && item.amount !== 0) {
            const itemSelectionValue = this.itemSelection.value;


            let idToUpdate = '';

            for (const k in itemSelectionValue) {
                if (itemSelectionValue.hasOwnProperty(k)) {

                    if (itemSelectionValue[k].selected) {
                        idToUpdate = k;
                    }
                }
            }
            const newArray = this.itemSelection.value;

            newArray[idToUpdate].amount = item.amount;

            this.itemSelection.next(newArray);
        }

    }

    public toggleSelection(event, item) {

        const itemId = item.id;

        const itemsSel = this.itemSelection.value;

        if (itemsSel[itemId]) {
            itemsSel[itemId] = {...itemsSel[itemId], selected: event.checked};
        } else {
            itemsSel[itemId] = {selected: event.checked};
        }

        if (event.checked) {
            itemsSel[itemId].amount = itemsSel[itemId].amount ? itemsSel[itemId].amount : item.toBeInvoiced;
        }

        this.itemSelection.next(itemsSel);

        this.numberItemSelected += event.checked ? 1 : -1;

    }

    public changeItemAmount($event, itemId) {
        const itemAmount = Number($event);

        const itemsSel = this.itemSelection.value;

        if (itemsSel[itemId]) {
            itemsSel[itemId] = {...itemsSel[itemId], amount: itemAmount};
        } else {
            itemsSel[itemId] = {amount: itemAmount};
        }

        this.itemSelection.next(itemsSel);
    }

    public submitInvoice() {
        console.log(this.invoicesForm.value);

        this.invoicesAdding = true;

        const newInvoice: Invoice = this.getNewInvoice();
        newInvoice.invoiceNr = newInvoice.invoiceNr.trim();

        if (!this.editMode) {
            // ADD INVOICE

            this.dataService.addInvoices(newInvoice).then(() => {
                this.invoicesForm.reset();
                this.itemsInitialization();
                this.invoicesAdding = false;
                this.invoiceAdded.emit(newInvoice);
            }).catch((err) => {
                console.error(err);
                this.invoicesAdding = false;
            });
        } else {
            // UPDATE INVOICE

            this.dataService.updateInvoices(this._invoice.id, newInvoice).then(() => {
                this.invoicesAdding = false;
                this.router.navigate(['/invoices']);
                this.invoiceAdded.emit(newInvoice);
            }).catch((err) => {
                console.error(err);
                this.invoicesAdding = false;
            });
        }

    }

    private filterOrder(val: string): Partial<Order>[] {
        return this.orders
            .filter(option => {
                const orderNrString = option.orderNr;
                return orderNrString.toLowerCase().includes(val.toLowerCase());
            });
    }

    private getTotalAmountPerMaterial(item: OrderEntry) {
        return (item.costEach * item.quantity) * (1 - 1 / 100 * item.discount);
    }

    private setTotalInvoiceAmount(items) {
        let totalAmount = 0;
        items.forEach(item => {
            if (item.selected) {
                totalAmount = totalAmount + parseFloat(item.amount);
            }
        });

        return totalAmount;
    }

    private setPaymentPurpose(items) {
        let positiveAmounts = 0;
        let negativeAmounts = 0;
        let paymentPurpose = '';
        items.forEach(item => {
            if (item.selected) {
                item.amount > 0 ? positiveAmounts++ : negativeAmounts++;
            }
        });

        paymentPurpose = 'ACQ';

        if (positiveAmounts > 0 && negativeAmounts === 0) {
            paymentPurpose = 'ACQ';
        }
        if (negativeAmounts > 0 && positiveAmounts === 0) {
            paymentPurpose = 'RF';
        }

        return paymentPurpose;
    }

    private prepareInvoiceItems() {
        return this.displayedItems.filter(item => item.selected).map(item => {
            delete item.copyDesc;
            delete item.orderAmount;
            delete item.invoiceQuantity;
            delete item.toBeInvoiced;
            delete item.itemDisabled;
            return item;
        });
    }

    private getNewInvoice() {
        let newInvoice: Invoice = this.invoicesForm.value;

        const newInvoiceItems = this.prepareInvoiceItems();

        if (!this.editMode) {
            // ADD INVOICE

            newInvoice = {
                ...newInvoice,
                order: {
                    id: this.invoicesForm.value.orderId,
                    orderNr: this.invoicesForm.value.order.orderNr,
                    date: this.invoicesForm.value.order.date,
                    totalValue: this.invoicesForm.value.order.totalValue,
                    vat: this.invoicesForm.value.order.vat,
                    vendor: this.invoicesForm.value.order.vendor,
                    primaryContact: this.invoicesForm.value.order.primaryContact
                },
                orderId: newInvoice.orderId,
                sentToAccounting: false,
                reasonCode: '',
                registeredToCosmo: '',
                cosmoErrors: '',
                invoiceDate: moment(firestoreDateToJsDate(newInvoice.invoiceDate)).toDate(),
                items: newInvoiceItems,
                totalAmount: this.setTotalInvoiceAmount(newInvoiceItems),
                paymentPurpose: this.setPaymentPurpose(newInvoiceItems),
                paymentMethod: this.invoicesForm.value.order.paymentMethod ? this.invoicesForm.value.order.paymentMethod : '',
                paymentMethodCode: this.invoicesForm.value.order.paymentMethodCode ? this.invoicesForm.value.order.paymentMethodCode : ''
            };

            newInvoice['searchMode'] = 'quick';

        } else {
            // UPDATE INVOICE

            newInvoice = {
                ...newInvoice,
                order: {
                    id: this.invoicesForm.value.orderId,
                    orderNr: this.invoicesForm.value.order.orderNr,
                    date: this.invoicesForm.value.order.date,
                    totalValue: this.invoicesForm.value.order.totalValue,
                    vat: this.invoicesForm.value.order.vat,
                    vendor: this.invoicesForm.value.order.vendor,
                    primaryContact: this.invoicesForm.value.order.primaryContact
                },
                orderId: newInvoice.orderId,
                invoiceDate: moment(firestoreDateToJsDate(newInvoice.invoiceDate)).toDate(),
                items: newInvoiceItems,
                totalAmount: this.setTotalInvoiceAmount(newInvoiceItems),
                paymentPurpose: this.setPaymentPurpose(newInvoiceItems)
            };

        }

        return newInvoice;

    }

}
