export const environment = {
    production: false,
    stage: true,
    firebase: {
        apiKey: 'AIzaSyDDL9DLz-ctDYWJutPjC5uHVSVJ_VQUBy4',
        authDomain: 'extravega-management-stage.firebaseapp.com',
        databaseURL: 'https://extravega-management-stage.firebaseio.com',
        projectId: 'extravega-management-stage',
        storageBucket: 'extravega-management-stage',
        messagingSenderId: '1054300572310'
    },
    clientId: '1054300572310-5g6crej3qoj4rvvhu9v3lnilivj73u0e.apps.googleusercontent.com'
};
