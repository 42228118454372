import {map} from 'rxjs/operators';
import {ContactPickerDialogComponent} from '../contact-picker-dialog/contact-picker-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DataService} from '../../services/data.service';
import {PaymentMethods} from '../../interfaces/payment-methods';



const intParsableValidator = (control: FormControl) => {
    const number = control.value;
    if (Number.isNaN(parseInt(number, 10))) {
        return {numberParsable: 'Not parsable '};
    }
    return null;
};


@Component({
    selector: 'app-open-project-dialog',
    templateUrl: './open-project-dialog.component.html'
})
export class OpenProjectDialogComponent implements OnInit {

    public filteredOptions: Observable<string[]>;
    public projectNr: FormControl = new FormControl(null, [Validators.required, intParsableValidator]);
    public finalOffer: FormControl = new FormControl(0, Validators.required);
    public projectManager: FormControl;
    public deliveryDate: FormControl = new FormControl(Date, Validators.required);
    public paymentMethod: FormControl = new FormControl('', Validators.required);
    public paymentMethodCode: FormControl = new FormControl('', Validators.required);

    public paymentMethodsSubscription;
    public minDeliveryDate;

    public paymentMethodOptions = [];

    public isExport: boolean;

    constructor(
        public dialogRef: MatDialogRef<ContactPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dataService: DataService
    ) {
    }

    get isValid() {
        return this.projectNr.valid &&
            this.finalOffer.valid &&
            this.projectManager.valid &&
            this.paymentMethod.valid &&
            this.deliveryDate.value;
    }

    ngOnInit() {

        this.isExport = this.data.budget.export ? true : false;

        if (this.isExport) {
            this.projectNr.setValue(this.data.projectNrITA + 'NY');
        } else {
            this.dataService.getNextProjectNr()
                .subscribe((projectNr) => {
                    this.projectNr.setValue(projectNr);
                });
        }


        if (this.data.oData) {
            // use offerAmount by default
            this.finalOffer.setValue(Math.round(this.data.oData.offerAmount * 100) / 100);

        }

        if (this.data.user) {
            // this.projectManager.setValue(this.data.user);
            this.projectManager = new FormControl(this.data.user, Validators.required);
        } else {
            this.projectManager = new FormControl(undefined, Validators.required);
        }

        this.paymentMethodsSubscription = this.dataService.getPaymentMethods().pipe(
            map((paymentMethodsList) => paymentMethodsList.map((paymentMethod) => {
                return paymentMethod.payload.doc.data() as PaymentMethods;
            })))
            .subscribe((paymentMethods) => {
                this.paymentMethodOptions = paymentMethods;
            });

        this.minDeliveryDate = new Date();

    }

    getErrorMessage() {
        return this.projectNr.hasError('required') ? 'You must enter a value' :
            this.projectNr.hasError('numberParsable') ? 'Project number must start with an integer' :
                '';
    }

    closeDialog() {
        if (this.isValid) {
            const selectedPaymentMethod: any = this.paymentMethodOptions.filter(pm => {
                return pm.methodValue === this.paymentMethod.value;
            });
            const paymentMethodCode = selectedPaymentMethod[0].methodCode;

            this.dialogRef.close({
                projectNr: this.projectNr.value,
                finalOffer: this.finalOffer.value,
                projectManager: this.projectManager.value,
                paymentMethod: this.paymentMethod.value,
                paymentMethodCode: paymentMethodCode,
                deliveryDate: this.deliveryDate.value
            });
        }
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

}
