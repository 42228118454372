import {combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import * as firebase from 'firebase/app';
import {AclUser, AppUser} from '../interfaces/app-user';

import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {GoogleAuthService} from 'ng-gapi';
import {calculateCustomerFromHostname} from '../util';
import GoogleUser = gapi.auth2.GoogleUser;



interface Role {
    permissions: Set<string>;
}

@Injectable({providedIn: 'root'})
export class AuthService {

    // Google Auth
    public static SESSION_STORAGE_KEY = 'accessToken';
    public isLoggedIn = false;
    // store the URL so we can redirect after logging in
    public redirectUrl: string;
    public userStream$: Observable<AppUser>;
    public auth2: any;
    private googleUser: GoogleUser;
    // Domain
    private readonly CUSTOMER_DOMAIN;
    private _loggedUser = new ReplaySubject<AppUser>(1);

    private firebaseUser$: Observable<firebase.User>;

    private userColRef;
    private userPermissions = new ReplaySubject<Set<string>>(1);
    private readonly cDR: AngularFirestoreDocument<any>;

    constructor(
        private firebaseAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private googleAuth: GoogleAuthService,
        private router: Router) {

        this.CUSTOMER_DOMAIN = calculateCustomerFromHostname(document.location.hostname);
        console.log('Authservice constructor. Setting customerDocumentReference. Domain inferred from the URL: ', this.CUSTOMER_DOMAIN);
        this.cDR = this.afs.collection('Customers').doc(this.CUSTOMER_DOMAIN);

        this.firebaseUser$ = firebaseAuth.authState;
        if (!this.firebaseUser$) {
            // HANDLE THIS!!!
            console.error('firebaseAuth.authState is NULL ', this.firebaseUser$);
        } else {
            console.log('firebaseAuth.authState: ', this.firebaseUser$);
        }

        this._provider = new firebase.auth.GoogleAuthProvider();
        this._provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        // ACL on FIRESTORE
        this.userColRef = this.cDR
            .collection<AclUser>('Users', ref => ref.where('active', '==', true).where('deleted', '==', false));


        this.userStream$ = this._loggedUser.asObservable();
        combineLatest([
            googleAuth.getAuth(),
            this.firebaseUser$,
            this.userColRef.valueChanges()])
            .pipe(switchMap((value) => {
                console.log('received auth values.');
                const auth = value[0];
                const firebaseUser = value[1];
                const activeUsersFromDb = <AclUser[]>value[2];

                // Diversi controlli da fare
                const gUser = auth.currentUser.get();
                const guidFromGoogle = gUser.getId();
                if (!firebaseUser) return of(null);
                const uGoogleData = firebaseUser.providerData.find((pd) => pd.providerId === 'google.com');
                if (!uGoogleData) return of(null);
                if (!guidFromGoogle) return of(null);
                if (!activeUsersFromDb) return of(null);

                if (uGoogleData.uid !== guidFromGoogle) {
                    console.log('UID differs. We need to authenticate again');
                    firebase.auth().signOut().then(p => {
                        this.router.navigate(['/home']);
                    });
                    return of(null);
                }
                const dbUser = activeUsersFromDb.find((i) => i.googleUid === uGoogleData.uid);
                if (!dbUser) {
                    console.log('No DB user defined for this user! uid: ', uGoogleData.uid)
                    return of(null);
                }

                const au: AppUser = {
                    ...dbUser,
                    firebaseUser: firebaseUser,
                    googleUid: uGoogleData.uid,
                    googleUser: gUser
                };
                this._currentUser = au;
                console.log('Finally firing final user after checks:', au)
                return of(au)
            })).subscribe(this._loggedUser);

        // Once the user is logged load permissions
        this._loggedUser.pipe(
            switchMap((u) => {
                if (!u) {
                    return of(new Set([]));
                }
                const oArray = [];
                if (!u.type) u.type = [];

                for (const role of u.type) {
                    /** ISSUE!!
                     * Roles can be accessed only by authenticated users on firestore.
                     * This mean we need to unsubscribe as soon as the user logout to avoid missing permission error
                     */
                    oArray.push(this.cDR.collection<Role>('Roles').doc<Role>(role).valueChanges().pipe(
                        map((r) => {
                            return r ? r.permissions : []
                        })));
                }

                return combineLatest(oArray);
            }),
            map((rPerms) => {
                let mergedPerms = [];
                for (const p of rPerms) {
                    mergedPerms = mergedPerms.concat(p);
                }
                const noDuplicates = mergedPerms.filter((item, pos) => mergedPerms.indexOf(item) === pos)
                return new Set(noDuplicates)
            }))
            .subscribe(this.userPermissions);

    }

    private _provider;

    get provider() {
        return this._provider;
    }

    private _currentUser: AppUser;

    // BE CAREFUL CAN BE undefined
    get currentUser(): AppUser {
        return this._currentUser;
    }

    get customerDocumentReference() {
        console.log('getting customerDocumentReference:', this.cDR)
        return this.cDR;
    }

    get appDomain() {
        return this.CUSTOMER_DOMAIN;
    }

    // Pure Google Auth
    public getGoogleToken(): string {
        const token: string = sessionStorage.getItem(AuthService.SESSION_STORAGE_KEY);
        if (!token) {
            throw new Error('no token set , authentication required');
        }
        return sessionStorage.getItem(AuthService.SESSION_STORAGE_KEY);
    }

    public signInWithGoogle(): void {
        this.googleAuth.getAuth()
            .subscribe((auth) => {
                auth.signIn().then(res => this.signInSuccessHandler(res));
            });
    }

    public getUserIdToken = () => {
        return this.userStream$.pipe(switchMap((u) => {
                return from(u.firebaseUser.getIdToken());
            }),
            catchError((err) => {
                console.warn('Error tring to get token ID: ', err);
                return of(null);
            }));
    }

    public logout() {
        return firebase.auth().signOut();
    }

    public userHasPermission = (permission: string) => {
        return this.userPermissions.pipe(map((permSet) => {
            return permSet.has(permission);
        }));
    }

    public userHasPermissions = (permissions: string[]) => {
        return this.userPermissions.pipe(map((permSet) => {
            const ret = [];
            for (const p of permissions) {
                ret.push(permSet.has(p));
            }
            return ret;
        }));
    }

    private signInSuccessHandler(res: GoogleUser) {
        this.googleUser = res;

        const credential = firebase.auth.GoogleAuthProvider.credential(
            this.googleUser.getAuthResponse().id_token);
        firebase.auth().signInWithCredential(credential)
            .then((pData) => {
                this.router.navigate(['/budget']);
            });
        sessionStorage.setItem(
            AuthService.SESSION_STORAGE_KEY, res.getAuthResponse().access_token
        );
    }

}
