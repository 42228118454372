<div class="fxlayout-row-spacearound-center">
    <mat-card style="width: 100%">
        <mat-card-title>
            <h3 i18n>Laser Cut Nesting Status</h3>
        </mat-card-title>

        <div class="example-header">
            <mat-form-field>
                <input (keyup)="applyFilter($event.target.value)" [value]="getListFilter()" matInput
                       placeholder="Filter">
            </mat-form-field>
        </div>

        <div class="">

            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="IdNesting">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>IdNesting</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.IdNesting }}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="CodiceNesting">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>CodiceNesting</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.CodiceNesting }}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="CodiceLamiera">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>CodiceLamiera</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.CodiceLamiera }}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="Materiale">
                    <mat-header-cell *matHeaderCellDef i18n>Materiale</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.Materiale }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="DataEsportazione">
                    <mat-header-cell *matHeaderCellDef i18n>DataEsportazione</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.DataEsportazione | timestamp | date }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="StatoNesting">
                    <mat-header-cell *matHeaderCellDef i18n>StatoNesting</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.StatoNesting }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row (click)="expandedElement = row" *matRowDef="let row; columns: displayedColumns;" [class.expanded]="expandedElement == row"
                         class="element-row" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator [length]="dataSource.resultsLength" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </mat-card>
</div>
