import {Component, OnInit} from '@angular/core';



@Component({
    selector: 'app-internal-project',
    templateUrl: './internal-project.component.html',
    styleUrls: ['./internal-project.component.scss']
})
export class InternalProjectComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
