<ngx-charts-bar-horizontal-stacked
        (select)="onSelect($event)"
        [gradient]="gradient"
        [legend]="showLegend"
        [results]="chartData"
        [scheme]="colorScheme"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [view]="view"
        [xAxisLabel]="xAxisLabel"
        [xAxis]="showXAxis"
        [yAxisLabel]="yAxisLabel"
        [yAxis]="showYAxis">
</ngx-charts-bar-horizontal-stacked>