<form (ngSubmit)="submit()" [formGroup]="assemblyCostsForm" class="assemblyCosts-form-container">
    <mat-form-field>
        <input formControlName="employee" i18n-placeholder matInput min="0" placeholder="Employee" type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="projectManager" i18n-placeholder matInput min="0" placeholder="Project Manager"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="employeeDays" i18n-placeholder matInput min="0" placeholder="Employee Days"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="projectManagerDays" i18n-placeholder matInput min="0" placeholder="Project Manager Days"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="employeeCost" i18n-placeholder matInput min="0" placeholder="Employee Cost"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="projectMangerCost" i18n-placeholder matInput min="0" placeholder="Project Manager Cost"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="travelExpensesAccomodation" i18n-placeholder matInput min="0"
               placeholder="Travel Expenses Accomodation" type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="travelExpensesFlights" i18n-placeholder matInput min="0"
               placeholder="Travel Expenses Flights" type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="externalManpower" i18n-placeholder matInput min="0" placeholder="External Manpower"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="renting" i18n-placeholder matInput min="0" placeholder="Renting" type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="other" i18n-placeholder matInput min="0" placeholder="Other" type="number">
    </mat-form-field>
    <button [disabled]="!assemblyCostsForm.valid" mat-button type="submit">Save</button>
</form>