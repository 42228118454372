<div class="dialog-content">
    <div class="dialog-title" mat-dialog-title>
        <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>
    <div [formGroup]="form" class="dialog-form" mat-dialog-content>
        <div>
            <span>Order: <strong>{{ order.orderNr }}</strong> is delivered </span>
            <mat-checkbox formControlName="isDelivered"></mat-checkbox>
        </div>
        <mat-form-field style="margin-top: 5px">
            <mat-label>Real Delivery Date</mat-label>
            <input [matDatepicker]="dp" formControlName="realDeliveryDate" matInput>
            <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            <mat-error *ngIf="form.get('realDeliveryDate').hasError('required')">Required Field</mat-error>
        </mat-form-field>
    </div>
    <div class="dialog-actions" mat-dialog-actions>
        <button (click)="updateOrder()" [disabled]="!enableConfirmButton()" color="primary" mat-flat-button>Confirm
        </button>
    </div>
</div>
