import {filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Budget} from '../../budget/interfaces/index';
import {DataService} from '../../services/data.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {unities} from '../../interfaces/unit';
import {Material, OrderEntry, resumeMaterial} from '@wondersys/wonderbudget-lib';
import {ProjectNrPipe} from '../../shared/pipes/project-nr.pipe';
import {MatDialog} from '@angular/material/dialog';
import {MaterialDialogComponent} from '../../budget/sub-budget.component';



const guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

@Component({
    selector: 'app-order-entry-control',
    templateUrl: 'order-entry.component.html',
    styleUrls: ['./order-entry.component.scss']
})
export class OrderEntryComponent implements OnInit, OnDestroy {

    public materials$: Observable<Partial<resumeMaterial>[]>;
    public newMaterial: Material;
    public materials: Array<Material> = [];

    @Input()
    public currency: string;
    @Input()
    public index: number;

    deletionBlocked: boolean = false; // if this item is linked to any invoice and/or ddt
    @Output()
    public removed: EventEmitter<number> = new EventEmitter<number>();
    unities = unities;
    total = 0;
    public budgets: BehaviorSubject<Partial<Budget>[]> = new BehaviorSubject([]);
    public filteredBudgets: Observable<Partial<Budget>[]>;
    public selectedBudget: BehaviorSubject<Budget> = new BehaviorSubject(null);
    private budgetSubscription: Subscription;

    constructor(private dataService: DataService,
                public dialog: MatDialog,
                private projectNrPipe: ProjectNrPipe) {
    }

    private _item: FormGroup;

    public get item(): FormGroup {
        return this._item;
    }

    @Input()
    public set item(i: FormGroup) {
        this._item = i;
        this.total = this.calculateTotal(this._item.value);
    }

    static calculateEntryValue(costEach: number, quantity: number, discount: number) {
        if (costEach === undefined || costEach === null) costEach = 0;
        if (quantity === undefined || quantity === null) quantity = 0;
        if (discount === undefined || discount === null) discount = 0;
        return (costEach * quantity) * (1 - discount / 100);
    }

    static buildItem(item?: OrderEntry) {
        if (item) {

            return new FormGroup({
                id: new FormControl(item.id),
                budget: new FormControl(item.budget, Validators.required),
                material: new FormControl(item.material, Validators.required),
                description: new FormControl(item.description, Validators.required),
                quantity: new FormControl(item.quantity, Validators.required),
                costEach: new FormControl(item.costEach, Validators.required),
                discount: new FormControl(item.discount),
                unit: new FormControl(item.unit, Validators.required),
                code: new FormControl(item.code),
                ddt: new FormControl(item.ddt),
                invoices: new FormControl(item.invoices)
            });
        } else {
            return new FormGroup({
                id: new FormControl(guid()),
                budget: new FormControl(undefined, Validators.required),
                material: new FormControl(undefined, Validators.required),
                description: new FormControl('', Validators.required),
                quantity: new FormControl(undefined, Validators.required),
                costEach: new FormControl(undefined, Validators.required),
                discount: new FormControl(0),
                unit: new FormControl(undefined, Validators.required),
                code: new FormControl(),
                ddt: new FormControl(undefined),
                invoices: new FormControl(undefined)
            });
        }
    }

    public compareMaterial = (mat1: any, mat2: any) => {
        if (this.item.get('costEach').value === 1249.08) {
            console.log('comparing....', mat1, mat2, this.materials.length)
        }

        if (!mat1 || !mat2) {
            return false;
        }
        if (mat1.id !== undefined && mat2.id !== undefined && mat1.id === mat2.id) return true;
        const sameDescr = mat1.description ? mat2.description.trim().toLowerCase() === mat1.description.trim().toLowerCase() : true;
        //const sameCategory = mat1.category ? mat2.category.trim().toLowerCase() === mat1.category.trim().toLowerCase() : true;
        const sameUnit = mat1.unit ? mat2.unit.trim().toLowerCase() === mat1.unit.trim().toLowerCase() : true;
        //const sameCostEach = mat1.costEach ? mat2.costEach === mat1.costEach : true;
        return sameDescr && sameUnit;
    }


    public onMaterialSelected(m: resumeMaterial) {
        console.log('selected one of the resume materials..', m)
        this.item.patchValue({
            costEach: m.costEach,
            quantity: m.isOnlyFinal ? m.finalQuantity : m.quantity,
            unit: m.unit
        });
        this.total = this.calculateTotal(this.item.value);
        console.log('material changed in the form, item patched. Situation: ', this.item)
        console.log('----> total triggered by item material change: ', this.total)
    }

    ngOnInit() {

        if (this.item.get('costEach').value === 1249.08) {
            console.log('!!!!!!!!!!!! Order entry component! Regarding item:', this.item);
            console.log('-----> Order entry component! Budget selected:', this.selectedBudget.value, this.materials);
        }


        if (this.item.get('invoices').value && this.item.get('invoices').value.length > 0) {
            this.deletionBlocked = true;
        }
        if (this.item.get('ddt').value && this.item.get('ddt').value.length > 0) {
            this.deletionBlocked = true;
        }

        if (this.item.get('budget').value) {
            this.selectedBudget.next(this.item.get('budget').value);
        }

        this.dataService.getNotArchivedBudgetList().pipe(
            map(budgets =>
                budgets
                    .map((b) => {
                        const retB = this._project({budgetNr: 1, id: 1, project: 1})(b);
                        if ('prosperworks' in b) {
                            retB.prosperworks = {
                                company_name: b.prosperworks.company_name
                            };
                        }
                        return retB;
                    })
            ))
            .subscribe(this.budgets);

        this.filteredBudgets = this.item.get('budget').valueChanges.pipe(
            startWith({} as Budget),
            map(budget => budget && typeof budget === 'object' ? budget.budgetNr : String(budget)),
            map(budgetNr => budgetNr ? this.filterBudget(budgetNr) : this.budgets.value.slice())
        );

        this.materials$ = this.selectedBudget.pipe(
            filter(budget => budget !== null),
            switchMap((budget) => {
                return this.dataService.getSubBudgetResume(budget.id)
            }),
            map((summary: any) => summary.materials.map((material: Material) => {
                const found = this.materials.find(mat => mat.description === material.description && mat.costEach === material.costEach && mat.category === material.category);
                if (!found) this.materials.push(material);
                return material;
            })),
            tap((mat) => {
                if (this.item.get('costEach').value === 1249.08) {
                    console.log('filled materials!', this.item.get('material').value)
                    this.item.get('material').markAsTouched()
                }
                this.item.get('material').enable();
            }));

        this.item.get('costEach').valueChanges.subscribe(value => {
            this.total = OrderEntryComponent.calculateEntryValue(
                this.item.get('costEach').value,
                this.item.get('quantity').value,
                this.item.get('discount').value);
        });

        this.item.get('quantity').valueChanges.subscribe(value => {
            this.total = OrderEntryComponent.calculateEntryValue(
                this.item.get('costEach').value,
                this.item.get('quantity').value,
                this.item.get('discount').value);
        });

        this.item.get('discount').valueChanges.subscribe(value => {
            this.total = OrderEntryComponent.calculateEntryValue(
                this.item.get('costEach').value,
                this.item.get('quantity').value,
                this.item.get('discount').value);
        });
    }

    public budgetDisplayFn = (budget: Budget): string => {
        if (budget) {
            let ret = (budget.project) ? this.projectNrPipe.transform(budget) : budget.budgetNr;
            if (budget.companyName) {
                ret += ' ' + budget.companyName;
            } else if (budget.prosperworks && budget.prosperworks.company_name) {
                ret += ' ' + budget.prosperworks.company_name;
            }
            return ret;
        } else {
            return null;
        }
    }

    public budgetChange($event) {
        console.log('budget changed... (selected). Resetting material.')
        if (!$event.option.value.id) {
            return;
        }
        this.item.get('material').reset('');
        this.selectedBudget.next($event.option.value);
    }

    public emptyItem() {
        this.item.get('material').reset('');
        this.item.patchValue({
            costEach: 0,
            quantity: 0,
            discount: 0,
            unit: ''
        });
        this.total = this.calculateTotal(this.item.value);
    }

    public ngOnDestroy() {
        if (this.budgetSubscription) {
            this.budgetSubscription.unsubscribe();
        }
    }

    public addNewMaterial() {
        this.emptyItem();
        const dialogRef = this.dialog.open(MaterialDialogComponent, {
            width: '350px',
            data: {
                budgetId: this.selectedBudget.value,
                subBudgetId: '',
                type: '',
                material: null,
                forOrderBuild: true,
                assignPossibilities: this.materials
            }
        });

        dialogRef.afterClosed().subscribe((m: any) => {
            if (!m || !this.selectedBudget.value) return;
            delete m.id;
            this.item.get('material').patchValue({
                id: 'id' + (Math.random() * 1000000000).toString(10).slice(0, 8),
                description: m.description,
                category: m.category,
                costEach: m.costEach,
                quantity: 0,
                unit: m.unit,
                isOnlyFinal: true,
                referencedMaterialId: m.referencedMaterialId ? m.referencedMaterialId : ''
            });
            // this.item.get('quantity').setValue(m.quantity);
            // this.total = this.calculateTotal(this.item.value);
            console.log('After closed. ', this.item.value);
            this.onMaterialSelected(m)
        });
    }

    public getRefItemDescr(id: string) {
        const found = this.materials.find(m => m.id === id);
        if (found) return found.description;
        return '';
    }

    private calculateTotal(orderEntry: OrderEntry) {
        return OrderEntryComponent.calculateEntryValue(orderEntry.costEach, orderEntry.quantity, orderEntry.discount);
    }

    private filterBudget(val: string): Partial<Budget>[] {
        return this.budgets.value
            .filter(option => {
                if (option.budgetNr) {
                    let searchString = option.budgetNr;
                    if (option.project) {
                        searchString += this.projectNrPipe.transform(option);
                    }
                    if (option.companyName) {
                        searchString += option.companyName;
                    } else if (option.prosperworks && option.prosperworks.company_name) {
                        searchString += option.prosperworks.company_name;
                    }
                    return searchString.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                } else {
                    return option.budgetId.toLowerCase().indexOf(val.toLowerCase()) === 0;
                }
            });
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object)) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }
}

/*
export interface OrderEntry {
  description: string;
  budgetId: string;
  subBudgetId: string;
  materialId: string;
  value: number;
}
*/
