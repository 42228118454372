<mat-card>
    <form (ngSubmit)="submit()" [formGroup]="internalProjectForm" class="iproject-form-container">
        <mat-form-field>
            <input formControlName="description" i18n-placeholder matInput placeholder="Description" required
                   type="text">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="projectNr" i18n-placeholder matInput placeholder="Project Number" required
                   type="text">
            <mat-error *ngIf="internalProjectForm.controls['projectNr'].invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-radio-group class="promo-radio-group" formControlName="type">
            <mat-radio-button class="promo-radio-btn" i18n- matInput placeholder="Type" type="radio" value="internal">
                Internal
            </mat-radio-button>
            <mat-radio-button class="promo-radio-btn" i18n matInput placeholder="Type" type="radio" value="promo">
                Promo
            </mat-radio-button>
        </mat-radio-group>
        <mat-form-field>
            <input formControlName="orderTotal" i18n-placeholder matInput placeholder="Total Budget" type="number">
        </mat-form-field>
        <button [disabled]="!internalProjectForm.valid" mat-button type="submit">Save</button>
    </form>
</mat-card>