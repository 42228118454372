<mat-card>
    <form (ngSubmit)="submit()" [formGroup]="invoicesForm">
        <mat-card-content>
            <div class="invoices-container">
                <div class="invoices-meta">
                    <div class="invoices-meta-form-left">
                        <p><b>Invoice Nr:</b><br/>
                            <span *ngIf="_invoice && _invoice.invoiceNr2">{{ _invoice.invoiceNr }}
                                /{{ _invoice.invoiceNr2 }}</span>
                            <span *ngIf="_invoice && !_invoice.invoiceNr2">{{ _invoice.invoiceNr }}</span>
                        </p>
                        <p><b>Order Nr:</b><br/>
                            <span *ngIf="_invoice">{{ _invoice.order.orderNr }}</span>
                        </p>
                        <p><b>Date:</b><br/>
                            <span *ngIf="_invoice">{{ _invoice.invoiceDate | timestamp | date }}</span>
                        </p>
                        <p><b>Payment Purpose:</b><br/>
                            <span *ngIf="_invoice && _invoice.paymentPurpose.length > 0">{{ _invoice.paymentPurpose }}</span>
                            <span *ngIf="_invoice && _invoice.paymentPurpose.length == 0" style="color: red">Cannot determine the
                payment purpose</span>
                        </p>
                        <p><b>Vendor:</b><br/>
                            <span *ngIf="_invoice">{{ _invoice.order.vendor.name }}
                                , {{ _invoice.order.vendor.address?.street }},
                                {{ _invoice.order.vendor.address?.city }}</span>
                        </p>
                        <p><b>Total Amount:</b><br/>
                            <span *ngIf="_invoice">{{ _invoice.totalAmount | currency:'EUR' }}</span>
                        </p>
                        <p><b>Note:</b><br/>
                            <span *ngIf="_invoice">{{ _invoice.note }}</span>
                        </p>
                        <p *ngIf="_invoice && invoiceRegisteredToCosmo"><b>Payment method:</b><br/>
                            <span>{{ _invoice.paymentMethod }}</span>
                        </p>

                        <p *ngIf="invoicesForm.get('downloadUrl').value && _invoice"><b>File:</b><br/>
                            <a [href]="invoicesForm.get('downloadUrl').value" class="invoiceFile" mat-button
                               target="_blank">
                                <mat-icon matListIcon>attach_file</mat-icon>
                                <span class="menu-label" i18n="Invoice file">See file</span>
                            </a>
                        </p>
                        <p><b>Registered To Cosmo:</b><br/>
                            <span *ngIf="_invoice">{{ _invoice.registeredToCosmo }}</span>
                        </p>

                        <mat-form-field *ngIf="_invoice && !invoiceRegisteredToCosmo">
                            <mat-select formControlName="paymentMethod" placeholder="Payment Method" required>
                                <mat-option *ngFor="let pm of paymentMethodOptions" [value]="pm.methodValue">
                                    {{ pm.methodValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-card-actions>
              <span *ngIf="invoiceOperations">
                <button [disabled]="invoiceRegisteredToCosmo || invoicesForm.invalid || invoicesAdding" mat-raised-button
                        type="submit">
                  <span i18n>Update</span>
                </button>
                <button (click)="sendToCosmo()" *ngIf="cosmoEnabled" [disabled]="invoicesAdding || invoicesForm.invalid" mat-raised-button
                        type="button">
                  <span i18n>Register To Cosmo</span>
                </button>
                <button (click)="sendBackToSales()" [disabled]="!_invoice || invoicesAdding" mat-raised-button
                        type="button">
                  <span i18n>Send Back To Sales Dep.</span>
                </button>
              </span>
                            <a mat-raised-button routerLink="/invoices-cosmo">
                                <span i18n>Back</span>
                            </a>

                            <!-- if stuck processing simply click here to resend it -->
                            <button (click)="resendToCosmo()" *ngIf="_invoice && _invoice.registeredToCosmo === 'processing' && userRole === 'admin'" [disabled]="invoicesAdding || invoicesForm.invalid"
                                    color="warn"
                                    mat-raised-button type="button">
                                <span i18n>Re-send To Cosmo</span>
                            </button>
                        </mat-card-actions>
                    </div>
                    <div class="invoices-meta-form-right">
                        <div class="invoices-entry-array">
                            <h5>Materials: </h5>

                            <div class="example-container mat-elevation-z8">
                                <mat-table [dataSource]="dataSourceItems" matSort>

                                    <ng-container class="descriptionItem" matColumnDef="description">
                                        <mat-header-cell *matHeaderCellDef i18n>Description</mat-header-cell>
                                        <mat-cell *matCellDef="let row"
                                                  class="descriptionItem"> {{ row.description | ellipsis:300 }}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="invoiceDescription">
                                        <mat-header-cell *matHeaderCellDef i18n>Invoice Description</mat-header-cell>
                                        <mat-cell *matCellDef="let row"
                                                  class="descriptionItem"> {{ row.invoiceDescription | ellipsis:300 }}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="quantity">
                                        <mat-header-cell *matHeaderCellDef i18n>Quantity</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.quantity }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="costEach">
                                        <mat-header-cell *matHeaderCellDef i18n>Price per unit</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.costEach | currency:'EUR' }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="amount">
                                        <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
                                        <mat-cell
                                                *matCellDef="let row; let i = index"> {{ row.amount | currency:'EUR' }}
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns">
                                    </mat-row>
                                </mat-table>

                                <mat-paginator [length]="dataSourceItems.resultsLength"
                                               [pageSizeOptions]="[100]"></mat-paginator>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </form>
</mat-card>
