import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {map} from 'rxjs/operators';
import {Timereport} from '../timereport/interfaces';
import {Order} from '@wondersys/wonderbudget-lib';
import {OrderEntryComponent} from '../order/components/order-entry.component';
import {Budget} from '../budget/interfaces';



@Injectable()
export class BudgetService {

    private currentBudget$ = new BehaviorSubject<Budget>(null);

    constructor(
        private dataService: DataService
    ) {
    }

    get currentBudget() {
        return this.currentBudget$.value;
    }

    set currentBudget(b: Budget) {
        this.currentBudget$.next(b);
    }

    subscribeCurrentBudget() {
        return this.currentBudget$.asObservable();
    }

    clearCurrentBudget$() {
        this.currentBudget$.next(null);
    }


    public getSummaryBalanceByBudget(budgetId: string, forceRevenue?: boolean): Observable<SummaryBalanceData> {

        const timeReports$ = this.dataService.getTimereportByBudget(budgetId)
            .pipe(
                map((trList) => trList.map((tr: any) => {
                    const data = tr.payload.doc.data() as Timereport;
                    const id = tr.payload.doc.id;
                    if (!('totalValue' in data)) {
                        try {
                            data.totalValue = data.hours * data.actualCost;
                        } catch (err) {
                            data.totalValue = 0;
                        }
                    }
                    return {id: id, ...data};
                }).filter(tr => !tr.user.external))
            );

        const materialItems$ = this.dataService.getOrdersByBudget(budgetId)
            .pipe(
                map((orders: Order[]) => {
                    return orders
                        .filter(o => o.deleted !== true)
                        .map(o => o.items)
                        .reduce((i1, i2) => i1.concat(i2), [])
                        .filter((i) => (i.budget && i.budget.id === budgetId));
                })
            );

        return combineLatest([
            this.dataService.getSimpleBudget(budgetId),
            timeReports$,
            materialItems$
        ]).pipe(
            map(([b, a, o]: [Budget, any, any]) => {

                const totAct = a.filter((t: Timereport) => !Number.isNaN(t.totalValue))
                    .map((t: Timereport) => t.totalValue)
                    .reduce((t1: number, t2: number) => t1 + t2, 0);

                const totMat = o.map(item => OrderEntryComponent.calculateEntryValue(item.costEach, item.quantity, item.discount))
                    .reduce((t1, t2) => t1 + t2, 0);

                const costSummary = b.export ? b.canonicCostSummary : b.costSummary;

                const summary: any = {
                    budgetId,
                    budgetNr: b.budgetNr,
                    budget: {
                        act: costSummary ? costSummary.hoursValue : 0,
                        mat: costSummary ? costSummary.goodsValue : 0
                    },
                    final: {
                        act: totAct,
                        mat: totMat
                    }
                };

                if (b.project) {
                    const finalInvoiced = b.project.finalInvoiced ? b.project.finalInvoiced : 0;
                    const finalOfferOrder = b.project.finalOfferOrder ? b.project.finalOfferOrder : 0;
                    const profitFinalOperator = forceRevenue ? finalInvoiced : (b.status !== 'archived' ? finalOfferOrder : finalInvoiced);
                    summary.revenue = finalInvoiced;
                    summary.finalOfferOrder = finalOfferOrder;
                    summary.profit = {
                        budget: finalOfferOrder - summary.budget.act - summary.budget.mat,
                        final: profitFinalOperator - summary.final.act - summary.final.mat
                    };
                } else {
                    summary.revenue = 0;
                    summary.profit = {
                        budget: 0,
                        final: 0
                    };
                }

                console.log('Summary', summary);
                return summary;
            })
        );
    }

}


export interface SummaryBalanceData {
    budgetId: string;
    budgetNr: string;
    budget: {
        act: number,
        mat: number,
    };
    final: {
        act: number,
        mat: number
    };
    profit?: {
        budget: number;
        final: number;
    };

    // campi solo budgets
    revenue: number;

    // campi solo commesse
    finalOfferOrder?: number;
}
