import {Material} from '../interfaces';



export class MaterialModel {

    constructor(
        public description?: string,
        public category?: string,
        public costEach?: number,
        public quantity?: number,
        public unit?: string,
        public id?: string,
        public matrixCode?: string,
        public marking?: string,
        public referencedMaterialId?: string,
        public isOnlyFinal?: boolean
    ) {
    }

    public static fromObj(m: Material): MaterialModel {

        return new MaterialModel(
            m.description,
            m.category,
            m.costEach,
            m.quantity,
            m.unit,
            m.id
            // m.matrixCode,
            // m.marking
        );
    }

    public toPlainObj(): Material {
        return {
            description: this.description,
            category: this.category,
            costEach: this.costEach,
            quantity: this.quantity,
            unit: this.unit,
            id: this.id
            // matrixCode: this.matrixCode,
            // marking: this.marking,
        };
    }


}
