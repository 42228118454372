import {filter, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Budget, GeneratedBy, Material, SplittedCostTableElement} from '../../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../services/data.service';
import {BudgetModel} from '../../models/budget-model';
import {Subject} from 'rxjs';
import {SubBudgetModel} from '../../models/sub-budget';
import {AuthService} from '../../../services/auth.service';



/**
 * This component is only valid for EXPORT kind of budgets
 */

@Component({
    selector: 'app-export-form',
    templateUrl: './export-form.component.html',
    styleUrls: ['./export-form.component.scss']
})
export class ExportFormComponent implements OnInit, OnDestroy {

    public operationInProgress = false;
    @Output() changeSelectedTab = new EventEmitter<string>();
    @Output() updateCostSummary = new EventEmitter<boolean>();
    public exportForm: FormGroup;
    public exchangeRateHint: string;
    private justUpdatedFromFix = false;
    private unsubscribe$ = new Subject();

    constructor(private dataService: DataService, private fb: FormBuilder, private auth: AuthService) {
    }

    private _budget: Budget;

    @Input() set budget(b: Budget) {
        this._budget = b;
        if (this.exportForm) {
            this.exportForm.patchValue({
                exchangeRate: this._budget.export ? this._budget.export.exchangeRate : undefined
            }, {
                emitEvent: false
            });
        }
    }

    public _authorizedToModify = false;

    get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input() set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
    }

    public _splittedCostsIta: SplittedCostTableElement[] = [];

    @Input() set splittedCostsIta(a: any[]) {
        if (a) {
            this._splittedCostsIta = a;
        }
    }

    ngOnInit() {

        this.exportForm = this.fb.group({
            exchangeRate: [this._budget.export ? this._budget.export.exchangeRate : undefined, Validators.min(0)],
            design: [0, Validators.min(0)],
            shipping: [0, Validators.min(0)],
            customDuties: [0, Validators.min(0)]
        });

        this.listenForSubBudgetMaterials();

        if (!BudgetModel.isEditable(this._budget, this.auth.currentUser) && !this.authorizedToModify) {
            this.exportForm.disable();
        } else {
            this.exportForm.enable();
        }

        // Enable auto commit on change
        // this.onChange();
    }

    public listenForSubBudgetMaterials() {

        this.dataService.getSBMaterial(this._budget.id, 'AUTO_BUDGET', 'design')
            .pipe(
                filter((mat) => mat != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((mat: Material) => {
                this.exportForm.patchValue({
                        design: mat.costEach
                    },
                    {
                        emitEvent: false
                    });
            });

        this.dataService.getSBMaterial(this._budget.id, 'AUTO_BUDGET', 'shipping')
            .pipe(
                filter((mat) => mat != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((mat: Material) => {
                this.exportForm.patchValue({
                        shipping: mat.costEach
                    },
                    {
                        emitEvent: false
                    });
            });

        this.dataService.getSBMaterial(this._budget.id, 'AUTO_BUDGET', 'customDuties')
            .pipe(
                filter((mat) => mat != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((mat: Material) => {
                if (mat && mat.description) {
                    const customDutiesPerc = mat.description.split(' ')[0];
                    if (customDutiesPerc && Number(customDutiesPerc)) {
                        this.exportForm.patchValue({
                                customDuties: Number(customDutiesPerc)
                            },
                            {
                                emitEvent: false
                            });
                    }
                }

            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public refreshExchangeRate = () => {
        this.dataService.getExchangeRate('USD')
            .subscribe((r) => {

                this.exchangeRateHint = 'Rate update from https://fixer.io';
                this.justUpdatedFromFix = true;
                this.exportForm.patchValue({
                    exchangeRate: r
                });

            });
    }

    // // Auto commit values on the database if something changes in the form
    // private onChange(): void {
    //
    //   // EXCHANGE RATIO
    //   this.exportForm.get('exchangeRate').valueChanges.pipe(
    //     tap(() => {
    //       if (!this.justUpdatedFromFix) {
    //         this.exchangeRateHint = '';
    //       }
    //       this.justUpdatedFromFix = false;
    //     }))
    //     .pipe(
    //       debounce(() => timer(500)),
    //       filter(() => this.exportForm.get('exchangeRate').valid),
    //       distinctUntilChanged()
    //     )
    //     .subscribe(val => {
    //       const exportData = this._budget.export ? this._budget.export : {};
    //       exportData.exchangeRate = this.exportForm.value.exchangeRate;
    //       this.dataService.patchBudget(this._budget.id, {
    //         export: exportData
    //       })
    //         .then((data) => {
    //           console.log('Budget patched');
    //         });
    //     });
    //
    //   // DESIGN
    //   this.exportForm.get('design').valueChanges
    //     .pipe(
    //       debounce(() => timer(500)),
    //       filter(() => this.exportForm.get('design').valid),
    //       distinctUntilChanged()
    //     )
    //     .subscribe(val => {
    //       const designCost = this.exportForm.value.design;
    //       const batch = this.dataService.getBatch();
    //
    //       this.dataService.updateSubBudget(this._budget.id, 'AUTO_BUDGET',
    //         new SubBudgetModel('Overseas', GeneratedBy.Export, 'AUTO_BUDGET', true), batch);
    //
    //       this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'design', {
    //         description: 'Overseas Design',
    //         category: 'IUS',
    //         costEach: designCost,
    //         quantity: 1,
    //         unit: 'pieces'
    //       }, batch);
    //
    //       return batch.commit();
    //     });
    //
    //   // SHIPPING
    //   this.exportForm.get('shipping').valueChanges
    //     .pipe(
    //       debounce(() => timer(500)),
    //       filter(() => this.exportForm.get('shipping').valid),
    //       distinctUntilChanged()
    //     )
    //     .subscribe(val => {
    //       const exportShipping = this.exportForm.value.shipping;
    //       const batch = this.dataService.getBatch();
    //
    //       this.dataService.updateSubBudget(this._budget.id, 'AUTO_BUDGET',
    //         new SubBudgetModel('Overseas', GeneratedBy.Export, 'AUTO_BUDGET', true), batch);
    //
    //       this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'shipping', {
    //         description: 'Overseas Shipping',
    //         category: 'SPE',
    //         costEach: exportShipping,
    //         quantity: 1,
    //         unit: 'pieces'
    //       }, batch);
    //
    //       return batch.commit();
    //     });
    //
    //   // CUSTOM DUTIES
    //   this.exportForm.get('customDuties').valueChanges
    //     .pipe(
    //       debounce(() => timer(500)),
    //       filter(() => this.exportForm.get('customDuties').valid),
    //       distinctUntilChanged()
    //     )
    //     .subscribe(val => {
    //       const batch = this.dataService.getBatch();
    //
    //       const customDutiesPercentage = this.exportForm.value.customDuties;
    //       const exchangeRate = this.exportForm.value.exchangeRate;
    //       const customDutiesValue = this.calculateCustomDutiesValue(customDutiesPercentage, exchangeRate);
    //
    //       this.dataService.updateSubBudget(this._budget.id, 'AUTO_BUDGET',
    //         new SubBudgetModel('Overseas', GeneratedBy.Export, 'AUTO_BUDGET', true), batch);
    //
    //       this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'customDuties', {
    //         description: `${customDutiesPercentage} % Overseas Custom Duties`,
    //         category: 'CDT',
    //         costEach: customDutiesValue,
    //         quantity: 1,
    //         unit: 'pieces'
    //       }, batch);
    //
    //       return batch.commit();
    //     });
    // }

    calculateCustomDutiesValue(customDutiesPercentage: number, exchangeRate: number) {
        const productionIta = this._splittedCostsIta.find(i => i.id === 'production');
        if (productionIta) {
            const customDutiesValue = (Math.round((customDutiesPercentage * 0.01) * (productionIta.total * exchangeRate) * 100)) / 100;
            console.log('Calculated customduties:', customDutiesValue, customDutiesPercentage, exchangeRate, productionIta.total);
            return customDutiesValue ? customDutiesValue : 0;
        } else {
            return 0;
        }
    }

    async submit() {

        try {
            this.operationInProgress = true;
            const exportData = this._budget.export ? this._budget.export : {};
            exportData.exchangeRate = this.exportForm.value.exchangeRate;
            const batch = this.dataService.getBatch();

            await this.dataService.updateSubBudget(this._budget.id, 'AUTO_BUDGET',
                new SubBudgetModel('Overseas', GeneratedBy.Export, 'AUTO_BUDGET', false), batch);

            const exportDesign = this.exportForm.value.design;
            await this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'design', {
                description: 'Overseas Design & Project Management',
                category: 'IUS',
                costEach: exportDesign,
                quantity: 1,
                unit: 'pieces'
            }, batch);

            const exportShipping = this.exportForm.value.shipping;
            await this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'shipping', {
                description: 'Overseas Shipping',
                category: 'SPE',
                costEach: exportShipping,
                quantity: 1,
                unit: 'pieces'
            }, batch);

            const customDutiesPercentage = this.exportForm.value.customDuties;
            const exchangeRate = this.exportForm.value.exchangeRate;
            const customDutiesValue = this.calculateCustomDutiesValue(customDutiesPercentage, exchangeRate);
            await this.dataService.setSBMaterial(this._budget.id, 'AUTO_BUDGET', 'customDuties', {
                description: `${customDutiesPercentage} % Overseas Custom Duties`,
                category: 'CDT',
                costEach: customDutiesValue,
                quantity: 1,
                unit: 'pieces'
            }, batch);

            const data1 = await batch.commit();
            console.log('ExportForm - Budget patched, force updating costSummary..', data1, this._budget.id);
            await this.dataService.refreshCostSummary(this._budget.id).toPromise();
            console.log('ExportForm - cost summary force updated');
        } catch (err) {
            console.log(err);
        } finally {
            this.operationInProgress = false;
            this.updateCostSummary.emit(true);
        }

    }


    changeTab(label: string) {
        this.changeSelectedTab.emit(label);
    }
}

