<form #materialForm="ngForm" (ngSubmit)="onFormSubmit()">
    <mat-form-field>
        <input [(ngModel)]="model.description" i18n-placeholder id="description" matInput name="description" placeholder="Description"
               required type="text">
    </mat-form-field>
    <mat-form-field>
        <mat-select [(ngModel)]="model.unit" i18n-placeholder name="unit" placeholder="Unit" required>
            <mat-option *ngFor="let u of unities" [value]="u">
                {{ u }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <!-- <mat-form-field>
      <input matInput type="string" id="matrixCode" placeholder="Matrix Code" i18n-placeholder [(ngModel)]="model.matrixCode" name="matrixCode">
    </mat-form-field> -->
    <mat-form-field>
        <input [(ngModel)]="model.costEach" i18n-placeholder id="costEach" matInput name="costEach" placeholder="Cost Each"
               required type="number">
    </mat-form-field>
    <!-- <mat-form-field>
        <input matInput type="text" id="marking" placeholder="Marking" i18n-placeholder required [(ngModel)]="model.marking" name="marking">
    </mat-form-field> -->
    <!--<mat-form-field>
      <input type="text" placeholder="Vendor" [(ngModel)]="model.vendor" matInput [matAutocomplete]="autoVendor">
      <mat-autocomplete #autoVendor="matAutocomplete" [displayWith]="vendorDisplayFn" (optionSelected)="vendorChange($event)">
        <mat-option *ngFor="let vendor of filteredVendors | async" [value]="vendor">
          {{ vendor.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>-->
    <mat-form-field>
        <input [(ngModel)]="model.uniqueCode" i18n-placeholder id="uniqueCode" matInput name="uniqueCode" placeholder="Unique Code"
               required type="text">
    </mat-form-field>
    <mat-form-field>
        <input [(ngModel)]="model.type" i18n-placeholder id="type" matInput name="type" placeholder="Type" required
               type="text">
    </mat-form-field>
    <div class="button-row">
        <button [disabled]="!materialForm.form.valid" i18n mat-raised-button
                type="submit">{{ editMode && type != 'copy' ? 'Update storage' : 'Add storage' }}
        </button>
        <button i18n mat-dialog-close mat-raised-button type="button">Cancel</button>
    </div>
</form>