import {map, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {BehaviorSubject, of} from 'rxjs';
import {Settings} from '../interfaces/settings';
import {calculateCustomerFromHostname} from '../util';
import {AuthService} from './auth.service';



@Injectable({providedIn: 'root'})
export class ConfigService {

    private _settings$ = new BehaviorSubject<Settings>(null);
    private readonly cDR: AngularFirestoreDocument<any>;

    constructor(private afs: AngularFirestore, private authService: AuthService) {

        const CUSTOMER_DOMAIN = calculateCustomerFromHostname(document.location.hostname);
        console.log('Configservice setting customerDocumentReference. Domain inferred from the URL: ', CUSTOMER_DOMAIN);
        this.cDR = this.afs.collection('Customers').doc(CUSTOMER_DOMAIN);

        // Subscribe to Settings
        this.authService.userStream$
            .pipe(switchMap(user => {

                console.log('User in ConfigService: ', user);
                if (user) {
                    return this.cDR.collection<Settings>('Settings',
                        ref => ref.where('latest', '==', true)).valueChanges().pipe(
                        map((aat) => aat[0]),
                        tap((sett) => console.log('Settings have changed!', sett)));
                } else {
                    return of(null);
                }
            })).subscribe(this._settings$);

    }

    get settings(): BehaviorSubject<Settings> {
        return this._settings$;
    }

    public getUserDashboardLink = (useremail: string) => {
        return this.cDR.collection<any>('DashboardLinks', ref => ref.where('email', '==', useremail)).get();
    }

}
