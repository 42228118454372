<div class="example-container mat-elevation-z8 table-container">
    <mat-progress-bar *ngIf="tableIsLoading$ | async" mode="indeterminate" style="width:100%"></mat-progress-bar>
    <table [dataSource]="dataSource" mat-table matSort matSortActive="invoiceDate" matSortDirection="desc"
           matSortDisableClear style="width:100%">

        <ng-container matColumnDef="select">
            <th *matHeaderCellDef i18n mat-header-cell>Select</th>
            <td *matCellDef="let row" mat-cell>
                <mat-checkbox (change)="toggleInvoiceSelection(row, $event)" *ngIf="displayAccountingCheckbox(row)"
                              [checked]="isInSelection(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="invoiceNr">
            <th *matHeaderCellDef i18n mat-header-cell mat-sort-header>Invoice Nr.</th>
            <td *matCellDef="let row" mat-cell>
                <span *ngIf="row.invoiceNr2">{{ row.invoiceNr }}/{{ row.invoiceNr2 }}</span>
                <span *ngIf="!row.invoiceNr2">{{ row.invoiceNr }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="downloadUrl">
            <th *matHeaderCellDef i18n mat-header-cell>Document</th>
            <td *matCellDef="let row" mat-cell>
        <span *ngIf="row.downloadUrl">
          <a [href]="row.downloadUrl" target="_blank">
            <mat-icon matListIcon>attach_file</mat-icon>
          </a>
        </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="note">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
            <td *matCellDef="let row" mat-cell> {{ row.note }}</td>
        </ng-container>

        <ng-container matColumnDef="vendor">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Vendor</th>
            <td *matCellDef="let row" mat-cell>
                <div> {{ row.order?.vendor?.name }}</div>
                <a *ngIf="row.order.primaryContact?.emails" [href]="'https://mail.google.com/mail/u/0/?view=cm&fs=1&to='+ row.order.primaryContact.emails[0].email"
                   target="_blank">
          <span class="author-email">
            {{ row.order?.primaryContact?.name }}
          </span>
                    <span class="author-email">
            &#x3C;{{ row.order?.primaryContact?.emails[0].email }}&#x3E;
          </span>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="orderNr">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Order Nr.</th>
            <td *matCellDef="let row" mat-cell><a [routerLink]="['/order/view',row.order.id]"
                                                  target="_blank">{{ row.order.orderNr }}</a></td>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
            <th *matHeaderCellDef i18n mat-header-cell mat-sort-header>Invoice Date</th>
            <td *matCellDef="let row" mat-cell> {{ row.invoiceDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th *matHeaderCellDef i18n mat-header-cell>Amount</th>
            <td *matCellDef="let row" mat-cell><span
                    *ngIf="row.totalAmount"> {{ row.totalAmount | currency:'EUR' }} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="sentToAccounting">
            <th *matHeaderCellDef i18n mat-header-cell>Sent To Accounting</th>
            <td *matCellDef="let row" mat-cell>
                <mat-icon *ngIf="row.sentToAccounting" matListIcon>done</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef i18n mat-header-cell>Actions</th>
            <td *matCellDef="let row" mat-cell>
                <a [routerLink]="['/invoices',row.id]" mat-icon-button>
                    <mat-icon *ngIf="!row.sentToAccounting">mode_edit</mat-icon>
                    <mat-icon *ngIf="row.sentToAccounting">visibility</mat-icon>
                </a>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator [length]="totalElements" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>