import {Injectable} from '@angular/core';
import {Invoice} from '@wondersys/wonderbudget-lib';
import {Observable, of, ReplaySubject} from 'rxjs';
import {DataService} from './data.service';
import {catchError, map, tap} from 'rxjs/operators';



export interface InvoicesLIstFilter {
    orderNr: string,
    invoiceNr: string,
    vendor: string,
    sortActive?: string,
    sortDirection?: string,
}

export interface InvoicesListResponse {
    data: Invoice[],
    totalSize: number
}

@Injectable({
    providedIn: 'root'
})
export class InvoicesListTableService {

    private appliedFilterAndSorting: ReplaySubject<InvoicesLIstFilter> = new ReplaySubject();
    private appliedFilter: InvoicesLIstFilter;

    private performingQuickSerch: ReplaySubject<boolean> = new ReplaySubject();
    private performingSlowSearch: ReplaySubject<boolean> = new ReplaySubject();

    constructor(
        private dataService: DataService
    ) {
    }

    public get appliedFilterAndSorting$(): Observable<InvoicesLIstFilter> {
        return this.appliedFilterAndSorting.asObservable();
    }

    public loadData(mode: 'quick' | 'slow', pageIndex: number, pageSize: number): Observable<InvoicesListResponse> {
        switch (mode) {
            case 'quick':
                return this.getQuickList();
            case 'slow':
                return this.getSlowList(pageIndex, pageSize);
        }
    }

    public isLoading$(mode: 'quick' | 'slow'): Observable<boolean> {
        return (mode === 'quick') ? this.performingQuickSerch.asObservable() : this.performingSlowSearch.asObservable();
    }

    retrieveFiltersFromStorage(): InvoicesLIstFilter {
        const filter = {
            orderNr: (localStorage.getItem('invoiceList_orderNr')) ? localStorage.getItem('invoiceList_orderNr') : null,
            invoiceNr: (localStorage.getItem('invoiceList_invoiceNr')) ? localStorage.getItem('invoiceList_invoiceNr') : null,
            vendor: (localStorage.getItem('invoiceList_vendor')) ? localStorage.getItem('invoiceList_vendor') : null,
            sortDirection: (localStorage.getItem('invoiceList_sortDirection')) ? localStorage.getItem('invoiceList_sortDirection') : 'desc',
            sortActive: (localStorage.getItem('invoiceList_sortActive')) ? localStorage.getItem('invoiceList_sortActive') : 'invoiceDate'
        };

        this.appliedFilter = filter;
        this.appliedFilterAndSorting.next(filter);

        return filter;
    }

    storeFilters(filter: InvoicesLIstFilter) {
        if (this.compareFilters(filter, this.appliedFilter)) {
            localStorage.setItem('invoiceList_invoiceNr', filter.invoiceNr ? filter.invoiceNr : '');
            localStorage.setItem('invoiceList_vendor', filter.vendor ? filter.vendor : '');
            localStorage.setItem('invoiceList_orderNr', filter.orderNr ? filter.orderNr : '');
            localStorage.setItem('invoiceList_sortActive', filter.sortActive);
            localStorage.setItem('invoiceList_sortDirection', filter.sortDirection);

            this.appliedFilter = filter;
            this.appliedFilterAndSorting.next(filter);
        }
    }

    storeSorting(sortActive: string, sortDirection: string) {
        this.storeFilters({...this.appliedFilter, sortActive: sortActive, sortDirection: sortDirection});
    }

    compareFilters(actual: InvoicesLIstFilter, old: InvoicesLIstFilter) {
        if (!old) return true;
        return !Object.keys(actual).reduce((a: boolean, k: string) => a && actual[k] === old[k], true);
    }

    public bulkSendToAccounting(selectedInvoices: Invoice[]) {
        const bulkSend = selectedInvoices.map(inv => {
            const invoice = <Invoice>inv;
            invoice.sentToAccounting = true;
            invoice.registeredToCosmo = 'ready';
            invoice.totalAmount = this.setTotalInvoiceAmount(invoice.items);
            invoice.paymentPurpose = this.setPaymentPurpose(invoice.items);
            return this.dataService.updateInvoices(inv.id, invoice);
        });

        Promise.all(bulkSend).catch(err => {
            console.log('Error while bulk sending to accounting', err);
        });
    }

    private getQuickList(): Observable<InvoicesListResponse> {
        this.performingQuickSerch.next(true);
        return this.dataService.getQuickInvoicesList().pipe(
            map((d) => {
                return {data: d, totalSize: d.length}
            }),
            tap(() => this.performingQuickSerch.next(false)),
            catchError((error) => {
                this.performingQuickSerch.next(false);
                console.error('Error getting quick orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }

    private getSlowList(pageIndex: number, pageSize: number): Observable<InvoicesListResponse> {
        console.log('getting slow data: ', pageIndex, pageSize)
        let startAt = pageIndex * pageSize;
        if (pageIndex !== 0) startAt++;
        this.performingSlowSearch.next(true);
        return this.dataService.getInvoicesListPaginated(pageSize, startAt, this.appliedFilter).pipe(
            tap(() => this.performingSlowSearch.next(false)),
            catchError((error) => {
                this.performingSlowSearch.next(false);
                console.error('Error getting slow orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }

    private setTotalInvoiceAmount(items) {
        let totalAmount = 0;
        items.forEach(item => {
            if (item.selected) {
                totalAmount = totalAmount + parseFloat(item.amount);
            }
        });

        return totalAmount;
    }

    private setPaymentPurpose(items) {
        let positiveAmounts = 0;
        let negativeAmounts = 0;
        let paymentPurpose = 'ACQ';
        items.forEach(item => {
            if (item.selected) {
                item.amount > 0 ? positiveAmounts++ : negativeAmounts++;
            }
        });

        if (positiveAmounts > 0 && negativeAmounts === 0) {
            paymentPurpose = 'ACQ';
        }
        if (negativeAmounts > 0 && positiveAmounts === 0) {
            paymentPurpose = 'RF';
        }

        return paymentPurpose;
    }
}
