import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';



@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.css'],
    template: `
  <router-outlet></router-outlet>
`
})
export class AppComponent {
    title = 'app';

    constructor(
        public router: Router) {
        if (window.location.hostname.indexOf('evtest.wonder-sys.com') !== -1) {
            document.getElementById('favicon').setAttribute('href', '/assets/img/logoTISv3.ico');
        }
        if (window.location.hostname.indexOf('extraglass') !== -1) {
            document.getElementById('favicon').setAttribute('href', '/assets/img/extraglass.ico');
        }
    }
}
