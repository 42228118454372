import {Pipe, PipeTransform} from '@angular/core';
import {Budget} from '../../budget/interfaces';



@Pipe({
    name: 'projectNr'
})
export class ProjectNrPipe implements PipeTransform {

    transform(budget: Partial<Budget>, useBudgetNr?: boolean): any {
        // budget.project?.internal?'i':'' }}{{ budget.project?.projectNr

        if (!budget.project && !useBudgetNr) {
            return '';
        } else if (!budget.project) {
            return budget.budgetNr;
        }
        let prefix = '';
        if (budget.project.internal) {
            prefix = 'i';
        }

        if (budget.project.promo) {
            prefix += 'p';
        }

        return prefix + budget.project.projectNr;

    }

}
