<div class="detail-card-container">
    <mat-card>
        <mat-card-title i18n="Title of the list of external goods to be purchased">
            Storage
            <button (click)="openMaterialDialog($event, 'add')" color="accent" mat-fab>
                <mat-icon>add</mat-icon>
            </button>
        </mat-card-title>
        <mat-table #table [dataSource]="storageDataSource">
            <!-- Nome Column -->
            <!-- <ng-container matColumnDef="matrixCode">
                <mat-header-cell *matHeaderCellDef i18n>Matrix Code</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.matrixCode }} </mat-cell>
            </ng-container> -->

            <!-- Costo Column -->
            <!-- <ng-container matColumnDef="marking">
                <mat-header-cell *matHeaderCellDef i18n>Marking</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.marking }} </mat-cell>
            </ng-container> -->

            <!-- Category Column -->
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef i18n>Description</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.description }}</mat-cell>
            </ng-container>

            <!-- Costo Column -->
            <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef i18n>Unit</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.unit }}</mat-cell>
            </ng-container>

            <!-- Ore Column -->
            <ng-container matColumnDef="costEach">
                <mat-header-cell *matHeaderCellDef i18n>Cost Each</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.costEach }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="vendor">
                <mat-header-cell *matHeaderCellDef i18n>Vendor</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.vendor }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="uniqueCode">
                <mat-header-cell *matHeaderCellDef i18n>Unique Code</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.uniqueCode }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef i18n>Type</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.type }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef i18n>Revision Date</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.date }}</mat-cell>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef i18n>Actions</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button mat-icon-button title="Edit Material Entry">
                        <mat-icon (click)="openMaterialDialog($event, 'edit', row)">edit</mat-icon>
                    </button>
                    <button mat-icon-button title="Copy Material Entry">
                        <mat-icon (click)="openMaterialDialog($event, 'copy', row)">file_copy</mat-icon>
                    </button>
                    <button mat-icon-button title="Delete Storage Entry">
                        <mat-icon (click)="deleteStorage($event, row)">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <!--<mat-card-actions>
                <button mat-fab color="accent" (click)="openMaterialDialog($event)">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-card-actions>-->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </mat-card>
</div>
