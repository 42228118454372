import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BudgetListComponent} from './budget-list.component';
import {BudgetDetailComponent} from './budget-detail.component';
import {MaterialDialogComponent, SubBudgetComponent} from './sub-budget.component';
import {MaterialModule} from '../material.module';
import {SubBudgetFormComponent} from './components/sub-budget-form.component';
import {MaterialFormComponent} from './components/material-form.component';
import {MarginFormComponent} from './components/margin-form/margin-form.component';
import {SharedModule} from '../shared/shared.module';
import {AssemblyCostsFormComponent} from './components/assembly-costs/assemblyCosts-form.component';
import {ExportFormComponent} from './components/export-form/export-form.component';
import {RouterModule} from '@angular/router';
import {BudgetGuard} from './guards/budget.guard';
import {SummaryChartComponent} from './components/summary-chart/summary-chart.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {SubBudgetResumeComponent} from './sub-budget-resume/sub-budget-resume.component';
import {OpenProjectDialogComponent} from './open-project-dialog/open-project-dialog.component';
import {ChangeFinalofferDialogComponent} from './change-finaloffer-dialog/change-finaloffer-dialog.component';
import {BudgetListTableComponent} from './budget-list-table/budget-list-table.component';
import {SplittedCostsTableComponent} from './components/splitted-costs-table/splitted-costs-table.component';
import {ContactPickerDialogComponent} from './contact-picker-dialog/contact-picker-dialog.component';
import {ArchiveProjectDialogComponent} from './archive-project-dialog/archive-project-dialog.component';
import {DocumentEditorDialogComponent} from './doc-editor-dialog/doc-editor-dialog.component';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxChartsModule
    ],
    exports: [MaterialDialogComponent],
    declarations: [
        BudgetListComponent,
        BudgetDetailComponent,
        SubBudgetComponent,
        SubBudgetFormComponent,
        ContactPickerDialogComponent,
        OpenProjectDialogComponent,
        ArchiveProjectDialogComponent,
        DocumentEditorDialogComponent,
        MaterialFormComponent,
        MaterialDialogComponent,
        MarginFormComponent,
        AssemblyCostsFormComponent,
        ExportFormComponent,
        SummaryChartComponent,
        SubBudgetResumeComponent,
        ChangeFinalofferDialogComponent,
        BudgetListTableComponent,
        SplittedCostsTableComponent
    ],
    entryComponents: [ContactPickerDialogComponent,
        DocumentEditorDialogComponent,
        OpenProjectDialogComponent,
        ArchiveProjectDialogComponent,
        MaterialDialogComponent,
        ChangeFinalofferDialogComponent], // https://angular.io/guide/ngmodule-faq#why-does-angular-need-entrycomponents
    providers: [BudgetGuard]
})
export class BudgetModule {
}
