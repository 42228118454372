import {Storage} from '../../interfaces/storage';



export class StorageModel {

    constructor(
        public description?: string,
        //public matrixCode?: string,
        public costEach?: number,
        public unit?: string,
        //public marking?: string,
        //public vendor?: string,
        public uniqueCode?: string,
        public type?: string,
        public date?: Date,
        public id?: string
    ) {
    }

    public static fromObj(s: Storage): StorageModel {

        return new StorageModel(
            s.description,
            //s.matrixCode,
            s.costEach,
            s.unit,
            //s.marking,
            //s.vendor,
            s.uniqueCode,
            s.type,
            s.date,
            s.id);
    }

    public toPlainObj(): Storage {
        const obj: Storage = {
            description: this.description,
            costEach: this.costEach,
            unit: this.unit,
            //marking: this.marking,
            //vendor: this.vendor,
            uniqueCode: this.uniqueCode,
            type: this.type,
            date: new Date()
        };
        // if (this.matrixCode) {
        //     obj.matrixCode = this.matrixCode;
        // }
        return obj;
    }


}
