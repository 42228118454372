import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject, Subscription} from 'rxjs';
import {
    TimereportListFilter,
    TimereportListResponse,
    TimereportListTableService
} from '../../services/timereport-list-table.service';
import {Timereport} from '../interfaces';
import {filter, takeUntil} from 'rxjs/operators';
import {ProjectNrPipe} from '../../shared/pipes/project-nr.pipe';
import {firestoreDateToJsDate} from '../../shared/utils/utils';



@Component({
    selector: 'app-timereport-list-table',
    templateUrl: './timereport-list-table.component.html',
    styleUrls: ['./timereport-list-table.component.scss']
})
export class TimereportListTableComponent implements OnInit {

    @Input() searchMode: 'quick' | 'slow';

    @Input() canChangeUser: boolean;

    @Output() updateTotalElements: EventEmitter<number> = new EventEmitter<number>();

    totalElements = 0;
    appliedFilter: TimereportListFilter;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    dataSource = new MatTableDataSource<Timereport>();
    displayedColumns = [
        'sent',
        'budgetId',
        'activityId',
        'hours',
        'date',
        'userId',
        'description',
        'actions'
    ];
    pageSizeOptions = [5, 10, 25, 100];
    public disableAllActions: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private firstLoad = true;
    private isLoadingData: Subscription;

    constructor(
        private timereportListTableService: TimereportListTableService,
        private projectNrPipe: ProjectNrPipe
    ) {
    }

    get tableIsLoading$() {
        return this.timereportListTableService.isLoading$(this.searchMode);
    }

    ngOnInit(): void {
        this.paginator.pageSize = 10;

        this.timereportListTableService.appliedFilterAndSorting$.pipe(
            filter(f => f.fromDate !== null && f.toDate !== null),
            filter(newFilter => this.timereportListTableService.compareFilters(newFilter, this.appliedFilter)),
            takeUntil(this.unsubscribe$)
        ).subscribe(inputFilter => this.handleFilterChange(inputFilter));

        if (this.searchMode === 'slow') {
            this.timereportListTableService.refreshSlowTable$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {
        this.sort.sortChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.paginator.pageIndex = 0;
                this.timereportListTableService.storeSorting(this.sort.active, this.sort.direction);
            });

        if (this.searchMode === 'slow') {
            this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
        } else {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.quickTableFilterPredicate();
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = this.quickTableSort();
        }
    }

    editTimereport(event: Event, tr: Timereport) {
        event.stopPropagation();
        this.timereportListTableService.editTimereport(tr.id);
    }

    async deleteTimereport(event: Event, tr: Timereport) {
        event.stopPropagation();
        await this.timereportListTableService.deleteTimereport(tr);
        this.loadData();
    }

    loadData() {
        console.log(this.searchMode, ' loading data...');

        if (this.searchMode === 'slow') {
            // empty the slow table while waiting for a new response
            this.dataSource = new MatTableDataSource<Timereport>();
            if (this.isLoadingData) {
                // delete pending requests caused by quick change of the filter/sort
                this.isLoadingData.unsubscribe();
            }
        }

        this.disableAllActions = true;
        this.isLoadingData = this.timereportListTableService.loadData(this.searchMode, this.paginator.pageIndex, this.paginator.pageSize)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                this.disableAllActions = false;
                if (!result || !result.data) {
                    return;
                }

                for (const timereport of result.data) {
                    timereport.date = timereport.date ? (timereport.date['seconds']) ?
                        new Date(timereport.date['seconds'] * 1000) : new Date(timereport.date['_seconds'] * 1000) : null;
                }

                console.log(this.searchMode, ' received data:', result);
                this.setDataSource(result);
            });
    }

    private handleFilterChange(inputFilter: TimereportListFilter) {
        this.restoreSorting(inputFilter);
        const newFromDate = this.appliedFilter?.fromDate.getTime() - inputFilter.fromDate.getTime();
        const newToDate = this.appliedFilter?.toDate.getTime() - inputFilter.toDate.getTime();
        const dateRangeChanged = newFromDate || newToDate;

        this.appliedFilter = {...inputFilter};
        this.paginator.pageIndex = 0;

        // for the slow table dataSource.filter is not meaningful
        if (this.searchMode === 'quick') {
            this.dataSource.filter = JSON.stringify(inputFilter);

            if (dateRangeChanged) {
                // if the date range changes reload data from db (need to update the query)
                this.dataSource.data = [];
                this.loadData();
                return;
            }
        }

        // for the quick table load data only once
        if (this.searchMode === 'slow' || this.firstLoad) {
            this.loadData();
        }
    }

    private restoreSorting(inputFilter: TimereportListFilter) {
        const needRefresh = this.sort.active !== inputFilter.sortActive || this.sort.direction !== inputFilter.sortDirection;
        this.sort.active = (inputFilter.sortActive) ? inputFilter.sortActive : 'date';
        this.sort.direction = (inputFilter.sortDirection) ? inputFilter.sortDirection as SortDirection : 'desc';
        if (needRefresh) {
            this.sort.sortChange.emit();
        }
    }

    private setDataSource(response: TimereportListResponse) {
        if (this.searchMode === 'quick') {
            this.dataSource.data = response.data as any;
        } else {
            this.dataSource = response.data as any;
        }

        this.totalElements = response.totalSize;
        this.paginator.length = response.totalSize;

        if (this.firstLoad) {
            this.firstLoad = false;
            // this will force the table to apply the initial filter and update the paginator values
            setTimeout(() => this.dataSource.filter = JSON.stringify(this.appliedFilter));
        }

        this.updateTotalElements.emit(this.totalElements);
    }

    private quickTableFilterPredicate(): (timereport: Timereport, filter: string) => boolean {
        return (timereport: Timereport, inputFilter: string) => {
            let filterCondition = true;
            let fromDateCondition = true;
            let toDateCondition = true;

            if (this.appliedFilter.filter) {
                const userMatch = timereport.user.displayName?.toLowerCase().includes(this.appliedFilter.filter.toLowerCase());
                const activityMatch = timereport.activity.activity.toLowerCase().includes(this.appliedFilter.filter.toLowerCase());
                const projectNrSearchString = this.projectNrPipe.transform(timereport.budget);
                const projectNrMatch = projectNrSearchString.toLowerCase().includes(this.appliedFilter.filter.toLowerCase());
                const budgetNrMatch = timereport.budget?.budgetNr?.toLowerCase().includes(this.appliedFilter.filter.toLowerCase());

                filterCondition = budgetNrMatch || projectNrMatch || activityMatch || userMatch;
            }

            fromDateCondition = (timereport.date as Date).getTime() > this.appliedFilter.fromDate.getTime();
            toDateCondition = (timereport.date as Date).getTime() < this.appliedFilter.toDate.getTime();

            return filterCondition && fromDateCondition && toDateCondition;
        };
    }

    private quickTableSort(): (items: Timereport[], sort: MatSort) => Timereport[] {
        return (items: Timereport[], sort: MatSort) => {
            return items.sort((a: Timereport, b: Timereport) => {

                let propertyA: number | string | Date = '';
                let propertyB: number | string | Date = '';

                switch (this.sort.active) {
                    case 'budget':
                        const abid = (typeof a.budget === 'object') ? a.budget.budgetNr : a.budget;
                        const bbid = (typeof b.budget === 'object') ? b.budget.budgetNr : b.budget;
                        [propertyA, propertyB] = [abid, bbid];
                        break;
                    case 'userId':
                        [propertyA, propertyB] = [a.user.displayName, b.user.displayName];
                        break;
                    case 'activityId':
                        [propertyA, propertyB] = [a.activity.activity, b.activity.activity];
                        break;
                    case 'hours':
                        [propertyA, propertyB] = [a.hours, b.hours];
                        break;
                    case 'date':
                        [propertyA, propertyB] = [firestoreDateToJsDate(a.date), firestoreDateToJsDate(b.date)];
                        break;
                    case 'user':
                        [propertyA, propertyB] = [a.user.email, b.user.email];
                        break;
                }

                let valueA;
                let valueB;

                if (propertyA instanceof Date) {
                    valueA = propertyA;
                    valueB = propertyB;
                } else {
                    valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                    valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                }

                return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
            });
        };
    }
}
