import {first, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import {AppStateService} from '../services/app-state.service';
import {AuthService} from '../services/auth.service';

import * as firebase from 'firebase/app';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject, Subject} from 'rxjs';



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    /**
     * Set our default values
     */
    public localState = {value: ''};
    public isLoginError = false;

    public authCheckEmitter$ = new BehaviorSubject<boolean>(false);
    public canSeeBudgets$;
    public userStream$;

    private unsubscribe$ = new Subject();

    /**
     * TypeScript public modifiers
     */
    constructor(
        public appState: AppStateService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private _document: Document,
        private _changeDetectionRef: ChangeDetectorRef
    ) {
    }

    get authDomain() {
        if (this.authService) {
            return this.authService.appDomain;
        } else {
            return '';
        }
    }

    public ngOnInit() {
        // Start loading auth data
        this.route.queryParams
            .subscribe(params => {
                this.setLoginError(params['loginError']);
            });


        this.userStream$ = this.authService.userStream$;

        this.authService.userStream$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((user) => {
                this.authCheckEmitter$.next(true);
                this._changeDetectionRef.detectChanges();
            }, (err) => {
                console.log('Error occured on user stream. ', err);
                this.authCheckEmitter$.next(false);
            });

        this.canSeeBudgets$ = this.authService.userHasPermission('app.budget');

        if (window.location.hostname.indexOf('evtest.wonder-sys.com') !== -1) {
            console.log('TIS app. Loading TIS home assets.', window.location.hostname)
            this._document.getElementById('logo-home').setAttribute('src', '/assets/img/logoTISv3.ico');
            this._document.getElementById('logo-home').setAttribute('class', 'logo-tis');
            this._document.getElementById('main').style.backgroundImage = 'url(\'/assets/img/sfondo_tis.jpg\')';
            this._document.getElementById('main').style.backgroundSize = '100%';
            this._document.getElementById('main').style.backgroundPosition = 'center';
        }

        if (window.location.hostname.indexOf('extraglass') !== -1) {
            console.log('Extraglass app. Loading Extraglass home assets.', window.location.hostname)
            this._document.getElementById('logo-home').setAttribute('src', '/assets/img/extraglass.png');
            this._document.getElementById('logo-home').setAttribute('class', 'logo-extraglass');
            this._document.getElementById('main').style.backgroundImage = 'url(\'/assets/img/sfondo-eg.jpg\')';
            this._document.getElementById('main').style.backgroundSize = '100%';
            this._document.getElementById('main').style.backgroundPosition = 'center';
        }
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public loginWithGoogle() {
        this.authService.signInWithGoogle();
    }

    public enterTheApp() {
        console.log('Enter the app');
        this.authService.userHasPermission('app.budget').pipe(
            first()).toPromise()
            .then((val) => {
                if (val) {
                    this.router.navigate(['/budget']).then(res => console.log('ho navigato!', res));
                } else {
                    this.router.navigate(['/timereport']);
                }
            });
    }


    public logingWithFireBase() {
        console.log('fired logingWithFireBase');
        firebase.auth().signInWithPopup(this.authService.provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const token = result.credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log('logged in with Firebase, resulting user: ', user);
            // console.log('token', token);

            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/budget';
            // Redirect the user
            console.log('Redirecting to...', redirect);
            this.router.navigate([redirect]);
            this._changeDetectionRef.detectChanges();
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(`Error occurred during login: ${errorCode} : ${errorMessage}`);
            this.setLoginError(true);
            // The email of the user's account used.
            const email = error.email;
            // The firebase.default.auth.AuthCredential type that was used.
            const credential = error.credential;
            // ...
        });
    }

    public logoutWithFireBase() {
        console.log('logoutUser');
        this.authService.logout().then(() => {
            this.router.navigate(['/home']);
        });
    }

    public setLoginError(isError) {
        this.isLoginError = isError == 'true' ? isError : false;
        console.log('ISLOGINERROR', this.isLoginError);
    }

}
