import {filter, switchMap, takeUntil} from 'rxjs/operators';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {AppStateService} from '../services/app-state.service';
import {DataService} from '../services/data.service';
import {Observable, Subject, of} from 'rxjs';
import {Invoice, Order} from '@wondersys/wonderbudget-lib';



@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService,
        private dataService: DataService
    ) {
    }

    private iId;
    public invoice: Invoice;
    public unsubscribe$ = new Subject();

    reloadSubject: Subject<void> = new Subject<void>();

    handleInvoiceAdd(newInvoice: Invoice) {
        console.log('invoice added event captured.', newInvoice);
        this.reloadSubject.next();
    }

    ngOnInit() {
        this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('id')),
            switchMap((params: ParamMap) => {
                this.iId = params.get('id');
                console.log('invoiceID: ', this.iId);
                return this.dataService.getInvoice(this.iId);
            }),
            switchMap((invoice) => {
                if (!invoice) {
                    this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this invoices id'});
                    this.router.navigate(['/invoices']);
                    return of(null)
                } else {
                    invoice.id = this.iId;
                    this.invoice = invoice;
                    return this.dataService.getOrder(invoice.orderId)
                }
            }),
            takeUntil(this.unsubscribe$))
            .subscribe((order) => {
                if (order) {
                    this.invoice.order = order;
                    console.log('Invoice: ', this.invoice);
                    console.log('Order: ', this.invoice.order);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
