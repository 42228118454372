import {Component, Input, OnInit} from '@angular/core';



@Component({
    selector: 'app-summary-chart',
    templateUrl: './summary-chart.component.html',
    styleUrls: ['./summary-chart.component.scss']
})
export class SummaryChartComponent implements OnInit {

    chartData: any[] = [];
    view: any[] = [undefined, 300];

    // single: any[];
    // multi: any[];
    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = false;
    xAxisLabel = 'Country';
    showYAxisLabel = false;
    yAxisLabel = 'Population';
    colorScheme = 'vivid';

    @Input() set data(data: any[]) {

        this.chartData = data;
    }

    onSelect(event) {
        console.log(event);
    }

    ngOnInit() {

    }

}
