import {map, take} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';
import {DataService} from '../services/data.service';
import {combineLatest, Subscription} from 'rxjs';
import {StatField, StatReport} from '@wondersys/wonderbudget-lib';
import {Timestamp} from '@firebase/firestore-types';
import {AppUser} from '../interfaces/app-user';
import {User} from '../users/interfaces/users';



export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY'
    }
};


@Component({
    selector: 'app-statcollector',
    templateUrl: './statcollector.component.html',
    styleUrls: ['./statcollector.component.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
})
export class StatcollectorComponent implements OnInit {

    public disableSubmit = false;

    public statsForm: FormGroup;
    public maxDate = new Date();
    public today: moment.Moment;
    public todaysWeek: number;
    public selectedWeek: number;

    public selectedUser: AppUser;
    public selectedDate: Date;

    public isCurrentUserSelected: boolean = true;
    public mainSubscription: Subscription;
    public currentReport: StatReport;
    public currentUserStatFields: Array<StatField> = [];
    public projectEfficencyOptions = [
        //{ name: 'NESSUNO', value: -1 },
        {name: 'ROSSO', value: 0},
        {name: 'GIALLO', value: 1},
        {name: 'VERDE', value: 2}
    ];
    public users = []
    private _canModifyAll;

    constructor(private dataService: DataService,
                private authService: AuthService,
                public dialog: MatDialog,
                private _snackbar: MatSnackBar) {
    }

    ngOnInit() {

        this.today = moment().tz('Europe/Rome');
        this.todaysWeek = this.today.isoWeek();

        this.selectedDate = this.today.toDate();
        this.selectedUser = this.authService.currentUser;

        this.dataService.getActiveUserList().pipe(take(1)).pipe(
            map((userList) => userList.map((user: any) => {
                const data = user.payload.doc.data() as User;
                const id = user.payload.doc.id;
                return {id: id, ...data};
            }))).subscribe(users => {
            this.users = users;
        });

        // initially, load current user data
        this.loadUserData(this.selectedUser, this.selectedDate);

        this.authService.userHasPermission('app.stats.modify.all')
            .subscribe((val) => {
                this._canModifyAll = val;
            });
    }

    public save(formValue: any, valid) {
        console.log('is valid? submitting report..', valid, this.statsForm.errors, formValue);
        if (!valid || this.disableSubmit) return;
        this.disableSubmit = true;

        formValue.lastModifiedDate = new Date();
        formValue.user = this._project({
            id: 1,
            googleUid: 1,
            email: 1,
            displayName: 1,
            personalName: 1
        })(this.selectedUser);
        console.log(`report exists for this week? ${this.currentReport}`, this.currentReport, typeof (this.currentReport))

        if (this.currentReport) {

            // edit
            console.log('edit doc: ', formValue);
            formValue.lastModifiedBy = this.authService.currentUser.email;
            this.dataService.updateStatReport(this.currentReport.id, formValue).then(res => {
                this._snackbar.open('Report submitted', 'close', {duration: 4000});
                this.loadUserData(this.selectedUser, this.selectedDate);
            });
        } else {

            // create
            console.log('creating doc: ', formValue, this.selectedDate);
            formValue.creationDate = this.selectedDate;
            this.dataService.addStatReport(formValue).then(res => {
                this._snackbar.open('Report submitted', 'close', {duration: 4000});
                this.loadUserData(this.selectedUser, this.selectedDate);
            });
        }
    }

    public ngOnDestroy() {
        if (this.mainSubscription) this.mainSubscription.unsubscribe();
    }

    public setSelectedUser(event) {
        if (!event || !event.value) return;

        this.isCurrentUserSelected = this.authService.currentUser.email === event.value.email ? true : false;
        console.log('set selected user', event);
        this.selectedUser = event.value;
        this.loadUserData(this.selectedUser, this.selectedDate);
    }

    public changeDate(event) {
        if (!event || !event.value) return;

        console.log('changed date:', event.value.toDate(), typeof (event.value.toDate()));
        this.selectedDate = event.value.toDate();
        this.loadUserData(this.selectedUser, this.selectedDate);
    }

    compareObjects(o1: any, o2: any): boolean {
        if (!o1 || !o2) return false;
        return o1.email === o2.email;
    }

    private loadUserData(user: AppUser, refDate: Date) {
        this.currentReport = undefined;
        this.disableSubmit = true;

        this.selectedWeek = moment(refDate).isoWeek();
        console.log(`quering for user ${user.email} and date ${refDate}. today is: ${this.today.format('YYYY-MM-DD')}, selected week: ${this.selectedWeek} `);
        this.mainSubscription = combineLatest([this.dataService.getStatFields().pipe(take(1)), this.dataService.getSingleStatReport(user, refDate).pipe(take(1))])
            .subscribe(([statFieldsRef, reports]: any) => {

                const statFields: Array<StatField> = statFieldsRef.map(s => {
                    return {...s.payload.doc.data(), id: s.payload.doc.id}
                });


                if (reports.length === 0) {
                    console.log('no report found for given user/date')
                    this.initForm(undefined, statFields);
                } else {
                    this.currentReport = {...reports[0].payload.doc.data(), id: reports[0].payload.doc.id};
                    if (this.currentReport.lastModifiedDate) this.currentReport.lastModifiedDate = (<Timestamp>this.currentReport.lastModifiedDate).toDate();
                    this.initForm(this.currentReport, statFields);
                }
                this.disableSubmit = false;

            }, err => {
                console.log(err)
            });
    }

    private initForm(report: StatReport, statFields: Array<StatField>) {

        this.currentUserStatFields = [];
        const formFields = {};

        for (const statField of statFields) {
            if (statField.permissionMap.indexOf(this.selectedUser.email) !== -1) {

                if (statField.isManual) formFields[statField.name] = new FormControl(null, [Validators.required, Validators.min(0)]);
                else formFields[statField.name] = new FormControl(0);

                this.currentUserStatFields.push(statField);

            }
        }

        this.currentUserStatFields.sort((a, b) => a.description > b.description ? 1 : -1);

        this.statsForm = new FormGroup(formFields);
        if (report) {
            console.log('Report received, initializing form values:', report);
            this.statsForm.patchValue(report);
        }

        this.statsForm.updateValueAndValidity();
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object) && object[prop] !== undefined) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }


}
