import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';



@Component({
    selector: 'app-link-list-dialog',
    templateUrl: './link-list-dialog.component.html',
    styleUrls: ['./link-list-dialog.component.scss']
})
export class LinkListDialogComponent implements OnInit {

    title = '';
    links = [];

    constructor(
        public dialogRef: MatDialogRef<LinkListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, links: { link: string, descr: string }[] }
    ) {
        console.log(data);
        this.title = data.title;
        this.links = data.links;
    }

    ngOnInit() {
    }

}
