<div style="margin-bottom: 20px">
    <button (click)="sendDailyReport()" [disabled]="sendReportDisabled" i18n mat-raised-button>Send daily report
    </button>
</div>
<div class="row">
    <div class="row">
        <mat-form-field style="margin-right: 80px">
            <input #filter matInput placeholder="Filter">
        </mat-form-field>

        <mat-form-field>
            <input #fromDate (dateChange)="storeFilters()" [matDatepicker]="fromDatePicker" [value]="dateFromSubject.value"
                   matInput
                   placeholder="From Date">
            <mat-datepicker-toggle [for]="fromDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input #toDate (dateChange)="storeFilters()" [matDatepicker]="toDatePicker" [value]="dateToSubject.value" matInput
                   placeholder="To Date">
            <mat-datepicker-toggle [for]="toDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="center-in-row" style="margin-left: 10px">
            <button (click)="resetDatePicker()" mat-raised-button>Reset Dates</button>
        </div>
    </div>

    <div *ngIf="_canDownloadReport" class="row">
        <mat-form-field>
            <input #reportFromDate [matDatepicker]="reportFromDatePicker" [value]="reportDateFromSubject.value" matInput
                   placeholder="From Date">
            <mat-datepicker-toggle [for]="reportFromDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #reportFromDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input #reportToDate [matDatepicker]="reportToDatePicker" [value]="reportDateToSubject.value" matInput
                   placeholder="To Date">
            <mat-datepicker-toggle [for]="reportToDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #reportToDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="center-in-row" style="margin-left: 10px">
            <button (click)="downloadHourReport()" [disabled]="loading" color="primary" i18n mat-raised-button>
                Download hours summary
            </button>
        </div>
    </div>
</div>

<div>
    <mat-tab-group (selectedIndexChange)="selectedTab = $event" [selectedIndex]="selectedTab">

        <mat-tab>
            <ng-template mat-tab-label>
                <span matTooltip="Lista veloce">Timereports list</span>
            </ng-template>

            <app-timereport-list-table [canChangeUser]="canChangeUser" searchMode='quick'></app-timereport-list-table>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div *ngIf="performingSlowSearch$ | async; else loaded" class="tab-label-loading">
                    <mat-icon matTooltip="Searching...">cloud_sync</mat-icon>
                </div>
                <ng-template #loaded>
                    <div class="tab-label-loaded">
                        <mat-icon matTooltip="Lista esaustiva ({{slowListElements}} timereports)">cloud_done</mat-icon>
                        <span>({{ slowListElements }})</span>
                    </div>
                </ng-template>
            </ng-template>

            <app-timereport-list-table (updateTotalElements)="slowListElements = $event" [canChangeUser]="canChangeUser"
                                       searchMode='slow'></app-timereport-list-table>
        </mat-tab>
    </mat-tab-group>
</div>
