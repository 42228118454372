import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import {DdtComponent} from './ddt.component';
import {DdtAddComponent} from './ddt-add/ddt-add.component';
import {DdtListComponent} from './ddt-list/ddt-list.component';
import {SharedModule} from '../shared/shared.module';
import {DdtListTableComponent} from './ddt-list-table/ddt-list-table.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [DdtComponent, DdtAddComponent, DdtListComponent, DdtListTableComponent]
})
export class DdtModule {
}
