import {Pipe, PipeTransform} from '@angular/core';
import * as firebase from 'firebase';



@Pipe({
    name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

    transform(value: firebase.firestore.Timestamp | Date, args?: any): Date {
        if (value instanceof firebase.firestore.Timestamp) {
            return value.toDate();
        } else {
            return value;
        }
    }


}
