import {catchError, map, switchMap, takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../services/app-state.service';
import {DataService} from '../services/data.service';
import {AuthService} from '../services/auth.service';
import {firestoreDateToJsDate} from '../shared/utils/utils';
import {Observable, of, Subject} from 'rxjs';
import {TimereportListTableService} from '../services/timereport-list-table.service';
import {Timereport} from './interfaces';



@Component({
    selector: 'timereport',
    templateUrl: './timereport.component.html',
    styleUrls: ['./timereport.component.scss']
})
export class TimereportComponent implements OnInit {

    public timereport;
    private trId;
    private canChangeUser = false;
    private unsubscribe$ = new Subject();

    constructor(
        private appState: AppStateService,
        private dataService: DataService,
        private authService: AuthService,
        private timereportListTableService: TimereportListTableService
    ) {
    }

    private _sentDates;

    get sentDates(): Set<number> {
        return this._sentDates;
    }

    ngOnInit() {

        this.authService.userHasPermission('app.timereport.anyone.write').subscribe(val => {
            this.canChangeUser = val;
        });

        this.timereportListTableService.editTimeReportRequest$.pipe(
            takeUntil(this.unsubscribe$),
            switchMap(id => this.onNewEditRequest(id))
        ).subscribe((timereport) => this.handleTimeReportSelection(timereport));

        // Subscribe to already sent email
        this.dataService.getReportEmailByUser(this.authService.currentUser).pipe(
            map((emailReports) => {
                return new Set(emailReports.filter((er: any) => er.reportDate).map((er: any) => firestoreDateToJsDate(er.reportDate).valueOf()));
            }),
            takeUntil(this.unsubscribe$))
            .subscribe((set) => {
                console.log('Got list of sent timereports for current user: ', this.authService.currentUser, set);
                this._sentDates = set;
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private onNewEditRequest(id: string) {
        this.trId = id;
        console.log('TR ID', this.trId);
        return (id) ? this.getTimereport(id) : of(null);
    }

    private getTimereport(id: string): Observable<Timereport> {
        return this.dataService.getTimeReport(id).pipe(catchError(error => {
            console.error(error);
            this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this timereport id'});
            return of(null);
        }));
    }

    private handleTimeReportSelection(timereport: Timereport) {
        if (timereport) {
            timereport.id = this.trId;
            this.timereport = timereport;
            console.log('Timereport set', timereport);
        } else if (!this.canChangeUser && timereport?.sent) {
            // A norma  user cannot edit this timereport then navigate back to the list
            this.timereportListTableService.cancelTimereportEdit();
        } else {
            this.trId = null;
            this.timereport = null;
        }
    }

}
