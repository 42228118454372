<div class="fxlayout-row-spacearound-center">
    <mat-card style="width: 100%">
        <mat-card-title>
            <h3 i18n>Users list</h3>
            <span class="add-user">
        <button [routerLink]="['/users/add']" color="accent" mat-fab>
          <mat-icon>add</mat-icon>
        </button>
      </span>
        </mat-card-title>

        <div class="example-header">
            <mat-form-field class="filterMargin">
                <input (keyup)="applyFilter($event.target.value)" [value]="getUserListFilter()" matInput
                       placeholder="Filter">
            </mat-form-field>
        </div>

        <div class="example-container mat-elevation-z8">

            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="displayName">
                    <mat-header-cell *matHeaderCellDef i18n>Display Name</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.displayName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef i18n>Email</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.email }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef i18n>First Name</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.firstName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <mat-header-cell *matHeaderCellDef i18n>Last Name</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.lastName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="googleUid">
                    <mat-header-cell *matHeaderCellDef i18n>Google UID</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.googleUid }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="initials">
                    <mat-header-cell *matHeaderCellDef i18n>Initials</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.initials }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="roles">
                    <mat-header-cell *matHeaderCellDef i18n>Roles</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.type?.join(', ') }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="referent">
                    <mat-header-cell *matHeaderCellDef i18n>Referenti (timereports)</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.referent }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="jobClassification">
                    <mat-header-cell *matHeaderCellDef i18n>Classificazione</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.jobClassification }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef i18n>Active</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="row.active" matListIcon>done</mat-icon>
                        <span *ngIf="!row.active && row.deactivatedOn">
              deactivated: {{ row.deactivatedOn|timestamp | date }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="external">
                    <mat-header-cell *matHeaderCellDef i18n>External</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="row.external" matListIcon>done</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <a [routerLink]="['/users/add',row.id]" mat-icon-button>
                            <mat-icon title="update user">edit</mat-icon>
                        </a>
                        <button (click)="deleteUser(row)" mat-icon-button>
                            <mat-icon title="delete user">delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>

            <mat-paginator [length]="dataSource.resultsLength" [pageSizeOptions]="[50, 100, 200, 500]"></mat-paginator>
        </div>
    </mat-card>
</div>
