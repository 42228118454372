import {Observable, of} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';



@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {

        const url: string = state.url;
        console.log('Can activate url', url);
        return this.checkLogin(url);
    }

    public canDeactivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;
        console.log('Can deactivate url', url);
        // return this.checkLogin(null).map((res) => !res);
        return of(true);
    }

    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        console.log('Can Activate Child route', route);
        return this.canActivate(route, state);
    }

    public checkLogin(url: string): Observable<boolean> {
        return this.authService.userStream$.pipe(map((u) => {
            console.log('AuthGuard - checkLogin...')
            if (u && u.role && (['user', 'admin'].indexOf(u.role) > -1)) {
                console.log('AuthGuard - acl passed');
                return true;
            } else if (url) {
                // Store the attempted URL for redirecting
                this.authService.redirectUrl = url;

                // Navigate to the login page with extras
                console.log('AuthGuard - acl failed with url case: ', u);
                //this.router.navigate(['/home'], { queryParams: { loginError: true } });
                this.router.navigate(['/home']);
                return false;
            } else {
                console.log('AuthGuard - acl failed', u);
                return false;
            }
        }));
    }
}
