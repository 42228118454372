import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {AppStateService} from '../../services/app-state.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {Budget} from '../../budget/interfaces/index';
import {Company, Person} from '../../interfaces/address';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AclUser} from '../../interfaces/app-user';
import {Order, OrderEntry, resumeMaterial} from '@wondersys/wonderbudget-lib';



@Component({
    selector: 'app-order-view',
    templateUrl: './order-view.component.html',
    styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit, OnDestroy, AfterViewChecked {

    // For the mat-table
    displayedColumns = ['project', 'subBudget', 'description', 'code', 'unit', 'quantity', 'pricePerUnit', 'discount', 'total', 'invoices', 'invoiceCompletion', 'ddt', 'ddtCompletion'];
    dataSource: ItemDataSource;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public orderForm: FormGroup;
    public vendors: BehaviorSubject<Company[]> = new BehaviorSubject([]);
    public _order;
    public filteredVendors: Observable<Company[]>;
    public orderNr: string;
    public currentOrderNr: string;
    public currentOrderDate: any;
    public selectedVendor: Partial<Company>;
    public people;
    public oId;
    public order;
    public canAuthOrder;
    disableAuthorization = false;
    public materialList$;
    public triggerRefresh = new BehaviorSubject<boolean>(false);
    public budget$: Observable<Budget>;
    public materialTotalCost: Observable<number>;
    private unsubscribe$ = new Subject();
    private itemsStream$ = new BehaviorSubject([]);

    constructor(
        private _changeDetectionRef: ChangeDetectorRef,
        private dataService: DataService,
        private authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService) {
    }

    get vendorIsValid() {
        return this.orderForm.get('vendor').valid;
    }

    ngAfterViewChecked(): void {
        // your code
        this._changeDetectionRef.detectChanges();
    }

    ngOnInit() {
        console.log('Begin.');

        this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('id')),
            switchMap((params: ParamMap) => {
                this.oId = params.get('id');
                console.log('Init order-view! orderId: ', this.oId);
                return this.dataService.getOrder(this.oId);
            })
        ).subscribe((order) => {
            if (!order) {
                this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this order id'});
                this.router.navigate(['/order']);
            } else {
                order.id = this.oId;
                this.order = order;
                this.currentOrderNr = order.orderNr;
                this.selectedVendor = order.vendor;
                this.currentOrderDate = order.date;

                this.setItemProperties(order.items);


                this.itemsStream$.next(order.items);
                console.log('Order retrieved and set: ', order);
            }
        });

        this.authService.userHasPermission('app.order.auth')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.canAuthOrder = val;
                if (val && !this.displayedColumns.includes('outOfBudget')) this.displayedColumns.push('outOfBudget');
            });

        this.selectedVendor = undefined;

        this.buildForm();

        this.dataService.getNextOrderNr()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((num) => {
                const paddedNum = num.toString().padStart(4, '0');
                const year = (new Date).getFullYear();
                this.orderNr = this.authService.currentUser.initials + 'OF ' + paddedNum + '-' + year;
            });

        this.people = this.orderForm.get('vendor').valueChanges.pipe(
            distinctUntilChanged(),
            filter(vendor => (vendor && vendor.id)),
            switchMap((vendor) => {
                return this.dataService.getPeopleByCompany(vendor.id);
            }));

        // Assign the data to the data source for the table to render
        this.dataSource = new ItemDataSource(
            this.itemsStream$,
            this.paginator, this.sort);

    }

    public orderAuth(order, isAuth) {
        this.disableAuthorization = true;
        this.dataService.ackOrderAuth(order, isAuth)
            .subscribe((res) => {
                    console.log('Authorization confirmed', res);
                },
                (err) => {
                    console.log('Error while authorizing order', err);
                });
    }

    public comparePeople = (p1: Person, p2: Person) => {
        if (!p1 || !p2) {
            return false;
        }
        return p1.id === p2.id;
    }

    ngOnDestroy() {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }

    public vendorDisplayFn(vendor: Company): string {
        if (vendor) {
            return vendor.name ? vendor.name : vendor.email_domain;
        } else {
            return null;
        }
    }

    public getListFilter() {
        let listFilter = localStorage.getItem('orderViewListFilter') ? localStorage.getItem('orderViewListFilter') : '';
        this.applyFilter(listFilter);
        return listFilter;
    }

    public setListFilter(filterName, filterValue) {
        localStorage.setItem(filterName, filterValue);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.setListFilter('orderViewListFilter', filterValue)
    }

    private buildForm() {
        // Definition of the Order Form
        // build the form model
        this.orderForm = this.fb.group({
            vendor: [undefined, Validators.required],
            primaryContact: [undefined, Validators.compose([Validators.required, this.primaryContactValidator])],
            techContact: <AclUser>null,
            note: '',
            pmNote: <string>undefined,
            paymentMethod: <string>undefined,
            shippingMethod: <string>undefined,
            returnMethod: <string>undefined,
            deliveryDate: <Date>undefined,
            vat: 22,
            invoice: this.fb.group({
                invoiceNr: <string>undefined,
                date: <Date>undefined
            }),
            deliveryNote: this.fb.group({
                deliveryNoteNr: <string>undefined,
                date: <Date>undefined,
                reason: <string>undefined
            }),
            items: this.fb.array([]),
            // https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/
            invoices: this.fb.array([]),
            deliveryNotes: this.fb.array([])
        });
    }

    private primaryContactValidator = (control): { [key: string]: any } => {
        if (!control.value) {
            return {'invalidPrimaryContact': {value: control.value}};
        }
        return (typeof control.value === 'object'
            && control.value.emails && control.value.emails.length > 0) ? null : {'invalidPrimaryContact': {value: control.value}};
    }

    private async setItemProperties(items: OrderEntry[]) {
        await Promise.all(items.map(async (item) => {
            // TODO use from a library
            item.total = (item.costEach * item.quantity) - (item.costEach * item.quantity / 100 * item.discount);
            let ddtCompletion = 0;
            if (item.ddt) {
                item.ddt.forEach(ddt => {
                    ddtCompletion = ddtCompletion + ddt.deliveryQuantity;
                });
            }

            item.itemDeliveryCompletion = (ddtCompletion / item.quantity * 100);

            const invoiceCompletion = item.invoices ? item.invoices.map(i => i.materialAmount).reduce((a, b) => a + b, 0) : 0;

            item.itemInvoiceCompletion = item.total - invoiceCompletion;

            // in Budget or out of Budget?
            if (item.budget.id) {
                await this.setOutOfBudget(item);
            } else {
                console.log('setOutOfBudget - cannot set out of budget for this item, no budget ID found', item);
            }

        }));
    }

    private async setOutOfBudget(item) {
        return this.dataService.getSubBudgetResume(item.budget.id)
            .pipe(
                take(1))
            .subscribe((summary: any) => {
                if (!summary) {
                    console.warn('setOutOfBudget - cannot calculate out of budget for budget: ', item.budget.id)
                    item.outOfBudget = null;
                }
                const material: resumeMaterial = summary.materials
                    .filter(m => m.material)
                    .find(m =>
                        m.id === item.material.id ||
                        (m.description === item.material.description && m.category === item.material.category && m.costEach === item.material.costEach))

                if (!material) {
                    console.log('setOutOfBudget - This item material is not in summary:', item);
                    item.outOfBudget = null;
                    return;
                }
                item.outOfBudget = material.referencedMaterialId ? false : (material.finalAmount > (material.costEach * material.quantity));
                console.log('setOutOfBudget - material for out of budget:', item.material, item.outOfBudget, summary);
            })
    }

}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class ItemDataSource extends DataSource<Order> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable
            .subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Order[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('orderViewListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('orderViewListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('orderViewListSortId');
        const sortDirection = localStorage.getItem('orderViewListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            const data = this._dataChange.value;

            // Filter
            const fData = data.filter((item: Order) => {
                let srcStr;
                try {
                    srcStr = '';
                } catch (err) {
                    srcStr = '';
                }

                return srcStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: Order[]) => {
        localStorage.setItem('orderViewListSortId', this._sort.active);
        localStorage.setItem('orderViewListSortDirection', this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: Order, b: Order) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'vendor':
                    [propertyA, propertyB] = [a.vendor.name, b.vendor.name];
                    break;
                case 'date':
                    [propertyA, propertyB] = [<Date>a.date, <Date>b.date];
                    break;
                case 'author':
                    [propertyA, propertyB] = [a.author.email, b.author.email];
                    break;
            }

            let valueA;
            let valueB;

            if (propertyA instanceof Date) {
                valueA = propertyA;
                valueB = propertyB;
            } else {
                valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            }

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
