<mat-card>
    <div class="bal-mat-head">
        <div class="bal-mat-head-num">
            <div *ngIf="budget$ | async; let budget">
                <a [routerLink]="['/budget',budget.id]">
                    <h4>Project: {{ budget | projectNr:true }}&nbsp;-&nbsp;{{ budget.prosperworks?.name }}</h4>
                </a>
                <button (click)="exportCsv()" class="btn btn-primary">Export to CSV</button>
                <table>
                    <tr>
                        <td>Total (Budget):</td>
                        <td>{{ budget.costSummary?.goodsValue | currency:budget.export ? 'USD' : 'EUR' }}</td>
                        <td>{{ budget.costSummary?.total | currency:budget.export ? 'USD' : 'EUR' }}</td>
                    </tr>
                    <tr>
                        <td> Total Material:</td>
                        <td>{{ materialTotalCost | async | currency:budget.export ? 'USD' : 'EUR' }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Difference:</td>
                        <td>
                            {{ budget.costSummary?.goodsValue - (materialTotalCost | async) | currency:budget.export ? 'USD' : 'EUR' }}
                        </td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="bal-mat-head-icon">
            <button (click)="refresh()" mat-icon-button>
                <mat-icon>refresh</mat-icon>
            </button>
            <a [routerLink]="['/balances/activities/',budgetId]">Go To Activities</a>
        </div>
        <div class="bal-mat-head-icon">
            <a [routerLink]="['/balances/summary/',budgetId]">Go To Summary</a>
        </div>
    </div>
</mat-card>

<app-materials-balance-table (dataChanged)="updateRenderedData($event)" *ngIf="budgetId" [budgetId]="budgetId">
</app-materials-balance-table>
