<div class="fxlayout-row-stretch">

    <mat-card style="flex-grow: 1;">

        <mat-toolbar class="sub-budget-toolbar">
            <div class="row-centered sub-budget-toolbar__left">
                <button (click)="createResumeSheet()" [disabled]="disableGenerateSheet" mat-menu-item>
                    <mat-icon>insert_drive_file</mat-icon>
                    <span i18n>Create sheet</span>
                </button>
                <span *ngIf="resume && resume.spreadsheetId" class="small-text">
          (
          <a href="https://docs.google.com/spreadsheets/d/{{resume.spreadsheetId}}" target="_blank">open</a>
          )
        </span>
            </div>
        </mat-toolbar>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title i18n>Activities</mat-panel-title>
                <mat-panel-description>
                    <span>Total</span>:&nbsp;
                    <span>{{ subBudgetTotals?.activities | currency:budget.export ? 'USD' : 'EUR' }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-table #table [dataSource]="activitiesDataSource">
                <!-- Nome Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef i18n>Activity</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.activity }}
                    </mat-cell>
                </ng-container>

                <!-- Category Column -->
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef i18n>Category</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ categoryMap.get(row.category) }}</mat-cell>
                </ng-container>

                <!-- Costo Column -->
                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef i18n>Cost</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.cost | currency:budget.export ? 'USD' : 'EUR' }}</mat-cell>
                </ng-container>

                <!-- Ore Column -->
                <ng-container matColumnDef="hours">
                    <mat-header-cell *matHeaderCellDef i18n>Hours</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.hours }}</mat-cell>
                </ng-container>

                <!-- Ore Column
                            <ng-container matColumnDef="hoursNew">
                                <mat-header-cell *matHeaderCellDef i18n>Hours</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <mat-form-field class="hours-input-field">
                                        <input type="number" min="0" [(ngModel)]="avm[row.id]" [name]="row.id" matInput #hours>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container> -->

                <!-- Final Value Column -->
                <ng-container matColumnDef="finalHours">
                    <mat-header-cell *matHeaderCellDef i18n>Final Hours</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.finalHours }}</mat-cell>
                </ng-container>

                <!-- Importo Column -->
                <ng-container matColumnDef="importoCalc">
                    <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.cost * avm[row.id] | number:'1.0-2' }}</mat-cell>
                </ng-container>

                <!-- Importo Column -->
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef i18n>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.amount | number:'1.0-2' }}</mat-cell>
                </ng-container>

                <!-- Final Value Column -->
                <ng-container matColumnDef="finalAmount">
                    <mat-header-cell *matHeaderCellDef i18n>Final Amount</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.finalHours * row.cost | number:'1.0-2' }}</mat-cell>
                </ng-container>

                <!-- Color Column -->
                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef i18n>Weight</mat-header-cell>
                    <mat-cell *matCellDef="let row" [style.color]="row.color">
                        {{ 100 * row.cost * avm[row.id] / budgetTotal | number:'1.0-2' }} %
                    </mat-cell>
                </ng-container>

                <!-- Difference Column -->
                <ng-container matColumnDef="difference">
                    <mat-header-cell *matHeaderCellDef i18n>Difference</mat-header-cell>
                    <mat-cell *matCellDef="let row" [style.color]="row.color">
            <span
                    [ngClass]="{'diff-positive': ((row.hours - row.finalHours) * row.cost >= 0), 'diff-negative': ((row.hours - row.finalHours) * row.cost < 0)}">{{ (row.hours - row.finalHours) * row.cost | number:'1.0-2' }}
            </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-expansion-panel>


        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title i18n="Title of the list of external goods to be purchased">
                    Materials
                </mat-panel-title>
                <mat-panel-description>
                    <span i18n>Total</span>:&nbsp;
                    <span>{{ subBudgetTotals?.materials | currency:budget.export ? 'USD' : 'EUR' }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <table [dataSource]="materialDataSource" mat-table matSort matSortDisableClear multiTemplateDataRows>

                <!-- normal rows -->
                <ng-container matColumnDef="description">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Material</th>
                    <td *matCellDef="let row" mat-cell>
                        <mat-icon *ngIf="row.sons.length > 0 && expandedElement !== row" class="icon-centered"
                                  title="Custom defined material">
                            keyboard_arrow_down
                        </mat-icon>
                        <mat-icon *ngIf="row.sons.length > 0 && expandedElement === row" class="icon-centered"
                                  title="Custom defined material">
                            keyboard_arrow_up
                        </mat-icon>
                        <mat-icon *ngIf="row.sons.length === 0 && row.isOnlyFinal" class="green-icon-centered"
                                  title="Custom defined material">
                            new_releases
                        </mat-icon>
                        {{ row.description }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Category</th>
                    <td *matCellDef="let row" mat-cell> {{ categoryMap.get(row.category) }}</td>
                </ng-container>
                <ng-container matColumnDef="unit">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Unit</th>
                    <td *matCellDef="let row" mat-cell> {{ row.unit }}</td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Quantity</th>
                    <td *matCellDef="let row" class="give-margin-cell" mat-cell> {{ row.quantity }}</td>
                </ng-container>
                <ng-container matColumnDef="finalQuantity">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Final Quantity</th>
                    <td *matCellDef="let row" class="give-margin-cell" mat-cell> {{ row.finalQuantity }}</td>
                </ng-container>
                <ng-container matColumnDef="costEach">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Cost Each</th>
                    <td *matCellDef="let row" mat-cell> {{ row.costEach | number:'1.0-2' }}</td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Amount</th>
                    <td *matCellDef="let row" class="give-margin-cell" mat-cell>
                        {{ row.quantity * row.costEach | number:'1.0-2' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="finalAmount">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Final Amount</th>
                    <td *matCellDef="let row" class="give-margin-cell" mat-cell>
                        <span *ngIf="row.sons.length === 0"
                              class="icon-centered">{{ row.finalAmount | number:'1.0-2' }}</span>
                        <b *ngIf="row.sons.length !== 0">{{ row.finalAmount | number:'1.0-2' }}</b>

                        <a (click)="openOrderLinkList(row)" *ngIf="row.processedOrders?.length > 0" class="icon-centered ml-1"
                           matTooltip="Open relative orders" style="cursor: pointer;">
                            <mat-icon>local_shipping</mat-icon>
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="weight">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Weight</th>
                    <td *matCellDef="let row" class="give-margin-cell" mat-cell>
                        {{ 100 * row.quantity * row.costEach / budgetTotal | number:'1.0-2' }} %
                    </td>
                </ng-container>
                <ng-container matColumnDef="difference">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Difference</th>
                    <td *matCellDef="let row" class="give-margin-cell" mat-cell> <span
                            [ngClass]="{'diff-positive': (row.quantity * row.costEach - row.finalAmount >= 0), 'diff-negative': (row.quantity * row.costEach - row.finalAmount < 0)}">{{ row.quantity * row.costEach - row.finalAmount | number:'1.0-2' }}</span>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td *matCellDef="let element" [attr.colspan]="matDisplayedColumns.length" mat-cell
                        style="padding:0px; background-color:#fffaf0ab;">
                        <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                             class="example-element-detail">
                            <div *ngIf="element.sons.length > 0">

                                <table [dataSource]="element.sons" class="give-margin-cell-reversed inner-table"
                                       mat-table
                                       multiTemplateDataRows>

                                    <!-- normal rows -->
                                    <ng-container matColumnDef="description">
                                        <th *matHeaderCellDef mat-header-cell> Material</th>
                                        <td *matCellDef="let row" mat-cell>
                                            <mat-icon *ngIf="row.sons.length === 0 && row.isOnlyFinal"
                                                      class="green-icon-centered"
                                                      title="Custom defined material">
                                                new_releases
                                            </mat-icon>
                                            {{ row.description }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="category">
                                        <th *matHeaderCellDef mat-header-cell> Category</th>
                                        <td *matCellDef="let row" mat-cell> {{ categoryMap.get(row.category) }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="unit">
                                        <th *matHeaderCellDef mat-header-cell> Unit</th>
                                        <td *matCellDef="let row" mat-cell> {{ row.unit }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="finalQuantity">
                                        <th *matHeaderCellDef mat-header-cell> Final Quantity</th>
                                        <td *matCellDef="let row" class="give-margin-cell" mat-cell>
                                            {{ row.finalQuantity }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="costEach">
                                        <th *matHeaderCellDef mat-header-cell> Cost Each</th>
                                        <td *matCellDef="let row" mat-cell> {{ row.costEach | number:'1.0-2' }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="finalAmount">
                                        <th *matHeaderCellDef mat-header-cell> Final Amount</th>
                                        <td *matCellDef="let row" class="give-margin-cell" mat-cell>
                                            <b>{{ row.finalAmount | number:'1.0-2' }}</b>
                                        </td>
                                    </ng-container>

                                    <tr *matHeaderRowDef="matDisplayedColumnsSubItems" mat-header-row
                                        style="height: 30px;"></tr>
                                    <tr *matRowDef="let row; columns: matDisplayedColumnsSubItems;" mat-row></tr>
                                </table>

                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="matDisplayedColumns" mat-header-row></tr>
                <tr (click)="setExpandedNode(element)" *matRowDef="let element; columns: matDisplayedColumns;" [class.expanded-row]="expandedElement === element"
                    class="example-element-row" mat-row>
                </tr>
                <tr *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" mat-row></tr>
            </table>
        </mat-expansion-panel>

    </mat-card>
</div>
