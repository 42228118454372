<h3 i18n mat-dialog-title>Assign budget to a Project Manager</h3>
<mat-dialog-content>
    <app-user-picker [myControl]="myControl" placeholder="Select a project manager"></app-user-picker>
    <mat-form-field *ngIf="!showOnlyUser">
    <textarea [formControl]="noteToPrjMgr" matAutosizeMaxRows="5" matAutosizeMinRows="2" matInput
              matTextareaAutosize placeholder="Add note to Project manager"></textarea>
    </mat-form-field>
    <mat-form-field *ngIf="!showOnlyUser">
        <input [formControl]="deliveryDateControl" [matDatepicker]="picker" [min]="minDate" i18n-placeholder matInput name="deliveryDate"
               placeholder="Delivery Date" required>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="closeDialog()" i18n mat-button type="submit">Assign budget</button>
</mat-dialog-actions>
