<mat-card>
    <form (ngSubmit)="submit()" [formGroup]="orderForm">
        <mat-card-header>
            <mat-card-title>
                <h4>
                    <div *ngIf="editMode; else newOrder">
                        <span i18n>Order</span>&nbsp;
                        <span>{{ currentOrderNr }}</span>
                        <span><mat-slide-toggle
                                formControlName="isInternalOrder">Internal Order</mat-slide-toggle></span>
                    </div>
                    <ng-template #newOrder>
                        <span i18n>New Order</span>&nbsp;
                        <span style="padding-left: 20px;"><mat-slide-toggle formControlName="isInternalOrder">Internal Order</mat-slide-toggle></span>
                    </ng-template>
                </h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="order-container">
                <div class="order-meta">


                    <div class="order-meta-form">

                        <div class="row">
                            <div class="column">
                                <mat-form-field class="f-medium">
                                    <input #vendorInput [matAutocomplete]="autoVendor" formControlName="vendor" matInput
                                           placeholder="Vendor"
                                           type="text">
                                    <mat-autocomplete #autoVendor="matAutocomplete" (optionSelected)="vendorChange($event)"
                                                      [displayWith]="vendorDisplayFn">
                                        <mat-option *ngFor="let vendor of filteredVendors" [value]="vendor">
                                            {{ vendor.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field class="f-medium">
                                    <mat-select [compareWith]="comparePeople" formControlName="primaryContact"
                                                i18n-placeholder placeholder="Primary Contact" required>
                                        <mat-option *ngFor="let person of people | async" [value]="person">
                                            {{ person.name }}&nbsp;
                                            <span
                                                    *ngIf="person.emails && person.emails.length > 0">&#x3C;{{ person.emails[0].email }}&#x3E;</span>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                            *ngIf="orderForm.get('primaryContact').hasError('invalidPrimaryContact')">
                                        <ng-container i18n>Please enter a valid Primary Contact</ng-container>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <mat-form-field class="f-large ml-1">
                <textarea class="f-tall" formControlName="note" matInput placeholder="Note to vendor"
                          type="text"></textarea>
                            </mat-form-field>
                        </div>


                        <div class="row f-large">
                            <app-user-picker [myControl]="techPersonControl"
                                             placeholder="Select a project manager"></app-user-picker>
                            <mat-form-field class="ml-1 f-large">
                <textarea class="f-tall" formControlName="pmNote" matInput placeholder="Note to Project Manager"
                          type="text"></textarea>
                            </mat-form-field>
                        </div>


                        <div class="row">
                            <mat-form-field class="f-medium">
                                <mat-select (selectionChange)="resetHint('payment', $event)"
                                            formControlName="paymentMethod"
                                            placeholder="Payment Method">
                                    <mat-option *ngFor="let pm of paymentMethodOptions" [value]="pm.methodValue">
                                        {{ pm.methodValue }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="hintPaymentMethod" color="warn">Payment hint:
                                    {{ orderForm.get('paymentMethod').value }}
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="f-large ml-1">
                <textarea class="f-tall" formControlName="paymentText" matAutosizeMinRows="3" matInput placeholder="Payment Notes"
                          type="text"></textarea>
                            </mat-form-field>
                        </div>


                        <div class="row">
                            <mat-form-field class="f-medium">
                                <mat-select (selectionChange)="resetHint('shipping', $event)"
                                            formControlName="shippingMethod"
                                            placeholder="Shipping Method">
                                    <mat-option *ngFor="let sm of shippingMethodOptions" [value]="sm">
                                        {{ sm }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="hintShippingMethod" color="warn">Shipping hint:
                                    {{ orderForm.get('shippingMethod').value }}
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="f-large ml-1">
                <textarea class="f-tall" formControlName="shippingText" matAutosizeMinRows="3" matInput placeholder="Shipping Notes"
                          type="text"></textarea>
                            </mat-form-field>
                        </div>


                        <div class="row">
                            <mat-form-field>
                <textarea formControlName="returnMethod" matAutosizeMinRows="3" matInput placeholder="Return Method"
                          type="text"></textarea>
                            </mat-form-field>
                            <mat-form-field class="ml-1">
                                <input [matDatepicker]="deliveryDate" formControlName="deliveryDate" matInput
                                       placeholder="Delivery Date">
                                <mat-datepicker-toggle [for]="deliveryDate" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #deliveryDate></mat-datepicker>
                                <mat-error *ngIf="orderForm.get('deliveryDate').hasError('required')">
                                    Required field
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="ml-1">
                                <mat-select formControlName="vat" placeholder="VAT">
                                    <mat-option *ngFor="let v of vatOptions" [value]="v">
                                        {{ v }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div class="row">
                            <mat-form-field style="width: 540px !important">
                <textarea class="f-tall" formControlName="invoiceText" matAutosizeMinRows="3" matInput placeholder="Invoices Free Text"
                          type="text"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field style="width: 540px !important">
                <textarea class="f-tall" formControlName="deliveryText" matAutosizeMinRows="3" matInput
                          placeholder="Delivery Notes Free Text" type="text"></textarea>
                            </mat-form-field>
                        </div>


                        <div>
                            <label>Order currency:&nbsp;</label>
                            <mat-radio-group>
                                <mat-radio-button (change)="setCurrency('EUR')" [checked]="orderForm.get('currency').value === 'EUR'"
                                                  i18n
                                                  matInput type="radio">
                                    EUR
                                </mat-radio-button>&nbsp;&nbsp;
                                <mat-radio-button (change)="setCurrency('USD')" [checked]="orderForm.get('currency').value === 'USD'"
                                                  [checked]="false"
                                                  i18n matInput type="radio" value="USD">
                                    USD
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>


                    </div>


                    <div *ngIf="selectedVendor" class="vendor-info">
                        <div>{{ selectedVendor.name }}</div>
                        <div>
                            <span>{{ selectedVendor.address?.street }}</span>
                        </div>
                        <div>
                            <span>{{ selectedVendor.address?.city }}</span>,&nbsp;
                            <span>{{ selectedVendor.address?.state }}</span>,&nbsp;
                            <span>{{ selectedVendor.address?.postal_code }}</span>
                        </div>
                        <div>
                            <span>{{ selectedVendor.address?.country }}</span>
                        </div>
                        <div *ngIf="vendorHasNda">
                            <a href="{{ndaFile?.result?.webViewLink}}" target="_blank">Open NDA file</a>
                        </div>
                        <div *ngIf="vendorNdaFieldIsSet && !vendorHasNda">
                            <p class="message">NDA file link is set in the CRM but your user cannot access it</p>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div class="form-tables-row">
                    <div class="form-items-table">
                        <table *ngIf="invoiceFormArray.controls.length">
                            <tr>
                                <th i18n>Invoice Nr</th>
                                <th i18n>Invoice Date</th>
                                <th></th>
                            </tr>
                            <tbody *ngFor="let invoice of invoiceFormArray.controls; let i = index;"
                                   formArrayName="invoices">
                            <tr [formGroupName]="i">
                                <td>
                                    <mat-form-field>
                                        <input formControlName="invoiceNr" matInput type="text">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input [matDatepicker]="inpicker" formControlName="date" matInput>
                                        <mat-datepicker-toggle [for]="inpicker" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #inpicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <button (click)="removeInvoiceItem(i)" mat-icon-button type="button">
                                        <mat-icon>remove</mat-icon>
                                    </button>

                                    <button (click)="goToFixInvoiceDdt(invoice.value.invoiceNr, invoice.value.date, 'invoices')" color="warn"
                                            i18n
                                            mat-raised-button>
                                        Fix Invoice
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <!--<button mat-raised-button type="button" (click)="addInvoiceItem()"  [disabled]="!invoiceFormArray.valid" i18n>Add Invoice</button>-->
                    </div>
                    <div class="form-items-table">
                        <table *ngIf="dNotesFormArray.controls.length">
                            <tr>
                                <th i18n>Delivery Note</th>
                                <th i18n>Delivery Reason</th>
                                <th i18n>Note Date</th>
                                <th></th>
                            </tr>
                            <tbody *ngFor="let deliveryNote of dNotesFormArray.controls; let i = index;"
                                   formArrayName="deliveryNotes">
                            <tr [formGroupName]="i">
                                <td>
                                    <mat-form-field>
                                        <input formControlName="deliveryNoteNr" matInput type="text">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input formControlName="reason" matInput type="text">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <input [matDatepicker]="inpicker" formControlName="date" matInput>
                                        <mat-datepicker-toggle [for]="inpicker" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #inpicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <button (click)="removeDeliveryNoteItem(i)" mat-icon-button type="button">
                                        <mat-icon>remove</mat-icon>
                                    </button>

                                    <button (click)="goToFixInvoiceDdt(deliveryNote.value.deliveryNoteNr, deliveryNote.value.date, 'ddt')" color="warn"
                                            i18n
                                            mat-raised-button>
                                        Fix Delivery Note
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <!--<button mat-raised-button type="button" (click)="addDeliveryNoteItem()" [disabled]="!dNotesFormArray.valid" i18n>Add Delivery Note</button>-->
                    </div>
                </div>
                <div class="order-entry-array">
                    <app-order-entry-array *ngIf="orderForm.get('vendor').valid" [currency]="orderForm.get('currency').value"
                                           [itemsFormArray]="orderForm.get('items')"
                                           formArrayName="items">
                    </app-order-entry-array>
                </div>
            </div>

            <div *ngIf="selectedVendor">
                <p *ngIf="!vendorHasNda" class="message">Selected vendor has no NDA file attached.</p>
                <div>
                    <mat-checkbox (change)="proceedWithNoNda = $event.checked" *ngIf="!vendorHasNda"
                                  [checked]="proceedWithNoNda">
                        Proceed anyway
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox (change)="sendReminderEmail = $event.checked" *ngIf="!vendorHasNda"
                                  [checked]="sendReminderEmail">
                        Send reminder email
                    </mat-checkbox>
                </div>
                <div>
                    <mat-form-field *ngIf="!vendorHasNda && sendReminderEmail">
                        <mat-label>Send to</mat-label>
                        <mat-select (selectionChange)="setReminderSendTo($event)" [(value)]="reminderSendTo" multiple>
                            <mat-option *ngFor="let user of users$ | async" [value]="user.email">
                                {{ user.email }}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="!vendorHasNda && sendReminderEmail" class="send-reminder-container">
                    <button (click)="submitReminderEmail()" [disabled]="reminderSendTo.length === 0 || reminderMailSending || reminderMailSent" mat-raised-button
                            type="button">
                        <span *ngIf="!reminderMailSent">Send reminder email</span>
                        <span *ngIf="reminderMailSent">Reminder email sent</span>
                    </button>
                    <div class="spinner-container">
                        <mat-spinner *ngIf="reminderMailSending" diameter="24"></mat-spinner>
                    </div>

                </div>
            </div>


        </mat-card-content>
        <mat-card-actions>
            <button *ngIf="!editMode || authorized" [disabled]="orderForm.invalid || orderAdding || (!vendorHasNda && !proceedWithNoNda)" mat-raised-button
                    type="submit">
                <span *ngIf="editMode; else elseBlock" i18n>Update</span>
                <ng-template #elseBlock>
                    <span i18n>Submit</span>
                </ng-template>
            </button>
            <a [routerLink]="['/order']" mat-raised-button>
                <span i18n>Cancel</span>
            </a>
            <a *ngIf="editMode" [routerLink]="['/order/view', _order.id]" mat-raised-button>
                <span i18n>View</span>
            </a>
        </mat-card-actions>
    </form>
</mat-card>
