<div class="row">
    <h2>Report activities</h2>
</div>


<div class="row-centered">
    <!-- Insert new -->
    <mat-card style="min-width:60vw; margin-left: 5px; margin-right: 5px;">
    <span *ngIf="statsForm">

      <form (ngSubmit)="save(statsForm.value, statsForm.valid)" [formGroup]="statsForm">
        <div class="column">


          <div class="column-centered">
            <h2>Current week report</h2>

              <!-- Change user\date form, for admins -->
            <div *ngIf="_canModifyAll">

              <form>
                <mat-form-field class="document-editor-field">
                  <mat-label>User</mat-label>
                  <mat-select (selectionChange)="setSelectedUser($event)" [(ngModel)]="selectedUser"
                              [compareWith]="compareObjects" name="selectedUser">
                    <mat-option *ngFor="let user of users" [value]="user">
                      {{ user.displayName }}
                    </mat-option>
                  </mat-select>
                  <mat-icon [ngClass]="isCurrentUserSelected ? 'green-icon' : 'orange-icon'" matSuffix>
                    sentiment_very_satisfied
                  </mat-icon>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Referring date</mat-label>
                  <input (dateChange)="changeDate($event)" [matDatepicker]="picker" [max]="maxDate" [value]="today"
                         i18n-placeholder matInput placeholder="Ref date">
                  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </form>
            </div>
          </div>


          <div class="row-center">
            <h4>Week nr.:</h4>&nbsp;&nbsp;
            <p [ngClass]="selectedWeek !== todaysWeek ? 'orange-icon' : ''">{{ selectedWeek }}&nbsp;&nbsp; <i>(today:
                {{ today | date: 'dd/MM/yyyy' }})</i>
            </p>
          </div>

            <!-- Report fields -->
          <div class="bordered row">
            <div *ngFor="let field of currentUserStatFields" class="give-margin">
              <mat-form-field *ngIf="field.isManual && field.name !== 'projectEfficiency'">
                <h5>{{ field.description }}</h5>
                <input formControlName="{{ field.name }}" i18n-placeholder matInput name="{{ field.name }}" required
                       type="number">
                <mat-hint>{{ field.hint }}</mat-hint>
              </mat-form-field>

              <div *ngIf="field.isManual && field.name === 'projectEfficiency'">
                <h5>{{ field.description }}</h5>
                <mat-select formControlName="{{ field.name }}" placeholder="Efficenza commesse">
                  <mat-option *ngFor="let opt of projectEfficencyOptions" [value]="opt.value">
                    {{ opt.name }}
                  </mat-option>
                </mat-select>
              </div>

            </div>
          </div>
          <div class="row-center">
            <button [disabled]="disableSubmit || !statsForm.valid" i18n mat-raised-button type="submit">Submit
              report</button>
          </div>

          <div *ngIf="currentReport?.lastModifiedDate" class="row-center-no-margin">
            <b>Last update:</b> <i>{{ currentReport.lastModifiedDate }}</i>
          </div>
        </div>

      </form>
    </span>
    </mat-card>


    <!-- list existing-->
    <mat-card style="min-width:30vw; margin-left: 5px; margin-right: 5px;">
        <div class="row-center-spaced">
            <h2>Past weeks Reports </h2>
            <h4><i>past weeks</i></h4>
            <div>
                <a routerLink="dashboard">
                    <button mat-mini-fab type="button">
                        <mat-icon>analytics</mat-icon>
                    </button>
                </a>
            </div>
        </div>
        <app-statcollector-list [currentUserStatFields]="currentUserStatFields" [selectedUser]="selectedUser">
        </app-statcollector-list>
    </mat-card>

</div>
