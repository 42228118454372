import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AppStateService} from '../services/app-state.service';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {NotificationsComponent} from './notifications.component';
import {environment} from '../../environments/environment';
import {ConfigService} from '../services/config.service';



@Component({
    selector: 'ev-header',
    styleUrls: ['./header.component.css'],
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    public pageTitle = '';

    public loggedUser = '';
    public loggedUserPhoto = null;
    public userrole;
    public userSubscription: Subscription;
    public settingsSubscription: Subscription;
    public notifications;
    public environment;
    public settings;
    public readonly toolbarColor;

    constructor(private appState: AppStateService,
                private _changeDetectionRef: ChangeDetectorRef,
                private authService: AuthService,
                private configService: ConfigService,
                private router: Router,
                public dialog: MatDialog) {

        if (environment.stage) {
            this.toolbarColor = 'warn';
        } else {
            this.toolbarColor = 'primary';
        }

        this.userSubscription = this.authService.userStream$.subscribe((user) => {
            if (user) {
                this.loggedUser = user.email;
                this.userrole = user.role;
                this.loggedUserPhoto = user.firebaseUser.photoURL;
            } else {
                this.loggedUser = 'Anonymus';
            }
        });
        this.notifications = this.setNotificationsConfig();
    }

    get authDomain() {
        if (this.authService) {
            return this.authService.appDomain;
        } else {
            return '';
        }
    }

    public logoutUser() {
        console.log('logoutUser');
        this.authService.logout().then(() => {
            console.log('Redirecting to home...');
            this.router.navigate(['/home']);
        });
    }


    public ngOnInit() {
        // this.environment = environment.stage ? 'TEST' : undefined;

        this.settingsSubscription = this.configService.settings.subscribe(settings => {
            if (!settings) return;
            console.log('LOADED SETTINGS', settings)
            this.settings = settings;
        })


    }

    public ngOnDestroy() {
        // prevent memory leak when component destroyed
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
        }
    }

    public toggleMenu() {
        this.appState.toggleSideBar(true).then(res => {
            console.log('Toggle click, detecting changes on GUI');
            this._changeDetectionRef.detectChanges();
        })
    }

    public openNotifications(): void {
        const dialogRef = this.dialog.open(NotificationsComponent, this.notifications);

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    public setNotificationsData(data) {
        this.notifications.data = data;
    }

    private setNotificationsConfig() {
        let config = new MatDialogConfig();
        config = {
            position: {
                top: '64px',
                right: '205px'
            },
            width: '300px'
        };

        return config;
    }
}
