export const unities = [
    'pieces',
    'm2',
    'ml',
    'kg',
    'l'
];





