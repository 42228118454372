<div class="fxlayout-row-spacearound-center">
    <mat-card style="width: 100%">
        <div class="row-distance">

            <div class="row center-in-row">
                <mat-form-field>
                    <input #filterF matInput placeholder="Filter">
                </mat-form-field>

                <mat-slide-toggle (change)="changeShowExports()" [checked]="showExport">Show export</mat-slide-toggle>

                <span class="iconfilter" style="margin-right: 10px;">
          <mat-icon (click)="setIconFilter('new')" [ngClass]="{'icon-on': iconFilter == 'new'}" class="icons"
                    title="new">whatshot</mat-icon>

          <mat-icon (click)="setIconFilter('assigned')" [ngClass]="{'icon-on': iconFilter == 'assigned'}" class="icons"
                    title="assigned">person</mat-icon>

          <mat-icon (click)="setIconFilter('closed')" [ngClass]="{'icon-on': iconFilter == 'closed'}" class="icons"
                    title="underway">loop</mat-icon>

          <mat-icon (click)="setIconFilter('archived')" [ngClass]="{'icon-on': iconFilter == 'archived'}" class="icons"
                    title="archived">archived</mat-icon>
        </span>
            </div>


            <div *ngIf="_canDownloadReport" class="row center-in-row">
                <mat-form-field>
                    <input #reportFromDate [matDatepicker]="reportFromDatePicker" [value]="reportDateFromSubject.value" matInput
                           placeholder="From Date">
                    <mat-datepicker-toggle [for]="reportFromDatePicker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #reportFromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <input #reportToDate [matDatepicker]="reportToDatePicker" [value]="reportDateToSubject.value" matInput
                           placeholder="To Date">
                    <mat-datepicker-toggle [for]="reportToDatePicker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #reportToDatePicker></mat-datepicker>
                </mat-form-field>

                <div class="center-in-row" style="margin-left: 10px">
                    <button (click)="downloadHourReport()" [disabled]="loading" color="primary" i18n mat-raised-button>
                        Download hours report
                    </button>
                </div>

            </div>
        </div>

        <div>
            <mat-tab-group (selectedIndexChange)="selectedTab = $event" [selectedIndex]="selectedTab">

                <mat-tab>
                    <ng-template mat-tab-label>
                        <span matTooltip="Lista veloce">Budgets list</span>
                    </ng-template>

                    <app-budget-list-table [queryParamFilter]="queryParamFilter"
                                           searchMode='quick'></app-budget-list-table>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <div *ngIf="performingSlowSearch$ | async; else loaded" class="tab-label-loading">
                            <mat-icon matTooltip="Searching...">cloud_sync</mat-icon>
                        </div>
                        <ng-template #loaded>
                            <div class="tab-label-loaded">
                                <mat-icon matTooltip="Lista esaustiva ({{slowListElements}} budgets)">cloud_done
                                </mat-icon>
                                <span>({{ slowListElements }})</span>
                            </div>
                        </ng-template>
                    </ng-template>

                    <app-budget-list-table (updateTotalElements)="slowListElements = $event"
                                           searchMode='slow'></app-budget-list-table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card>
</div>
