import {filter, switchMap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AppStateService} from '../services/app-state.service';
import {DataService} from '../services/data.service';
import {Subject} from 'rxjs';
import {Invoice} from '@wondersys/wonderbudget-lib';



@Component({
    selector: 'app-ddt',
    templateUrl: './ddt.component.html',
    styleUrls: ['./ddt.component.scss']
})
export class DdtComponent implements OnInit, OnDestroy {

    reloadSubject: Subject<void> = new Subject<void>();
    public ddt;
    private oId;
    private ddtSubscription;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService,
        private dataService: DataService
    ) {
    }

    handleDdtAdd(newInvoice: Invoice) {
        console.log('added event captured.', newInvoice);
        this.reloadSubject.next();
    }

    ngOnInit() {

        this.ddtSubscription = this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('id')),
            switchMap((params: ParamMap) => {
                this.oId = params.get('id');
                console.log('INVOICE ID', this.oId);
                return this.dataService.getDdt(this.oId);
            })).subscribe((ddt) => {
            if (!ddt) {
                this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this ddt id'});
                this.router.navigate(['/ddt']);
            } else {
                this.dataService.getOrder(ddt.orderId)
                    .subscribe((order) => {
                        ddt.id = this.oId;
                        ddt.order = order;
                        this.ddt = ddt;
                        console.log('Ddt set', ddt);
                    });
            }
        });

    }

    ngOnDestroy() {
        if (this.ddtSubscription) {
            this.ddtSubscription.unsubscribe();
        }
    }

}
