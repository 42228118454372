import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-mark-order-as-delivered-dialog',
    templateUrl: './mark-order-as-delivered-dialog.component.html',
    styleUrls: ['./mark-order-as-delivered-dialog.component.scss']
})
export class MarkOrderAsDeliveredDialogComponent implements OnInit, OnDestroy {

    form: FormGroup;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private dialogRef: MatDialogRef<MarkOrderAsDeliveredDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public order: any
    ) {
        this.form = new FormGroup({
            isDelivered: new FormControl(null),
            realDeliveryDate: new FormControl(null, [Validators.required])
        });

        this.form.get('isDelivered').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(isDelivered => {
            console.log('isDelivered: ', isDelivered);
            if (isDelivered) {
                this.form.get('realDeliveryDate').enable();
                if (new Date(this.order.realDeliveryDate.toString()).getTime()) {
                    this.form.get('realDeliveryDate').setValue(new Date(this.order.realDeliveryDate.toString()));
                }
            } else {
                this.form.get('realDeliveryDate').setValue(null);
                this.form.get('realDeliveryDate').disable();
            }
        });
    }

    ngOnInit(): void {
        this.form.get('isDelivered').setValue(this.order.isDelivered);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    updateOrder() {
        this.form.get('realDeliveryDate').markAsTouched();
        this.form.updateValueAndValidity({emitEvent: false});

        if (this.form.valid) {
            this.dialogRef.close(this.form.getRawValue());
        }
    }

    closeDialog() {
        this.dialogRef.close(null);
    }

    enableConfirmButton() {
        return this.form.get('isDelivered').value !== this.order.isDelivered ||
            new Date(this.form.get('realDeliveryDate').value).getTime() !== new Date(this.order.realDeliveryDate.toString()).getTime();
    }
}
