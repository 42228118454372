import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {DataService} from './data.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Budget} from '../budget/interfaces';



export interface BudgetListFilter {
    filter: string;
    status: string;
    showExport: boolean;
    sortActive?: string;
    sortDirection?: string;
}

export interface BudgetListResponse {
    data: Budget[];
    totalSize: number;
}

@Injectable({
    providedIn: 'root'
})
export class BudgetListTableService {

    private appliedFilterAndSorting: ReplaySubject<BudgetListFilter> = new ReplaySubject();
    private appliedFilter: BudgetListFilter;

    private performingQuickSerch: ReplaySubject<boolean> = new ReplaySubject();
    private performingSlowSearch: ReplaySubject<boolean> = new ReplaySubject();

    constructor(
        private dataService: DataService
    ) {
        this.performingQuickSerch.next(true);
        this.performingSlowSearch.next(true);
    }

    public get appliedFilterAndSorting$(): Observable<BudgetListFilter> {
        return this.appliedFilterAndSorting.asObservable();
    }

    public loadData(mode: 'quick' | 'slow', pageIndex: number, pageSize: number): Observable<BudgetListResponse> {
        switch (mode) {
            case 'quick':
                return this.getQuickList();
            case 'slow':
                return this.getSlowList(pageIndex, pageSize);
        }
    }

    public isLoading$(mode: 'quick' | 'slow') {
        return (mode === 'quick') ? this.performingQuickSerch.asObservable() : this.performingSlowSearch.asObservable();
    }

    retrieveFiltersFromStorage(): BudgetListFilter {

        const filter = {
            filter: (localStorage.getItem('budgetList_filter')) ? localStorage.getItem('budgetList_filter') : null,
            status: (localStorage.getItem('budgetList_status')) ? localStorage.getItem('budgetList_status') : null,
            showExport: localStorage.getItem('budgetList_showExport') === 'true',
            sortDirection: (localStorage.getItem('budgetList_sortDirection')) ? localStorage.getItem('budgetList_sortDirection') : 'desc',
            sortActive: (localStorage.getItem('budgetList_sortActive')) ? localStorage.getItem('budgetList_sortActive') : 'creation'
        };

        this.appliedFilter = filter;
        this.appliedFilterAndSorting.next(filter);

        return filter;
    }

    storeFilters(filter: BudgetListFilter) {
        if (this.compareFilters(filter, this.appliedFilter)) {
            localStorage.setItem('budgetList_filter', filter.filter ? filter.filter : '');
            localStorage.setItem('budgetList_status', filter.status ? filter.status : '');
            localStorage.setItem('budgetList_sortActive', filter.sortActive);
            localStorage.setItem('budgetList_sortDirection', filter.sortDirection);
            localStorage.setItem('budgetList_showExport', (filter.showExport) ? 'true' : 'false');

            this.appliedFilter = filter;
            this.appliedFilterAndSorting.next(filter);
        }
    }

    storeSorting(sortActive: string, sortDirection: string) {
        this.storeFilters({...this.appliedFilter, sortActive: sortActive, sortDirection: sortDirection});
    }

    /**
     *
     * @param actual
     * @param old
     * @returns true if the filters are different, false if are equals
     */
    compareFilters(actual: BudgetListFilter, old: BudgetListFilter) {
        if (!old) {
            return true;
        }
        return !Object.keys(actual).reduce((a: boolean, k: string) => a && actual[k] === old[k], true);
    }

    private getQuickList(): Observable<BudgetListResponse> {
        this.performingQuickSerch.next(true);
        return this.dataService.getQuickBudgetList().pipe(
            map((d) => ({data: d, totalSize: d.length})),
            tap(() => this.performingQuickSerch.next(false)),
            catchError((error) => {
                this.performingQuickSerch.next(false);
                console.error('Error getting quick orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }

    private getSlowList(pageIndex: number, pageSize: number): Observable<BudgetListResponse> {
        console.log('getting slow data: ', pageIndex, pageSize);
        let startAt = pageIndex * pageSize;
        if (pageIndex !== 0) {
            startAt++;
        }
        this.performingSlowSearch.next(true);
        return this.dataService.getBudgetsListPaginated(pageSize, startAt, this.appliedFilter).pipe(
            tap(() => this.performingSlowSearch.next(false)),
            catchError((error) => {
                this.performingSlowSearch.next(false);
                console.error('Error getting slow orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }
}
