import {BehaviorSubject, merge, Observable} from 'rxjs';

import {debounceTime, first, map, take} from 'rxjs/operators';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Timereport} from '../../../timereport/interfaces';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {ProjectNrPipe} from '../../../shared/pipes/project-nr.pipe';
import {DataSource} from '@angular/cdk/table';
import {DataService} from '../../../services/data.service';


import {firestoreDateToJsDate} from '../../../shared/utils/utils';



@Component({
    selector: 'app-activity-balance-table',
    templateUrl: './activity-balance-table.component.html',
    styleUrls: ['./activity-balance-table.component.scss']
})
export class ActivityBalanceTableComponent implements OnInit {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Input() budgetId;

    activityChange;

    currency: string = 'EUR';


    budgetActivities = []
    reducedBudgetActivities = []
    result = []

    public activitiesList$: Observable<Timereport[]>;

    // For the mat-table
    displayedColumns =
        ['activityName', 'hourlyCost', 'hours', 'cost', 'finalHours', 'finalCost'];
    dataSource: TimereportDataSource;

    constructor(private projectNrPipe: ProjectNrPipe,
                private dataService: DataService
    ) {
    }

    get totalBudget() {
        return this.result.reduce((a, b) => (a + b.cost), 0)
    }

    get totalFinal() {
        return this.result.reduce((a, b) => (a + b.finalCost), 0)
    }

    get totalHBudget() {
        return this.result.reduce((a, b) => (a + b.hours), 0)
    }

    get totalHFinal() {
        return this.result.reduce((a, b) => (a + b.finalHours), 0)
    }

    ngOnInit() {

        this.dataService.getBudgetCurrency(this.budgetId).pipe(take(1)).subscribe(c => this.currency = c);

        this.dataService.getAllBudgetActivities(this.budgetId).pipe(first()).subscribe(data => {

            data.then(acts => {
                acts.forEach(act => {

                    this.budgetActivities = this.budgetActivities.concat(act.docs.map(doc => doc.data()));

                });

                // console.log(this.budgetActivities);

                this.budgetActivities.forEach(ba => {
                    const reducedBA = this.reducedBudgetActivities.find(a => {
                        return ba.activity === a.activity;
                    });

                    const finalHours = ba.finalHours ? ba.finalHours : 0;
                    const hours = ba.hours ? ba.hours : 0;

                    if (reducedBA) {
                        reducedBA.hours += Number(hours);
                        reducedBA.cost += Number(ba.cost) * Number(hours);

                    } else {
                        this.reducedBudgetActivities.push({
                            activity: ba.activity,
                            category: ba.category,
                            hourlyCost: Number(ba.cost),
                            hours: Number(hours),
                            cost: Number(ba.cost) * hours,
                            finalCost: 0,
                            finalHours: 0,
                            actualCost: 0

                        });
                    }
                });

                // console.log("Reduced ---->>", this.reducedBudgetActivities)

                this.result = this.reducedBudgetActivities;

                this.dataService.getTimereportByBudget(this.budgetId)
                    .subscribe(trData => {
                        // console.log(data.map(doc => doc.payload.doc.data()));

                        trData.forEach(doc => {
                            const TRAct = doc.payload.doc.data();
                            if (TRAct.user.external) return;

                            const resAct = this.result.find(ra => ra.activity === TRAct.activity.activity);


                            if (resAct) {
                                resAct.finalHours += TRAct.hours;
                                resAct.finalCost += TRAct.actualCost * TRAct.hours;
                                resAct.actualCost = TRAct.actualCost;
                            } else {
                                this.result.push({
                                    activity: TRAct.activity.activity,
                                    category: TRAct.activity.category,
                                    hourlyCost: 0,
                                    hours: 0,
                                    cost: 0,
                                    finalHours: TRAct.hours,
                                    finalCost: TRAct.actualCost * TRAct.hours,
                                    actualCost: TRAct.actualCost
                                });
                            }

                        });

                        // console.log("merged ---->>", this.result)
                    });
            });
        });
    }

}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class TimereportDataSource extends DataSource<Timereport> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort,
                private projectNrPipe: ProjectNrPipe) {
        super();
        this.observable.subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Timereport[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('activityBalanceListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('activityBalanceListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('activityBalanceListSortId');
        const sortDirection = localStorage.getItem('activityBalanceListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            // console.log('Combine ', data, filter, page);
            const data = this._dataChange.value;

            // ???????????????????????????????
            // Filter
            const fData = data.filter((item: Timereport) => {
                if (!item.activity) {
                    return false;
                }
                let searchStr = (item.budget.budgetNr + item.activity.activity + item.user.displayName).toLowerCase();
                if (item.budget.project) {
                    searchStr += this.projectNrPipe.transform(item.budget).toLowerCase();
                }
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: Timereport[]) => {
        // console.log('sort ', this._sort.active, this._sort.direction);
        localStorage.setItem('activityBalanceListSortId', this._sort.active);
        localStorage.setItem('activityBalanceListSortDirection', this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: Timereport, b: Timereport) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'budget':
                    const abid = (typeof a.budget === 'object') ? a.budget.budgetNr : a.budget;
                    const bbid = (typeof b.budget === 'object') ? b.budget.budgetNr : b.budget;
                    [propertyA, propertyB] = [abid, bbid];
                    break;
                case 'userId':
                    [propertyA, propertyB] = [a.user.displayName, b.user.displayName];
                    break;
                case 'activity':
                    [propertyA, propertyB] = [a.activity.activity, b.activity.activity];
                    break;
                case 'hours':
                    [propertyA, propertyB] = [a.hours, b.hours];
                    break;
                case 'date':
                    [propertyA, propertyB] = [firestoreDateToJsDate(a.date), firestoreDateToJsDate(b.date)];
                    break;
                case 'user':
                    [propertyA, propertyB] = [a.user.email, b.user.email];
                    break;
            }

            let valueA;
            let valueB;

            if (propertyA instanceof Date) {
                valueA = propertyA;
                valueB = propertyB;
            } else {
                valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            }

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}

