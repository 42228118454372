import {debounceTime, distinctUntilChanged, filter, first, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {AppStateService} from '../../services/app-state.service';
import {OrderEntryComponent} from '../components/order-entry.component';
import {OrderEntryArrayComponent} from '../components/order-entry-array.component';
import {BehaviorSubject, fromEvent, Subject} from 'rxjs';
import {Company, Person} from '../../interfaces/address';
import {PaymentMethods} from '../../interfaces/payment-methods';
import {DeliveryNote, Invoice, Order} from '@wondersys/wonderbudget-lib';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AclUser} from '../../interfaces/app-user';
import * as firebase from 'firebase';
import * as moment from 'moment-timezone';
import {ConfigService} from '../../services/config.service';



@Component({
    selector: 'app-order-add',
    templateUrl: './order-add.component.html',
    styleUrls: ['./order-add.component.scss']
})
export class OrderAddComponent implements OnInit, OnDestroy, AfterViewChecked {
    public orderForm: FormGroup;
    public vendors: BehaviorSubject<Company[]> = new BehaviorSubject([]);
    public hintPaymentMethod;
    public hintShippingMethod;
    @ViewChild('vendorInput', {static: true}) vendorInput: ElementRef;
    public filteredVendors: Company[];
    public orderNr: string;
    public currentOrderNr: string;
    public selectedVendor: Partial<Company>;
    public people;
    public users$;
    public ndaFile;
    public orderAdding = false;
    public editMode = false;
    public vendorHasNda = false;
    public vendorNdaFieldIsSet = false;
    public proceedWithNoNda = false;
    public sendReminderEmail = false;
    public reminderMailSent = false;
    public reminderMailSending = false;
    public reminderSendTo = [];
    public paymentMethodOptions = [];
    public shippingMethodOptions = [
        'a ns carico',
        'a vs carico',
        'a 1/2 corriere',
        'a 1/2 destinatario',
        'a 1/2 mittente',
        'a 1/2 vostro con addebito in fattura',
        'Autotrasporti Giobbio',
        'Autotrasporti Moretti',
        'Bartolini',
        'Corriere Convenzionato',
        'GLS',
        'Trial Srl S.S. Giovanni',
        'UPS',
        'da definire',
        'vedi Nota'
    ];
    public vatOptions = [22, 10, 4, 0];
    public oId;
    public order;
    public authorized;
    private _order;
    private unsubscribe$ = new Subject();
    private invoices: FormArray;
    private deliveryNotes: FormArray;

    constructor(
        private _changeDetectionRef: ChangeDetectorRef,
        private dataService: DataService,
        private configService: ConfigService,
        private authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService) {
    }

    get invoiceFormArray(): FormArray {
        return (this.orderForm.get('invoices') as FormArray);
    }

    get dNotesFormArray(): FormArray {
        return (this.orderForm.get('deliveryNotes') as FormArray);
    }

    get techPersonControl() {
        return this.orderForm.get('techContact');
    }

    ngAfterViewChecked(): void {
        // your code
        this._changeDetectionRef.detectChanges();
    }

    public setReminderSendTo(ev) {
        this.reminderSendTo = ev.value;
    }

    public addInvoiceItem(): void {
        this.invoiceFormArray.push(this.createInvoiceItem());
    }

    public removeInvoiceItem(i): void {
        this.invoiceFormArray.removeAt(i);
    }

    public addDeliveryNoteItem(): void {
        this.dNotesFormArray.push(this.createDeliveryNoteItem());
    }

    public removeDeliveryNoteItem(i): void {
        this.dNotesFormArray.removeAt(i);
    }

    ngOnInit() {

        this.selectedVendor = undefined;

        this.users$ = this.dataService.getUserList().pipe(
            map((userList) => userList.map((user: any) => {
                const data = user.payload.doc.data()
                const id = user.payload.doc.id;
                return {id: id, ...data};
            }))).pipe(takeUntil(this.unsubscribe$));

        this.buildForm();

        this.orderForm.get('isInternalOrder').valueChanges.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(value => {
            if (value) {
                this.orderForm.get('deliveryDate').setValue(null);
                this.orderForm.get('deliveryDate').disable();
            } else {
                this.orderForm.get('deliveryDate').enable();
            }
        });

        this.dataService.getPaymentMethods().pipe(
            map((paymentMethodsList) => paymentMethodsList.map((paymentMethod) => {
                const data = paymentMethod.payload.doc.data() as PaymentMethods;
                return data;
            }))).pipe(takeUntil(this.unsubscribe$))
            .subscribe((paymentMethods) => {
                this.paymentMethodOptions = paymentMethods;
            });

        this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('id')),
            switchMap((params: ParamMap) => {
                this.oId = params.get('id');
                console.log('ORDER ID', this.oId);
                return this.dataService.getOrder(this.oId).pipe(takeUntil(this.unsubscribe$));
            })).subscribe((order: Order | any) => {
            if (!order) {
                this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this order id'});
                this.router.navigate(['/order']);
            } else {
                this._order = order;
                order.id = this.oId;
                this.editMode = true;
                this.currentOrderNr = order.orderNr;
                this.orderForm.reset();
                while ((<FormArray>this.orderForm.get('items')).length) {
                    (<FormArray>this.orderForm.get('items')).removeAt(0);
                }

                const patchOrder: any = {
                    vendor: order.vendor,
                    primaryContact: order.primaryContact,
                    note: order.note,
                    pmNote: order.pmNote,
                    techContact: order.techContact,
                    invoiceText: order.invoiceText ? order.invoiceText : '',
                    deliveryText: order.deliveryText ? order.deliveryText : '',
                    paymentMethod: order.paymentMethod,
                    paymentMethodCode: order.paymentMethodCode ? order.paymentMethodCode : '',
                    paymentText: order.paymentText ? order.paymentText : '',
                    shippingMethod: order.shippingMethod,
                    shippingText: order.shippingText ? order.shippingText : '',
                    returnMethod: order.returnMethod,
                    vat: order.vat,
                    authorized: order.authorized,
                    currency: order.currency ? order.currency : this.findCurrency(order.items)
                };

                if (order.deliveryDate) {
                    patchOrder.deliveryDate = moment((<firebase.firestore.Timestamp>order.deliveryDate).toDate());
                }

                if (order.invoice) {
                    patchOrder.invoice = order.invoice;
                }
                if (order.deliveryNote) {
                    patchOrder.deliveryNote = order.deliveryNote;
                }

                if (order.isDelivered !== undefined && order.isDelivered !== null) {
                    patchOrder.isDelivered = order.isDelivered;
                }

                if (order.isInternalOrder !== undefined && order.isInternalOrder !== null) {
                    patchOrder.isInternalOrder = order.isInternalOrder;
                }

                if (order.realDeliveryDate !== undefined && order.realDeliveryDate !== null) {
                    patchOrder.realDeliveryDate = order.realDeliveryDate;
                }

                this.orderForm.patchValue(patchOrder);
                this.orderForm.controls['primaryContact'].updateValueAndValidity();

                order.items.forEach(item => {
                    item.total = (item.costEach * item.quantity) - (item.costEach * item.quantity / 100 * item.discount);

                    let ddtCompletion: any = 0;
                    if (item.ddt) {
                        item.ddt.forEach(ddt => {
                            ddtCompletion = ddtCompletion + ddt.deliveryQuantity;
                        });
                    }

                    item.itemDeliveryCompletion = parseInt((ddtCompletion / item.quantity * 100).toFixed(0));

                    let invoiceCompletion: any = 0;
                    if (item.invoices) {
                        item.invoices.forEach(invoice => {
                            invoiceCompletion = parseFloat(invoiceCompletion) + invoice.materialAmount;
                        });
                    }

                    item.itemInvoiceCompletion = item.total - parseFloat(invoiceCompletion);
                });

                this.hintPaymentMethod = (this.paymentMethodOptions.map(function (pm) {
                    return pm.methodValue;
                }).indexOf(order.paymentMethod) == -1);
                this.hintShippingMethod = (this.shippingMethodOptions.indexOf(order.shippingMethod) == -1);

                this.selectedVendor = order.vendor;
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Calling build items again....')
                this.orderForm.setControl('items', OrderEntryArrayComponent.buildItems(order.items));

                const invoiceFa = this.invoiceFormArray;
                while (invoiceFa.length) {
                    invoiceFa.removeAt(0);
                }
                if (order.invoices) {
                    order.invoices.forEach((i: Invoice) => {
                        invoiceFa.push(this.fb.group(i));
                    });
                }

                const deliveryNotesFa = this.dNotesFormArray;
                while (deliveryNotesFa.length) {
                    deliveryNotesFa.removeAt(0);
                }
                if (order.deliveryNotes) {
                    order.deliveryNotes.forEach((dn: DeliveryNote) => {
                        deliveryNotesFa.push(this.fb.group(dn));
                    });
                }

                this.vendorChange(null);
                this.authorized = (!order.authorized || order.authorized != 'waiting');
                console.log('Order set', order);
            }
        });

        fromEvent(this.vendorInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                map((key: any) => {
                    const vendor = this.vendorInput.nativeElement.value;
                    console.log('got', vendor);
                    const name = vendor && typeof vendor === 'object' ? vendor.name : String(vendor);

                    this.dataService.getFilteredCompanies(name).pipe(take(1)).subscribe(result => this.filteredVendors = result);
                })
            )
            .subscribe();

        this.dataService.getNextOrderNr().pipe(takeUntil(this.unsubscribe$)).subscribe((num) => {
            const paddedNum = num.toString().padStart(4, '0');
            const year = (new Date).getFullYear();
            this.orderNr = this.authService.currentUser.initials + 'OF ' + paddedNum + '-' + year;
        });

        this.people = this.orderForm.get('vendor').valueChanges.pipe(
            distinctUntilChanged(),
            filter(vendor => (vendor && vendor.id)),
            switchMap((vendor) => {
                return this.dataService.getPeopleByCompany(vendor.id);
            }));

    }

    // method for legacy fields: old orders do not have any currency so we infer it
    findCurrency(items) {
        if (!items || items.length === 0) return 'EUR';
        const exportBudget = items.find(i => i.budget.budgetNr.includes('export'));
        if (exportBudget) return 'USD';
        return 'EUR';
    }

    public resetHint(method, $event) {
        switch (method) {
            case 'payment':
                this.hintPaymentMethod = (this.paymentMethodOptions.map(function (pm) {
                    return pm.methodValue;
                }).indexOf($event.value) == -1);
                break;
            case 'shipping':
                this.hintShippingMethod = (this.shippingMethodOptions.indexOf($event.value) == -1);
                break;
        }
    }

    public comparePeople = (p1: Person, p2: Person) => {
        console.log('comparing people....')
        if (!p1 || !p2) {
            return false;
        }
        return p1.id === p2.id;
    }

    public goToFixInvoiceDdt(number, date, type) {
        this.router.navigate(['/' + type, number, this._order.id, new Date(date).getTime()]);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    submit() {
        console.log('Reactive Form submitted: ', this.orderForm);

        if (this.orderForm.valid) {
            // Disable submit button
            this.orderAdding = true;
            const order = this.orderForm.getRawValue();
            order.totalValue = this.calculateOrderTotal(order);
            order.author = this._project({
                id: 1,
                googleUid: 1,
                email: 1,
                displayName: 1,
                initials: 1
            })(this.authService.currentUser);
            order.techContact = this._project({
                id: 1,
                googleUid: 1,
                email: 1,
                displayName: 1,
                initials: 1
            })(order.techContact);
            order.projects = order.items.filter((entry) => entry.budget.project).map((entry) => entry.budget.project.projectNr);
            order.budgets = order.items.map((entry) => entry.budget.id).filter((el, i, a) => i === a.indexOf(el));


            // MOMENT DATEPICKER
            if (order.deliveryDate) {
                order.deliveryDate = moment(order.deliveryDate).toDate();
            }
            if (order.deliveryNote.date) {
                order.deliveryNote.date = moment(order.deliveryNote.date).toDate();
            }
            if (order.invoice.date) {
                order.invoice.date = moment(order.invoice.date).toDate();
            }
            // if (order.invoices) {
            //   order.invoices.forEach(i => {
            //     const md = moment(i.date);
            //     i.date = md.isValid() ?  md.toDate() : null;
            //   });
            // }
            // if (order.deliveryNotes) {
            //   order.deliveryNotes.forEach(dn => {
            //     const md = moment(dn.date);
            //     dn.date = md.isValid() ?  md.toDate() : null;
            //   });
            // }
            order.authorized = 'no';
            const selectedPaymentMethod: any = this.paymentMethodOptions.filter(pm => {
                return pm.methodValue == order.paymentMethod;
            })

            if (selectedPaymentMethod[0]) {
                order.paymentMethodCode = selectedPaymentMethod[0].methodCode;
            }

            if (this.editMode) {
                // UPDATE
                order.orderNr = this.currentOrderNr;
                this.dataService.updateOrder(this._order.id, order)
                    .then(() => {
                        this.router.navigate(['/order/view', this._order.id]);
                    }).catch((err) => {
                    console.log('Error while updating order', err);
                    this.orderAdding = false;
                });
            } else {
                // ADD
                order.date = firebase.firestore.FieldValue.serverTimestamp();
                order.orderNr = this.orderNr;
                order.searchMode = 'quick';

                this.dataService.addOrder(order)
                    .then(() => {
                        console.log('Order added');
                        while ((<FormArray>this.orderForm.get('items')).length) {
                            (<FormArray>this.orderForm.get('items')).removeAt(0);
                        }
                        this.orderForm.reset();
                        this.orderAdding = false;
                    })
                    .catch((err) => {
                        console.log('Error while adding order', err);
                        this.orderAdding = false;
                    });
            }
        } else {
            console.log('Order invalid');
        }
    }

    setCurrency(currency: string) {
        this.orderForm.get('currency').setValue(currency);
    }

    public vendorDisplayFn(vendor: Company): string {
        if (vendor) {
            return vendor.name ? vendor.name : vendor.email_domain;
        } else {
            return null;
        }
    }

    public submitReminderEmail() {
        console.log(this.reminderSendTo)
        console.log(this.selectedVendor)
        this.reminderMailSending = true;
        this.dataService.sendNdaReminder(this.reminderSendTo, this.selectedVendor).pipe(first()).subscribe(res => {
            console.log('send reminder result: ', res);
            const response: any = res;
            this.reminderMailSending = false;
            if (response.error) {
                this.reminderMailSent = false;
            } else {
                this.reminderMailSent = true;
            }
        });
    }

    public vendorChange($event) {
        var component = this;
        const vendor = this.orderForm.get('vendor').value;
        this.selectedVendor = vendor;
        this.vendorHasNda = false;
        this.dataService.getCompanyById(vendor.id).pipe(
            map(this._project({id: 1, address: 1, name: 1, custom_fields: 1})))
            .subscribe((company) => {
                this.orderForm.get('vendor').setValue(company, {
                    onlySelf: true,
                    emitEvent: false,
                    emitModelToViewChange: true
                });
                if ($event != null && company.id !== this.orderForm.get('vendor').value.id) {
                    this.orderForm.controls['primaryContact'].reset();
                }
                this.selectedVendor = company;

                console.log(company)

                const cfNdaId = this.configService.settings.value.value.prosperworks.cfNdaId;

                console.log(cfNdaId);

                if (!cfNdaId) {
                    throw Error(`nda field id is not defined in settings`);
                }

                const vendorNdaFile = company.custom_fields.find(cf => cf.custom_field_definition_id.toString() === cfNdaId);

                if (vendorNdaFile && vendorNdaFile.value) {
                    this.vendorNdaFieldIsSet = true;
                } else {
                    this.vendorNdaFieldIsSet = false;
                }

                //NOTE: this.dataService.getVendorNda(company) might be undefined
                const vendorNdaObs = this.dataService.getVendorNda(company)?.pipe(takeUntil(this.unsubscribe$))
                if (vendorNdaObs) {

                    vendorNdaObs.pipe(first()).subscribe(driveFilePromise => {

                        driveFilePromise.then(driveFileResponse => {
                            console.log('found this NDA drive file: ', driveFileResponse)
                            component.ndaFile = driveFileResponse;
                            component.vendorHasNda = true;
                            component._changeDetectionRef.detectChanges();
                        })

                    });

                } else {
                    console.log('drive file observable not initialized')
                    this.vendorHasNda = false;
                }
            });

        // this.dataService.getDriveFileById('0BzlPWxgKAlnIMXIxVDBZZldTTVNyTTNUb1B1bDdFSEZCSnBv').subscribe(data => {
        //   console.log(data);
        // });

        // this.dataService.getPeopleByCompany(vendor.id)
        // .subscribe((people) => {
        //   console.log('people', people);
        // });
    }

    private buildForm() {
        // Definition of the Order Form
        // build the form model
        this.orderForm = this.fb.group({
            vendor: [undefined, Validators.required],
            primaryContact: [undefined, Validators.compose([Validators.required, this.primaryContactValidator])],
            techContact: <AclUser>null,
            note: '',
            pmNote: <string>undefined,
            paymentMethod: <string>undefined,
            paymentMethodCode: <string>undefined,
            paymentText: <string>undefined,
            shippingMethod: <string>undefined,
            shippingText: <string>undefined,
            returnMethod: <string>undefined,
            deliveryDate: [<Date>undefined, Validators.required],
            currency: <string>'EUR',
            isDelivered: <boolean>false,
            isInternalOrder: <boolean>false,
            realDeliveryDate: <Date>null,

            vat: 22,
            invoice: this.fb.group({
                invoiceNr: <string>undefined,
                date: <Date>undefined
            }),
            deliveryNote: this.fb.group({
                deliveryNoteNr: <string>undefined,
                date: <Date>undefined,
                reason: <string>undefined
            }),
            items: OrderEntryArrayComponent.buildItems(),
            // https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/
            invoices: this.fb.array([]),
            invoiceText: <string>undefined,
            deliveryText: <string>undefined,
            deliveryNotes: this.fb.array([])
        });

    }

    private primaryContactValidator = (control): { [key: string]: any } => {
        if (!control.value) {
            return {'invalidPrimaryContact': {value: control.value}};
        }
        return (typeof control.value === 'object'
            && control.value.emails && control.value.emails.length > 0) ? null : {'invalidPrimaryContact': {value: control.value}};
    }

    // INVOICES
    private createInvoiceItem(): FormGroup {
        return this.fb.group({
            invoiceNr: ['', Validators.required],
            date: undefined
        });
    }

    // DELIVERY NOTE
    private createDeliveryNoteItem(): FormGroup {
        return this.fb.group({
            deliveryNoteNr: ['', Validators.required],
            reason: '',
            date: undefined
        });
    }

    private calculateOrderTotal = (order: Order) => {
        return order.items
            .map(item => OrderEntryComponent.calculateEntryValue(item.costEach, item.quantity, item.discount))
            .reduce((prev, curr) => {
                return prev + curr;
            }, 0);
    }

    private filterVendor(val: string): Company[] {
        return this.vendors.value
            .filter(option => {
                if (option.contact_type_id === 100) {
                    // Filter out only customers
                    return false;
                }
                if (option.name) {
                    return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
                } else {
                    return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
                }
            });
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object)) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }

}
