<mat-expansion-panel *ngFor="let report of reports.value" [expanded]="false" style="background-color:#d3d3d333">
    <mat-expansion-panel-header>
        <mat-panel-title i18n>

            <div class="row">
        <span>
          <b>week:</b> {{ report.weekOfYear }} | <b>Last modified:</b> {{ report.lastModifiedDate | date:'dd/MM/yyyy' }}
        </span>
            </div>

        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="column" style="border-top: 10px">

        <div *ngFor="let field of report.fields" class="give-margin bordered">
            <div *ngIf="field.show" class="row">
                <b>{{ field.description }}:&nbsp;&nbsp; </b> {{ field.value }}
            </div>
        </div>

    </div>
</mat-expansion-panel>