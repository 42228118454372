import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {firestoreDateToJsDate} from '../../shared/utils/utils';
import {ContactPickerDialogComponent} from '../contact-picker-dialog/contact-picker-dialog.component';



@Component({
    selector: 'app-change-finaloffer-dialog',
    templateUrl: './change-finaloffer-dialog.component.html',
    styleUrls: ['./change-finaloffer-dialog.component.scss']
})
export class ChangeFinalofferDialogComponent implements OnInit {

    public columns = ['date', 'user', 'values'];
    public finalOfferOrder: FormControl = new FormControl(0, Validators.required);
    public finalOfferOrderChanges: any[];
    public isExport = false;

    constructor(
        public dialogRef: MatDialogRef<ContactPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    get isValid() {
        return this.finalOfferOrder.valid;
    }

    ngOnInit() {

        if (this.data.finalOfferOrder) {
            this.finalOfferOrder.setValue(this.data.finalOfferOrder);
        }
        if (this.data.finalOfferOrderChanges) {
            this.data.finalOfferOrderChanges.map(i => i.date = firestoreDateToJsDate(i.date));
            this.finalOfferOrderChanges = this.data.finalOfferOrderChanges;
        }
        this.isExport = this.data.isExport;
    }

    closeDialog() {
        if (this.isValid) {
            this.dialogRef.close({
                finalOfferOrder: this.finalOfferOrder.value
            });
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
