<h3 i18n mat-dialog-title>Confirm delete order</h3>
<mat-dialog-content>
    Are you sure you want to delete order {{ data.order.orderNr }}?

</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="cancel()" color="basic" mat-flat-button>Cancel</button>
    <button (click)="confirm()" color="warn" mat-raised-button>Delete</button>

</mat-dialog-actions>
