<mat-card>
    <form (ngSubmit)="submit()" [formGroup]="userForm">
        <mat-card-header>
            <mat-card-title>
                <h4>
                    <div *ngIf="editMode; else newUser">
                        <span i18n>Edit User</span>&nbsp;
                    </div>
                    <ng-template #newUser>
                        <span i18n>New User</span>&nbsp;
                    </ng-template>
                </h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="user-container">
                <div class="user-meta">
                    <div class="user-meta-form">
                        <div>
                            <mat-form-field>
                                <input formControlName="userId" i18n-placeholder matInput placeholder="User ID" required
                                       type="text">
                            </mat-form-field>
                            <mat-form-field>
                                <input formControlName="displayName" i18n-placeholder matInput placeholder="Display Name"
                                       required
                                       type="text">
                            </mat-form-field>
                            <mat-form-field>
                                <input formControlName="email" i18n-placeholder matInput placeholder="Email" required
                                       type="text">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <input formControlName="firstName" i18n-placeholder matInput placeholder="First Name"
                                       required
                                       type="text">
                            </mat-form-field>
                            <mat-form-field>
                                <input formControlName="lastName" i18n-placeholder matInput placeholder="Last Name" required
                                       type="text">
                            </mat-form-field>
                            <mat-form-field>
                                <input formControlName="initials" i18n-placeholder matInput placeholder="Initials" required
                                       type="text">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <input formControlName="googleUid" i18n-placeholder matInput placeholder="Google UID"
                                       required
                                       type="text">
                                <mat-hint>get this ID from:
                                    https://developers.google.com/admin-sdk/directory/reference/rest/v1/users/get
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-select formControlName="role" placeholder="User type">
                                    <mat-option *ngFor="let type of userTypes" [value]="type">{{ type }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-select formControlName="type" multiple placeholder="Roles">
                                    <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.id }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-select formControlName="jobClassification" placeholder="Job Classification">
                                    <mat-option *ngFor="let job of jobClassifications" [value]="job">{{ job }}
                                    </mat-option>
                                </mat-select>
                                <mat-hint>used by reporting</mat-hint>
                            </mat-form-field>

                        </div>
                        <div>
                            <mat-form-field>
                                <input formControlName="personalName" i18n-placeholder matInput placeholder="Personal Name"
                                       type="text">
                                <mat-hint>Used by email counter service</mat-hint>
                            </mat-form-field>
                            <mat-form-field>
                                <input formControlName="referent" i18n-placeholder matInput placeholder="Referent"
                                       type="text">
                                <mat-hint>email addresses, comma separated</mat-hint>
                            </mat-form-field>
                            <mat-checkbox formControlName="external" i18n-placeholder
                                          matTooltip="not a direct employee">Exclude
                                timereports hours in budget summary (External flag)
                            </mat-checkbox>
                        </div>
                        <div style="margin-top: 10px">
                            <mat-checkbox formControlName="active" i18n-placeholder>Active</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="userForm.invalid || userAdding" mat-raised-button type="submit">
                <span *ngIf="editMode; else elseBlock" i18n>Update</span>
                <ng-template #elseBlock>
                    <span i18n>Submit</span>
                </ng-template>
            </button>
            <a [routerLink]="['/users']" mat-raised-button>
                <span i18n>Cancel</span>
            </a>
        </mat-card-actions>
    </form>
</mat-card>
