import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import {TimereportComponent} from './timereport.component';
import {AddTimereportDialogComponent, TimereportAddComponent} from './timereport-add/timereport-add.component';
import {SendReportDialogComponent, TimereportListComponent} from './timereport-list/timereport-list.component';
import {SharedModule} from '../shared/shared.module';
import {NgxWigModule} from 'ngx-wig';
import {TimereportListTableComponent} from './timereport-list-table/timereport-list-table.component';
import {TimeReportGuard} from './timereport.guard';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgxWigModule
    ],
    declarations: [TimereportComponent, TimereportAddComponent, TimereportListComponent,
        AddTimereportDialogComponent, SendReportDialogComponent, TimereportListTableComponent],
    entryComponents: [AddTimereportDialogComponent, SendReportDialogComponent],
    providers: [TimeReportGuard]
})
export class TimereportModule {
}
