import {BehaviorSubject, merge, Observable} from 'rxjs';

import {debounceTime, map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {LantekNesting} from './interfaces/lantekNesting';
import {DataService} from '../services/data.service';
import {AuthService} from '../services/auth.service';



@Component({
    selector: 'app-laser-cut-list',
    templateUrl: './laser-cut-list.component.html',
    styleUrls: ['./laser-cut-list.component.scss']
})
export class LaserCutListComponent implements OnInit {

    public displayedColumns = ['IdNesting', 'CodiceNesting', 'CodiceLamiera', 'Materiale', 'DataEsportazione', 'StatoNesting'];
    public dataSource: NestingDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private nestingStream$;

    constructor(private dataService: DataService,
                private authService: AuthService) {
    }

    ngOnInit() {

        this.nestingStream$ = this.dataService.getLaserCutNesting().pipe(
            // .do((n) => {console.log('Nesting snap', n); })
            map((nestingList) => nestingList.map((nestingSnap: any) => {
                const data = nestingSnap.payload.doc.data() as LantekNesting;
                const id = nestingSnap.payload.doc.id;
                return {id: id, ...data};
            })));

        // Assign the data to the data source for the table to render
        this.dataSource = new NestingDataSource(
            this.nestingStream$,
            this.paginator, this.sort);

    }

    public getListFilter() {
        let listFilter = localStorage.getItem('laserCutListFilter') ? localStorage.getItem('laserCutListFilter') : '';
        this.applyFilter(listFilter);
        return listFilter;
    }

    public setListFilter(filterName, filterValue) {
        localStorage.setItem(filterName, filterValue);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.setListFilter('laserCutListFilter', filterValue);
    }

}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class NestingDataSource extends DataSource<LantekNesting> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable.subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<LantekNesting[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('laserCutListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('laserCutListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('laserCutListSortId');
        const sortDirection = localStorage.getItem('laserCutListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            // console.log('Combine ', data, filter, page);
            const data = this._dataChange.value;

            // ???????????????????????????????
            // Filter
            const fData = data.filter((item: LantekNesting) => {
                if (!item.IdNesting) {
                    return false;
                }
                const searchStr = item.CodiceNesting.toLowerCase();
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: LantekNesting[]) => {
        // console.log('sort ', this._sort.active, this._sort.direction);
        localStorage.setItem('laserCutListSortId', this._sort.active);
        localStorage.setItem('laserCutListSortDirection', this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: LantekNesting, b: LantekNesting) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'IDNesting':
                    [propertyA, propertyB] = [a.IdNesting, a.IdNesting];
                    break;
            }

            let valueA;
            let valueB;

            valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
