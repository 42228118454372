import {fromEvent, merge, Observable, Subject} from 'rxjs';
import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {InvoicesLIstFilter, InvoicesListTableService} from '../../services/invoices-list-table.service';
import {debounceTime, takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-invoices-list',
    templateUrl: './invoices-list.component.html',
    styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit, OnDestroy {


    public resultsLength = 0;
    public operationInExecution = false;
    @Input() reloadDataSubject: Observable<void>;
    @ViewChild('invoiceNrF', {static: true}) invoiceNrF: ElementRef;
    @ViewChild('vendorF', {static: true}) vendorF: ElementRef;
    @ViewChild('orderNrF', {static: true}) orderNrF: ElementRef;
    public selection = [];
    public selectedTab = 0;
    public slowListElements: number = 0;
    public performingQuickSerch$: Observable<boolean>;
    public performingSlowSearch$: Observable<boolean>;
    public bulkSendToAccounting: Subject<void> = new Subject<void>();
    private unsubscribe$ = new Subject();

    constructor(
        private configService: ConfigService,
        private invoicesListTableService: InvoicesListTableService
    ) {
        this.performingQuickSerch$ = this.invoicesListTableService.isLoading$('quick');
        this.performingSlowSearch$ = this.invoicesListTableService.isLoading$('slow');
    }

    ngOnInit() {
        this.restoreFilters();

        const invoicNrFevent$ = fromEvent(this.invoiceNrF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const vendorFevent$ = fromEvent(this.vendorF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const orderNrFevent$ = fromEvent(this.orderNrF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );

        merge(invoicNrFevent$, vendorFevent$, orderNrFevent$).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => this.storeFilters());
    }

    public ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    restoreFilters() {
        const filter = this.invoicesListTableService.retrieveFiltersFromStorage();

        this.orderNrF.nativeElement.value = filter.orderNr;
        this.invoiceNrF.nativeElement.value = filter.invoiceNr;
        this.vendorF.nativeElement.value = filter.vendor;
    }

    storeFilters() {
        const filter: InvoicesLIstFilter = {
            orderNr: this.orderNrF.nativeElement.value.trim(),
            invoiceNr: this.invoiceNrF.nativeElement.value.trim(),
            vendor: this.vendorF.nativeElement.value.trim()

        };

        this.invoicesListTableService.storeFilters(filter);
    }
}
