<div class="fxlayout-row-spacearound-center">
    <mat-card style="width: 100%">
        <div class="row">
            <mat-form-field class="filterMargin">
                <input #orderNrF matInput placeholder="Order Nr">
            </mat-form-field>

            <mat-form-field class="filterMargin">
                <input #projectNrF matInput placeholder="Project">
            </mat-form-field>

            <mat-form-field class="filterMargin">
                <input #vendorF matInput placeholder="Vendor">
            </mat-form-field>

            <mat-form-field class="filterMargin">
                <input #itemDescrF matInput placeholder="Item description">
            </mat-form-field>

            <div class="ml-1">
                <mat-slide-toggle #pendingF (change)="storeFilters()">Pending</mat-slide-toggle>
            </div>

            <div class="ml-1">
                <mat-slide-toggle #urgentF (change)="storeFilters()">Urgent</mat-slide-toggle>
            </div>

            <div class="ml-1">
                <mat-slide-toggle #deliveredF (change)="storeFilters()">Delivered</mat-slide-toggle>
            </div>

            <div class="ml-1">
                <mat-slide-toggle #waitingF (change)="storeFilters()" class="filterMargin">Waiting for Authorization
                </mat-slide-toggle>
            </div>

            <div class="ml-1">
                <button (click)="exportMaterialExcel()" mat-raised-button>Export materials</button>
            </div>

            <span class="add-order">
        <button [routerLink]="['/order/add']" color="accent" mat-fab>
          <mat-icon>add</mat-icon>
        </button>
      </span>

        </div>

        <div>
            <mat-tab-group (selectedIndexChange)="selectedTab = $event" [selectedIndex]="selectedTab">

                <mat-tab>
                    <ng-template mat-tab-label>
                        <span matTooltip="Lista veloce">Orders list</span>
                    </ng-template>

                    <app-order-list-table [exportRequest]="exportRequest" searchMode='quick'></app-order-list-table>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <div *ngIf="performingSlowSearch$ | async; else loaded" class="tab-label-loading">
                            <mat-icon matTooltip="Searching...">cloud_sync</mat-icon>
                        </div>
                        <ng-template #loaded>
                            <div class="tab-label-loaded">
                                <mat-icon matTooltip="Lista esaustiva ({{slowListElements}} orders)">cloud_done
                                </mat-icon>
                                <span>({{ slowListElements }})</span>
                            </div>
                        </ng-template>
                    </ng-template>

                    <app-order-list-table (updateTotalElements)="slowListElements = $event" [exportRequest]="exportRequest"
                                          searchMode='slow'></app-order-list-table>
                </mat-tab>
            </mat-tab-group>
        </div>

    </mat-card>
</div>
