import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import * as moment from 'moment-timezone';
import {BehaviorSubject, Subscription} from 'rxjs';
import {StatField, StatReport} from '@wondersys/wonderbudget-lib';
import {AclUser} from '../../interfaces/app-user';
import {map} from 'rxjs/operators';



@Component({
    selector: 'app-statcollector-list',
    templateUrl: './statcollector-list.component.html',
    styleUrls: ['./statcollector-list.component.scss']
})
export class StatcollectorListComponent implements OnInit {

    public reportsSub: Subscription;
    public reports: BehaviorSubject<Partial<StatReport>[]> = new BehaviorSubject([]);
    private deleteSub;
    private authSub;
    @Input()
    private currentUserStatFields: Array<StatField>;

    constructor(private dataService: DataService) {
    }

    @Input()
    set selectedUser(user: AclUser) {

        console.log('selected user: ', user.email)
        this.reportsSub = this.dataService.getStatReportsList(user).pipe(
            map(reports => reports.map((r: any) => {
                const incomingReport = {...r.payload.doc.data(), id: r.payload.doc.id};

                // just show fields for current user
                this.currentUserStatFields.map(field => {
                    if (field.permissionMap.indexOf(user.email) === -1) delete incomingReport[field.name];
                });


                const report = {
                    lastModifiedDate: this.extractDateFromTimestamp(<firebase.firestore.Timestamp>incomingReport.lastModifiedDate),
                    weekOfYear: moment(this.extractDateFromTimestamp(<firebase.firestore.Timestamp>incomingReport.creationDate)).isoWeek(),
                    fields: []
                }
                Object.keys(incomingReport).forEach(key => {
                    const field = this.currentUserStatFields.find(f => f.name === key);
                    if (!field) return;
                    report.fields.push({
                        key: key,
                        value: incomingReport[key],
                        description: field.description,
                        hint: field.hint,
                        name: field.name,
                        show: true
                    })
                });

                // sort
                report.fields = report.fields.sort((a, b) => a.description > b.description ? 1 : -1)
                return report;
            }))).subscribe(this.reports);
    }

    ngOnInit() {

    }


    deleteRow(row: StatReport) {
        console.log('deleting:', row);
        if (!row.id) return;
        this.deleteSub = this.dataService.deleteStatReport(row.id)
            .subscribe(() => console.log('report deleted'));
    }


    public ngOnDestroy() {
        if (this.reportsSub) this.reportsSub.unsubscribe();
        if (this.deleteSub) this.deleteSub.unsubscribe();
        if (this.authSub) this.authSub.unsubscribe();
    }

    extractDateFromTimestamp(date: firebase.firestore.Timestamp) {
        if (!date) return undefined;
        return date.toDate();
    }
}
