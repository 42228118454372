<main>
    <mat-sidenav-container class="main-container">
        <!-- sidebar menu  -->
        <mat-sidenav #sidebar class="sidebar-container" mode="over">
            <app-sidebar-list></app-sidebar-list>
        </mat-sidenav>

        <mat-sidenav-content>


            <div style="min-height: 97vh;">
                <!-- header -->
                <ev-header></ev-header>

                <!-- main content of the page -->
                <div class="content">
                    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
                </div>

            </div>

            <footer
                    [ngStyle]="{backgroundColor:settings ? settings.value.headerColor : '#d0d0ce', color: settings ? settings.value.headerTextColor : '#000000'}"
                    id="ev-footer">
                <div>
                    <a class="links" href="https://www.iubenda.com/privacy-policy/30177661" target="_blank">Privacy
                        Policy</a> |
                    <a class="links" href="https://www.iubenda.com/privacy-policy/30177661/cookie-policy"
                       target="_blank">Cookie
                        Policy</a> |
                    <a class="links iubenda-cs-preferences-link" href="#">Aggiorna le tue preferenze di tracciamento</a>
                </div>
                <span *ngIf="userCanSeeVersion$ | async" class="version-number">Version: {{ version }}</span>
            </footer>

        </mat-sidenav-content>

    </mat-sidenav-container>
</main>
