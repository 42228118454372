import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Invoice} from '@wondersys/wonderbudget-lib';
import {
    InvoicesLIstFilter,
    InvoicesListResponse,
    InvoicesListTableService
} from '../../services/invoices-list-table.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-invoices-list-table',
    templateUrl: './invoices-list-table.component.html',
    styleUrls: ['./invoices-list-table.component.scss']
})
export class InvoicesListTableComponent implements OnInit {

    @Input() searchMode: 'quick' | 'slow';
    @Input() sendBulkToAccounting$: Observable<void>;
    @Input() reloadDataSubject: Observable<void>;
    @Input() selection: Invoice[];

    pageSizeOptions = [10, 20, 50, 100, 500];

    displayedColumns = [
        'invoiceNr',
        'downloadUrl',
        'note',
        'vendor',
        'orderNr',
        'invoiceDate',
        'amount',
        'sentToAccounting',
        'actions'
    ];

    totalElements: number = 0;

    dataSource = new MatTableDataSource<Invoice>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Output() updateTotalElements: EventEmitter<number> = new EventEmitter<number>();

    private firstLoad: boolean = true;
    private isLoadingData: Subscription;
    private appliedFilter: InvoicesLIstFilter;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private invoicesListTableService: InvoicesListTableService
    ) {
    }

    ngOnInit(): void {
        this.paginator.pageSize = 50;

        this.invoicesListTableService.appliedFilterAndSorting$.pipe(
            filter(newFilter => this.invoicesListTableService.compareFilters(newFilter, this.appliedFilter)),
            takeUntil(this.unsubscribe$)
        ).subscribe(filter => this.handleFilterChange(filter));

        if (this.searchMode === 'quick') {

            this.displayedColumns = ['select', ...this.displayedColumns];

            this.sendBulkToAccounting$.pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(() => this.invoicesListTableService.bulkSendToAccounting(this.selection));
        }

        this.reloadDataSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {
        this.sort.sortChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.paginator.pageIndex = 0;
                this.invoicesListTableService.storeSorting(this.sort.active, this.sort.direction);
            });

        if (this.searchMode === 'slow') {
            this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
        } else {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.quickTableFilterPredicate();
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = this.quickTableSort();
        }
    }

    private handleFilterChange(filter: InvoicesLIstFilter) {
        this.restoreSorting(filter);
        this.appliedFilter = {...filter};
        this.paginator.pageIndex = 0;

        //for the slow table dataSource.filter is not meaningful
        if (this.searchMode === 'quick') {
            this.dataSource.filter = JSON.stringify(filter);
        }

        //for the quick table load data only once
        if (this.searchMode === 'slow' || this.firstLoad) {
            this.loadData();
        }
    }

    loadData() {
        console.log(this.searchMode, ' loading data...')

        if (this.searchMode === 'slow') {
            //empty the slow table while waiting for a new response
            this.dataSource = null;
            if (this.isLoadingData) {
                //delete pending requests caused by quick change of the filter/sort
                this.isLoadingData.unsubscribe();
            }
        }

        this.isLoadingData = this.invoicesListTableService.loadData(this.searchMode, this.paginator.pageIndex, this.paginator.pageSize)
            .pipe(take(1))
            .subscribe(result => {
                if (!result || !result.data) return;
                for (const invoice of result.data) {
                    const dateSeconds = (invoice.invoiceDate['_seconds']) ? invoice.invoiceDate['_seconds'] : invoice.invoiceDate['seconds'];
                    invoice.invoiceDate = dateSeconds ? new Date(dateSeconds * 1000) : null;
                }

                console.log(this.searchMode, ' received data:', result);
                this.setDataSource(result);
            });
    }

    private setDataSource(response: InvoicesListResponse) {
        if (this.searchMode === 'quick') {
            this.dataSource.data = response.data as any;
        } else {
            this.dataSource = response.data as any;
        }

        this.totalElements = response.totalSize;
        this.paginator.length = response.totalSize;

        if (this.firstLoad) {
            this.firstLoad = false;
            //this will force the table to apply the initial filter and update the paginator values
            setTimeout(() => this.dataSource.filter = JSON.stringify(this.appliedFilter));
        }

        this.updateTotalElements.emit(this.totalElements);
    }

    private restoreSorting(filter: InvoicesLIstFilter) {
        const needRefresh = this.sort.active !== filter.sortActive || this.sort.direction !== filter.sortDirection;
        this.sort.active = (filter.sortActive) ? filter.sortActive : 'invoiceDate';
        this.sort.direction = (filter.sortDirection) ? filter.sortDirection as SortDirection : 'desc';
        if (needRefresh) this.sort.sortChange.emit();
    }

    get tableIsLoading$() {
        return this.invoicesListTableService.isLoading$(this.searchMode);
    }

    displayAccountingCheckbox(invoice: Invoice) {
        return !invoice.sentToAccounting && this.searchMode === 'quick';
    }

    public toggleInvoiceSelection(invoice, e) {
        if (this.searchMode === 'quick') {
            if (e.checked) {
                this.selection.push(invoice);
            } else {
                this.selection.splice(this.selection.indexOf(invoice), 1);
            }
        }
    }

    public isInSelection(invoice) {
        return this.selection.some(inv => inv.id === invoice.id);
    }

    quickTableFilterPredicate(): (invoice: Invoice, filter: string) => boolean {
        return (invoice: Invoice, filter: string) => {
            let invoiceNrF = true;
            let vendorF = true;
            let orderF = true;

            if (this.appliedFilter['invoiceNr']) {
                invoiceNrF = invoice.invoiceNr && invoice.invoiceNr.toLowerCase().includes(this.appliedFilter['invoiceNr'].toLowerCase());
            }
            if (this.appliedFilter['vendor']) {
                vendorF = invoice.order.vendor && invoice.order.vendor.name && invoice.order.vendor.name.toLowerCase().includes(this.appliedFilter['vendor'].toLowerCase());
            }
            if (this.appliedFilter['orderNr']) {
                orderF = invoice.order.orderNr && invoice.order.orderNr.toLowerCase().includes(this.appliedFilter['orderNr'].toLowerCase());
            }

            return invoiceNrF && vendorF && orderF;
        };
    }

    quickTableSort(): (items: Invoice[], sort: MatSort) => Invoice[] {
        return (items: Invoice[], sort: MatSort) => {
            const sortDirection = (sort.direction === 'asc') ? 1 : -1;

            switch (sort.active) {
                case 'orderNr':
                    return items.sort((a, b) => a.order.orderNr.localeCompare(b.order.orderNr) * sortDirection);
                case 'vendor':
                    return items.sort((a, b) => a.order.vendor.name.localeCompare(b.order.vendor.name) * sortDirection);
                case 'invoiceDate':
                    return items.sort((a, b) => ((a.invoiceDate as Date).getTime() - (b.invoiceDate as Date).getTime()) * sortDirection);
                case 'note':
                    return items.sort((a, b) => this.compareNote(a, b) * sortDirection);
                case 'invoiceNr':
                    return items.sort((a, b) => a.invoiceNr.localeCompare(b.invoiceNr) * sortDirection);
                default:
                    return items;
            }
        };
    }

    compareNote(a: Invoice, b: Invoice) {
        if (!a.note && b.note) return -1;
        if (a.note && !b.note) return 1;
        if (a.note && b.note) return a.note.localeCompare(b.note);
        return 0;
    }
}
