<div class="invoice-list-header mt-1">
    <div class="close-row">
        <mat-form-field class="filterMargin">
            <input #orderNrF [disabled]="operationInExecution" matInput placeholder="Order Nr">
        </mat-form-field>

        <mat-form-field class="filterMargin">
            <input #invoiceNrF [disabled]="operationInExecution" matInput placeholder="Invoice Nr">
        </mat-form-field>

        <mat-form-field class="filterMargin">
            <input #vendorF [disabled]="operationInExecution" matInput placeholder="Vendor">
        </mat-form-field>
    </div>


    <button (click)="bulkSendToAccounting.next()" [disabled]="operationInExecution || selection.length === 0" color="primary"
            mat-raised-button>Bulk
        send to accounting
    </button>
</div>

<mat-tab-group (selectedIndexChange)="selectedTab = $event" [selectedIndex]="selectedTab">

    <mat-tab>
        <ng-template mat-tab-label>
            <span matTooltip="Lista veloce">Invoices list</span>
        </ng-template>

        <app-invoices-list-table [reloadDataSubject]="reloadDataSubject" [selection]="selection"
                                 [sendBulkToAccounting$]="bulkSendToAccounting.asObservable()"
                                 searchMode='quick'
        ></app-invoices-list-table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <div *ngIf="performingSlowSearch$ | async; else loaded" class="tab-label-loading">
                <mat-icon matTooltip="Searching...">cloud_sync</mat-icon>
            </div>
            <ng-template #loaded>
                <div class="tab-label-loaded">
                    <mat-icon matTooltip="Lista esaustiva ({{slowListElements}} orders)">cloud_done</mat-icon>
                    <span>({{ slowListElements }})</span>
                </div>
            </ng-template>
        </ng-template>

        <app-invoices-list-table (updateTotalElements)="slowListElements = $event"
                                 [reloadDataSubject]="reloadDataSubject"
                                 searchMode='slow'
        ></app-invoices-list-table>
    </mat-tab>
</mat-tab-group>


