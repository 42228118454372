<mat-toolbar
        [ngStyle]="{backgroundColor:settings ? settings.value.headerColor : '#d0d0ce', color: settings ? settings.value.headerTextColor : '#000000'}">
    <div class="logo-container" routerLink="/">
        <a class="yi-logo"></a>
    </div>
    <!-- open menu -->
    <button (click)="toggleMenu()" class="sidenav-toggle mat-button" mat-button="">
        <mat-icon aria-label="menu" class="material-icons mat-icon" role="img">menu</mat-icon>
    </button>


    <!-- This fills the remaining space of the current row -->
    <span *ngIf="settings ? settings.value.headerLogoUrl === '' : true" class="example-fill-remaining-space">
        {{ authDomain }}
    </span>

    <div *ngIf="settings ? settings.value.headerLogoUrl !== '' : false"
         class="example-fill-remaining-space header-logo-container">
        <img [alt]="authDomain" [src]="settings.value.headerLogoUrl" class="header-logo">
    </div>


    <!-- <a routerLink="/" mat-button><mat-icon>settings</mat-icon>&nbsp;Settings</a> -->

    <!--
    <button mat-button="" mat-icon-button (click)="openNotifications()">
        <div class="new-notifications"></div>
        <mat-icon>notifications</mat-icon>
    </button>
    -->

    <button [matMenuTriggerFor]="menu" mat-button>
        <img *ngIf="loggedUserPhoto" [src]="loggedUserPhoto" alt="Avatar" class="avatar">
        <mat-icon *ngIf="!loggedUserPhoto">person</mat-icon>&nbsp;{{ loggedUser }}
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" yPosition="below">
        <button (click)="logoutUser()" mat-menu-item>
            <mat-icon>flight_land</mat-icon>
            <span i18n="Logut button">Logout</span>
        </button>
    </mat-menu>
</mat-toolbar>