<mat-card *ngIf="(userRole | async) !== 'admin'">
    <mat-card-title>
        This page is only for admins
    </mat-card-title>
</mat-card>

<div *ngIf="(userRole | async) === 'admin'">


    <mat-card>
        <mat-card-title>
            Orders / Invoices items refactor
        </mat-card-title>
        <mat-card-content>
            <button (click)="getOrdersWithUndefinedItemId()">(1) get orders to fix</button>
            <button (click)="fixOrders()">(2) fix orders</button>
            <button (click)="getCurrentInvoices()">(3) get current invoices</button>
            <button (click)="getNewInvoices()" [disabled]="invoiceList.length === 0">(4) get new invoices</button>
            <button (click)="fixItemsAndInvoices()" [disabled]="updatedInvoices.length === 0">(5) update
                invoices
            </button>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>
            Fix Missing invoices in order items
        </mat-card-title>
        <mat-card-content>
            <button (click)="matchBrokenInvoices()">(1) Match broken invoices</button>
            <button (click)="fixInvoices()" [disabled]="disabledInvoiceFix">(2) Fix Missing invoices in order items
            </button>
        </mat-card-content>

        <!-- Results -->
        <div>
            <ul>
                <li *ngFor="let unmatchedInvoice of unmatchedInvoices">
                    <p><b>Invoice: </b>{{ unmatchedInvoice.invoice.id }}, NR: {{ unmatchedInvoice.invoice.invoiceNr }},
                        date:
                        {{ unmatchedInvoice.invoice.invoiceDateText }}</p>
                    <b>Ref Order:</b> {{ unmatchedInvoice.order.id }}

                    <button (click)="fixInvoice(unmatchedInvoice)" [disabled]="disabledInvoiceFix">Fix this
                        items
                    </button>
                </li>
            </ul>
        </div>
    </mat-card>

    <mat-card>
        <mat-card-title>
            Get invoice items without ids
        </mat-card-title>
        <mat-card-content>
            <button (click)="getInvoiceItemsWithoutIds()">(1) Get Invoice items without ids</button>
            <button (click)="createNewItemsInOrders()" [disabled]="invoiceItemsWithoutIds.length === 0">(2)
                createNewItemsInOrders
            </button>


            <table>
                <thead>
                <tr>
                    <th>Invoice Id</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let invoice of invoiceItemsWithoutIds">
                    <td>{{ invoice.id }}</td>
                </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>

</div>
