import {filter, takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppStateService} from '../services/app-state.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {AppError, AppInfo} from '../interfaces/app-error';
import {ConfigService} from '../services/config.service';
import {Observable, Subject} from 'rxjs';
import {AuthService} from '../services/auth.service';
import packageJson from '../../../package.json';



@Component({
    templateUrl: './private.component.html',
    styleUrls: ['./private.component.css']
})

export class PrivateComponent implements OnInit, OnDestroy {

    public settings;
    public version: string = packageJson.version;
    private unsubscribe$ = new Subject();
    @ViewChild('sidebar', {static: true}) private sidebar: MatSidenav;

    public constructor(
        private appState: AppStateService,
        private configService: ConfigService,
        public dialog: MatDialog,
        private authService: AuthService) {
    }

    get userCanSeeVersion$(): Observable<boolean> {
        return this.authService.userHasPermission('app.version.read');
    }

    public ngOnInit() {

        this.configService.settings.pipe(takeUntil(this.unsubscribe$)).subscribe(settings => {
            this.settings = settings;
        });

        this.appState.sidenav = this.sidebar;

        this.appState.errorStatus$.pipe(
            filter((appError) => appError !== null))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((appError) => {
                this.openDialog(appError);
            });

        // Set appState function
        this.appState.confirmDialog = this.openConfirm;
        this.appState.infoDialog = this.openInfoDialog;
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
    }

    public changeOfRoutes() {
        console.log('change of routes activated. Closing sidebar.')
        this.appState.toggleSideBar(false);
    }

    // Dialog for showing error messages
    openDialog(appError: AppError): void {
        console.log(appError);
        const dialogRef = this.dialog.open(AppErrorDialogComponent, {
            width: '250px',
            data: appError
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    // Dialog for showing info messages
    openInfoDialog = (appInfo: AppInfo): Promise<any> => {
        const dialogRef = this.dialog.open(AppInfoDialogComponent, {
            width: '500px',
            data: appInfo
        });

        return dialogRef.afterClosed().toPromise();
    }

    // Dialog for showing error messages
    openConfirm = (data: any): Promise<any> => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '450px',
            data: data
        });

        return dialogRef.afterClosed().toPromise();
    }
}

@Component({
    selector: 'app-error-dialog',
    template: `<h1 mat-dialog-title>Error</h1>
    <div mat-dialog-content>
        <p>{{ data.errorMessage }}
        <p>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
    </div>`
})
export class AppErrorDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<AppErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

@Component({
    selector: 'app-notify-dialog',
    template: `<h1 mat-dialog-title>Info</h1>
    <div mat-dialog-content>
        <p>{{ data.infoMessage }}
        <p>
        <p *ngIf="data.infoDetail">{{ data.infoDetail }}
        <p>
        <div class="html-preview" *ngIf="data.infoHtmlDetail" [innerHtml]="data.infoHtmlDetail">
            <div>
            </div>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
            </div>`,
    styles: [`
        .html-preview {
            font-size: smaller;
            font-style: italic;
            overflow-y: scroll;
            max-height: 300px;
        }`]
})
export class AppInfoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<AppInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

@Component({
    selector: 'app-confirm-dialog',
    template: `<h1 mat-dialog-title i18n>Please confirm, this action can not be reverted</h1>
    <div mat-dialog-content>
        <p>{{ data.confirmMessage }}
        <p>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close cdkFocusInitial i18n>Cancel</button>
        <button mat-button color="accent" (click)="confirm()" i18n>OK</button>
    </div>`
})
export class ConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    confirm(): void {
        this.dialogRef.close({confirm: true});
    }

}
