import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LaserCutListComponent} from './laser-cut-list.component';
import {LaserCutGuard} from './guards/laser-cut.guard';
import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared/shared.module';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule
    ],
    declarations: [LaserCutListComponent],
    providers: [LaserCutGuard]
})
export class LaserCutModule {
}
