import {BehaviorSubject, combineLatest, Observable} from 'rxjs';

import {map, switchMap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../services/data.service';
import {Order} from '@wondersys/wonderbudget-lib';
import {OrderEntryComponent} from '../order/components/order-entry.component';
import {Budget} from '../budget/interfaces';
import {MaterialItem} from './interfaces/material-item';

import {AngularCsv} from 'angular7-csv';



@Component({
    selector: 'app-materials-list',
    templateUrl: './materials-list.component.html',
    styleUrls: ['./materials-list.component.scss']
})
export class MaterialsListComponent implements OnInit, OnDestroy {

    public triggerRefresh = new BehaviorSubject<boolean>(false);
    public materialList$: Observable<any[]>;
    public budgetId;
    public budget$: Observable<Budget>;
    public materialTotalCost: Observable<number>;
    private materialList = new BehaviorSubject<Order[]>([]);
    private subscription;
    private renderedData: any;

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.subscription = combineLatest([
            this.route.paramMap,
            this.triggerRefresh
        ]).pipe(
            switchMap(([params, trigger]) => {
                this.budgetId = params.get('budgetId');

                this.budget$ = this.dataService.getSimpleBudget(this.budgetId);

                return this.dataService.getOrdersByBudget(this.budgetId);
            }))
            .subscribe(this.materialList);


        this.materialList$ = this.materialList.pipe(
            map((orders: Order[]) => {

                const flatMat = [];

                for (const order of orders) {
                    for (const item of order.items) {
                        if (item.budget.id === this.budgetId) {
                            const el: MaterialItem = {
                                item,
                                orderId: order.id,
                                orderNr: order.orderNr,
                                date: <Date>order.date,
                                deleted: order.deleted,
                                author: order.author.displayName,
                                vendor: order.vendor.name,
                                total: OrderEntryComponent.calculateEntryValue(item.costEach, item.quantity, item.discount)
                            };
                            flatMat.push(el);
                        }

                    }
                }

                this.materialTotalCost = this.materialList$.pipe(map((list) => list.map(i => i.total).reduce((a, b) => a + b, 0)));

                return flatMat;
            }));


    }

    updateRenderedData(e) {
        this.renderedData = e;
    }

    exportCsv() {
        const data = this.renderedData.map(d => {
            return {
                orderNr: d.orderNr,
                description: d.item ? d.item.description : '',
                date: d.date ? new Date(d.date._seconds * 1000) : '',
                vendor: d.vendor,
                author: d.author,
                total: d.total
            }
        })

        new AngularCsv(data, 'Materials_list', {
            decimalseparator: ',',
            fieldSeparator: ';'
        });
    }

    public refresh = () => {
        this.triggerRefresh.next(true);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

