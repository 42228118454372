import {AclUser} from '../../interfaces/app-user';
import {Timestamp} from '@firebase/firestore-types';



export interface Budget {

    id: string;
    budgetId: string;
    budgetNr: string;
    author: Partial<AclUser>;
    assignee: {
        displayName: string;
        googleUid: string;
        id: string;
        on: Date | Timestamp;
    };
    category: string;
    clientId: string;
    creation: Date;
    description: string;
    tags: string[];
    status: string;
    deliveryThr?: Date | Timestamp;
    prosperworks?: any;
    companyInfo?: any;
    offerUrl?: string;
    driveFolderId?: string;
    project?: Project;
    margin?: Margin;
    assemblyCosts?: AssemblyCosts;
    offer?: Offer;
    modifiedBy?: Partial<AclUser>;
    modifiedOn?: Date;
    costSummary?: CostSummary;
    canonicCostSummary?: CostSummary; // only on exports. ALL IN USD!
    export?: any;
    exportBudget?: any;
    companyName?: any;
    paymentStatus?: any;
    reportSummary?: any;
    filter?: string[];
    archivedDate?: Date;
    authorizedModify: string;
    offerDestinationFolderId?: string;

    imported?: boolean;
    offerDate?: Date;
    budgetCostTotal?: string;
    budgetHoursTotal?: string;
    budgetMaterialsTotal?: string;
    projectManager?: string;
    ref?: string;
    salesManager?: string;
    workerList?: boolean;
    reopened?: { userId: string, date: any };

    cosmoId?: { cosmoId: string };
    capitalImprovement?: boolean;
}


export interface ExportData {
    currency: string;
    exchangeRate: number;
    exRateUpdateDate?: Date;
}


export interface Offer {
    offerAmount: number;
    offerAmountShown?: number;
    shippingAmount: number;
    designAmount: number;
    productionAmount: number;
    assemblyAmount: number;
}


export interface Project {
    projectManager: Partial<AclUser>;
    projectNr: string;
    finalOfferOrder: number;
    finalOfferOrderChanges: { user: string, date: Date }[];
    user: Partial<AclUser>;
    internal?: boolean;
    promo?: boolean;
    deliveryDate?: string;
    finalInvoiced?: number;

}


export interface Margin {
    generalExpenses: number;
    ciTaxesPercentage: number;
    commission: number;
    profit: number;
    capitalImprovement: boolean;
}


export interface AssemblyCosts {
    employee: number;
    projectManager: number;
    employeeDays: number;
    projectManagerDays: number;
    employeeCost: number;
    projectMangerCost: number;
    travelExpensesAccomodation: number;
    travelExpensesFlights: number;
    renting: number;
    externalManpower: number;
    other: number[];
}


export interface SplittedCostTableElement {
    id: string;
    name: string;
    cost: number;
    profit: number;
    commission: number;
    expenses: number;
    taxes: number;
    total: number;
    totalEuro?: number;
    totalWithTaxes?: number;
    hint?: string;
}


export interface OfferTemplate {
    id: string;
    name: string;
    locale?: string;
    lang: string;
    templateId: string;
}

export interface CostSummary {
    total: number;
    goods: number;
    goodsValue: number;
    hours: number; // espresso in ore
    hoursValue: number; // espresso in euro
    production: number;
    design: number;
    shipping: number;
    assembly: number;
}
