import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InternalProjectComponent} from './internal-project.component';
import {InternalProjectDetailComponent} from './internal-project-detail.component';
import {InternalProjectListComponent} from './internal-project-list.component';
import {MaterialModule} from '../material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ],
    declarations: [InternalProjectComponent, InternalProjectDetailComponent, InternalProjectListComponent]
})
export class InternalProjectModule {
}
