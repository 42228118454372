<div class="main-home">
    <header>
        <mat-toolbar color="primary">
            <span>WonderBudget</span>
            <span>&nbsp;{{ authDomain }}</span>
            <span class="example-fill-remaining-space"></span>
            <button [matMenuTriggerFor]="menu" mat-button>
                <mat-icon>person</mat-icon>&nbsp;<span *ngIf="(userStream$ | async) as user">
          {{ user.email }}
        </span>
            </button>
            <mat-menu #menu="matMenu">

                <div *ngIf="(userStream$ | async) as user">
                    <div *ngIf="(!user); then thenBlock; else elseBlock">
                    </div>
                    <ng-template #thenBlock>
                        <button (click)="loginWithGoogle()" mat-menu-item type="button">
                            <mat-icon>flight_depa</mat-icon>
                            <span>Login</span>
                        </button>
                    </ng-template>
                    <ng-template #elseBlock>
                        <button (click)="logoutWithFireBase()" mat-menu-item type="button">
                            <mat-icon>flight_land</mat-icon>
                            <span>Logout</span>
                        </button>
                    </ng-template>
                </div>
            </mat-menu>
        </mat-toolbar>
    </header>
    <main id="main" style="min-height:90.5vh;">

        <mat-card class="home-login">
            <mat-card-content>
                <img alt="logo" class="logo-extravega" id="logo-home" src="/assets/img/extravega-logo.svg">
                <div class="home-control-container">
                    <div *ngIf="!(authCheckEmitter$ | async)">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div *ngIf="(authCheckEmitter$ | async)">
                        <div (click)="loginWithGoogle()" *ngIf="!(userStream$ | async) as user"
                             class="login-with-google">
                            <img alt="" src="/assets/img/btn_google_signin_light_normal_web@2x.png">
                        </div>
                        <div *ngIf="(userStream$ | async) as user">
                            <div *ngIf="canSeeBudgets$ | async">
                                <button [routerLink]="['/budget']" i18n mat-raised-button type="button">Enter</button>
                            </div>
                            <div *ngIf="!(canSeeBudgets$ | async)">
                                <button [routerLink]="['/timereport']" i18n mat-raised-button type="button">Enter
                                </button>
                            </div>
                        </div>
                    </div>
                    <p *ngIf="isLoginError" class="loginError" i18n>Error while trying to login. Please try again</p>
                </div>
            </mat-card-content>
        </mat-card>
    </main>

</div>
