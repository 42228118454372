import {filter, map, switchMap} from 'rxjs/operators';
import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {AppStateService} from '../../services/app-state.service';
import {Role, User} from '../interfaces/users';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';



@Component({
    selector: 'app-users-add',
    templateUrl: './users-add.component.html',
    styleUrls: ['./users-add.component.scss']
})
export class UsersAddComponent implements OnInit, OnDestroy, AfterViewChecked {
    public userForm: FormGroup;
    public userAdding = false;
    public editMode = false;
    public userSubscription;
    public rolesSubscription;
    public user;
    public roles;
    public jobClassifications = ['operaio', 'impiegato tecnico', 'impiegato amministrativo', 'utente tecnico', 'altro'];
    public userTypes: string[] = ['admin', 'user'];
    private _user: User;
    private uId;

    constructor(
        private _changeDetectionRef: ChangeDetectorRef,
        private dataService: DataService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService) {
    }


    ngAfterViewChecked(): void {
        // your code
        this._changeDetectionRef.detectChanges();
    }

    ngOnInit() {
        this.buildForm();

        this.rolesSubscription = this.dataService.getRoleList().pipe(
            map((rolesList) => rolesList.map((role: any) => {
                const data = role.payload.doc.data() as Role;
                const id = role.payload.doc.id;
                return {id: id, ...data};
            })))
            .subscribe((roles) => {
                this.roles = roles;
            });

        this.userSubscription = this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('id')),
            switchMap((params: ParamMap) => {
                this.uId = params.get('id');
                console.log('User ID', this.uId);
                return this.dataService.getUser(this.uId);
            })).subscribe((user) => {
            if (!user) {
                this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this user id'});
                this.router.navigate(['/users']);
            } else {
                this._user = this.cleanUserDetails(user);
                user.id = this.uId;
                this.editMode = true;
                this.userForm.reset();
                while ((<FormArray>this.userForm.get('type')).length) {
                    (<FormArray>this.userForm.get('type')).removeAt(0);
                }

                user.external = user.external ? user.external : false;
                if (!user.role) user.role = 'user';
                this.userForm.patchValue(user);
                console.log('User set', user);
                console.log(this.userForm)
            }
        });
    }

    // necessario un trim
    cleanUserDetails(user) {
        let key;
        for (key in user) {
            user[key.trim()] = user[key];
        }

        return user;
    }

    // può accadere che fra gli user già esistenti vi siano chiavi con spazi
    // esempio: " userId" invece di "userId"

    ngOnDestroy() {

    }

    submit() {
        if (this.userForm.valid) {
            // Disable submit button
            this.userAdding = true;
            const userUpdates = <User>this.userForm.value;

            if (this.editMode) {
                // UPDATE

                console.log('HERE:     ', this._user, userUpdates)
                if (this._user.active && !userUpdates.active) {
                    userUpdates.deactivatedOn = new Date();
                } else if (!this._user.active && userUpdates.active) {
                    userUpdates.deactivatedOn = null;
                }

                this.dataService.updateUser(this._user.id, userUpdates)
                    .then(() => {
                        this.router.navigate(['/users/add', this._user.id]);
                        this.userAdding = false;
                    }).catch((err) => {
                    console.log('Error while updating user', err);
                    this.userAdding = false;
                });
            } else {
                // ADD

                userUpdates.createdOn = new Date();
                userUpdates.deactivatedOn = null;
                this.dataService.addUser(userUpdates)
                    .then(() => {
                        console.log('User added');
                        this.router.navigate(['/users']);
                    })
                    .catch((err) => {
                        console.log('Error while adding user', err);
                        this.userAdding = false;
                    });
            }
        } else {
            console.log('User invalid');
        }
    }

    private buildForm() {
        // Definition of the Order Form
        // build the form model
        this.userForm = this.fb.group({
            userId: [undefined, Validators.required],
            displayName: [undefined, Validators.required],
            email: [undefined, Validators.required],
            firstName: [undefined, Validators.required],
            lastName: [undefined, Validators.required],
            googleUid: [undefined, Validators.required],
            initials: [undefined, Validators.required],
            type: <string[]>[],
            role: ['user', Validators.required],
            deleted: [false, Validators.required],
            active: [true],
            external: [false],
            personalName: [undefined],
            referent: [undefined],
            jobClassification: [undefined, Validators.required]
        });
    }

}
