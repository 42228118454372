import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Budget} from '../interfaces';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {Subject, Subscription} from 'rxjs';
import {BudgetListFilter, BudgetListResponse, BudgetListTableService} from '../../services/budget-list-table.service';
import {AuthService} from '../../services/auth.service';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ProjectNrPipe} from '../../shared/pipes/project-nr.pipe';
import {DataService} from '../../services/data.service';
import {firestoreDateToJsDate} from '../../shared/utils/utils';



@Component({
    selector: 'app-budget-list-table',
    templateUrl: './budget-list-table.component.html',
    styleUrls: ['./budget-list-table.component.scss']
})
export class BudgetListTableComponent implements OnInit {

    @Input() searchMode: 'quick' | 'slow';

    @Input() queryParamFilter: boolean;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    dataSource = new MatTableDataSource<Budget>([]);
    displayedColumns = [
        'status',
        'projectNr',
        'manager',
        'budgetNr',
        'pwName',
        'pwCompanyName',
        'author',
        'creation',
        'deliveryThr'
    ];

    totalElements = 0;

    @Output() updateTotalElements: EventEmitter<number> = new EventEmitter<number>();
    appliedFilter: BudgetListFilter;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private firstLoad = true;
    private isLoadingData: Subscription;

    constructor(
        private budgetListTableService: BudgetListTableService,
        private authService: AuthService,
        private router: Router,
        private projectNrPipe: ProjectNrPipe,
        private dataService: DataService
    ) {
    }

    get tableIsLoading$() {
        return this.budgetListTableService.isLoading$(this.searchMode);
    }

    ngOnInit(): void {
        this.paginator.pageSize = 10;

        this.budgetListTableService.appliedFilterAndSorting$.pipe(
            filter(newFilter => this.budgetListTableService.compareFilters(newFilter, this.appliedFilter)),
            takeUntil(this.unsubscribe$)
        ).subscribe(inputFilter => this.handleFilterChange(inputFilter));

        // Add link to balances if user has permission
        this.authService.userHasPermission('app.budget.final.read')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                if (val && !this.displayedColumns.includes('balances')) {
                    this.displayedColumns.push('balances');
                } else {
                    const index = this.displayedColumns.indexOf('balances');
                    if (index !== -1) {
                        this.displayedColumns.splice(index, 1);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {
        this.sort.sortChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.paginator.pageIndex = 0;
                this.budgetListTableService.storeSorting(this.sort.active, this.sort.direction);
            });

        if (this.searchMode === 'slow') {
            this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
        } else {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.quickTableFilterPredicate();
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = this.quickTableSort();
        }
    }

    public goToBudget(row: Budget) {
        const dest = '/budget/' + row.id;
        this.router.navigate([dest]);
    }

    loadData() {
        if (this.searchMode === 'slow') {
            // empty the slow table while waiting for a new response
            this.dataSource.data = [];
            if (this.isLoadingData) {
                // delete pending requests caused by quick change of the filter/sort
                this.isLoadingData.unsubscribe();
            }
        }

        if (this.queryParamFilter && this.searchMode === 'quick') {
            console.warn('Getting list of new only', this.queryParamFilter);
            this.isLoadingData = this.dataService.getBudgetList({status: 'new'}).pipe(take(1))
                .subscribe(result => {
                    if (!result) {
                        return;
                    }
                    this.setDataSource({data: result, totalSize: result.length});
                });
            return;
        }

        this.isLoadingData = this.budgetListTableService.loadData(this.searchMode, this.paginator.pageIndex, this.paginator.pageSize)
            .pipe(take(1))
            .subscribe(result => {
                if (!result?.data) {
                    return;
                }
                for (const budget of result.data) {
                    if (budget['deliveryThr']) {
                        budget['deliveryThr'] = firestoreDateToJsDate(budget['deliveryThr']);
                    } else {
                        budget['deliveryThr'] = null;
                    }


                    const creationDateSeconds = (budget.creation['seconds']) ? budget.creation['seconds'] : budget.creation['_seconds'];
                    budget.creation = new Date(creationDateSeconds * 1000);
                }
                this.setDataSource(result);
            });
    }

    private handleFilterChange(inputFilter: BudgetListFilter) {
        this.restoreSorting(inputFilter);
        this.appliedFilter = {...inputFilter};
        this.paginator.pageIndex = 0;

        // for the slow table dataSource.filter is not meaningful
        if (this.searchMode === 'quick') {
            this.dataSource.filter = JSON.stringify(inputFilter);
        }

        // for the quick table load data only once
        if (this.searchMode === 'slow' || this.firstLoad) {
            this.loadData();
        }
    }

    private setDataSource(response: BudgetListResponse) {
        if (this.searchMode === 'quick') {
            this.dataSource.data = response.data as any;
        } else {
            this.dataSource = response.data as any;
        }

        this.totalElements = response.totalSize;
        this.paginator.length = response.totalSize;

        if (this.firstLoad) {
            this.firstLoad = false;
            // this will force the table to apply the initial filter and update the paginator values
            setTimeout(() => this.dataSource.filter = JSON.stringify(this.appliedFilter));
        }

        this.updateTotalElements.emit(this.totalElements);
    }

    private restoreSorting(inputFilter: BudgetListFilter) {
        const needRefresh = this.sort.active !== inputFilter.sortActive || this.sort.direction !== inputFilter.sortDirection;
        this.sort.active = (inputFilter.sortActive) ? inputFilter.sortActive : 'creation';
        this.sort.direction = (inputFilter.sortDirection) ? inputFilter.sortDirection as SortDirection : 'desc';
        if (needRefresh) {
            this.sort.sortChange.emit();
        }
    }

    private quickTableFilterPredicate(): (budget: Budget, filterValue: string) => boolean {
        return (budget: Budget, inputFilter: string) => {
            let searchStr = budget.budgetNr;
            if (budget.project) {
                searchStr += this.projectNrPipe.transform(budget);
            }
            if (budget.prosperworks?.company_name) {
                searchStr += budget.prosperworks.company_name;
            } else if (budget.companyName) {
                searchStr += budget.companyName;
            }
            if (budget.project?.projectManager) {
                searchStr += budget.project.projectManager.displayName;
            }
            if (budget.assignee) {
                searchStr += budget.assignee.displayName;
            }
            if (budget.author) {
                searchStr += budget.author.displayName;
            }
            if (budget.prosperworks) {
                searchStr += budget.prosperworks.name;
            } else {
                searchStr += budget.description;
            }
            searchStr = searchStr.toLowerCase();

            let iconSearchStr = [undefined, null, ''].includes(budget.status) ? 'default' : budget.status;
            iconSearchStr = iconSearchStr.toLowerCase();

            const filterCondition = (this.appliedFilter.filter) ? searchStr.indexOf(this.appliedFilter.filter.toLowerCase()) !== -1 : true;
            const statusCondition = (this.appliedFilter.status) ? iconSearchStr.includes(this.appliedFilter.status.toLowerCase()) : true;
            return (filterCondition && statusCondition) && (this.appliedFilter.showExport || (!budget.export));
        };
    }

    private quickTableSort(): (items: Budget[], sort: MatSort) => Budget[] {
        return (items: Budget[], sort: MatSort) => {
            const sortDirection = (sort.direction === 'asc') ? 1 : -1;
            switch (sort.active) {
                case 'status':
                    return items.sort((a, b) => a.status.localeCompare(b.status) * sortDirection);
                case 'budgetNr':
                    return items.sort((a, b) => a.budgetNr.localeCompare(b.budgetNr) * sortDirection);
                case 'manager':
                    return items.sort((a, b) => {
                        const managerA = a.project ? (a.project.projectManager ? a.project.projectManager?.displayName : '') : '';
                        const managerB = b.project ? (b.project.projectManager ? b.project.projectManager?.displayName : '') : '';
                        return managerA.localeCompare(managerB) * sortDirection;
                    });
                case 'projectNr':
                    return items.sort((a, b) => {
                        const prjNrA = a.project ? a.project.projectNr : '';
                        const prjNrB = b.project ? b.project.projectNr : '';
                        return prjNrA.localeCompare(prjNrB) * sortDirection;
                    });
                case 'pwName':
                    return items.sort((a, b) => {
                        const nameA = a.prosperworks ? a.prosperworks.name : a.description;
                        const nameB = b.prosperworks ? b.prosperworks.name : b.description;
                        return nameA.localeCompare(nameB) * sortDirection;
                    });
                case 'pwCompanyName':
                    return items.sort((a, b) => {
                        const companyA = a.companyName ? a.companyName : a.prosperworks ? a.prosperworks.company_name : '';
                        const companyB = b.companyName ? b.companyName : b.prosperworks ? b.prosperworks.company_name : '';
                        return companyA.localeCompare(companyB) * sortDirection;
                    });
                case 'author':
                    return items.sort((a, b) => {
                        const authA = a.author ? a.author.displayName : '';
                        const authB = b.author ? b.author.displayName : '';
                        return authA.localeCompare(authB) * sortDirection;
                    });
                case 'category':
                    return items.sort((a, b) => (a.category.localeCompare(b.category) * sortDirection));
                case 'creation':
                    return items.sort((a, b) => (a.creation.getTime() - b.creation.getTime()) * sortDirection);
                case 'deliveryThr':
                    return items.sort((a, b) => {
                        const dateA = (<Date>a.deliveryThr) ? (<Date>a.deliveryThr).getTime() : 0;
                        const dateB = (<Date>b.deliveryThr) ? (<Date>b.deliveryThr).getTime() : 0;
                        return (dateA - dateB) * sortDirection;
                    });
            }
        };
    }
}
