import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {EllipsisPipe} from '../shared/pipes/ellipsis.pipe';
import {Budget} from '../budget/interfaces';



@Component({
    selector: 'app-workers-summary',
    templateUrl: './workers-summary.component.html',
    styleUrls: ['./workers-summary.component.scss']
})
export class WorkersSummaryComponent implements OnInit {

    selectProject: FormControl;

    timeInterval: FormControl;

    private unsubscribe$ = new Subject();

    public allProjects: Partial<Budget>[] = [];
    public options: Partial<Budget>[] = [];

    public canChange = false;


    constructor(private dataService: DataService,
                private authService: AuthService,
                private ellipsisPipe: EllipsisPipe
    ) {
    }

    ngOnInit() {
        this.selectProject = new FormControl();
        this.timeInterval = new FormControl(10000);

        this.dataService.getProjectList()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(projects => {
                this.allProjects = projects;
                this.options = this.filterOptions(this.allProjects, undefined);
            });

        this.selectProject.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(value => {

                if (typeof value === 'string' || typeof (value) === 'string') {
                    console.log('filtering', value);
                    this.options = this.filterOptions(this.allProjects, value);

                } else {
                    console.log('skipped bullet', value);
                }
            });

        this.authService.userHasPermission('app.workers.edit')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.canChange = val;
            });
    }


    public selectedProject($event) {
        console.log('selected project:', $event);

        if (!$event.option.value.id) {
            return;
        }

        this.dataService.selectForWorkersList($event.option.value.id);

    }

    private filterOptions(allProjects: Partial<Budget>[], value: string): Partial<Budget>[] {
        console.log('filter options: ', value, this.selectProject.value, allProjects.length);
        return allProjects.filter(p => {
            return !value ||
                (p.project && p.project.projectNr.includes(value)) ||
                (p.budgetNr && p.budgetNr.toUpperCase().includes(value.toUpperCase()));
        });
    }

    public budgetDisplayFn = (budget: Budget): string => {
        if (budget) {
            let ret = budget.budgetNr ? budget.budgetNr : budget.budgetId;
            if (budget.project) {
                ret = (budget.project.internal ? 'i' : '') + budget.project.projectNr + '-' + ret;
            }
            let cn;
            if (budget.prosperworks && budget.prosperworks.company_name) {
                cn = this.ellipsisPipe.transform(budget.prosperworks.company_name, 20);
                // cn = budget.prosperworks.company_name;
                ret = cn + ' ' + ret;
            } else if (budget.companyName) {
                cn = this.ellipsisPipe.transform(budget.companyName, 20);
                // cn = budget.companyName;
                ret = cn + ' ' + ret;
            }
            return ret;
        } else {
            return null;
        }
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

    }
}
