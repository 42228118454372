import {Activity} from '../interfaces/activity';



export class ActivitiesValueModel {

    constructor(activities: Activity[]) {
        activities.forEach((a) => {
            this._activityValueMap[a.id] = a.hours ? a.hours : 0;
        });
    }

    private _activityValueMap = {};

    get activityValueMap() {
        return this._activityValueMap;
    }

}
