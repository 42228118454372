import {GeneratedBy, SubBudget} from '../interfaces';
import * as firebase from 'firebase';



export class SubBudgetModel implements SubBudget {

    public id;
    public created = firebase.firestore.FieldValue.serverTimestamp();
    public deleted = null;
    public active = true;
    public createdBy = undefined;
    public calculate;

    constructor(
        public description: string,
        public generatedBy,
        id?: string,
        public overrideLock?: boolean
    ) {
        if (id) {
            this.id = id;
        }
        if (this.generatedBy !== GeneratedBy.User) {
            this.calculate = true;
        }
    }

    public toPlainObj(): SubBudget {
        const ret: any = {};
        Object.keys(this).forEach((k) => {
            if (this[k] !== undefined) {
                ret[k] = this[k];
            }
        });
        return ret;
    }
}
