import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageModel} from '../models/storage';
import {DataService} from '../../services/data.service';
import {unities} from '../../interfaces/unit';
import {Storage} from '../../interfaces/storage';
import {Observable} from 'rxjs';
import {Company} from '../../interfaces/address';



@Component({
    selector: 'app-storage-form',
    templateUrl: './storage-form.component.html',
    styleUrls: ['./storage-form.component.scss']
})
export class StorageFormComponent implements OnInit {
    @Input() public type: string;
    @Output() onSubmit = new EventEmitter<StorageModel>();
    public filteredVendors: Observable<Company[]>;
    public selectedVendor: Partial<Company>;
    model = new StorageModel();
    categories = [];
    unities = unities;
    submitted = false;
    private _material: Storage;

    constructor(private dataService: DataService) {
    }

    get storage(): Storage {
        return this.model.toPlainObj();
    }

    @Input() set storage(s: Storage) {
        if (s) {
            this._editMode = true;
            this.model = StorageModel.fromObj(s);
        } else {
            this._editMode = false; // New obj
        }
    }

    private _editMode = false;

    get editMode(): boolean {
        return this._editMode;
    }

    onFormSubmit() {
        console.log(this.model);
        this.submitted = true;
        let action;
        if (this._editMode && !(this.type == 'copy')) {
            action = this.dataService.updateStorage(this.model.id, this.model.toPlainObj());
        } else {
            action = this.dataService.addStorage(this.model.toPlainObj());
        }

        action.subscribe(() => {
            // close popu
            this.onSubmit.emit(this.model);
            console.log('close popup');
        });
    }

    ngOnInit() {
        this.selectedVendor = undefined;

        this.dataService.getItemCategories().subscribe((c) => {
            this.categories = c;
        });
        /*
        this.filteredVendors = this.storageForm.get('vendor').valueChanges
          .debounceTime(500)
          .map(vendor => vendor && typeof vendor === 'object' ? vendor.name : String(vendor))
          .filter((vendor) => vendor && vendor.length > 2)
          .switchMap((vendor) => this.dataService.getFilteredCompanies(vendor));

        this.vendorChange(null);*/
    }

    /*
    public vendorChange($event) {
      const vendor = this.model.vendor;
      this.selectedVendor = vendor;
      this.dataService.getCompanyById(vendor.id)
        .map(this._project({ id: 1, address: 1, name: 1 }))
        .subscribe((company) => {
          this.storageForm.get('vendor').setValue(company, {
            onlySelf: true,
            emitEvent: false,
            emitModelToViewChange: true
          });
          if ($event != null) {
            this.storageForm.controls['primaryContact'].reset();
          }
          this.selectedVendor = company;
        });
      // this.dataService.getPeopleByCompany(vendor.id)
      // .subscribe((people) => {
      //   console.log('people', people);
      // });
    }*/

    public vendorDisplayFn(vendor: Company): string {
        if (vendor) {
            return vendor.name ? vendor.name : vendor.email_domain;
        } else {
            return null;
        }
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object)) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }
}
