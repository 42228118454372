<h3 i18n mat-dialog-title>Change final offer <span *ngIf="isExport">- Export budget</span></h3>
<mat-dialog-content>
    <mat-form-field>
        <input [formControl]="finalOfferOrder" aria-label="Number" i18n-placeholder matInput placeholder="Final offer"
               type="number">
    </mat-form-field>
</mat-dialog-content>

<div *ngIf="finalOfferOrderChanges" class="box">
    <h4>Past changes:</h4>
    <table [dataSource]="finalOfferOrderChanges" class="mat-elevation-z0 full-width" mat-table>
        <ng-container matColumnDef="date">
            <td *matCellDef="let element" mat-cell>
                {{ element.date| date:'medium' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <td *matCellDef="let element" mat-cell>
                {{ element.user }}
            </td>
        </ng-container>
        <ng-container matColumnDef="values">
            <td *matCellDef="let element" mat-cell>
                from: {{ element.prevValue| currency:isExport ? 'USD' : 'EUR' }} to:
                {{ element.newValue| currency:isExport ? 'USD' : 'EUR' }}
            </td>
        </ng-container>

        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </table>
</div>

<mat-dialog-actions align="center">
    <button (click)="cancel()" color="basic" mat-flat-button>Cancel</button>
    <button (click)="closeDialog()" [disabled]="!isValid" i18n mat-button type="submit">Submit</button>
</mat-dialog-actions>
