import * as firebase from 'firebase';
import {AppUser} from '../../interfaces/app-user';



export enum GeneratedBy {
    'User' = 'user',
    'Assembly' = 'assembly',
    'Export' = 'export',
}

export interface SubBudget {
    tags?: string[];
    description: string;
    id: string;
    overrideEdit?: boolean;
    overrideLock?: boolean;
    created: Date | firebase.firestore.FieldValue;
    report?: boolean;
    deleted?: null | boolean;
    active: boolean; // If it is calculated in totals
    createdBy: Partial<AppUser>;
    generatedBy: GeneratedBy;
    calculate: boolean;
}

export interface ISubBudgetTotals {
    activities: number;
    materials: number;
    total?: number;
    calculate: boolean;
}

export class SubBudgetTotals implements ISubBudgetTotals {
    public calculate: boolean;

    private _activities: number;

    get activities(): number {
        return isNaN(this._activities) ? 0 : this._activities;
    }

    set activities(val: number) {
        this._activities = val;
        this.updateTotal();
    }

    private _materials: number;

    get materials(): number {
        return isNaN(this._materials) ? 0 : this._materials;
    }

    set materials(val: number) {
        this._materials = val;
        this.updateTotal();
    }

    private _total: number;

    get total(): number {
        return this._total;
    }

    private updateTotal = () => {
        this._total = this.materials + this.activities;
    }
}
