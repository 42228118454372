<form #materialForm="ngForm" (ngSubmit)="onFormSubmit()">
    <!-- <mat-form-field>
      <input matInput type="text" id="matrixCode" placeholder="Matrix Code" i18n-placeholder required [(ngModel)]="model.matrixCode" name="matrixCode">
    </mat-form-field> -->
    <!-- <mat-form-field>
      <input matInput type="text" id="marking" placeholder="Marking" i18n-placeholder required [(ngModel)]="model.marking" name="marking">
    </mat-form-field> -->
    <mat-form-field>
        <input [(ngModel)]="model.description" i18n-placeholder id="description" matInput name="description" placeholder="Description"
               required type="text">
    </mat-form-field>
    <mat-form-field>
        <mat-select [(ngModel)]="model.unit" i18n-placeholder name="unit" placeholder="Unit" required>
            <mat-option *ngFor="let u of unities" [value]="u">
                {{ u }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input [(ngModel)]="model.quantity" i18n-placeholder id="quantity" matInput name="quantity" placeholder="Quantity"
               required type="number">
    </mat-form-field>
    <mat-form-field>
        <input [(ngModel)]="model.costEach" i18n-placeholder id="costEach" matInput name="costEach" placeholder="Cost Each"
               required type="number">
    </mat-form-field>
    <mat-form-field>
        <mat-select [(ngModel)]="model.category" i18n-placeholder name="category" placeholder="Category">
            <mat-option *ngFor="let cat of categories" [value]="cat.name">
                {{ cat.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="forOrderBuild">
        <mat-select (selectionChange)="changeRef($event)" i18n-placeholder placeholder="Assign to existing material">
            <mat-option *ngFor="let mat of assignPossibilities" [value]="mat.id">
                {{ mat.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="button-row">
        <button [disabled]="!materialForm.form.valid" i18n mat-raised-button
                type="submit">{{ editMode && type != 'copy' ? 'Update material' : 'Add material' }}
        </button>
        <button i18n mat-dialog-close mat-raised-button type="button">Cancel</button>
    </div>
</form>