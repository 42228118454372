import {Component, EventEmitter, Output} from '@angular/core';
import {SubBudgetModel} from '../models/sub-budget';
import {GeneratedBy} from '../interfaces';



@Component({
    selector: 'app-sub-budget-form',
    templateUrl: './sub-budget-form.component.html',
    styleUrls: ['./sub-budget-form.component.scss']
})
export class SubBudgetFormComponent {

    model = new SubBudgetModel('', GeneratedBy.User);

    submitted = false;

    // TODO: I think there should be a better way of listening for an event
    // and re-triger it at an higer level
    // Can we maybe pass the event emitter directly to the parent?
    @Output() onSubmit = new EventEmitter<SubBudgetModel>();

    // TODO: Remove this when we're done
    get diagnostic() {
        return JSON.stringify(this.model);
    }

    onFormSubmit() {
        // console.log(this.model);
        this.submitted = true;
        this.onSubmit.emit(this.model);
    }

}
