<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h4>
                <div style="font-size: 20px;">
                    <span i18n>Order</span>&nbsp;
                    <span>{{ currentOrderNr }}</span>
                </div>
            </h4>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="selectedVendor && order" class="order-container">
            <div class="order-meta">
                <div class="order-meta-form">
                    <b i18n>Order Date:</b><br/>
                    <div style="margin-bottom: 20px;">{{ currentOrderDate | timestamp | date }}</div>
                    <b>Vendor:</b><br/>
                    <div>{{ selectedVendor.name }}</div>
                    <div>
                        <span>{{ selectedVendor.address?.street }}</span>
                    </div>
                    <div>
                        <span>{{ selectedVendor.address?.city }}</span>,&nbsp;
                        <span>{{ selectedVendor.address?.state }}</span>,&nbsp;
                        <span>{{ selectedVendor.address?.postal_code }}</span>
                    </div>
                    <div>
                        <span>{{ selectedVendor.address?.country }}</span>
                    </div>
                    <br/>

                    <p><b>Primary Contact:</b><br/>
                        {{ order.primaryContact?.name }}&nbsp;
                        <span
                                *ngIf="order.primaryContact && order.primaryContact.emails && order.primaryContact.emails.length > 0">&#x3C;{{ order.primaryContact?.emails[0].email }}&#x3E;</span>
                    </p>

                    <p><b>Note to vendor:</b><br/>
                        {{ order.note }}</p>

                    <p><b>Total:</b><br/>
                        {{ order.totalValue | currency:order.currency ? order.currency : 'EUR' }}</p>
                </div>

                <div class="order-meta-form">
                    <p><b>Project Manager:</b><br/>
                        <span>{{ order.techContact?.displayName }}</span>
                    </p>
                    <p><b>Note to Project Manager:</b><br/>
                        <span>{{ order.pmNote }}</span>
                    </p>
                    <div>
                        <p>
                            <b>Payment Method:</b><br/>
                            <span>{{ order.paymentMethod }}</span>
                            <span *ngIf="order.paymentMethodCode === 'CONCOR'"><i>{{ order.paymentText }}</i></span>
                        </p>
                    </div>
                    <p><b>Shipping Method:</b><br/>
                        <span>{{ order.shippingMethod }}</span>
                    </p>
                    <p><b>Return Method:</b><br/>
                        <span>{{ order.returnMethod }}</span>
                    </p>
                    <p><b>Delivery Date:</b><br/>
                        <span>{{ order.deliveryDate | timestamp | date }}</span>
                    </p>
                    <p><b>VAT:</b><br/>
                        <span>{{ order.vat }}%</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="example-header">
            <mat-form-field>
                <input (keyup)="applyFilter($event.target.value)" [value]="getListFilter()" matInput
                       placeholder="Filter">
            </mat-form-field>
        </div>

        <div class="example-container mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="project">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Project</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span *ngIf="row.budget.project">{{ row.budget.project.projectNr }}
                            -</span>{{ row.budget.budgetNr }}
                        <small *ngIf="row.budget.companyName">{{ row.budget.companyName }}</small>
                        <small
                                *ngIf="row.budget.prosperworks && row.budget.prosperworks.company_name">{{ row.budget.prosperworks.company_name }}</small>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="subBudget">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Sub Budget</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.subBudget?.description }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Description</mat-header-cell>
                    <mat-cell *matCellDef="let row" style="font-weight: bold;">{{ row.description }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="code">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Code</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.code }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="unit">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Unit</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.unit }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Quantity</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.quantity }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="pricePerUnit">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Price Per Unit</mat-header-cell>
                    <mat-cell
                            *matCellDef="let row">{{ row.costEach | currency:order.currency ? order.currency : 'EUR' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Discount</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.discount }}%</mat-cell>
                </ng-container>

                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Total</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.total | currency:order.currency ? order.currency : 'EUR' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoices">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Invoices</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div>
                            <ng-container *ngFor="let invoice of row.invoices">
                                <a [routerLink]="['/invoices/',invoice.invoiceId]" [title]="invoice.invoiceDate | timestamp |
                  date" class="invoiceDdt"
                                   target="_blank">{{ invoice.invoiceNr }}
                                    |<small>{{ invoice.materialAmount | currency:order.currency ? order.currency : 'EUR' }}</small></a><br>
                            </ng-container>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoiceCompletion">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Invoice Remaining</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span>{{ row.itemInvoiceCompletion | currency:order.currency ? order.currency : 'EUR' }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ddt">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Delivery Notes</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div *ngFor="let ddt of row.ddt">
                            <a [routerLink]="['/ddt/',ddt.ddtId]" [title]="ddt.ddtDate | timestamp | date" class="invoiceDdt"
                               target="_blank">{{ ddt.ddtNr }}</a>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ddtCompletion">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Delivery %</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span>{{ row.itemDeliveryCompletion || 0 }}%</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="outOfBudget">
                    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Out of budget</mat-header-cell>
                    <mat-cell *matCellDef="let row">
            <span *ngIf="row.outOfBudget === undefined">
              <mat-progress-spinner [diameter]="30" class="spinner-progress" mode="indeterminate">
              </mat-progress-spinner>
            </span>
                        <span *ngIf="row.outOfBudget">
              <mat-icon color="warn">warning</mat-icon>
            </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>

            <mat-paginator [length]="dataSource.resultsLength" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <a mat-raised-button routerLink="/order">
            <span i18n>Cancel</span>
        </a>
        <a *ngIf="order && (!order.authorized || order.authorized != 'waiting')" [routerLink]="['/order/add', order.id]"
           mat-raised-button>
            <span i18n>Edit</span>
        </a>
        <a (click)="orderAuth(order, true)"
           *ngIf="order && order.authorized && order.authorized == 'waiting' && canAuthOrder" [disabled]="disableAuthorization" mat-raised-button>
            <span i18n>Authorize Order</span>
        </a>
        <a (click)="orderAuth(order, false)"
           *ngIf="order && order.authorized && order.authorized == 'waiting' && canAuthOrder" [disabled]="disableAuthorization" mat-raised-button>
            <span i18n>Deny authorization</span>
        </a>
    </mat-card-actions>
</mat-card>
