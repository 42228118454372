<div *ngIf="timereportForm">
    <form (ngSubmit)="save(timereportForm.value, timereportForm.valid)" [formGroup]="timereportForm" novalidate>
        <!--
      <mat-form-field>
        <mat-select placeholder="Budget" formControlName="budgetId" i18n-placeholder>
          <mat-option *ngFor="let budget of budgets" [value]="budget.id">
            {{ budget.budgetNr }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    -->
        <mat-form-field class="mat-form-field-lg">
            <input [matAutocomplete]="autoBudget" formControlName="budget" matInput placeholder="Project (Budget) (Customer)"
                   type="text">
            <mat-autocomplete #autoBudget="matAutocomplete" (optionSelected)="budgetChange($event)"
                              [displayWith]="budgetDisplayFn">
                <mat-option *ngFor="let budget of filteredBudgets | async" [value]="budget">
                    <div [ngClass]="{'export-budget': budget.export }">
                        <ng-template [ngIfElse]="companyName" [ngIf]="budget.prosperworks">
                            <span>{{ budget.prosperworks.company_name | ellipsis:20 }}-</span>
                        </ng-template>
                        <ng-template #companyName>
                            <span *ngIf="budget.companyName">{{ budget.companyName | ellipsis:20 }}&nbsp;-</span>
                        </ng-template>
                        <span *ngIf="budget.project">{{ budget | projectNr }}&nbsp;-</span>{{ budget.budgetNr }}
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field *ngIf="!singleSubBudget">
            <mat-select [compareWith]="compareSubBudget" formControlName="subBudget" i18n-placeholder
                        placeholder="Sub Budget">
                <mat-option *ngFor="let subbudget of subbudgets | async" [value]="subbudget">
                    {{ subbudget.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="mat-form-field-md">
            <mat-select [compareWith]="compareActivity" formControlName="activity" i18n-placeholder
                        placeholder="Activity">
                <mat-option *ngFor="let activity of activities | async" [value]="activity">
                    {{ activity?.activity }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input formControlName="hours" i18n-placeholder id="hours" matInput name="hours" placeholder="Hours"
                   required
                   type="number">
        </mat-form-field>
        <mat-form-field>
            <input [matDatepickerFilter]="dateFilter" [matDatepicker]="picker" [max]="maxDate" formControlName="date"
                   i18n-placeholder
                   matInput name="date" placeholder="Date" required>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <!--
      <mat-form-field>
        <input matInput id="userId" placeholder="User" i18n-placeholder
        required [(ngModel)]="model.userId" name="userId">
      </mat-form-field>
    -->
        <mat-form-field *ngIf="canChangeUser; else elseBlock" class="mat-form-field-md">
            <input [matAutocomplete]="autoUsers" formControlName="user" matInput placeholder="User" type="text">
            <mat-autocomplete #autoUsers="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                    {{ user.email }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <ng-template #elseBlock>
            <input formControlName="user" type="hidden">
        </ng-template>
        <!-- <mat-form-field class="full-width">
        <textarea matInput  matTextareaAutosize matAutosizeMinRows="3"
        matAutosizeMaxRows="10" placeholder="Description" i18n-placeholder required formControlName="description" name="description"></textarea>
      </mat-form-field> -->
        <ngx-wig formControlName="description"></ngx-wig>
        <mat-error *ngIf="timereportForm.get('description').hasError('minlength')"><span i18n>You should enter at least 4
        character!</span></mat-error>
        <mat-error *ngIf="submitted && timereportForm.get('description').hasError('required')"><span i18n>You should enter a
        description!</span></mat-error>
        <div class="button-row">
            <!-- <button type="submit" mat-raised-button [disabled]="!timereportForm.valid" i18n>{{ timereport ? 'Update Time Report' : 'Add Time Report' }}</button> -->
            <button i18n mat-raised-button type="submit">{{ timereport ? 'Update Time Report' : 'Add Time Report' }}
            </button>
            <button (click)="cancelTimereportEdit()" *ngIf="timereport" i18n mat-raised-button>Cancel</button>
        </div>
    </form>
</div>
