<span *ngIf="budget$ | async as budget; else loading">
  <mat-card>
    <div>
      <mat-card-title>
        <a [routerLink]="['/budget',budget.id]">
          <span *ngIf="budget.project">Project {{ budget.project.projectNr }}</span>
          <span *ngIf="!budget.project">Budget {{ budget.budgetNr }}</span>
        </a>
        <span
                *ngIf="budget.prosperworks">&nbsp;{{ budget.prosperworks.name }}&nbsp;<small>({{ budget.prosperworks.company_name }}
            )</small></span>
      </mat-card-title>

      <ul class="summary-meta">
        <li><strong>Status:</strong> {{ budget.status }}</li>
        <li><strong>Budget Creation Date:</strong> {{ budget.creation | timestamp | date }}</li>
        <li *ngIf="budget.project"><strong>Project Delivery Date (Scheduled):</strong>
            {{ budget.project.deliveryDate | timestamp | date }}</li>
        <li *ngIf="budget.project"><strong>Project Archived Date:</strong>
            {{ budget.archivedDate | timestamp | date }}</li>
      </ul>
      <mat-card-content>
        <table>
          <tr *ngIf="budget.project">
            <td><strong>Project Manager:</strong></td>
            <td>{{ budget.project?.projectManager?.displayName }}</td>
          </tr>
          <tr>
            <td><strong>Budget Author:</strong></td>
            <td>{{ budget.assignee?.displayName }}</td>
          </tr>
        </table>
      </mat-card-content>
    </div>
  </mat-card>
  <mat-card style="overflow-x: auto;">
    <table *ngIf="summary$ | async as summary; else loadingSummary" class="table table-bordered">
      <th></th>
      <th><a [routerLink]="['/balances/materials/',budgetId]" i18n>Materials</a></th>
      <th i18n><a [routerLink]="['/balances/activities/',budgetId]">Activities</a></th>
      <th i18n>Total Cost</th>
      <th i18n>Commissions</th>
      <th i18n>Taxes</th>
      <th i18n>Fatturato</th>
      <th i18n>Gen Expenses & profits</th>
      <th i18n>%</th>
      <tr>
        <!-- Budget -->
        <td>Budget</td>
        <td>{{ summary.budget.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.budget.act | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.budget.mat + summary.budget.act  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td>{{ budgetCommission| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ budgetTaxes| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.finalOfferOrder | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.profit?.budget | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ +(summary.profit.budget / summary.finalOfferOrder) | percent:'1.2' }}</td>
      </tr>
      <tr>
        <!-- Consuntivo -->
        <td>Consuntivo</td>
        <td>{{ summary.final.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.final.act  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.final.mat + summary.final.act  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ consuntivoCommissions| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ consuntivoTaxes| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.status === 'archived'">
          {{ summary.revenue | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.status !== 'archived'">
          {{ summary.finalOfferOrder | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td [class.make-red]="!compare(summary.profit.final,0)">
          {{ summary.profit.final | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.status === 'archived'">
          {{ +(summary.profit ? (summary.profit.final / summary.revenue) : 0) | percent:'1.2' }}</td>
        <td *ngIf="budget.status !== 'archived'">
          {{ +(summary.profit ? (summary.profit.final / summary.finalOfferOrder) : 0) | percent:'1.2' }}</td>
      </tr>
      <tr class="differences">
        <!-- Difference -->
        <td>Difference</td>
        <td [class.make-red]="summary.final.mat > summary.budget.mat">
          {{ summary.budget.mat - summary.final.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td [class.make-red]="summary.final.act > summary.budget.act">
          {{ summary.budget.act - summary.final.act | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td [class.make-red]="summary.final.act +summary.final.mat > summary.budget.act + summary.budget.mat">
          {{ summary.budget.act + summary.budget.mat - summary.final.act - summary.final.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td>{{ budgetCommission - consuntivoCommissions| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ budgetTaxes - consuntivoTaxes| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.status === 'archived'" [class.make-red]="summary.finalOfferOrder > summary.revenue">
          {{ summary.revenue - summary.finalOfferOrder | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.status !== 'archived'">
          {{ summary.finalOfferOrder - summary.finalOfferOrder | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td [class.make-red]="summary.profit.budget >summary.profit.final">
          {{ summary.profit.final - summary.profit.budget | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
      </tr>
    </table>

    <ng-template #loadingSummary>
      Loading...
    </ng-template>


  </mat-card>

</span>

<ng-template #loading>
    <mat-card-title>
        Loading budget...
    </mat-card-title>
</ng-template>

<app-activity-balance-table *ngIf="budgetId" [budgetId]="budgetId"></app-activity-balance-table>

<app-materials-balance-table *ngIf="budgetId" [budgetId]="budgetId"></app-materials-balance-table>
