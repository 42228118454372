import {AssemblyCosts, Budget, Margin, Offer, Project, SplittedCostTableElement} from '../interfaces';
import {AclUser, AppUser} from '../../interfaces/app-user';
import {Timestamp} from '@firebase/firestore-types';



export class BudgetModel implements Budget {

    id: string;
    budgetId: string;
    budgetNr: string;
    author: Partial<AclUser>;
    assignee: {
        displayName: string;
        googleUid: string;
        id: string;
        on: Date | Timestamp;
    };
    category: string;
    clientId: string;
    creation: Date;
    description: string;
    tags: string[];
    status: string;
    prosperworks?: any;
    companyInfo?: any;
    offerUrl?: string;
    driveFolderId?: string;
    project?: Project;
    margin?: Margin;
    assemblyCosts?: AssemblyCosts;
    offer?: Offer;
    modifiedBy?: Partial<AclUser>;
    modifiedOn?: Date;
    costSummary?: any;
    export?: any;
    exportBudget?: any;
    companyName?: any;
    filter?: string[];
    authorizedModify: string;


    static isEditable(budget: Budget, currentUser: AppUser) {
        if (['closed', 'archived', 'new'].indexOf(budget.status) !== -1) {
            return false;
        } else {
            return true;
        }
    }


    static recalculateItaSplittedCostsTable = (b: Budget) => {
        const newSplittedCostsTableData = [];

        if (!b || !b.costSummary || !b.margin) {
            return newSplittedCostsTableData;
        }
        console.log('IMPORT, THE COST SUMMARY:', b.costSummary);
        console.log('IMPORT, THE MARGINS:', b.margin);

        const f = BudgetModel._calculatesValue(b.margin);
        // Assembly 0
        BudgetModel._elaborateSummaryElement(b.costSummary, newSplittedCostsTableData, 'assembly', 'Installation', f);
        // Design 1
        BudgetModel._elaborateSummaryElement(b.costSummary, newSplittedCostsTableData, 'design', 'Design', f);
        // Production 2
        BudgetModel._elaborateSummaryElement(b.costSummary, newSplittedCostsTableData, 'production', 'Production', f);
        // Shipping 3
        BudgetModel._elaborateSummaryElement(b.costSummary, newSplittedCostsTableData, 'shipping', 'Shipping', f);
        // Taxes 4
        BudgetModel._elaborateSummaryElement(b.costSummary, newSplittedCostsTableData, 'ciTax', 'Customs', f);

        const totalRow = BudgetModel._elaborateSummaryTotalRow(newSplittedCostsTableData);
        newSplittedCostsTableData.push(totalRow);
        console.log('IMPORT, UPDATING newSplittedCostsTableData:', newSplittedCostsTableData);
        return newSplittedCostsTableData;
    }

    /**
     * The splitted costs for the USA budgets is calculated in function of the ITA one (ITA production is taken into account)
     * @param b
     * @param splittedCostsTableDataIta
     */
    static recalculateUsaSplittedCostsTable = (b: Budget, splittedCostsTableDataIta: SplittedCostTableElement[]) => {
        const splittedCostsTableDataExport = [];
        const splittedCostsTableDataExportCanonic = [];

        if (!b || !b.costSummary || !b.margin) {
            console.log('EXPORT has no margin or costSummary yet.. can not run: recalculateUsaSplittedCostsTable', b);
            return {splittedCostsTableDataExport, splittedCostsTableDataExportCanonic};
        }
        const productionRow = splittedCostsTableDataIta.find(r => r.id === 'production');

        console.log('EXPORT, THE COST SUMMARY:', b.costSummary);
        console.log('EXPORT, THE MARGINS:', b.margin);

        const f = BudgetModel._calculatesExportValue(b.margin, b.export.exchangeRate, productionRow.total);
        // Assembly
        BudgetModel._elaborateSummaryElement(b.costSummary, splittedCostsTableDataExport, 'assembly', 'Installation', f);
        BudgetModel._elaborateSummaryElement(b.canonicCostSummary, splittedCostsTableDataExportCanonic, 'assembly', 'Installation', f);
        // Design
        BudgetModel._elaborateSummaryElement(b.costSummary, splittedCostsTableDataExport, 'design', 'Design', f);
        BudgetModel._elaborateSummaryElement(b.canonicCostSummary, splittedCostsTableDataExportCanonic, 'design', 'Design', f);
        // Production
        BudgetModel._elaborateSummaryElement(b.costSummary, splittedCostsTableDataExport, 'production', 'Production', f);
        BudgetModel._elaborateSummaryElement(b.canonicCostSummary, splittedCostsTableDataExportCanonic, 'production', 'Production', f);
        // Shipping
        BudgetModel._elaborateSummaryElement(b.costSummary, splittedCostsTableDataExport, 'shipping', 'Shipping', f);
        BudgetModel._elaborateSummaryElement(b.canonicCostSummary, splittedCostsTableDataExportCanonic, 'shipping', 'Shipping', f);

        // Total
        const totalRow = BudgetModel._elaborateSummaryTotalRow(splittedCostsTableDataExport);
        const totalCanonicRow = BudgetModel._elaborateSummaryTotalRow(splittedCostsTableDataExportCanonic);
        const granTotalRow = BudgetModel._elaborateSummaryGranTotalRow(splittedCostsTableDataExport, b.export.exchangeRate);
        const granTotalCanonicRow = BudgetModel._elaborateSummaryGranTotalRow(splittedCostsTableDataExportCanonic, b.export.exchangeRate);
        splittedCostsTableDataExport.push(totalRow);
        splittedCostsTableDataExportCanonic.push(totalCanonicRow);
        splittedCostsTableDataExport.push(granTotalRow);
        splittedCostsTableDataExportCanonic.push(granTotalCanonicRow);
        return {splittedCostsTableDataExport, splittedCostsTableDataExportCanonic};
    }

    static _elaborateSummaryTotalRow = (tableData: any[]): SplittedCostTableElement => {
        return tableData.map((el) => el).reduce((el1, el2) => {
                return {
                    id: 'total',
                    name: 'TOTAL',
                    cost: el1.cost + el2.cost,
                    profit: el1.profit + el2.profit,
                    expenses: el1.expenses + el2.expenses,
                    taxes: el1.taxes + el2.taxes,
                    commission: el1.commission + el2.commission,
                    total: el1.total + el2.total,
                    totalEuro: el1.totalEuro + el2.totalEuro,
                    hint: ''
                };
            },
            {
                id: 'total',
                name: 'TOTAL',
                cost: 0,
                profit: 0,
                expenses: 0,
                taxes: 0,
                commission: 0,
                total: 0,
                totalEuro: 0,
                hint: ''
            });
    }

    static _elaborateSummaryGranTotalRow = (tableData: any[], exchangeRate: number): SplittedCostTableElement => {
        return tableData.map((el) => el).reduce((el1, el2) => {
                const el1TaxesEur = el1.taxes / exchangeRate;
                const el2TaxesEur = el2.taxes / exchangeRate;
                return {
                    id: 'grantotal',
                    name: 'TOTAL + TAX',
                    cost: null,
                    profit: null,
                    expenses: null,
                    taxes: null,
                    commission: null,
                    total: el1.total + el2.total + el1.taxes + el2.taxes,
                    totalEuro: el1.totalEuro + el1TaxesEur + el2.totalEuro + el2TaxesEur,
                    hint: el1.hint
                };
            },
            {
                id: 'grantotal',
                name: 'TOTAL + TAX',
                cost: null,
                profit: null,
                expenses: null,
                taxes: null,
                commission: null,
                total: 0,
                totalEuro: 0,
                hint: 'Total + Capital Improv. Tax'
            });
    }

    static _elaborateSummaryElement = (costSummary: any, summaryToUpdate: any[], elId: string, name: string, applyF) => {
        if (!costSummary) {
            return;
        }
        if (elId in costSummary) {
            const cost = costSummary[elId];
            const a = applyF(elId, name, cost);
            summaryToUpdate.push(a);
        }
    }

    static _calculatesExportValue = (margin: Margin, exchangeRate, totalProductionIta: number) => {
        const k = (100 - (margin.commission + margin.generalExpenses + margin.profit));
        return function (id: string, name: string, cost: number) {
            const a: SplittedCostTableElement = {
                id,
                name,
                cost,
                commission: cost * margin.commission / k,
                expenses: cost * margin.generalExpenses / k,
                taxes: 0,
                profit: cost * margin.profit / k,
                total: 0,
                hint: ''
            };
            a.total = a.cost + a.commission + a.expenses + a.profit;

            if (exchangeRate) {
                a.totalEuro = a.total / exchangeRate;
            }
            if (id === 'production' && margin.capitalImprovement) {
                a.taxes = (totalProductionIta * exchangeRate) * (margin.ciTaxesPercentage * 0.01);
                a.totalWithTaxes = a.total + a.taxes;
            }
            return a;
        };
    }

    static _calculatesValue = (margin: Margin) => {
        const k = (100 - (margin.commission + margin.generalExpenses + margin.profit));
        return function (id: string, name: string, cost: number) {
            const a: SplittedCostTableElement = {
                id,
                name,
                cost,
                commission: cost * margin.commission / k,
                expenses: cost * margin.generalExpenses / k,
                taxes: 0,
                profit: cost * margin.profit / k,
                total: 0
            };
            a.total = a.cost + a.commission + a.expenses + a.profit;
            return a;
        };
    }

}
