import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {NoContentComponent} from './no-content/no-content.component';



const appRoutes: Routes = [
    {path: 'home', component: HomeComponent},
    {path: 'private', loadChildren: () => import('./private').then(m => m.PrivateModule)},
    {path: '**', component: NoContentComponent},
    {path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {useHash: true, preloadingStrategy: PreloadAllModules})
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
