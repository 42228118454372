import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {Order} from '@wondersys/wonderbudget-lib';
import {DataService} from './data.service';
import {catchError, map, tap} from 'rxjs/operators';
import * as firebase from 'firebase';
import {Timestamp} from '@firebase/firestore-types';
import * as moment from 'moment-timezone';
import * as XLSX from 'xlsx';



export interface OrderLIstFilter {
    orderNr: string;
    projectNr: string;
    vendor: string;
    itemDescr: string;
    urgent: boolean;
    waiting: boolean;
    pending: boolean;
    delivered: boolean;
    sortActive?: string;
    sortDirection?: string;
}

export interface OrderListResponse {
    data: Order[];
    totalSize: number;
}

@Injectable({
    providedIn: 'root'
})
export class OrderListTableService {

    private appliedFilterAndSorting: ReplaySubject<OrderLIstFilter> = new ReplaySubject();
    private appliedFilter: OrderLIstFilter;

    private performingQuickSerch: ReplaySubject<boolean> = new ReplaySubject();
    private performingSlowSearch: ReplaySubject<boolean> = new ReplaySubject();

    private refreshAllTables: Subject<void> = new Subject<void>();

    constructor(
        private dataService: DataService
    ) {
        this.performingQuickSerch.next(true);
        this.performingSlowSearch.next(true);
    }

    public get refreshAllTables$(): Observable<void> {
        return this.refreshAllTables.asObservable();
    }

    public get appliedFilterAndSorting$(): Observable<OrderLIstFilter> {
        return this.appliedFilterAndSorting.asObservable();
    }

    public forceRefresh() {
        this.refreshAllTables.next();
    }

    public markOrderAsDelivered(order: Order, isDelivered: boolean, realDeliveryDate: Date) {
        return this.dataService.markOrderAsDelivered(order.id, isDelivered, realDeliveryDate).toPromise();
    }

    public loadData(mode: 'quick' | 'slow', pageIndex: number, pageSize: number): Observable<OrderListResponse> {
        switch (mode) {
            case 'quick':
                return this.getQuickList();
            case 'slow':
                return this.getSlowList(pageIndex, pageSize);
        }
    }

    public isLoading$(mode: 'quick' | 'slow'): Observable<boolean> {
        return (mode === 'quick') ? this.performingQuickSerch.asObservable() : this.performingSlowSearch.asObservable();
    }

    retrieveFiltersFromStorage(): OrderLIstFilter {

        const filter = {
            orderNr: (localStorage.getItem('orderList_orderNr')) ? localStorage.getItem('orderList_orderNr') : null,
            projectNr: (localStorage.getItem('orderList_projectNr')) ? localStorage.getItem('orderList_projectNr') : null,
            vendor: (localStorage.getItem('orderList_vendor')) ? localStorage.getItem('orderList_vendor') : null,
            itemDescr: (localStorage.getItem('orderList_itemDescr')) ? localStorage.getItem('orderList_itemDescr') : null,
            urgent: localStorage.getItem('orderList_urgent') === 'true',
            waiting: localStorage.getItem('orderList_waiting') === 'true',
            pending: localStorage.getItem('orderList_pending') === 'true',
            delivered: localStorage.getItem('orderList_delivered') === 'true',
            sortDirection: (localStorage.getItem('orderList_sortDirection')) ? localStorage.getItem('orderList_sortDirection') : 'desc',
            sortActive: (localStorage.getItem('orderList_sortActive')) ? localStorage.getItem('orderList_sortActive') : 'date'
        };

        this.appliedFilter = filter;
        this.appliedFilterAndSorting.next(filter);

        return filter;
    }

    storeFilters(filter: OrderLIstFilter) {
        if (this.compareFilters(filter, this.appliedFilter)) {
            localStorage.setItem('orderList_orderNr', filter.orderNr ? filter.orderNr : '');
            localStorage.setItem('orderList_projectNr', filter.projectNr ? filter.projectNr : '');
            localStorage.setItem('orderList_vendor', filter.vendor ? filter.vendor : '');
            localStorage.setItem('orderList_itemDescr', filter.itemDescr ? filter.itemDescr : '');
            localStorage.setItem('orderList_urgent', filter.urgent ? 'true' : 'false');
            localStorage.setItem('orderList_waiting', filter.waiting ? 'true' : 'false');
            localStorage.setItem('orderList_pending', filter.pending ? 'true' : 'false');
            localStorage.setItem('orderList_delivered', filter.delivered ? 'true' : 'false');
            localStorage.setItem('orderList_sortActive', filter.sortActive);
            localStorage.setItem('orderList_sortDirection', filter.sortDirection);

            this.appliedFilter = filter;
            this.appliedFilterAndSorting.next(filter);
        }
    }

    storeSorting(sortActive: string, sortDirection: string) {
        this.storeFilters({...this.appliedFilter, sortActive: sortActive, sortDirection: sortDirection});
    }

    /**
     *
     * @param actual
     * @param old
     * @returns true if the filters are different, false if are equals
     */
    compareFilters(actual: OrderLIstFilter, old: OrderLIstFilter) {
        if (!old) {
            return true;
        }
        return !Object.keys(actual).reduce((a: boolean, k: string) => a && actual[k] === old[k], true);
    }

    exportMaterialExcel(data: Order[]) {
        console.log('Creating excel with data: ', data.length);

        const preparedData = [];
        const header = ['Project', 'Date', 'Vendor', 'Unit', 'Quantity', 'Price per unit', 'Total', 'Description', 'IT/Estero'];
        preparedData.push(header);

        for (const order of data) {
            for (const item of order.items) {
                const projectRef = item.budget.project && item.budget.project.projectNr ? item.budget.project.projectNr : item.budget.budgetNr;
                let date = order.date ? order.date : '';
                if (date && date instanceof firebase.firestore.Timestamp) {
                    date = moment((<Timestamp>order.date)).format('DD/MM/YYYY');
                }

                const country = order.vendor.address ? order.vendor.address.country : '';
                preparedData.push([projectRef, date, order.vendor.name, item.unit, item.quantity, item.costEach,
                    this.getItemTotal(item), item.description, country]);
            }
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(preparedData);
        XLSX.utils.book_append_sheet(wb, ws, 'Export');

        const today = moment().format('YYYY_MM_DD_HH_mm');
        const fileName = `${today}_order_items_export.xlsx`;
        XLSX.writeFile(wb, fileName);
    }

    getItemTotal(item) {
        item.total = (item.costEach * item.quantity) - (item.costEach * item.quantity / 100 * item.discount);
        return item.total;
    }

    private getQuickList(): Observable<OrderListResponse> {
        this.performingQuickSerch.next(true);
        return this.dataService.getQuickOrderList().pipe(
            map((d) => {
                return {data: d, totalSize: d.length};
            }),
            tap(() => this.performingQuickSerch.next(false)),
            catchError((error) => {
                this.performingQuickSerch.next(false);
                console.error('Error getting quick orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }

    private getSlowList(pageIndex: number, pageSize: number): Observable<OrderListResponse> {
        console.log('getting slow data: ', pageIndex, pageSize);
        let startAt = pageIndex * pageSize;
        if (pageIndex !== 0) {
            startAt++;
        }
        this.performingSlowSearch.next(true);
        return this.dataService.getOrderListPaginated(pageSize, startAt, this.appliedFilter).pipe(
            tap(() => this.performingSlowSearch.next(false)),
            catchError((error) => {
                this.performingSlowSearch.next(false);
                console.error('Error getting slow orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }
}
