<div *ngIf="linkSelection.length > 0" class="row-center">
    <mat-form-field style="width:230px;">
        <mat-label>Select dashboard</mat-label>
        <mat-select (selectionChange)="switchDashboard($event.value)">
            <mat-option *ngFor="let obj of linkSelection" [value]="obj.link">
                {{ obj.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="iframe-container">
    <iframe *ngIf="iframeLink; else nocontent" [src]="iframeLink" allowfullscreen frameborder="0" frameborder="0" height="100%"
            id="iframe" width="100%">
    </iframe>
</div>

<ng-template #nocontent>
    <div class="row-center">
        <h3>Your user is not allowed to see any dashboard.</h3>
    </div>
</ng-template>
