import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {TimereportListTableService} from '../services/timereport-list-table.service';



@Injectable({
    providedIn: 'root'
})
export class TimereportEditGuard implements CanActivate {

    constructor(
        private router: Router,
        private timereportListTableService: TimereportListTableService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const timereportId = route.params.id;
        console.log('TimereportEditGuard - timereport id is: ', timereportId)
        this.router.navigate(['/timereport']).then(() => this.timereportListTableService.editTimereport(timereportId));
        return true;
    }

}
