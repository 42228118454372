<h3 i18n mat-dialog-title>Open Project <span *ngIf="isExport"> USA</span></h3>

<mat-dialog-content>
    <mat-form-field>
        <input [disabled]="isExport" [formControl]="projectNr" aria-label="Number" i18n-placeholder matInput
               maxlength="6" placeholder="Project Nr." type="text">
        <mat-error *ngIf="projectNr.invalid">{{ getErrorMessage() }}</mat-error>
    </mat-form-field>
    <mat-form-field>
        <input [formControl]="finalOffer" aria-label="Number" i18n-placeholder matInput placeholder="Final Offer"
               type="number">
        <mat-icon matSuffix>{{ isExport ? 'attach_money' : 'euro' }}</mat-icon>
    </mat-form-field>

    <app-user-picker [myControl]="projectManager" placeholder="Project Manager"></app-user-picker>
    <mat-form-field>
        <mat-select [formControl]="paymentMethod" placeholder="Payment Method">
            <mat-option *ngFor="let pm of paymentMethodOptions" [value]="pm.methodValue">
                {{ pm.methodValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input [formControl]="deliveryDate" [matDatepicker]="deliveryFormDate" [min]="minDeliveryDate" matInput
               placeholder="Delivery Date">
        <mat-datepicker-toggle [for]="deliveryFormDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #deliveryFormDate></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="closeDialog()" [disabled]="!isValid" i18n mat-button type="submit">Submit</button>
</mat-dialog-actions>
