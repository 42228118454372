<div (dropped)="startUpload($event)"
     (hovered)="toggleHover($event)"
     [class.hovering]="isHovering"
     class="dropzone"
     dropZone>

    <div class="file">
        <label class="file-label">
            <input (change)="startUpload($event.target.files)" class="file-input" type="file">
            <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
          </span>
        </label>
    </div>
</div>


<div *ngIf="snapshot | async as snap">
    <div *ngIf="percentage | async as pct">
        <progress *ngIf="isActive(snap)" [value]="pct"
                  class="progress is-info"
                  max="100">
        </progress>

        <span *ngIf="isActive(snap)">{{ pct | number }}%</span>
    </div>

    <!--<div *ngIf="downloadURL | async as url">
      <h3>Results!</h3>
      <img [src]="url"><br>
      <a [href]="url" target="_blank" rel="noopener">Download Me!</a>
    </div> -->

    <div *ngIf="isActive(snap)">
        <button (click)="task.pause()" [disabled]="!isActive(snap)" class="button is-warning" mat-raised-button>Pause
        </button>
        <button (click)="task.cancel()" [disabled]="!isActive(snap)" class="button is-danger" mat-raised-button>Cancel
        </button>
        <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')" class="button is-info"
                mat-raised-button>Resume
        </button>
    </div>
</div>