import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DataSource} from '@angular/cdk/table';
import {Component, Inject, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {Storage} from '../interfaces/storage';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';



@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
    public storageDataSource;
    public displayedColumns = ['matrixCode', 'marking', 'description', 'unit', 'costEach',
        'vendor', 'uniqueCode', 'type', 'date', 'actions']; // L'ordine conta!!!
    private storageMaterials$;

    // ['matrixCode', 'marking', 'description', 'unit', 'costEach',
    //   'vendor', 'uniqueCode', 'type', 'date', 'actions']; // L'ordine conta!!!

    constructor(
        private dataService: DataService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.storageMaterials$ = this.dataService.getStorage().pipe(
            map((material) => {
                return material.map((m: any) => {
                    const data = m.payload.doc.data() as Storage;
                    const id = m.payload.doc.id;
                    return {id, ...data};
                });
            }));

        this.storageDataSource = new StorageDataSource(this.storageMaterials$);
    }

    public openMaterialDialog($event, type, s?: Storage) {
        const dialogRef = this.dialog.open(StorageDialogComponent, {
            width: '350px',
            data: {
                type: type,
                storage: s
            }
        });
    }

    public deleteStorage($event, row: Storage) {
        console.log('Event', $event, row);
        this.dataService.deleteStorage(row.id)
            .subscribe(() => {
                console.log('Deleted');
                // this.update.emit();
            });
    }

}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class StorageDataSource extends DataSource<any> {

    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<any[]> {
        return this.observable.pipe(map((data: any) => {
            this.resultsLength = data.length;
            return data;
        }));

    }

    disconnect() {
    }

}

@Component({
    selector: 'app-storage-dialog',
    template: `
        <div>
            <app-storage-form (onSubmit)="closeDialog($event)" [type]="data.type"
                              [storage]="data.storage"></app-storage-form>
        </div>`
})
export class StorageDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<StorageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

    }

    closeDialog(mat) {
        this.dialogRef.close();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }


}