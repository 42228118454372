import {BehaviorSubject, merge, Observable} from 'rxjs';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/table';
import {Budget, SubBudgetTotals} from '../interfaces';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {ActivitiesValueModel} from '../models/activities-value';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {LinkListDialogComponent} from '../../shared/link-list-dialog/link-list-dialog.component';
import {map} from 'rxjs/operators';



@Component({
    selector: 'app-sub-budget-resume',
    templateUrl: './sub-budget-resume.component.html',
    styleUrls: ['./sub-budget-resume.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class SubBudgetResumeComponent implements OnInit {

    @Input() public budget: Budget;
    @Input() public budgetId: string;
    @Input() public budgetTotal: number;
    @Input() public tabSelector: number;
    @Input() public subBudgets: Map<String, String>;
    @Input() public subBudgetSummary$: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public materialDataSource: MaterialsDataSource;
    public activitiesDataSource;
    public subBudgetTotals = new SubBudgetTotals();
    public categoryMap = new Map<string, string>();
    public avm: {};
    public matDisplayedColumns = [];
    public matDisplayedColumnsSubItems = [];
    public displayedColumns = [];
    public disableGenerateSheet = true;
    public expandedElement: any;
    private activityValMap: ActivitiesValueModel;

    constructor(private dataService: DataService, public dialog: MatDialog, private auth: AuthService) {
    }

    private _resume: any;

    get resume() {
        return this._resume;
    }

    @Input() set resume(r: any) {
        if (!r) {
            return;
        }
        this._resume = r;
        console.log('subbudgetresume component: received updated resume', this._resume);
        this.disableGenerateSheet = false;
        this.activityValMap = new ActivitiesValueModel(this._resume.activities);
        this.avm = this.activityValMap.activityValueMap;
        this.subBudgetTotals.activities = this._resume.activities.filter((a) => a.cost && a.hours)
            .map((a) => a.cost * a.hours).reduce((v1, v2) => v1 + v2, 0);
        this.subBudgetTotals.materials = this._resume.materials.filter((m) => m.costEach && m.quantity)
            .map((m) => m.costEach * m.quantity).reduce((v1, v2) => v1 + v2, 0);
        this._resume.materials = this.groupMaterials(this._resume.materials);
    }

    ngOnInit() {
        this.auth.userHasPermissions(['app.budget.figures.read', 'app.budget.final.read', 'app.budget.write', 'app.iproject.write'])
            .subscribe((userPerm: any[]) => {
                console.log('user permissions:', userPerm);
                if (userPerm[0] && userPerm[1]) {
                    // Can see figures and finals
                    this.displayedColumns = ['name', 'category', 'cost', 'hours', 'finalHours', 'importoCalc', 'finalAmount', 'weight', 'difference'];
                    this.matDisplayedColumns =
                        ['description', 'category', 'unit', 'quantity', 'finalQuantity',
                            'costEach', 'amount', 'finalAmount', 'weight', 'difference'];
                    this.matDisplayedColumnsSubItems = ['description', 'category', 'unit', 'finalQuantity',
                        'costEach', 'finalAmount'];
                } else if (userPerm[0]) {
                    // Can only see figures
                    this.displayedColumns = ['name', 'category', 'cost', 'hours', 'importoCalc', 'weight'];
                    this.matDisplayedColumns = ['description', 'category', 'unit', 'quantity',
                        'costEach', 'amount', 'weight'];
                    this.matDisplayedColumnsSubItems = ['description', 'category', 'unit'];
                }
            });

        this.materialDataSource = new MaterialsDataSource(this.subBudgetSummary$.pipe(map((i: any) => i.materials)), this.sort);
        this.activitiesDataSource = new ActivitiesDataSource(this.subBudgetSummary$);

        this.dataService.getItemCategories()
            .subscribe((cats) => {
                this.categoryMap.clear();
                cats.forEach((c) => {
                    this.categoryMap.set(c.name, c.description);
                });
            });
    }

    /**
     * Groups materials referencing a materials in the "sons" array, for graphical purposes
     * @param materials
     */
    public groupMaterials(materials: Array<any>) {
        console.log('initial materials:', materials);
        const fathersIds = materials.map(m => {
            if (m.isOnlyFinal && m.referencedMaterialId) {
                return m.referencedMaterialId;
            }
            return null;
        }).filter(m => m !== null);

        return materials.map((m: any) => {
            const isFather = fathersIds.find(id => id === m.id);
            m.sons = [];
            if (isFather) {
                m.sons = materials.map(mat => mat.referencedMaterialId === m.id ? mat : null).filter(m => m !== null);
                m.finalAmount = m.finalAmount + m.sons.reduce((a, b) => a + b.finalAmount, 0);
            }
            return m;
        }).filter(m => (!m.referencedMaterialId));
    }

    public isExpansionDetailRow = (row: any) => row.hasOwnProperty('sons') && row['sons'].length > 0;

    public async createResumeSheet() {
        this.disableGenerateSheet = true;

        this.dataService.setReportSubBudget(this.budgetId).subscribe(res => {
            this.disableGenerateSheet = false;
            console.log(res);
        }, err => {
            console.error(err);
            this.disableGenerateSheet = false;
        });
    }

    public setExpandedNode(element: any) {
        if (this.expandedElement != element) {
            this.expandedElement = element;
        } else {
            this.expandedElement = undefined;
        }
    }

    async openOrderLinkList(row) {
        if (!row.processedOrders) {
            return;
        }
        row.processedOrders = [...new Set(<string[]>row.processedOrders)];

        const orders = [];
        for (const orderId of row.processedOrders) {
            const order = await this.dataService.getSingleOrder(orderId).toPromise();
            orders.push({...order.data(), id: order.id});
        }

        const links = orders.map(o => {
            return {
                descr: `${o.orderNr} - ${o.vendor ? o.vendor.name : ''}`, link: `#/order/view/${o.id}`
            };
        });
        const title = 'Linked orders';


        this.dialog.open(LinkListDialogComponent, {
            width: '500px',
            data: {links: links, title: title}
        });
    }
}


class MaterialsDataSource extends DataSource<any> {

    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;
    _dataChange = new BehaviorSubject([]);

    constructor(private observable,
                private _sort: MatSort) {
        super();
        this.observable.subscribe(this._dataChange);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<any[]> {

        const displayDataChanges = [
            this.observable,
            this._sort.sortChange
        ];


        return merge(...displayDataChanges).pipe(map(() => {

            console.log('Combine ', this._dataChange.value);
            const data = this._dataChange.value;

            this.resultsLength = data ? data.length : 0;
            this.sortData(data);
            return data;
        }));
    }


    disconnect() {
    }

    private sortData = (materialList: any[]) => {
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }

        materialList.sort((a: any, b: any) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';
            switch (this._sort.active) {
                case 'description':
                    [propertyA, propertyB] = [a.description ? a.description.toUpperCase() : '', b.description ? b.description.toUpperCase() : ''];
                    break;
                case 'category':
                    [propertyA, propertyB] = [a.category ? a.category : '', b.category ? b.category : ''];
                    break;
                case 'unit':
                    [propertyA, propertyB] = [a.unit ? a.unit : '', b.unit ? b.unit : ''];
                    break;
                case 'quantity':
                    [propertyA, propertyB] = [a.quantity, b.quantity];
                    break;
                case 'finalQuantity':
                    [propertyA, propertyB] = [a.finalQuantity, b.finalQuantity];
                    break;
                case 'costEach':
                    [propertyA, propertyB] = [a.costEach, b.costEach];
                    break;
                case 'amount':
                case 'weight':
                    [propertyA, propertyB] = [a.quantity * a.costEach, b.quantity * b.costEach];
                    break;
                case 'finalAmount':
                    [propertyA, propertyB] = [a.finalAmount, b.finalAmount];
                    break;
                case 'difference':
                    [propertyA, propertyB] = [a.quantity * a.costEach - a.finalAmount, b.quantity * b.costEach - b.finalAmount];
                    break;
            }

            let valueA;
            let valueB;

            if (propertyA instanceof Date) {
                valueA = propertyA;
                valueB = propertyB;
            } else {
                valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            }

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}

class ActivitiesDataSource extends DataSource<any> {

    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<any[]> {
        return this.observable.pipe(map((data: any) => {
            this.resultsLength = data.activities.length;
            return data.activities.sort((a, b) => a.activity > b.activity ? 1 : -1);
        }));

    }

    disconnect() {
    }

}
