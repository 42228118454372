import {filter, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Timereport} from '../interfaces/timereport';
import {DataService} from '../../services/data.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {AclUser} from '../../interfaces/app-user';
import {Activity, Budget, SubBudget} from '../../budget/interfaces/index';
import {Router} from '@angular/router';

import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';

import {EllipsisPipe} from '../../shared/pipes/ellipsis.pipe';
import {ProjectNrPipe} from '../../shared/pipes/project-nr.pipe';

import {firestoreDateToJsDate} from '../../shared/utils/utils';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as firebase from 'firebase';
import {TimereportListTableService} from '../../services/timereport-list-table.service';



@Component({
    selector: 'timereport-add',
    templateUrl: './timereport-add.component.html',
    styleUrls: ['./timereport-add.component.scss'],
    providers: []

})
export class TimereportAddComponent implements OnInit, OnDestroy {

    public filteredUsers: Observable<Partial<AclUser>[]>;
    public filteredBudgets: Observable<Partial<Budget>[]>;
    public canChangeUser = false;
    public timereportForm: FormGroup; // our model driven form
    public submitted: boolean; // keep track on whether form is submitted
    public events: any[] = []; // use later to display form changes
    public singleSubBudget = true;
    public update = false;
    public text1 = '';
    public selectedBudget;
    model: Timereport;
    budgets: BehaviorSubject<Partial<Budget>[]> = new BehaviorSubject([]);
    subbudgets: BehaviorSubject<Partial<SubBudget>[]> = new BehaviorSubject([]);
    activities: Observable<Partial<Activity>[]>;
    maxDate = new Date(); // cannot add an activity in the future
    users: BehaviorSubject<Partial<AclUser>[]> = new BehaviorSubject([]);
    private unsubscribe$ = new Subject();
    private _timeReport;
    // FORM Validation depends on user permission
    private descriptionFormControlValidators = [
        Validators.required,
        Validators.minLength(4)
    ];
    private loggedUser;

    constructor(private dataService: DataService, private authService: AuthService,
                public dialog: MatDialog,
                private router: Router,
                private ellipsisPipe: EllipsisPipe,
                private projectNrPipe: ProjectNrPipe,
                private timereportListTableService: TimereportListTableService
    ) {
    }

    private _sentDates;

    @Input() set sentDates(sd: Set<number>) {
        console.log('TimereportAddComponent sentDates', sd)
        this._sentDates = sd;

        if (this.timereportForm) {
            this.timereportForm.get('date').updateValueAndValidity();
            if (this.timereportForm.get('date').invalid) {
                this.timereportForm.patchValue({
                    date: undefined
                }, {
                    emitEvent: false
                });
            }
        }

    }

    get timereport() {
        return this._timeReport;
    }

    @Input() set timereport(timereport: Timereport) {
        console.log('TimereportAddComponent set timereport', timereport)
        if (timereport) {

            this.timereportForm.get('activity').enable();
            this.timereportForm.get('subBudget').enable();

            this.update = true;
            this._timeReport = timereport;

            const theDate = moment.tz((<firebase.firestore.Timestamp>timereport.date).toMillis(), 'Europe/Rome').set('hours', 14);

            console.log('patching:', new Date(), timereport.date, firestoreDateToJsDate(theDate))
            this.timereportForm.patchValue({
                creationDate: firestoreDateToJsDate(timereport.creationDate),
                user: timereport.user,
                date: firestoreDateToJsDate(theDate),
                description: timereport.description,
                budget: timereport.budget,
                subBudget: timereport.subBudget,
                activity: timereport.activity,
                hours: timereport.hours
            }, {
                emitEvent: true
            });

            // compareWith


        } else {
            this._timeReport = null;
            this.initForm();
        }
    }

    ngOnInit() {
        console.log('init timereport-add....')

        this.authService.userHasPermission('app.timereport.anyone.write').pipe(
            takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.canChangeUser = val;
                if (val) {
                    this.descriptionFormControlValidators = undefined; // Admin do not need to specify a description

                    this.dateFilter = () => true;
                } else {
                    this.dateFilter = (d: Moment | null): boolean => {

                        if (!this._sentDates) {
                            return true;
                        }
                        return !this._sentDates.has(d.valueOf());
                    };
                }
            });

        this.dataService.getNotArchivedBudgetList().pipe(
            map(budgets => {
                const isSimpleUser = this.authService.currentUser.type.indexOf('user') !== -1 && this.authService.currentUser.type.length === 1;
                return budgets.filter(budget => !isSimpleUser || budget.status !== 'new')
                    .map(this._project({
                        budgetNr: 1,
                        id: 1,
                        project: 1,
                        prosperworks: 1,
                        companyName: 1,
                        export: 1,
                        description: 1,
                        status: 1
                    }))
            }),
            takeUntil(this.unsubscribe$))
            .subscribe(this.budgets);

        // Get the list of users
        this.dataService.getAppUsers().pipe(map(actions => {
                return actions.map((a: any) => {
                    const data = a.payload.doc.data() as AclUser;
                    const id = a.payload.doc.id;
                    return data.deleted ? null : <any>{
                        id, ...this._project({
                            email: 1,
                            displayName: 1,
                            personalName: 1,
                            googleUid: 1,
                            external: 1
                        })(data)
                    };
                }).filter(u => u);
            }),
            takeUntil(this.unsubscribe$))
            .subscribe(this.users);


        this.setLoggedUser();
        this.initForm();


        if (this.authService.currentUser.type.indexOf('purch') !== -1) {
            this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);
        }
    }

    public cancelTimereportEdit() {
        this._timeReport = null;
        this.timereportListTableService.cancelTimereportEdit();
        this.initForm();
    }

    public dateFilter = (d: Moment | null): boolean => {

        if (!this._sentDates) {
            return true;
        }
        return !this._sentDates.has(d.valueOf());
    }

    save(model: any | Timereport, isValid: boolean) {
        this.submitted = true; // set form submit to true
        console.log('Model is', model, isValid);

        if (!isValid) {
            return;
        }

        // We represent the italian day with the italian midnight that day
        const md = moment(model.date);
        const dateItaly = moment.tz({
            year: md.year(),
            month: md.month(),
            day: md.date()
        }, 'Europe/Rome');
        model.date = dateItaly.toDate();
        console.log('date to be saved in new timereport: ', model.date);


        let action;
        if (this._timeReport) {
            // UPDATE
            if (this._timeReport.actualCost !== undefined) {
                model.actualCost = this._timeReport.actualCost;
            } else {
                // BIG BIG WORKAROUND. TODO: PLEASE FIX
                model.actualCost = Number.parseFloat(model.activity.cost);
            }
            console.log('UPDATE WITH', model);
            // HOT FIX CreationDate.
            if (!model.creationDate) {
                model.creationDate = firebase.firestore.FieldValue.serverTimestamp();
            }

            action = this.dataService.updateTimereport(this._timeReport.id, model);
        } else {
            // BIG BIG WORKAROUND. TODO: PLEASE FIX
            // The actual value should be taken from the current activity blueprint!!!
            // Here we get it from the budget estimate hourly cost
            model.actualCost = Number.parseFloat(model.activity.cost);
            console.log('NEW WITH', model);
            // HOT FIX CreationDate.
            model.creationDate = firebase.firestore.FieldValue.serverTimestamp();
            model['searchMode'] = 'quick';

            action = this.dataService.addTimereport(model);
        }
        action.then(() => {
            this.openDialog(true);
            this.cancelTimereportEdit();
            this.timereportListTableService.refreshSlowTable();
        }).catch((err) => {
            this.openDialog(false);
        });
    }

    compareSubBudget(sb1, sb2) {
        return sb1.id === sb2.id;
    }

    compareActivity(att1, att2) {
        if (!att1 || !att2) {
            return false;
        }
        return att1.id === att2.id;
    }

    public budgetChange($event) {
        if (!$event.option.value.id) {
            return;
        }
        const budgetId = $event.option.value.id;
        this.updateSubBudgets(budgetId).pipe(
            takeUntil(this.unsubscribe$))
            .subscribe(this.subbudgets);
    }

    public displayFn(user: AclUser): string {
        if (user) {
            return user.email ? user.email : user.id;
        } else {
            return null;
        }
    }

    public budgetDisplayFn = (budget: Budget): string => {
        if (budget) {
            let ret = budget.budgetNr ? budget.budgetNr : budget.budgetId;
            if (budget.project) {
                ret = (budget.project.internal ? 'i' : '') + budget.project.projectNr + '-' + ret;
            }
            let cn;
            if (budget.prosperworks && budget.prosperworks.company_name) {
                cn = this.ellipsisPipe.transform(budget.prosperworks.company_name, 20);
                // cn = budget.prosperworks.company_name;
                ret = cn + ' ' + ret;
            } else if (budget.companyName) {
                cn = this.ellipsisPipe.transform(budget.companyName, 20);
                // cn = budget.companyName;
                ret = cn + ' ' + ret;
            }
            return ret;
        } else {
            return null;
        }
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public openDialog(success) {
        let result;
        if (success) {
            result = 'Time Report succesfully added';
        } else {
            result = 'Error while adding this time report';
            const dialogRef = this.dialog.open(AddTimereportDialogComponent, {
                width: '350px',
                data: {
                    result: result
                }
            });
        }
    }

    getFormValidationErrors() {
        Object.keys(this.timereportForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.timereportForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    private setLoggedUser() {
        this.loggedUser = this._project({
            id: 1,
            googleUid: 1,
            email: 1,
            displayName: 1,
            personalName: 1,
            external: 1
        })(this.authService.currentUser);
        for (const key in this.loggedUser) {
            if (!this.loggedUser[key]) {
                console.log('key not valid', key, this.loggedUser[key]);
                delete this.loggedUser[key];
            }
        }
    }

    private initForm() {
        const today = moment();
        const todayItaly = moment.tz({
            year: today.year(),
            month: today.month(),
            day: today.date()
        }, 'Europe/Rome');

        // Definition of the Time Report Form
        this.timereportForm = new FormGroup({
            budget: new FormControl('', [
                Validators.required,
                this.existsValidator
            ]),
            activity: new FormControl({value: null, disabled: true}, [
                Validators.required,
                this.existsValidator
            ]),
            subBudget: new FormControl({value: '', disabled: true}, [
                this.existsValidator
            ]),
            hours: new FormControl(0, Validators.required),
            creationDate: new FormControl(),
            date: new FormControl(this.dateFilter(todayItaly) ? todayItaly : undefined, Validators.required),
            user: new FormControl(this.loggedUser, [
                Validators.required,
                this.userExistsValidator
            ]),
            description: new FormControl('', this.descriptionFormControlValidators)
        });

        this.filteredUsers = this.timereportForm.get('user').valueChanges
            .pipe(
                startWith({} as AclUser),
                map(user => user && typeof user === 'object' ? user.email : String(user)),
                map(email => email ? this.filterUser(email) : this.users.value.slice())
            );

        this.filteredBudgets = this.timereportForm.get('budget').valueChanges
            .pipe(
                tap((v) => console.log('vvvv', v)),
                startWith({} as Budget),
                map(budget => budget && typeof budget === 'object' ? budget.budgetNr : String(budget)),
                map(budgetNr => budgetNr ? this.filterBudget(budgetNr) : this.budgets.value.slice())
            );

        const budgetActListFilter = ['Preventivi ed appuntamenti clienti', 'Marketing'];
        const prjmgrActList = ['Preventivi ed appuntamenti clienti', 'Gestione commessa', 'Marketing', 'Progettazione e programmi macchina', 'Formazione e spiegaz. comm.', 'Assenza'];
        const prdmgrActList = ['Gestione commessa', 'Progettazione e programmi macchina', 'Formazione e spiegaz. comm.', 'Assenza'];
        const prdmgrCatList = ['OFI', 'TRI', 'ASS'];
        this.activities = this.timereportForm.get('subBudget').valueChanges.pipe(
            filter((subBudget) => subBudget),
            tap((subBudget) => {
                this.timereportForm.get('activity').reset();
            }),
            switchMap((subBudget) => {
                const budgetId = this.timereportForm.get('budget').value.id;
                this.selectedBudget = this.budgets.value.find(b => b.id === this.timereportForm.get('budget').value.id);
                return this.dataService.getSBActivities(budgetId, subBudget.id);
            }),
            map((actList) => actList.map((act: any) => {
                    const data = act.payload.doc.data() as Activity;
                    const id = act.payload.doc.id;
                    return {id: id, activity: data.activity, cost: data.cost, category: data.category};
                })
                    .filter(act => {

                        // for everyone: no activities other than enabled are allowed on simple budgets (not projects)
                        if (this.selectedBudget && (['new', 'assigned'].includes(this.selectedBudget.status) &&
                            budgetActListFilter.indexOf(act.activity) === -1)) return false;

                        const isSimpleUser = this.authService.currentUser.type.indexOf('user') !== -1 && this.authService.currentUser.type.length === 1;
                        const isPrjMgr = this.authService.currentUser.type.indexOf('prjmgr') !== -1 && this.authService.currentUser.type.length === 1;
                        const isReadOnlyPrjMgr = this.authService.currentUser.type.indexOf('readonlyprjmgr') !== -1 && this.authService.currentUser.type.length === 1;
                        const isPrdMgr = this.authService.currentUser.type.indexOf('prdmgr') !== -1 && this.authService.currentUser.type.length === 1;
                        // users (operai) can not put hours on "Ufficio tecnico" (UFT)
                        if (isSimpleUser && (act.category === 'UFT' || act.category === 'AMM' || act.category === 'PRI')) return false;
                        // prj managers only-users can put hours only on particular activities
                        if (isPrjMgr && prjmgrActList.indexOf(act.activity) === -1) return false;
                        // readonlyprjmgr can not put hours
                        if (isReadOnlyPrjMgr && prjmgrActList.indexOf(act.activity) === -1) return false;
                        // prd managers only-users can put hours only on particular activities
                        if (isPrdMgr && (prdmgrActList.indexOf(act.activity) === -1 && prdmgrCatList.indexOf(act.category) === -1)) return false;

                        return true;
                    })
            ),
            tap((actList) => {
                this.timereportForm.get('activity').enable();
            }));
    }

    // Validators. Check if typeahed selection is an object
    private existsValidator = (control): { [key: string]: any } => {
        return (typeof control.value === 'object') ? null : {'notExists': {value: control.value}};
    }

    private updateSubBudgets(budgetId) {
        this.timereportForm.get('activity').reset();
        this.timereportForm.get('subBudget').reset('');
        this.timereportForm.get('activity').disable();
        return this.dataService.getReportSubBudget(budgetId).pipe(
            map((sbList: any[]) => sbList.map((sb) => {
                    const data = sb.payload.doc.data() as SubBudget;
                    const id = sb.payload.doc.id;
                    return {id, ...data};
                })
            ),
            tap((sbList) => {
                // console.log('sblist', sbList);
                this.timereportForm.get('subBudget').enable();
                if (sbList.length === 1) {
                    this.timereportForm.patchValue({
                        subBudget: sbList[0]
                    });
                    this.singleSubBudget = true;
                } else {
                    this.singleSubBudget = false;
                }
            }));
    }

    private userExistsValidator = (control): { [key: string]: any } => {
        return (typeof control.value === 'object') ? null : {'userNotExists': {value: control.value}};
    }

    private filterUser(val: string): Partial<AclUser>[] {
        return this.users.value
            .filter(option => {
                if (option.email) {
                    return option.email.toLowerCase().indexOf(val.toLowerCase()) === 0;
                } else {
                    return option.id.toLowerCase().indexOf(val.toLowerCase()) === 0;
                }
            });
    }

    private filterBudget(val: string): Partial<Budget>[] {
        return this.budgets.value
            .filter(option => {
                if (option.budgetNr) {
                    let searchString = option.budgetNr;
                    if (option.project) {
                        searchString += this.projectNrPipe.transform(option);
                    }
                    if (option.prosperworks && option.prosperworks.company_name) {
                        searchString += option.prosperworks.company_name;
                    } else if (option.companyName) {
                        searchString += option.companyName;
                    }
                    return searchString.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                } else {
                    return option.budgetId.toLowerCase().indexOf(val.toLowerCase()) === 0;
                }
            });
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object)) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }
}


@Component({
    selector: 'add-timereport-material-dialog',
    template: `
        <div>
            <p>{{ data.result }}</p>
            <button mat-button (click)="closeDialog()" i18n>Close</button>
        </div>`
})
export class AddTimereportDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<AddTimereportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }


}

