<fieldset>
    <h4 i18n>Order Rows&nbsp;-&nbsp;<span>Total:&nbsp;</span><span>{{ total$ | async  | currency:currency }}</span></h4>

    <div *ngIf="itemsFormArray.hasError('minSum')">
        You must buy a total sum of at least {{ itemsFormArray.getError('minSum') }}.
    </div>
    <div class="order-entry-list">
        <mat-accordion #accordion="matAccordion" [multi]=true>

            <mat-expansion-panel *ngFor="let item of itemsFormArray.controls; let i=index" [disabled]="true"
                                 [expanded]="true">

                <app-order-entry-control (removed)="removeItem($event)" [currency]="currency" [index]="i" [item]="item">
                </app-order-entry-control>
            </mat-expansion-panel>

        </mat-accordion>
    </div>

    <div style="margin-top: 20px">
        <button (click)="addItem()" color="accent" mat-fab>
            <mat-icon>add</mat-icon>
        </button>
    </div>

</fieldset>
