import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialModel} from '../models/material';
import {DataService} from '../../services/data.service';
import {unities} from '../../interfaces/unit';
import {Material} from '../interfaces';
import {resumeMaterial} from '@wondersys/wonderbudget-lib';



@Component({
    selector: 'app-item-material-form',
    templateUrl: './material-form.component.html',
    styleUrls: ['./material-form.component.scss']
})
export class MaterialFormComponent implements OnInit {

    @Input() public budgetId: string;
    @Input() public subBudgetId: string;
    @Input() public type: string;
    @Input() public forOrderBuild: boolean; // just return the material instead of saving it
    @Input() public assignPossibilities: Array<resumeMaterial>;

    public referencedMaterialId: string;
    @Output() onSubmit = new EventEmitter<MaterialModel>();
    model = new MaterialModel();
    categories = [];
    unities = unities;
    submitted = false;

    constructor(private dataService: DataService) {
    }

    private _material: Material;

    get material(): Material {
        return this.model.toPlainObj();
    }

    @Input() set material(m: Material) {
        if (m) {
            this._editMode = true;
            this.model = MaterialModel.fromObj(m);
        } else {
            this._editMode = false; // New obj
        }
    }

    private _editMode = false;

    get editMode(): boolean {
        return this._editMode;
    }

    public changeRef(event) {
        console.log('choosed:', event.value);
        this.referencedMaterialId = event.value;
    }

    onFormSubmit() {
        console.log('form submit: ', this.forOrderBuild, this.model, this.model.toPlainObj());
        this.submitted = true;

        if (this.forOrderBuild) {
            this.model.referencedMaterialId = this.referencedMaterialId;
            this.onSubmit.emit(this.model);
            return;
        }

        let action;
        if (this._editMode && !(this.type === 'copy')) {
            action = this.dataService.updateMaterial(this.budgetId, this.subBudgetId, this.model.toPlainObj());
        } else {
            action = this.dataService.addMaterial(this.budgetId, this.subBudgetId, this.model.toPlainObj());
        }

        action.subscribe(() => {
            // close popu
            this.onSubmit.emit(this.model);
            console.log('close popup');
        });
    }

    ngOnInit() {
        this.dataService.getItemCategories().subscribe((c) => {
            this.categories = c;
        });
    }

}
