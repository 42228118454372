import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {DataService} from '../services/data.service';
import {FormGroup, FormBuilder, Validators, PatternValidator, FormControl} from '@angular/forms';
import {Budget} from '../budget/interfaces/index';



const intParsableValidator = (control: FormControl) => {
    const number = control.value;
    if (Number.isNaN(parseInt(number, 10))) {
        return {numberParsable: 'Not parsable '};
    }
    return null;
};

@Component({
    selector: 'app-internal-project-detail',
    templateUrl: './internal-project-detail.component.html',
    styleUrls: ['./internal-project-detail.component.scss']
})
export class InternalProjectDetailComponent implements OnInit, OnDestroy {

    @Input() budget: Budget;

    public internalProjectForm: FormGroup;

    private subscriptions;

    constructor(private dataService: DataService, private fb: FormBuilder) {
    }

    ngOnInit() {
        this.internalProjectForm = this.fb.group({
            projectNr: [null, [Validators.required, intParsableValidator]],
            type: 'internal',
            orderTotal: 0,
            description: ['', [Validators.required, Validators.minLength(3)]]
        });

        this.subscriptions = this.dataService.getNextProjectNr()
            .subscribe((projectNr) => {
                this.internalProjectForm.patchValue({
                    projectNr: projectNr
                });
            });
    }

    getErrorMessage() {
        return this.internalProjectForm.get('projectNr').hasError('required') ? 'You must enter a value' :
            this.internalProjectForm.get('projectNr').hasError('numberParsable') ? 'Project number must start with an integer' :
                '';
    }

    submit() {
        if (!this.internalProjectForm.valid) {
            return;
        }
        const projectNr = this.internalProjectForm.value.projectNr;
        const orderTotal = this.internalProjectForm.value.orderTotal;
        const description = this.internalProjectForm.value.description;
        const type = this.internalProjectForm.value.type;

        this.dataService.createInternalProject(projectNr, orderTotal, description, type)
            .subscribe((data) => {
                console.log('Project created', data);
                this.internalProjectForm.get('description').reset('');
                this.internalProjectForm.get('orderTotal').reset(0);
            });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
