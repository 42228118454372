import {Component, EventEmitter, Output} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {Observable} from 'rxjs';



@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

    @Output() public url = new EventEmitter<Observable<string>>();
    @Output() public uploadProcess = new EventEmitter<boolean>();

    // Main task
    task: AngularFireUploadTask;

    // Progress monitoring
    percentage: Observable<number>;

    snapshot: Observable<any>;

    // Download URL
    downloadURL: Observable<string>;

    // State for dropzone CSS toggling
    isHovering: boolean;

    constructor(private storage: AngularFireStorage) {
    }


    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    startUpload(event: FileList) {
        // The File object
        const file = event.item(0);

        // Client-side validation example
        if (file.type !== 'application/pdf') {
            console.error('unsupported file type :( ' + file.type + ')');
            return;
        }

        // The storage path
        const path = `invoices/${new Date().getTime()}_${file.name}`;

        // Totally optional metadata
        const customMetadata = {app: 'ExtraVega'};

        // The main task
        this.task = this.storage.upload(path, file, {customMetadata});

        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges();

        // The file's download URL
        this.task.then(() => {
            const ref = this.storage.ref(path);
            ref.getDownloadURL().subscribe(url => {
                this.downloadURL = url; // with this you can use it in the html
                this.url.emit(this.downloadURL);
                console.log(url);
            });
        });


    }

    // Determines if the upload task is active
    isActive(snapshot) {
        const progress = (snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes);
        this.uploadProcess.emit(progress);
        return progress;
    }

}
