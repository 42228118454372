<form [formGroup]="exportForm" class="export-form-container">
    <mat-form-field>
        <input formControlName="design" i18n-placeholder matInput min="0" placeholder="Design & Project Management USA"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="shipping" i18n-placeholder matInput min="0" placeholder="Shipping USA" type="number">
    </mat-form-field>
    <mat-form-field>
        <input formControlName="customDuties" i18n-placeholder matInput min="0" placeholder="Custom duties %"
               type="number">
    </mat-form-field>
    <mat-form-field>
        <input class="no-spinner" formControlName="exchangeRate" i18n-placeholder matInput min="0"
               placeholder="Exchange rate"
               type="number">
        <button (click)="refreshExchangeRate()" mat-icon-button matSuffix type="button">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-hint>{{ exchangeRateHint }}</mat-hint>
    </mat-form-field>
    <button (click)="submit()" [disabled]="!exportForm.valid || operationInProgress" mat-button type="submit">Save
    </button>
    <button (click)="changeTab('Overseas')" [disabled]="operationInProgress" mat-button type="button">
        Add materials "Overseas"
    </button>
</form>
