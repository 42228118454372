import {fromEvent, merge, Observable, Subject} from 'rxjs';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfigService} from '../../services/config.service';
import {MatButtonToggle} from '@angular/material/button-toggle';
import {OrderLIstFilter, OrderListTableService} from '../../services/order-list-table.service';
import {debounceTime, takeUntil} from 'rxjs/operators';



@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {

    @ViewChild('orderNrF', {static: true}) orderNrF: ElementRef;
    @ViewChild('projectNrF', {static: true}) projectNrF: ElementRef;
    @ViewChild('vendorF', {static: true}) vendorF: ElementRef;
    @ViewChild('itemDescrF', {static: true}) itemDescrF: ElementRef;
    @ViewChild('pendingF', {static: true}) pendingF: MatButtonToggle;
    @ViewChild('urgentF', {static: true}) urgentF: MatButtonToggle;
    @ViewChild('waitingF', {static: true}) waitingF: MatButtonToggle;
    @ViewChild('deliveredF', {static: true}) deliveredF: MatButtonToggle;
    public selectedTab = 0;
    public performingQuickSerch$: Observable<boolean>;
    public performingSlowSearch$: Observable<boolean>;
    public slowListElements: number = 0;
    public exportRequest: Subject<'quick' | 'slow'> = new Subject();
    private unsubscribe$ = new Subject();

    constructor(
        private configService: ConfigService, // do not remove, for loading settings on load page
        public dialog: MatDialog,
        private orderListTableService: OrderListTableService
    ) {
        this.performingQuickSerch$ = this.orderListTableService.isLoading$('quick');
        this.performingSlowSearch$ = this.orderListTableService.isLoading$('slow');
    }

    ngOnInit() {
        this.restoreFilters();
        const orderFevent$ = fromEvent(this.orderNrF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const projectFevent$ = fromEvent(this.projectNrF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const vendorFevent$ = fromEvent(this.vendorF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );
        const itemDescFevent$ = fromEvent(this.itemDescrF.nativeElement, 'input').pipe(
            takeUntil(this.unsubscribe$), debounceTime(500)
        );

        merge(orderFevent$, projectFevent$, vendorFevent$, itemDescFevent$).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => this.storeFilters());
    }

    restoreFilters() {
        const filter = this.orderListTableService.retrieveFiltersFromStorage();

        this.orderNrF.nativeElement.value = filter.orderNr;
        this.projectNrF.nativeElement.value = filter.projectNr;
        this.vendorF.nativeElement.value = filter.vendor;
        this.itemDescrF.nativeElement.value = filter.itemDescr;
        this.urgentF.checked = filter.urgent;
        this.waitingF.checked = filter.waiting;
        this.pendingF.checked = filter.pending;
        this.deliveredF.checked = filter.delivered
    }

    storeFilters() {
        const filter: OrderLIstFilter = {
            orderNr: this.orderNrF.nativeElement.value.trim(),
            projectNr: this.projectNrF.nativeElement.value.trim(),
            vendor: this.vendorF.nativeElement.value.trim(),
            itemDescr: this.itemDescrF.nativeElement.value.trim(),
            urgent: this.urgentF.checked,
            waiting: this.waitingF.checked,
            pending: this.pendingF.checked,
            delivered: this.deliveredF.checked
        };

        this.orderListTableService.storeFilters(filter);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public exportMaterialExcel() {
        const list = (this.selectedTab) ? 'slow' : 'quick';
        this.exportRequest.next(list);
    }
}
