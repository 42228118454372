<mat-card>
    <mat-card-title>Activity Summary</mat-card-title>

    <mat-table [dataSource]="result" matSort>
        <ng-container matColumnDef="activityName">
            <mat-header-cell *matHeaderCellDef i18n>Activity Name</mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span>{{ activity.activity }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hourlyCost">
            <mat-header-cell *matHeaderCellDef i18n>Cost/h ({{ currency }})</mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span>{{ activity.hourlyCost | number:'1.2' }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hours">
            <mat-header-cell *matHeaderCellDef i18n>H Budget</mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span>{{ activity.hours }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cost">
            <mat-header-cell *matHeaderCellDef i18n>Tot Budget ({{ currency }})</mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span>{{ activity.cost | number:'1.2' }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="finalHours">
            <mat-header-cell *matHeaderCellDef i18n>H Consunt</mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span>{{ activity.finalHours }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="finalCost">
            <mat-header-cell *matHeaderCellDef i18n>Tot Cons ({{ currency }})</mat-header-cell>
            <mat-cell *matCellDef="let activity">
                <span>{{ activity.finalCost | number:'1.2' }}</span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [class.element-row]="true"
                 [class.make-red]="row.finalCost > row.cost">
        </mat-row>
    </mat-table>
    <br>

    <table class="table table-bordered">
        <tr>
            <td></td>
            <td>Total Hours</td>
            <td>Total Cost</td>
        </tr>
        <!--Budget-->
        <tr>
            <td>Budget</td>
            <td>{{ totalHBudget }}</td>
            <td>{{ totalBudget | currency:currency:'symbol':'1.0-0' }}</td>
        </tr>
        <!--Consuntivo-->
        <tr>
            <td>Final</td>
            <td>{{ totalHFinal }}</td>
            <td>{{ totalFinal | currency:currency:'symbol':'1.0-0' }}</td>
        </tr>
        <!--Difference-->
        <tr class="differences">
            <td>Difference</td>
            <td [class.make-red]="totalHBudget < totalHFinal">{{ totalHBudget - totalHFinal }}</td>
            <td [class.make-red]="totalBudget < totalFinal">
                {{ totalBudget - totalFinal | currency:currency:'symbol':'1.0-0' }}
            </td>
        </tr>
    </table>
</mat-card>
