import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material.module';
import {InvoicesComponent} from './invoices.component';
import {InvoicesAddComponent} from './invoices-add/invoices-add.component';
import {InvoicesListComponent} from './invoices-list/invoices-list.component';
import {InvoicesCosmoComponent} from './invoices-cosmo/invoices-cosmo.component';
import {InvoicesCosmoAddComponent} from './invoices-cosmo/invoices-cosmo-add/invoices-cosmo-add.component';
import {InvoicesCosmoListComponent} from './invoices-cosmo/invoices-cosmo-list/invoices-cosmo-list.component';
import {InvoicesCosmoGuard} from './invoices-cosmo/guards/invoices-cosmo.guard';
import {SharedModule} from '../shared/shared.module';
import {InvoicesListTableComponent} from './invoices-list-table/invoices-list-table.component';
import {InvoiceGuard} from './invoice.guard';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [InvoicesComponent,
        InvoicesAddComponent,
        InvoicesListComponent,
        InvoicesCosmoComponent,
        InvoicesCosmoAddComponent,
        InvoicesCosmoListComponent,
        InvoicesListTableComponent],
    providers: [InvoicesCosmoGuard, InvoiceGuard]
})
export class InvoicesModule {
}
