import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';



@Component({
    selector: 'app-delete-order-dialog',
    templateUrl: './delete-order-dialog.component.html',
    styleUrls: ['./delete-order-dialog.component.scss']
})
export class DeleteOrderDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DeleteOrderDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data) {
    }

    ngOnInit() {
    }

    public confirm() {
        this.dialogRef.close(true);
    }

    public cancel() {
        this.dialogRef.close(false);
    }

}
