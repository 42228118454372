import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {DataService} from './data.service';
import {Ddt} from '../ddt/interfaces/ddt';
import {catchError, map, tap} from 'rxjs/operators';



export interface DdtListFilter {
    note: string;
    ddtNr: string;
    vendor: string;
    sortActive?: string;
    sortDirection?: string;
}

export interface DdtListResponse {
    data: Ddt[];
    totalSize: number;
}

@Injectable({
    providedIn: 'root'
})
export class DdtListTableService {

    private appliedFilterAndSorting: ReplaySubject<DdtListFilter> = new ReplaySubject();
    private appliedFilter: DdtListFilter;

    private performingQuickSerch: ReplaySubject<boolean> = new ReplaySubject();
    private performingSlowSearch: ReplaySubject<boolean> = new ReplaySubject();

    constructor(
        private dataService: DataService
    ) {
        this.performingQuickSerch.next(true);
        this.performingSlowSearch.next(true);
    }

    public get appliedFilterAndSorting$(): Observable<DdtListFilter> {
        return this.appliedFilterAndSorting.asObservable();
    }

    public loadData(mode: 'quick' | 'slow', pageIndex: number, pageSize: number): Observable<DdtListResponse> {
        switch (mode) {
            case 'quick':
                return this.getQuickList();
            case 'slow':
                return this.getSlowList(pageIndex, pageSize);
        }
    }

    public isLoading$(mode: 'quick' | 'slow'): Observable<boolean> {
        return (mode === 'quick') ? this.performingQuickSerch.asObservable() : this.performingSlowSearch.asObservable();
    }

    retrieveFiltersFromStorage(): DdtListFilter {

        const filter = {
            ddtNr: (localStorage.getItem('ddtList_ddtNr')) ? localStorage.getItem('ddtList_ddtNr') : null,
            vendor: (localStorage.getItem('ddtList_vendor')) ? localStorage.getItem('ddtList_vendor') : null,
            note: (localStorage.getItem('ddtList_note')) ? localStorage.getItem('ddtList_note') : null,
            sortDirection: (localStorage.getItem('ddtList_sortDirection')) ? localStorage.getItem('ddtList_sortDirection') : 'desc',
            sortActive: (localStorage.getItem('ddtList_sortActive')) ? localStorage.getItem('ddtList_sortActive') : 'ddtDate'
        };

        this.appliedFilter = filter;
        this.appliedFilterAndSorting.next(filter);

        return filter;
    }

    storeFilters(filter: DdtListFilter) {
        if (this.compareFilters(filter, this.appliedFilter)) {
            localStorage.setItem('ddtList_ddtNr', filter.ddtNr);
            localStorage.setItem('ddtList_vendor', filter.vendor);
            localStorage.setItem('ddtList_note', filter.note);
            localStorage.setItem('ddtList_sortActive', filter.sortActive);
            localStorage.setItem('ddtList_sortDirection', filter.sortDirection);

            this.appliedFilter = filter;
            this.appliedFilterAndSorting.next(filter);
        }
    }

    storeSorting(sortActive: string, sortDirection: string) {
        this.storeFilters({...this.appliedFilter, sortActive: sortActive, sortDirection: sortDirection});
    }

    /**
     *
     * @param actual
     * @param old
     * @returns true if the filters are different, false if are equals
     */
    compareFilters(actual: DdtListFilter, old: DdtListFilter) {
        if (!old) {
            return true;
        }
        return !Object.keys(actual).reduce((a: boolean, k: string) => a && actual[k] === old[k], true);
    }

    private getQuickList(): Observable<DdtListResponse> {
        this.performingQuickSerch.next(true);
        return this.dataService.getQuickDdtList().pipe(
            map((d) => ({data: d, totalSize: d.length})),
            tap(() => this.performingQuickSerch.next(false)),
            catchError((error) => {
                this.performingQuickSerch.next(false);
                console.error('Error getting quick orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }

    private getSlowList(pageIndex: number, pageSize: number): Observable<DdtListResponse> {
        console.log('getting slow data: ', pageIndex, pageSize);
        let startAt = pageIndex * pageSize;
        if (pageIndex !== 0) {
            startAt++;
        }
        this.performingSlowSearch.next(true);
        return this.dataService.getDdtListPaginated(pageSize, startAt, this.appliedFilter).pipe(
            tap(() => this.performingSlowSearch.next(false)),
            catchError((error) => {
                this.performingSlowSearch.next(false);
                console.error('Error getting slow orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }
}
