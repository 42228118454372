<h3 class="sidebar-title">Menu</h3>
<mat-nav-list class="sidebar-items fxlayout-column">
    <!--
      <mat-list-item fxFlex>
          <a routerLink="/budget" [queryParams]="{new: true}" mat-button>
              <mat-icon matListIcon>priority_high</mat-icon>
              <span class="menu-label" i18n>Incoming</span>
          </a>
      </mat-list-item>
  -->
    <mat-list-item *ngIf="canViewBudget">
        <a mat-button routerLink="/budget">
            <mat-icon matListIcon>attach_money</mat-icon>
            <span class="menu-label" i18n>Budget</span>
        </a>
    </mat-list-item>
    <!--
      <mat-list-item fxFlex>
          <a routerLink="/budget" mat-button>
              <mat-icon matListIcon>attach_money</mat-icon>
              <span class="menu-label" i18n="Application for comparing Budget to Final">Balances</span>
          </a>
      </mat-list-item>
  -->
    <mat-list-item *ngIf="canViewInternalProjects">
        <a mat-button routerLink="/internalproject">
            <mat-icon matListIcon>attach_money</mat-icon>
            <span class="menu-label" i18n="Application to manage internal project">IProjects</span>
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="canViewOrder">
        <a mat-button routerLink="/order">
            <mat-icon matListIcon>local_shipping</mat-icon>
            <span class="menu-label" i18n>Orders</span>
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="canViewOrder">
        <a mat-button routerLink="/invoices">
            <mat-icon matListIcon>receipt</mat-icon>
            <span class="menu-label" i18n>Invoices</span>
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="(canViewOrder || canViewInvoicesCosmo) && cosmoEnabled">
        <a mat-button routerLink="/invoices-cosmo">
            <mat-icon matListIcon>receipt</mat-icon>
            <span class="menu-label" i18n>Invoices To Cosmo</span>
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="canViewOrder">
        <a mat-button routerLink="/ddt">
            <mat-icon matListIcon>receipt</mat-icon>
            <span class="menu-label" i18n>Delivery Notes</span>
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="canViewTimeReport">
        <a mat-button routerLink="/timereport">
            <mat-icon matListIcon>access_time</mat-icon>
            <span class="menu-label" i18n="Activity time reports link">Time Report</span>
        </a>
    </mat-list-item>
    <mat-list-item *ngIf="canViewStatCollector">
        <a mat-button routerLink="/reportactivities">
            <mat-icon matListIcon>analytics</mat-icon>
            <span class="menu-label" i18n="Activity time reports link">Report Activites</span>
        </a>
    </mat-list-item>

    <mat-list-item *ngIf="canViewLaserCut">
        <a mat-button routerLink="/lasercut">
            <mat-icon matListIcon>flash_on</mat-icon>
            <span class="menu-label" i18n="Lase Cut Interface">Laser Cut</span>
        </a>
    </mat-list-item>

    <mat-list-item *ngIf="canViewUsers">
        <a mat-button routerLink="/users">
            <mat-icon matListIcon>group</mat-icon>
            <span class="menu-label" i18n="Lase Cut Interface">Users</span>
        </a>
    </mat-list-item>

    <mat-list-item *ngIf="canViewBalanceOverview">
        <a mat-button routerLink="/workSummary">
            <mat-icon matListIcon>people_outline</mat-icon>
            <span class="menu-label" i18n="Work hours">Consuntivo ore</span>
        </a>
    </mat-list-item>


    <!--
          TEMPORARILY COMMENTED
      <mat-list-item *ngIf="canViewUsers" fxFlex>
          <a routerLink="/store" mat-button>
              <mat-icon matListIcon>dns</mat-icon>
              <span class="menu-label" i18n>Storage</span>
          </a>
      </mat-list-item>
      -->

    <mat-list-item *ngIf="canViewUsers && userRole === 'admin'">
        <a mat-button routerLink="/superadmin">
            <mat-icon matListIcon>security</mat-icon>
            <span class="menu-label" i18n>Super Admin</span>
        </a>
    </mat-list-item>

</mat-nav-list>
