import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Budget, Margin} from '../../interfaces';
import {BudgetModel} from '../../models/budget-model';
import {AuthService} from '../../../services/auth.service';



@Component({
    selector: 'app-margin-form',
    templateUrl: './margin-form.component.html',
    styleUrls: ['./margin-form.component.scss']
})
export class MarginFormComponent implements OnInit {

    public formUpdating = false;
    @Input() kind: 'ITA' | 'USA' = 'ITA';
    public marginForm: FormGroup;

    constructor(private dataService: DataService, private fb: FormBuilder, private auth: AuthService) {
    }

    private _budget: Budget;

    get budget() {
        return this._budget;
    }

    @Input() set budget(b: Budget) {
        this._budget = b;

        if (this.marginForm) {
            this.patchForm();
        } else {
            console.log('Margin form not ready yet');
        }
    }

    public _authorizedToModify = false;

    public get authorizedToModify() {
        return this._authorizedToModify;
    }

    @Input()
    public set authorizedToModify(a: boolean) {
        this._authorizedToModify = a;
    }

    ngOnInit() {

        // TODO get Default value from settings
        this.marginForm = this.fb.group({
            generalExpenses: [12, [Validators.min(0), Validators.max(100)]],
            commission: [0, [Validators.min(0), Validators.max(100)]],
            profit: [20, [Validators.min(0), Validators.max(100)]],
            ciTaxesPercentage: [8.875, [Validators.min(0), Validators.max(100)]],
            capitalImprovement: !!this._budget?.export
        });
        this.marginForm.controls['capitalImprovement'].valueChanges.subscribe(value => {
            if (value) {
                this.marginForm.controls['ciTaxesPercentage'].enable();
            } else {
                this.marginForm.controls['ciTaxesPercentage'].disable();
            }
        });
        this.patchForm();

        // Enable auto commit
        // this.onChange();
    }

    patchForm() {

        if (this._budget && this._budget.margin) {

            if (this._budget.margin.generalExpenses !== undefined &&
                this._budget.margin.commission !== undefined &&
                this._budget.margin.profit !== undefined &&
                this._budget.margin.ciTaxesPercentage !== undefined &&
                this._budget.margin.capitalImprovement !== undefined) {
                console.log('margin-form: patch form: ', this._budget);
                this.marginForm.patchValue({
                    generalExpenses: this._budget.margin.generalExpenses,
                    ciTaxesPercentage: this._budget.margin.ciTaxesPercentage,
                    commission: this._budget.margin.commission,
                    profit: this._budget.margin.profit,
                    capitalImprovement: this._budget.margin.capitalImprovement
                });
            }
        }

        if (this._budget &&
            (BudgetModel.isEditable(this._budget, this.auth.currentUser) || this.authorizedToModify)) {
            this.marginForm.enable();
        } else {
            this.marginForm.disable();
        }
    }

    checkDisableCiTax(value: boolean) {
        console.log('margin-form: checkDisableCiTax....', value);
        if (value) {
            this.marginForm.controls['ciTaxesPercentage'].enable();
        } else {
            this.marginForm.controls['ciTaxesPercentage'].disable();
        }

    }

    updateBudget(updateObj: { margin: Margin }) {
        console.log('margin-form: updating budget margin...', updateObj.margin);
        this.formUpdating = true;
        this.marginForm.disable();
        this.dataService.patchBudget(this._budget.id, updateObj)
            .then((data) => {
                console.log('margin-form: Budget margin patched', data);
                this.formUpdating = false;
                this.marginForm.enable();
                this.checkDisableCiTax(this.marginForm.controls['capitalImprovement'].value);
            })
            .catch((err) => {
                console.log('margin-form: ERROR', err);
                this.formUpdating = false;
                this.marginForm.disable();
                this.checkDisableCiTax(this.marginForm.controls['capitalImprovement'].value);
            });
    }

    // private onChange(): void {
    //   this.marginForm.valueChanges
    //     .pipe(
    //       debounce(() => timer(500)),
    //       filter(() => this.marginForm.valid),
    //       distinctUntilChanged((x, y) => {
    //         return (x.generalExpenses === y.generalExpenses &&
    //           x.commission === y.commission &&
    //           x.profit === y.profit &&
    //           x.ciTaxesPercentage === y.ciTaxesPercentage &&
    //           x.capitalImprovement === y.capitalImprovement);
    //       })
    //     )
    //     .subscribe(val => {
    //
    //       const margin: Margin = {
    //         generalExpenses: this.marginForm.value.generalExpenses,
    //         ciTaxesPercentage: this.marginForm.getRawValue().ciTaxesPercentage,
    //         commission: this.marginForm.value.commission,
    //         profit: this.marginForm.value.profit,
    //         capitalImprovement: this.marginForm.value.capitalImprovement
    //       };
    //       this.updateBudget({margin});
    //     });
    // }

    submit() {
        const margin: Margin = {
            generalExpenses: this.marginForm.value.generalExpenses,
            ciTaxesPercentage: this.marginForm.getRawValue().ciTaxesPercentage,
            commission: this.marginForm.value.commission,
            profit: this.marginForm.value.profit,
            capitalImprovement: this.marginForm.value.capitalImprovement
        };
        this.updateBudget({margin});
    }

}

