import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AppError} from '../interfaces/app-error';
import {MatSidenav} from '@angular/material/sidenav';



@Injectable({providedIn: 'root'})
export class AppStateService {

    // Observable string sources
    // private sidebarOpened = false;
    // private sidebarStatusOpen = new BehaviorSubject<boolean>(false);
    // public sidebarStatusOpen$ = this.sidebarStatusOpen.asObservable();

    public sidenav: MatSidenav;

    private errorStatus = new BehaviorSubject<AppError>(null);
    public errorStatus$ = this.errorStatus.asObservable();

    constructor() {
    }

    public confirmDialog = (data: any): Promise<any> => Promise.resolve(); // Function

    public infoDialog = (data: any): Promise<any> => Promise.resolve(); // Function

    public toggleSideBar(newStatus?: boolean) {

        if (this.sidenav) {
            console.log('Toggle sidebar invoked new status: ', newStatus, this.sidenav.opened, this.sidenav.mode, this.sidenav._getWidth());
            return this.sidenav.toggle(newStatus, 'keyboard');
        } else {
            console.log('Sidenav toggle fired but is undefined..');
        }
    }

    public showError(newError: AppError) {
        this.errorStatus.next(newError);
    }

}
