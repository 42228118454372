import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';

import {debounceTime, filter, map, startWith, switchMap} from 'rxjs/operators';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppStateService} from '../../services/app-state.service';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {Order} from '@wondersys/wonderbudget-lib';
import {Ddt} from '../interfaces/ddt';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {Timestamp} from '@firebase/firestore-types';



@Component({
    selector: 'app-ddt-add',
    templateUrl: './ddt-add.component.html',
    styleUrls: ['./ddt-add.component.scss']
})
export class DdtAddComponent implements OnInit, OnDestroy, AfterViewChecked {

    // For the mat-table
    displayedColumns = ['description', 'quantity', 'selected', 'deliveryQuantity'];
    dataSourceItems: ItemsDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Output() ddtAdded = new EventEmitter<any>();
    public filteredOrders: Observable<Partial<Order>[]>;
    public operationInExecution = false;
    public ddtForm: FormGroup;
    public ddtNr: string;
    public currentDdtNr: string;
    public ddtAdding = false;
    public isItems = false;
    public editMode = false;
    public orders = [];
    public disabledSubmit;
    public iOrderId;
    public iDate;
    public iNumber;
    public numberItemSelected;
    public order;
    private itemsStream$ = new BehaviorSubject([]);
    private unsubscribe$ = new Subject();

    constructor(
        private _changeDetectionRef: ChangeDetectorRef,
        private dataService: DataService,
        private authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppStateService) {
    }

    private _ddt;

    get ddt() {
        return this._ddt;
    }

    @Input() set ddt(ddt: Ddt) {
        console.log('Ddt input', ddt);
        this._ddt = ddt;
        if (ddt) {
            console.log('dates: ', (<Timestamp>ddt.ddtDate), moment((<Timestamp>ddt.ddtDate).toDate()).toDate());
            this.editMode = true;
            this.emptyItems();

            const patchDdt: any = {
                ddtNr: ddt.ddtNr,
                orderId: ddt.orderId,
                order: ddt.order,
                items: ddt.items,
                ddtDate: moment((<Timestamp>ddt.ddtDate).toDate()).toDate(),
                note: ddt.note,
                downloadUrl: ddt.downloadUrl
            };

            this.ddtForm.patchValue(patchDdt);
            this.setItemsToDdt(ddt.items);
            this.isItems = (ddt.items.length > 0);
        }
    }

    get ddtFormArray(): FormArray {
        return (this.ddtForm.get('ddt') as FormArray);
    }

    ngAfterViewChecked(): void {
        // your code
        this._changeDetectionRef.detectChanges();
    }

    ngOnInit() {
        this.route.paramMap.pipe(
            filter((params: ParamMap) => params.has('orderId')),
            switchMap((params: ParamMap) => {
                this.iOrderId = params.get('orderId');
                this.iDate = moment(parseInt(params.get('date'))).toDate();
                this.iNumber = params.get('number');
                return this.dataService.getOrder(this.iOrderId)
            })).subscribe((order) => {
            if (!order) {
                this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this order id'});
                this.router.navigate(['/ddt']);
            } else {
                order.id = this.iOrderId;
                this.ddtForm.patchValue({
                    order: order,
                    orderId: this.iOrderId,
                    ddtDate: this.iDate,
                    ddtNr: this.iNumber,
                    fromImport: true
                });
                this.setItemsToDdt(order.items);
            }
        });

        this.ddtForm = new FormGroup({
            ddtNr: new FormControl('', [
                Validators.required
            ]),
            orderId: new FormControl('', [
                Validators.required
            ]),
            order: new FormControl('', [
                Validators.required
            ]),
            items: new FormArray([]),
            ddtDate: new FormControl(),
            note: new FormControl(''),
            downloadUrl: new FormControl(''),
            fromImport: new FormControl(false),
            sentToAccounting: new FormControl(),
            registeredToCosmo: new FormControl()
        });

        this.filteredOrders = this.ddtForm.get('order').valueChanges
            .pipe(
                startWith({} as Order),
                map(order => order && typeof order === 'object' ? order.orderNr : String(order)),
                map(orderNr => this.filterOrder(orderNr))
            );

        // Assign the data to the data source for the table to render
        this.dataSourceItems = new ItemsDataSource(
            this.itemsStream$,
            this.paginator, this.sort);
    }

    loadOrders() {
        if (this.orders.length === 0 && !this.operationInExecution) {
            this.ddtForm.get('order').disable();
            this.operationInExecution = true;
            this.dataService.getLimitedOrderListOnce().subscribe((orders) => {
                console.log('loaded orders list', orders.docs.length);
                this.operationInExecution = false;
                this.ddtForm.get('order').enable();
                this.orders = orders.docs.map(d => {
                    return {...d.data(), id: d.id};
                });
            }, error => {
                console.error(error);
                this.operationInExecution = false;
                this.ddtForm.get('order').enable();
            });
        }
    }

    public getItemsByOrder($event) {
        this.emptyItems();
        if (!$event.option.value) {
            return;
        }

        const itemsFromOrder = $event.option.value.items;
        this.setOrderId($event.option.value.id);
        this.setItemsToDdt(itemsFromOrder);

        this.isItems = true;
    }

    public orderDisplay = (order: Order): string => {
        if (order) {
            return order.orderNr;
        } else {
            return null;
        }
    }

    public changeSelectedItem($event, originalQuantity, index) {
        const itemSelected = $event.checked;
        (<FormArray>this.ddtForm.controls['items']).value[index].selected = itemSelected;
        (<FormArray>this.ddtForm.controls['items']).value[index].deliveryQuantity = itemSelected ? originalQuantity : 0;
        this.numberItemSelected = itemSelected ? this.numberItemSelected = this.numberItemSelected + 1 : this.numberItemSelected = this.numberItemSelected - 1;
    }

    public changeQuantityValue($event, index) {
        const quantity = $event.target.value;
        (<FormArray>this.ddtForm.controls['items']).value[index].deliveryQuantity = !Number.isNaN(quantity) && parseInt(quantity) > 0 ? parseInt(quantity) : 1;
    }

    public getDownloadUrl(evt) {
        return evt.subscribe(url => {
            this.ddtForm.patchValue({downloadUrl: url});
        });
    }

    public getUploadProcess(process) {
        this.disabledSubmit = process;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    submit() {
        console.log('Reactive Form submitted: ', this.ddtForm);
        if (this.ddtForm.valid) {
            // Disable submit button
            this.ddtAdding = true;
            const ddt = <Ddt>this.ddtForm.value;

            // MOMENT DATEPICKER
            if (ddt.ddtDate) {
                ddt.ddtDate = moment(ddt.ddtDate).toDate();
            }

            if (this.editMode) {
                // UPDATE
                var ddtId = this._ddt.id;
                this.dataService.updateDdt(this._ddt.id, ddt)
                    .then((order) => {
                        return this.setOrderDdt(ddtId, ddt)
                    })
                    .then((order) => {
                        return this.updateDdtCompletion(order);
                    })
                    .then((order) => {
                        this.order = order;
                        return this.dataService.updateOrder(order.id, order);
                    })
                    .then((updatedOrder) => {
                        this.router.navigate(['/ddt']);
                    })
                    .catch((err) => {
                        console.log('Error while updating ddt', err);
                        this.ddtAdding = false;
                    });
            } else {
                // ADD
                ddt['searchMode'] = 'quick';
                this.dataService.addDdt(ddt)
                    .then((addedDdt) => {
                        this.ddtAdded.emit(addedDdt);
                        return this.setOrderDdt(addedDdt.id, ddt);
                    })
                    .then((order) => {
                        if (this.iOrderId) {
                            let ddtIndex = 0;
                            order.deliveryNotes.forEach(ddt => {
                                if (!order.deliveryNotesOldProcess) {
                                    order.deliveryNotesOldProcess = {};
                                }
                                if (ddt.deliveryNoteNr == this.iNumber) {
                                    order.deliveryNotesOldProcess[ddtIndex] = order.deliveryNotes[ddtIndex];
                                    order.deliveryNotes.splice(ddtIndex, 1);
                                }
                                ddtIndex = ddtIndex + 1;
                            });
                        }

                        order = this.updateDdtCompletion(order);
                        this.order = order;
                        return this.dataService.updateOrder(order.id, order);
                    })
                    .then((res) => {
                        console.log('Ddt added');
                        this.emptyItems();
                        this.itemsStream$.next([]);
                        this.ddtForm.reset();
                        this.ddtAdding = false;
                        this.isItems = false;
                        if (this.iOrderId) {
                            this.router.navigate(['/order/view', this.iOrderId]);
                        }
                    })
                    .catch((err) => {
                        console.log('Error while adding ddt', err);
                        this.ddtAdding = false;
                    });
            }
        } else {
            console.log('Ddt invalid');
        }
    }

    private filterOrder(val: string): Partial<Order>[] {
        return this.orders
            .filter(option => {
                return option.orderNr.toLowerCase().indexOf(val.toLowerCase()) !== -1;
            });
    }

    private emptyItems() {
        while ((<FormArray>this.ddtForm.get('items')).value.length) {
            (<FormArray>this.ddtForm.get('items')).removeAt(0);
        }
    }

    private setItemsToDdt(items) {
        const itemsControl = <FormArray>this.ddtForm.controls['items'];
        let numberSelected = 0;

        items.forEach(item => {
            const orderItem = {
                description: item.description,
                quantity: item.quantity,
                selected: item.selected ? item.selected : false,
                deliveryQuantity: item.selected ? item.deliveryQuantity : 0
            }

            if (item.selected) numberSelected = numberSelected + 1;
            itemsControl.value.push(orderItem);
        });

        this.numberItemSelected = numberSelected;
        this.itemsStream$.next(itemsControl.value);
    }

    private setOrderId(orderId) {
        this.ddtForm.patchValue({orderId: orderId});
    }

    private _project(projection: object) {
        return function (object: any): Partial<any> {
            const returnObject = {};
            for (const prop in projection) {
                if (prop && (prop in object)) {
                    returnObject[prop] = object[prop];
                }
            }
            return returnObject;
        };
    }

    private setOrderDdt(ddtId, ddt) {
        let currentOrder = ddt.order;
        let itemIndex = 0;

        (this.ddtForm.get('items')).value.forEach(item => {
            if (!currentOrder.items[itemIndex].ddt) {
                currentOrder.items[itemIndex].ddt = [];
            }
            currentOrder = this.cleanDdt(currentOrder, itemIndex, ddtId);
            if (item.selected) {
                const ddtInformation = {
                    ddtId: ddtId,
                    ddtNr: ddt.ddtNr,
                    ddtDate: ddt.ddtDate,
                    deliveryQuantity: 0
                };

                ddtInformation.deliveryQuantity = item.deliveryQuantity;
                currentOrder.items[itemIndex].ddt.push(ddtInformation);
            }

            itemIndex = itemIndex + 1;
        });

        return currentOrder;
    }

    private cleanDdt(order, itemIndex, ddtId) {
        let ddtIndex = 0;

        order.items[itemIndex].ddt.forEach(ddt => {
            if (ddt.ddtId === ddtId) {
                order.items[itemIndex].ddt.splice(ddtIndex, 1);
                return order;
            }

            ddtIndex = ddtIndex + 1;
        });

        return order;
    }

    private updateDdtCompletion(order) {
        const orderItems = order.items;
        let orderDeliveryCompletion = 0;
        let orderTotalQuantity: any = 0;

        orderItems.forEach(item => {
            let itemDeliveryQuantity: any = 0;
            if (item.ddt) {
                item.ddt.forEach(ddt => {
                    itemDeliveryQuantity = parseInt(itemDeliveryQuantity) + parseInt(ddt.deliveryQuantity);
                });
            }

            item.itemDeliveryCompletion = parseInt((itemDeliveryQuantity / item.quantity * 100).toFixed(0));
            orderDeliveryCompletion = orderDeliveryCompletion + itemDeliveryQuantity;
            orderTotalQuantity = parseInt(orderTotalQuantity) + parseInt(item.quantity);
        });

        order.orderDeliveryCompletion = parseInt((orderDeliveryCompletion / orderTotalQuantity * 100).toFixed(0));
        return order;
    }

}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class ItemsDataSource extends DataSource<Order> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable
            // .do((data) => console.log(data))
            .subscribe(this._dataChange);
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Order[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('ddtAddListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('ddtAddListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('ddtAddListSortId');
        const sortDirection = localStorage.getItem('ddtAddListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            const data = this._dataChange.value;

            // Filter
            const fData = data.filter((item) => {
                const searchStr = (item.description);
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: Order[]) => {
        localStorage.setItem('ddtAddListSortId', this._sort.active);
        localStorage.setItem('ddtAddListSortDirection', this._sort.direction);
        // console.log('sort ', this._sort.active, this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: Order, b: Order) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';


            let valueA;
            let valueB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }


}
