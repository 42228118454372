import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../services/data.service';
import {AclUser} from '../../interfaces/app-user';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';



@Component({
    selector: 'app-user-picker',
    template: `<mat-form-field class="f-medium">
  <input type="text" [placeholder]="placeholder" aria-label="Number" matInput [formControl]="myControl"
  [matAutocomplete]="auto" [readonly]="readonly">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option.email }}
      </mat-option>
  </mat-autocomplete>
</mat-form-field>`,
    styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent implements OnInit {

    // @Input() user: AclUser;
    @Input() placeholder: string;
    @Input() myControl: FormControl;
    @Input() readonly = false;
    @Output() userSelected = new EventEmitter();

    // public myControl: FormControl = new FormControl();
    public filteredOptions: Observable<AclUser[]>;

    private userCache = new BehaviorSubject<AclUser[]>([]);

    constructor(private dataService: DataService) {
    }

    ngOnInit() {
        this.myControl.setValidators([this.userExistsValidator]);
        this.dataService.getAppUsers().pipe(
            map(actions => {
                return actions.map((a: any) => {
                    const data = a.payload.doc.data() as AclUser;
                    const id = a.payload.doc.id;
                    return {id, ...data};
                });
            }))
            .subscribe(this.userCache);


        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith({} as AclUser),
                map(user => user && typeof user === 'object' ? user.email : String(user)),
                map(email => email ? this.filter(email) : this.userCache.value.slice())
            );
    }

    displayFn(user: AclUser): string {
        if (user) {
            return user.displayName ? user.displayName : user.email ? user.email : user.id;
        } else {
            return null;
        }
    }

    private userExistsValidator = (control): { [key: string]: any } => {
        if (!control.value) {
            return {'userNotDefined': {value: control.value}};
        }
        return (typeof control.value === 'object') ? null : {'userNotExists': {value: control.value}};
    }

    private filter(val: string): AclUser[] {
        return this.userCache.value
            .filter(option => {
                if (option.email) {
                    return option.email.toLowerCase().indexOf(val.toLowerCase()) === 0;
                } else {
                    return option.id.toLowerCase().indexOf(val.toLowerCase()) === 0;
                }
            });
    }

}
