import {Injectable} from '@angular/core';
import {Timereport} from '../timereport/interfaces';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {DataService} from './data.service';
import {catchError, map, take, tap} from 'rxjs/operators';
import {AppStateService} from './app-state.service';
import {Router} from '@angular/router';



export interface TimereportListFilter {
    filter: string,
    fromDate: Date,
    toDate: Date,
    userEmail?: string | null,
    sortActive?: string,
    sortDirection?: string,
}

export interface TimereportListResponse {
    data: Timereport[],
    totalSize: number
}

@Injectable({
    providedIn: 'root'
})
export class TimereportListTableService {

    private appliedFilterAndSorting: ReplaySubject<TimereportListFilter> = new ReplaySubject();
    private appliedFilter: TimereportListFilter;

    private performingQuickSerch: ReplaySubject<boolean> = new ReplaySubject();
    private performingSlowSearch: ReplaySubject<boolean> = new ReplaySubject();

    private editTimeReportRequest: ReplaySubject<string | null> = new ReplaySubject();

    private _refreshSlowTable: Subject<void> = new Subject<void>();

    constructor(
        private dataService: DataService,
        private appState: AppStateService,
        private router: Router
    ) {
        this.performingQuickSerch.next(true);
        this.performingSlowSearch.next(true);
    }

    public get appliedFilterAndSorting$(): Observable<TimereportListFilter> {
        return this.appliedFilterAndSorting.asObservable();
    }

    public get editTimeReportRequest$(): Observable<string | null> {
        return this.editTimeReportRequest.asObservable();
    }

    public get refreshSlowTable$(): Observable<void> {
        return this._refreshSlowTable.asObservable();
    }

    public loadData(mode: 'quick' | 'slow', pageIndex: number, pageSize: number): Observable<TimereportListResponse> {
        switch (mode) {
            case 'quick':
                return this.getQuickList();
            case 'slow':
                return this.getSlowList(pageIndex, pageSize).pipe(take(1));
        }
    }

    public deleteTimereport(tr: Timereport) {
        return this.appState.confirmDialog({confirmMessage: 'Timereport will be deleted'})
            .then((result) => {
                if (result && result.confirm) {
                    return this.dataService.deleteTimereport(tr);
                } else {
                    return Promise.resolve();
                }
            })
            .then((d) => {
                console.log('Timereport Deleted', d);
                this.appState.infoDialog({infoMessage: 'Timereport deleted'})
            });
    }

    public editTimereport(timereportId: string) {
        //this.router.navigate(['/timereport', tr.id]);
        //return false;

        this.editTimeReportRequest.next(timereportId);
    }

    public cancelTimereportEdit() {
        this.editTimeReportRequest.next(null);
    }

    public isLoading$(mode: 'quick' | 'slow'): Observable<boolean> {
        return (mode === 'quick') ? this.performingQuickSerch.asObservable() : this.performingSlowSearch.asObservable();
    }

    public refreshSlowTable() {
        this._refreshSlowTable.next();
    }

    retrieveFiltersFromStorage(userEmail?: string): TimereportListFilter {

        const filter = {
            filter: (localStorage.getItem('timereportList_filter')) ? localStorage.getItem('timereportList_filter') : null,
            sortActive: (localStorage.getItem('timereportList_sortActive')) ? localStorage.getItem('timereportList_sortActive') : 'date',
            sortDirection: (localStorage.getItem('timereportList_sortDirection')) ? localStorage.getItem('timereportList_sortDirection') : 'desc',
            userEmail: userEmail ? userEmail : null,
            fromDate: null,
            toDate: null
        };

        this.appliedFilter = filter;
        this.appliedFilterAndSorting.next(filter);

        return filter;
    }

    storeFilters(filter: TimereportListFilter) {
        if (this.compareFilters(filter, this.appliedFilter)) {
            localStorage.setItem('timereportList_filter', filter.filter ? filter.filter : '');
            localStorage.setItem('timereportList_sortActive', filter.sortActive);
            localStorage.setItem('timereportList_sortDirection', filter.sortDirection);

            this.appliedFilter = filter;
            this.appliedFilterAndSorting.next(filter);
        }
    }

    storeSorting(sortActive: string, sortDirection: string) {
        this.storeFilters({...this.appliedFilter, sortActive: sortActive, sortDirection: sortDirection});
    }

    /**
     *
     * @param actual
     * @param old
     * @returns true if the filters are different, false if are equals
     */
    compareFilters(actual: TimereportListFilter, old: TimereportListFilter) {
        if (!old) return true;

        return !Object.keys(actual)
            .reduce((a: boolean, k: string) => {
                const checkCondition = (['fromDate', 'toDate'].includes(k)) ?
                    (actual[k]?.getTime() - old[k]?.getTime() === 0) : (actual[k] === old[k]);

                return a && checkCondition;
            }, true);
    }

    private getQuickList(): Observable<TimereportListResponse> {
        console.log('getting quick data with: ', this.appliedFilter)
        this.performingQuickSerch.next(true);
        return this.dataService.getQuickTimereportsList(this.appliedFilter).pipe(
            map((d) => {
                return {data: d, totalSize: d.length}
            }),
            tap(() => this.performingQuickSerch.next(false)),
            catchError((error) => {
                this.performingQuickSerch.next(false);
                console.error('Error getting quick orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }

    private getSlowList(pageIndex: number, pageSize: number): Observable<TimereportListResponse> {
        console.log('getting slow data: ', pageIndex, pageSize)
        let startAt = pageIndex * pageSize;
        if (pageIndex !== 0) startAt++;
        this.performingSlowSearch.next(true);
        return this.dataService.getTimereportsListPaginated(pageSize, startAt, this.appliedFilter).pipe(
            tap(() => this.performingSlowSearch.next(false)),
            catchError((error) => {
                this.performingSlowSearch.next(false);
                console.error('Error getting slow orders list');
                console.error(error);
                return of({data: [], totalSize: 0});
            })
        );
    }
}
