import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material.module';
import {UserPickerComponent} from './components/user-picker.component';
import {ReactiveFormsModule} from '@angular/forms';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {ProjectNrPipe} from './pipes/project-nr.pipe';
import {FileUploadModule} from '../uploads/file-upload/file-upload.module';
import {TimestampPipe} from './pipes/timestamp.pipe';
import {LinkListDialogComponent} from './link-list-dialog/link-list-dialog.component';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FileUploadModule
    ],
    declarations: [
        UserPickerComponent,
        EllipsisPipe,
        ProjectNrPipe,
        TimestampPipe,
        LinkListDialogComponent
    ],
    exports: [
        UserPickerComponent,
        EllipsisPipe,
        ProjectNrPipe,
        FileUploadModule,
        TimestampPipe
    ],
    providers: [
        EllipsisPipe,
        ProjectNrPipe,
        TimestampPipe
    ],
    entryComponents: [
        LinkListDialogComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [TimestampPipe]
        };
    }
}
