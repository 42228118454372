<div class="mat-elevation-z8 table-container">

    <mat-progress-bar *ngIf="tableIsLoading$ | async" mode="indeterminate" style="width:100%"></mat-progress-bar>
    <table [dataSource]="dataSource" mat-table matSort matSortActive="date" matSortDirection="desc" matSortDisableClear
           style="width:100%">

        <ng-container matColumnDef="delivered">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Delivered</th>
            <td *matCellDef="let row" mat-cell>
                <ng-container *ngIf="!row.isInternalOrder; else internalOrderBlock">
                    <mat-icon (click)="openMarkOrderAsDeliveredDialog(row)"
                              *ngIf="!isPending(row); else spinnerBlock"
                              [ngClass]="{'disabledIcon': row.isDelivered}" matTooltip="{{row.isDelivered ? (row.realDeliveryDate | date) : 'Mark as delivered'}}"
                              style="cursor:pointer"
                    >task
                    </mat-icon>
                    <ng-template #spinnerBlock>
                        <mat-spinner color="primary" diameter="20" strokeWidth="4"></mat-spinner>
                    </ng-template>
                </ng-container>
                <ng-template #internalOrderBlock>
                    <mat-icon matTooltip="Internal order" style="color:grey">doorbell</mat-icon>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="orderNr">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Order</th>
            <td *matCellDef="let row" mat-cell>
        <span
                *ngIf="(row.invoices && row.invoices.length > 0) || (row.deliveryNotes && row.deliveryNotes.length > 0)"
                title="There are invoices or delivery notes without an association to their materials">
          <mat-icon color="warn">warning</mat-icon>
        </span>
                {{ row.orderNr }}
            </td>
        </ng-container>

        <ng-container matColumnDef="project">
            <th *matHeaderCellDef mat-header-cell>Project</th>
            <td *matCellDef="let row" mat-cell>
                <div>{{ row.projects?.join(', ') }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="author">
            <th *matHeaderCellDef mat-header-cell>Author</th>
            <td *matCellDef="let row" mat-cell>
                <div>{{ row.author.displayName }}</div>
                <a [href]="'https://mail.google.com/mail/u/0/?view=cm&fs=1&to='+ row.author.email" target="_blank">
          <span class="author-email">
            {{ row.author.email }}
          </span>
                </a>
            </td>
        </ng-container>


        <ng-container matColumnDef="vendor">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Vendor</th>
            <td *matCellDef="let row" mat-cell>
                <div> {{ row.vendor.name }}</div>
                <a *ngIf="row.primaryContact?.emails" [href]="'https://mail.google.com/mail/u/0/?view=cm&fs=1&to='+ row.primaryContact.emails[0].email"
                   target="_blank">
          <span class="author-email">
            {{ row.primaryContact.name }}
          </span>
                    <span class="author-email">
            &#x3C;{{ row.primaryContact.emails[0].email }}&#x3E;
          </span>
                </a>
            </td>
        </ng-container>


        <ng-container matColumnDef="total">
            <th *matHeaderCellDef i18n mat-header-cell mat-sort-header>Total</th>
            <td *matCellDef="let row" mat-cell> {{ row.totalValue | currency:row.currency ? row.currency : 'EUR' }}</td>
        </ng-container>


        <ng-container matColumnDef="date">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Order Date</th>
            <td *matCellDef="let row" mat-cell> {{ row.date | date }}</td>
        </ng-container>

        <ng-container matColumnDef="deliveryDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Delivery Date</th>
            <td *matCellDef="let row" mat-cell> {{ row.deliveryDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="invoiceCompletion">
            <th *matHeaderCellDef i18n mat-header-cell mat-sort-header>Invoice %</th>
            <td *matCellDef="let row" mat-cell>
                <span>{{ row.orderInvoiceCompletion || 0 | percent:'1.0':'it' }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="invoiceRemaining">
            <th *matHeaderCellDef i18n mat-header-cell mat-sort-header>Invoice Remaining</th>
            <td *matCellDef="let row" mat-cell>
                <span>{{ row['invoiceRemaining'] | currency:row.currency ? row.currency : 'EUR' }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="authorized">
            <th *matHeaderCellDef i18n mat-header-cell mat-sort-header>Authorized</th>
            <td *matCellDef="let row" mat-cell>
        <span *ngIf="!row.authorized">
          <mat-icon [title]="row.date" color="primary">done_outline</mat-icon>
        </span>
                <span *ngIf="row.authorized && row.authorized == 'yes'">
          <mat-icon [title]="row.authorizationDate" color="primary">done_outline</mat-icon>
        </span>
                <span *ngIf="row.authorized && row.authorized == 'waiting'">
          <mat-icon title="waiting for authorization">access_time</mat-icon>
        </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="driveUrl">
            <th *matHeaderCellDef i18n mat-header-cell>Drive</th>
            <td *matCellDef="let row" mat-cell>
                <a *ngIf="row.driveUrl && (!row.authorized || row.authorized == 'yes')" [href]="row.driveUrl"
                   mat-icon-button target="_blank">
                    <img alt="Google Drive" src="assets/img/drive.png">
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef i18n mat-header-cell>Actions</th>
            <td *matCellDef="let row" mat-cell>
                <button (click)="prepareOrderPdf(row)"
                        *ngIf="!row.authorized || row.authorized == 'yes'"
                        [disabled]="orderPrepare.get(row.id) || disableAllActions" mat-icon-button>
                    <mat-icon title="create pdf order">mail_outline</mat-icon>
                </button>
                <button (click)="askOrderAuth(row)"
                        *ngIf="row.authorized && row.authorized == 'no'"
                        [disabled]="orderPrepare.get(row.id) || disableAllActions" mat-icon-button>
                    <mat-icon title="ask for authorization">vpn_key</mat-icon>
                </button>
                <a [disabled]="disableAllActions" [routerLink]="['/order/view',row.id]" mat-icon-button>
                    <mat-icon title="open order">visibility</mat-icon>
                </a>
                <button (click)="deleteOrder(row)" *ngIf="!orderHasInvoices(row)"
                        [disabled]="orderPrepare.get(row.id) || disableAllActions"
                        mat-icon-button>
                    <mat-icon title="delete order">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator
            [length]="totalElements"
            [pageSizeOptions]="[10, 20, 50, 100, 500]"
    ></mat-paginator>
</div>
