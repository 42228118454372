import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OrderLIstFilter, OrderListResponse, OrderListTableService} from '../../services/order-list-table.service';
import {Subject, Subscription} from 'rxjs';
import {filter, first, take, takeUntil} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Order} from '@wondersys/wonderbudget-lib';
import {MatDialog} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {DeleteOrderDialogComponent} from '../components/delete-order-dialog/delete-order-dialog.component';
import {AuthService} from '../../services/auth.service';
import {MarkOrderAsDeliveredDialogComponent} from '../mark-order-as-delivered-dialog/mark-order-as-delivered-dialog.component';



@Component({
    selector: 'app-order-list-table',
    templateUrl: './order-list-table.component.html',
    styleUrls: ['./order-list-table.component.scss']
})
export class OrderListTableComponent implements OnInit {

    @Input() searchMode: 'quick' | 'slow';
    @Input() exportRequest: Subject<'quick' | 'slow'>;
    appliedFilter: OrderLIstFilter;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    dataSource = new MatTableDataSource<Order>();
    displayedColumns = [];
    defaultColumns = [
        'orderNr',
        'project',
        'date',
        'deliveryDate',
        'vendor',
        'total',
        'invoiceCompletion',
        'invoiceRemaining',
        'authorized',
        'driveUrl',
        'actions'
    ];
    totalElements = 0;
    public todayDate;
    public dateInSevenDays;
    // Flags
    public orderPrepare = new Map<string, boolean>();
    @Output() updateTotalElements: EventEmitter<number> = new EventEmitter<number>();
    public pendingOperations: string[] = [];
    public disableAllActions = false;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private firstLoad = true;
    private isLoadingData: Subscription;

    constructor(
        private orderListTableService: OrderListTableService,
        public dialog: MatDialog,
        private dataService: DataService,
        private authService: AuthService
    ) {
        this.authService.userHasPermission('app.order.write').pipe(take(1)).subscribe((v) => {
            if (v && !this.displayedColumns.includes('delivered')) {
                this.displayedColumns = ['delivered', ...this.defaultColumns];
            } else {
                this.displayedColumns = this.defaultColumns;
            }
        });
    }

    get tableIsLoading$() {
        return this.orderListTableService.isLoading$(this.searchMode);
    }

    ngOnInit(): void {
        this.paginator.pageSize = 50;

        this.orderListTableService.appliedFilterAndSorting$.pipe(
            filter(newFilter => this.orderListTableService.compareFilters(newFilter, this.appliedFilter)),
            takeUntil(this.unsubscribe$)
        ).subscribe(inputFilter => this.handleFilterChange(inputFilter));

        this.exportRequest.pipe(
            filter(type => this.searchMode === type),
            takeUntil(this.unsubscribe$)
        ).subscribe(() => this.orderListTableService.exportMaterialExcel(this.dataSource.data));

        this.orderListTableService.refreshAllTables$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());

        this.todayDate = this.createTodayDate();
        const todayPlusSevenDays = this.addDays(this.todayDate, 8);
        this.dateInSevenDays = todayPlusSevenDays.getTime();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {
        this.sort.sortChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.paginator.pageIndex = 0;
                this.orderListTableService.storeSorting(this.sort.active, this.sort.direction);
            });

        if (this.searchMode === 'slow') {
            this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.loadData());
        } else {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.quickTableFilterPredicate();
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = this.quickTableSort();
        }
    }

    loadData() {
        console.log(this.searchMode, ' loading data...');

        if (this.searchMode === 'slow') {
            // empty the slow table while waiting for a new response
            this.dataSource = null;
            if (this.isLoadingData) {
                // delete pending requests caused by quick change of the filter/sort
                this.isLoadingData.unsubscribe();
            }
        }

        this.disableAllActions = true;
        this.isLoadingData = this.orderListTableService.loadData(this.searchMode, this.paginator.pageIndex, this.paginator.pageSize)
            .subscribe(result => {
                this.disableAllActions = false;
                if (!result || !result.data) {
                    return;
                }
                for (const order of result.data as any) {

                    const deliveryDateSeconds = (!order.deliveryDate) ? null :
                        (order.deliveryDate._seconds) ? order.deliveryDate._seconds : order.deliveryDate.seconds;
                    order.deliveryDate = order.deliveryDate ? new Date(deliveryDateSeconds * 1000) : null;

                    const dateSeconds = (order.date._seconds) ? order.date._seconds : order.date.seconds;
                    order.date = new Date(dateSeconds * 1000);

                    const realDeliverySeconds = (order.realDeliveryDate) ?
                        (order.realDeliveryDate._seconds) ? order.realDeliveryDate._seconds : order.realDeliveryDate.seconds : null;

                    order.realDeliveryDate = new Date(realDeliverySeconds * 1000);

                    order.urgent = this.isUrgentOrder(order);
                    order.ddtList = this.setDeliveryNotesList(order);
                }
                console.log(this.searchMode, ' received data:', result);
                this.setDataSource(result);
            });
    }

    public deleteOrder(order: Order) {
        const dialogRef = this.dialog.open(DeleteOrderDialogComponent, {
            width: '500px',
            data: {
                order: order
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataService.deleteOrder(order)
                    .then(() => {
                        console.log('Order deleted');
                        this.loadData();
                    })
                    .catch((err) => {
                        console.error('Error while removing order', err.message);
                    });
            } else {
                console.log('not deleted');
            }
        });
    }

    public orderHasInvoices(order: Order) {
        return order.items ? order.items.some(it => {
            return it.invoices && it.invoices.length > 0;
        }) : false;
    }

    public prepareOrderPdf(order: Order) {
        this.orderPrepare.set(order.id, true);
        this.dataService.prepareOrderPdf(order.id).pipe(first())
            .subscribe((res) => {
                    console.log('Order prepared', res);
                    const patchData: any = {};
                    if (res.document && res.document.webViewLink) {
                        patchData.driveUrl = res.document.webViewLink;
                    }
                    if (res.draft && res.draft.message) {
                        patchData.draftMessage = res.draft.message;
                    }
                    this.dataService.patchOrder(order.id, patchData);
                    this.orderPrepare.set(order.id, false);

                },
                (err) => {
                    console.warn('There was an error', err);
                    this.orderPrepare.set(order.id, false);
                });
    }

    public askOrderAuth(order: Order) {
        this.orderPrepare.set(order.id, true);
        this.dataService.askOrderAuth(order).pipe(first())
            .subscribe((res) => {
                    console.log('Authorization asked', res);
                    this.orderPrepare.set(order.id, false);
                    this.loadData();
                },
                (err) => {
                    console.warn('There was an error', err);
                    this.orderPrepare.set(order.id, false);
                });
    }

    createTodayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        return new Date(year + '-' + month + '-' + day).getTime();
    }

    addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    isPendingOrder(order: Order): boolean {
        return (!order.orderDeliveryCompletion || (order.orderDeliveryCompletion && order.orderDeliveryCompletion < 100));
    }

    openMarkOrderAsDeliveredDialog(order: Order) {
        const dialogData = {data: order, height: '230px', width: '400px', disableClose: true};
        const dialogRef = this.dialog.open(MarkOrderAsDeliveredDialogComponent, dialogData);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(`Dialog result: `, new Date(result.realDeliveryDate));
                this.pendingOperations.push(order.id);
                this.orderListTableService.markOrderAsDelivered(order, result.isDelivered, result.realDeliveryDate).then(res => {
                    console.log('res: ', res);
                    this.pendingOperations.splice(this.pendingOperations.indexOf(order.id), 1);
                    this.orderListTableService.forceRefresh();
                }).catch(err => {
                    console.log('err: ', err);
                    this.pendingOperations.splice(this.pendingOperations.indexOf(order.id), 1);
                });
            }
        });
    }

    isPending(order: Order) {
        return this.pendingOperations.find(id => id === order.id);
    }

    quickTableFilterPredicate(): (order: Order, filter: string) => boolean {
        return (order: Order, inputFilter: string) => {
            let orderNrF = true;
            let projectNrF = true;
            let vendorF = true;
            let descriptionF = true;
            let urgent = true;
            let pending = true;
            let waiting = true;
            let delivered = true;

            if (this.appliedFilter.orderNr) {
                orderNrF = order.orderNr && order.orderNr.toLowerCase().includes(this.appliedFilter.orderNr.toLowerCase());
            }
            if (this.appliedFilter.projectNr) {
                projectNrF = order.projects && order.projects.some(p => p.toLowerCase().includes(this.appliedFilter.projectNr.toLowerCase()));
            }
            if (this.appliedFilter.vendor) {
                vendorF = order.vendor && order.vendor.name && order.vendor.name.toLowerCase().includes(this.appliedFilter.vendor.toLowerCase());
            }
            if (this.appliedFilter.itemDescr) {
                descriptionF = order.items && order.items.length > 0 &&
                    order.items.some(i => i.description?.toLowerCase().includes(this.appliedFilter.itemDescr.toLowerCase()));
            }
            if (this.appliedFilter.urgent) {
                urgent = this.isUrgentOrder(order);
            }
            if (this.appliedFilter.pending) {
                pending = this.isPendingOrder(order);
            }
            if (this.appliedFilter.waiting) {
                waiting = order.authorized === 'waiting';
            }
            if (this.appliedFilter.delivered) {
                delivered = (<any>order).isDelivered;
            }

            return orderNrF && projectNrF && vendorF && descriptionF && urgent && pending && waiting && delivered;
        };
    }

    quickTableSort(): (items: Order[], sort: MatSort) => Order[] {
        return (items: Order[], sort: MatSort) => {
            const sortDirection = (sort.direction === 'asc') ? 1 : -1;

            switch (sort.active) {
                case 'orderNr':
                    return items.sort((a, b) => a.orderNr.localeCompare(b.orderNr) * sortDirection);
                case 'vendor':
                    return items.sort((a, b) => a.vendor.name.localeCompare(b.vendor.name) * sortDirection);
                case 'date':
                    return items.sort((a, b) => ((a.date as Date).getTime() - (b.date as Date).getTime()) * sortDirection);
                case 'deliveryDate':
                    return items.sort((a, b) => {
                        const dateA = (a.deliveryDate) ? (a.deliveryDate as Date)?.getTime() : 0;
                        const dateB = (b.deliveryDate) ? (b.deliveryDate as Date)?.getTime() : 0;
                        return (dateA - dateB) * sortDirection;
                    });
                case 'total':
                    return items.sort((a, b) => (a.totalValue - b.totalValue) * sortDirection);
                case 'invoiceCompletion':
                    return items.sort((a, b) => ((a.orderInvoiceCompletion || 0) - (b.orderInvoiceCompletion || 0)) * sortDirection);
                case 'invoiceRemaining':
                    return items.sort((a, b) => (a['invoiceRemaining'] - b['invoiceRemaining']) * sortDirection);
                case 'authorized':
                    return items.sort((a, b) => {
                        const authA = (a.authorized) ? a.authorized : 'yes';
                        const authB = (b.authorized) ? b.authorized : 'yes';
                        return authA.localeCompare(authB) * sortDirection;
                    });
                case 'delivered':
                    return items.sort((a, b) => {
                        const valueA = (<any>a).isInternalOrder ? '3' : (<any>a).isDelivered ? '1' : '2';
                        const valueB = (<any>b).isInternalOrder ? '3' : (<any>b).isDelivered ? '1' : '2';
                        return valueA.localeCompare(valueB) * sortDirection;
                    });
                default:
                    return items;
            }
        };
    }

    private handleFilterChange(inputFilter: OrderLIstFilter) {
        this.restoreSorting(inputFilter);
        this.appliedFilter = {...inputFilter};
        this.paginator.pageIndex = 0;

        // for the slow table dataSource.filter is not meaningful
        if (this.searchMode === 'quick') {
            this.dataSource.filter = JSON.stringify(inputFilter);
        }

        // for the quick table load data only once
        if (this.searchMode === 'slow' || this.firstLoad) {
            this.loadData();
        }
    }

    private restoreSorting(inputFilter: OrderLIstFilter) {
        const needRefresh = this.sort.active !== inputFilter.sortActive || this.sort.direction !== inputFilter.sortDirection;
        this.sort.active = (inputFilter.sortActive) ? inputFilter.sortActive : 'date';
        this.sort.direction = (inputFilter.sortDirection) ? inputFilter.sortDirection as SortDirection : 'desc';
        if (needRefresh) {
            this.sort.sortChange.emit();
        }
    }

    private setDataSource(response: OrderListResponse) {
        if (this.searchMode === 'quick') {
            this.dataSource.data = response.data as any;
        } else {
            this.dataSource = response.data as any;
        }

        this.totalElements = response.totalSize;
        this.paginator.length = response.totalSize;

        if (this.firstLoad) {
            this.firstLoad = false;
            // this will force the table to apply the initial filter and update the paginator values
            setTimeout(() => this.dataSource.filter = JSON.stringify(this.appliedFilter));
        }

        this.updateTotalElements.emit(this.totalElements);
    }

    private isUrgentOrder(order) {
        return (order.deliveryDate && order.deliveryDate >= this.todayDate && order.deliveryDate <= this.dateInSevenDays) &&
            (!order.orderDeliveryCompletion || order.orderDeliveryCompletion < 100);
    }

    private setDeliveryNotesList(order) {
        let ddtList = [];
        if (order.items) {
            ddtList = this.setDdtList(order.items);
        }

        // only unique ddt
        return this.setFilteredDdtList(ddtList);
    }

    private setDdtList(items) {
        const ddtList = [];
        items.forEach(item => {
            if (item.ddt && item.ddt.length > 0) {
                item.ddt.forEach(ddt => {
                    const ddtDetail = {
                        ddtId: ddt.ddtId,
                        ddtNr: ddt.ddtNr,
                        ddtDate: ddt.ddtDate
                    };
                    ddtList.push(ddtDetail);
                });
            }
        });

        return ddtList;
    }

    private setFilteredDdtList(ddtList) {
        const filteredDdtList = Array.from(new Set(ddtList.map(ddt => ddt.ddtId)))
            .map(ddtId => {
                return {
                    ddtId: ddtId,
                    ddtNr: ddtList.find(ddt => ddt.ddtId === ddtId).ddtNr,
                    ddtDate: ddtList.find(ddt => ddt.ddtId === ddtId).ddtDate
                };
            });

        return filteredDdtList;
    }
}
