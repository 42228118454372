import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material.module';
import {FileUploadComponent} from './file-upload.component';
import {DropZoneDirective} from '../../directives/drop-zone.directive';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule
    ],
    declarations: [
        FileUploadComponent,
        DropZoneDirective
    ],
    exports: [FileUploadComponent]
})
export class FileUploadModule {
}