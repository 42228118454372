export interface Material {
    id?: string;
    category: string; // Competenza
    description: string;
    costEach: number;
    quantity: number;
    amount?: number;
    finalAmount?: number;
    unit?: string;
    budgetId?: string;
    finalQuantity?: number;
    processedOrders?: string[];
    matrixCode?: string;
    marking?: string;
    hasOrders?: boolean;
}
