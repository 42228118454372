import {merge} from 'rxjs';

import {map, debounceTime} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {Observable, BehaviorSubject} from 'rxjs';
import {DataSource} from '@angular/cdk/collections';
import {Budget} from '../budget/interfaces';
import {DataService} from '../services/data.service';
import {Router} from '@angular/router';



@Component({
    selector: 'app-internal-project-list',
    templateUrl: './internal-project-list.component.html',
    styleUrls: ['./internal-project-list.component.scss']
})
export class InternalProjectListComponent implements OnInit {
    // For the mat-table
    displayedColumns = ['projectNr', 'description', 'total', 'final', 'date', 'actions'];
    dataSource: BudgetDataSource;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    private budgetStream$;

    constructor(private dataService: DataService, private router: Router) {
    }


    ngOnInit() {

        this.budgetStream$ = this.dataService.getInternalProjects();
        // Assign the data to the data source for the table to render
        this.dataSource = new BudgetDataSource(
            this.budgetStream$,
            this.paginator, this.sort);

    }

    public deleteProject(id: string) {
        this.dataService.deleteBudget(id).subscribe(() => {
            console.log('Budget deleted');
        });
    }

    public getListFilter() {
        let listFilter = localStorage.getItem('InternalProjectListFilter') ? localStorage.getItem('InternalProjectListFilter') : '';
        this.applyFilter(listFilter);
        return listFilter;
    }

    public setListFilter(filterName, filterValue) {
        localStorage.setItem(filterName, filterValue);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.setListFilter('InternalProjectListFilter', filterValue);
    }

    public goToBudget(row: Budget) {
        const dest = '/budget/' + row.id;
        this.router.navigate([dest]);
    }

}


// Thanks to:
// https://stackoverflow.com/questions/47230980/angular-material-data-table-with-filtering-using-firestore

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class BudgetDataSource extends DataSource<Budget> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable.subscribe(this._dataChange);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Budget[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('internalProjectListPageSize')))
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            localStorage.setItem('internalProjectListPageSize', paginationData.pageSize.toString());
        })

        const sortId = localStorage.getItem('internalProjectListSortId');
        const sortDirection = localStorage.getItem('internalProjectListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            // console.log('Combine ', data, filter, page);
            const data = this._dataChange.value;
            // Filter
            const fData = data.filter((item: Budget) => {
                let searchStr = item.budgetNr + item.author;
                if (item.project) {
                    searchStr += item.project.projectNr;
                }
                searchStr = searchStr.toLowerCase();
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (budget: Budget[]) => {
        // console.log('sort ', this._sort.active, this._sort.direction);

        localStorage.setItem('internalProjectListSortId', this._sort.active);
        localStorage.setItem('internalProjectListSortDirection', this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        budget.sort((a, b) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                case 'projectNr':
                    propertyA = a.project ? a.project.projectNr : '';
                    propertyB = b.project ? b.project.projectNr : '';
                    break;
                case 'author':
                    [propertyA, propertyB] = [a.author.displayName, b.author.displayName];
                    break;
                case 'category':
                    [propertyA, propertyB] = [a.category, b.category];
                    break;
                case 'creation':
                    [propertyA, propertyB] = [a.creation, b.creation];
                    break;
            }

            let valueA;
            let valueB;

            if (propertyA instanceof Date) {
                valueA = propertyA;
                valueB = propertyB;
            } else {
                valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            }

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
