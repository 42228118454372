<mat-card>
    <form [formGroup]="invoicesForm">
        <mat-card-header>
            <mat-card-title>
                <h4>
                    <div *ngIf="editMode; else newInvoices">
                        <span i18n>Invoice</span>&nbsp;
                        <span>{{ currentInvoicesNr }}</span>
                    </div>
                    <ng-template #newInvoices>
                        <span i18n>New Invoice</span>&nbsp;
                    </ng-template>
                </h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="invoices-container">
                <div class="invoices-meta">
                    <div class="invoices-meta-form-left">
                        <div>
                            <mat-form-field style="width:100px !important">
                                <input [disabled]="operationInExecution" formControlName="invoiceNr" i18n-placeholder
                                       matInput placeholder="Invoice Nr"
                                       type="text">
                            </mat-form-field>
                            /
                            <mat-form-field style="width:20px !important; margin-right: 45px !important">
                                <input formControlName="invoiceNr2" i18n-placeholder matInput maxlength="2" type="text">
                            </mat-form-field>
                            <mat-form-field class="mat-form-field-lg">
                                <input (focus)="loadOrders()" [matAutocomplete]="auto" formControlName="order" matInput
                                       placeholder="Order"
                                       type="text">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOrderSelected($event)"
                                                  [displayWith]="orderDisplay">
                                    <mat-option *ngFor="let order of filteredOrders$ | async" [disabled]="order.authorized !== 'yes'"
                                                [value]="order">
                                        {{ order.orderNr }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field style="max-width: 150px; padding-left: 5px;">
                                <input (click)="invoiceDatePicker.open()" (focus)="invoiceDatePicker.open()" [matDatepicker]="invoiceDatePicker"
                                       formControlName="invoiceDate"
                                       matInput placeholder="Invoice Date"
                                       type="text">
                                <mat-datepicker-toggle [for]="invoiceDatePicker" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #invoiceDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field style="width:540px !important">
                <textarea class="form-control" formControlName="note" i18n-placeholder matAutosizeMaxRows="120"
                          matAutosizeMinRows="3" matInput matTextareaAutosize
                          placeholder="Description"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="invoice-total">
                            <strong>Importo: {{ invoiceTotalAmount | async | currency:'EUR' }}</strong>
                        </div>
                        <div>
                            <app-file-upload (uploadProcess)="getUploadProcess($event)"
                                             (url)="getDownloadUrl($event)"
                                             *ngIf="(userIsInvoicesAdmin | async) || !invoiceSentToAccounting"></app-file-upload>
                            <a *ngIf="invoicesForm.get('downloadUrl').value"
                               [href]="invoicesForm.get('downloadUrl').value"
                               class="invoiceFile" mat-button target="_blank">
                                <mat-icon matListIcon>attach_file</mat-icon>
                                <span class="menu-label" i18n="Invoice file">See file</span>
                            </a>
                        </div>
                        <div>
                            <p *ngIf="!vendorHasNda && vendorNdaFieldIsSet" class="message">Vendor NDA file is set in
                                the CRM but your
                                user cannot access it</p>
                            <p *ngIf="!vendorHasNda && !vendorNdaFieldIsSet" class="message">Vendor has no NDA file</p>
                            <a *ngIf="vendorHasNda" href="{{ndaFile?.result?.webViewLink}}" target="_blank">Open NDA
                                file</a>
                            <p *ngIf="(userIsInvoicesAdmin | async)" class="message">Your user has Invoice Admin role,
                                so you can
                                update invoices even though they have been sent to accounting. Please be careful.</p>
                        </div>
                        <mat-card-actions>
                            <button (click)="submitInvoice()" [disabled]="!(userIsInvoicesAdmin | async) && (invoiceSentToAccounting ||
                                                                                invoicesForm.invalid ||
                                                                                invoicesAdding ||
                                                                                disabledSubmit ||
                                                                                numberItemSelected == 0 ||
                                                                                !vendorHasNda )" mat-raised-button type="submit">
                                <span *ngIf="editMode; else elseBlock" i18n>Update</span>
                                <ng-template #elseBlock>
                                    <span i18n>Submit</span>
                                </ng-template>
                            </button>
                            <a [routerLink]="['/invoices']" mat-raised-button>
                                <span>Cancel</span>
                            </a>

                            <button (click)="sendToAccounting()"
                                    *ngIf="(userIsInvoicesAdmin | async) || (editMode && !invoiceSentToAccounting && cosmoEnabled)"
                                    [disabled]="invoicesAdding || disabledSubmit" mat-raised-button
                                    type="button">
                                <span i18n>Send To Accounting</span>
                            </button>
                        </mat-card-actions>
                        <div *ngIf="invoiceLog | async as invoiceLog">
                            <h4 *ngIf="invoiceLog.length > 0">Error log:</h4>
                            <ul>
                                <li *ngFor="let log of invoiceLog">
                                    {{ log.date.toDate().toLocaleString() }} - {{ log.message }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="invoices-meta-form-right">
                        <div class="invoices-entry-array">
                            <h5>Materials: </h5>

                            <div class="example-container mat-elevation-z8">
                                <mat-table [dataSource]="displayedItems" matSort>

                                    <ng-container matColumnDef="selected">
                                        <mat-header-cell *matHeaderCellDef i18n>In this invoice?</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <mat-checkbox
                                                    (change)="toggleSelection($event, row)"
                                                    [checked]="row.selected"
                                                    [disabled]="!(userIsInvoicesAdmin | async) && (invoiceSentToAccounting || row.itemDisabled)"></mat-checkbox>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="description">
                                        <mat-header-cell *matHeaderCellDef i18n>Description</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.description | ellipsis:100 }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="descriptionCopy">
                                        <mat-header-cell *matHeaderCellDef i18n>Descr. copy</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">
                                            <mat-checkbox
                                                    (change)="copySelectedDescription($event, row.id)"
                                                    [checked]="row.copyDesc"
                                                    [disabled]="!(userIsInvoicesAdmin | async) && (invoiceSentToAccounting || !row.selected || row.itemDisabled)"></mat-checkbox>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="invoiceDescription">
                                        <mat-header-cell *matHeaderCellDef i18n>Invoice Description</mat-header-cell>
                                        <mat-cell *matCellDef="let row">
                      <textarea [disabled]="!(userIsInvoicesAdmin | async) && (invoiceSentToAccounting || !row.selected || row.itemDisabled)"
                                [value]="row.invoiceDescription"
                                class="form-control" i18n-placeholder matAutosizeMaxRows="2"
                                matAutosizeMinRows="1" matInput matTextareaAutosize></textarea>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="quantity">
                                        <mat-header-cell *matHeaderCellDef i18n>Order Quantity</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.quantity }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="orderQuantity">
                                        <mat-header-cell *matHeaderCellDef i18n>Invoice Quantity</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.invoiceQuantity }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="costEach">
                                        <mat-header-cell *matHeaderCellDef i18n>Price per unit</mat-header-cell>
                                        <mat-cell *matCellDef="let row"> {{ row.costEach | currency:'EUR' }}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="amount">
                                        <mat-header-cell *matHeaderCellDef i18n>Invoice Amount</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">

                                            <input
                                                    (change)="changeItemAmount($event.target.value, row.id)"
                                                    *ngIf="row.selected && (!invoiceSentToAccounting || (userIsInvoicesAdmin | async)) && !(row.itemDisabled && !(userIsInvoicesAdmin | async)); else noEdit" [value]="row.amount"
                                                    class="no-spinner"
                                                    matInput min="0" type="number">
                                            <ng-template #noEdit>
                        <span (click)="transferAmount(row)"
                              [ngClass]="{'unused': row.amount !== 0}">{{ row.amount | currency:'EUR' }}</span>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="toBeInvoiced">
                                        <mat-header-cell *matHeaderCellDef i18n>To be Invoiced</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">
                                            {{ row.toBeInvoiced | currency:'EUR' }}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="orderAmount">
                                        <mat-header-cell *matHeaderCellDef i18n>Order Amount</mat-header-cell>
                                        <mat-cell *matCellDef="let row; let i = index">
                                            {{ row.orderAmount | currency:'EUR' }}
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns">
                                    </mat-row>
                                </mat-table>

                                <mat-paginator [pageSizeOptions]="[100]"></mat-paginator>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </form>
</mat-card>
