import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OpenProjectDialogComponent} from '../open-project-dialog/open-project-dialog.component';
import {DataService} from '../../services/data.service';
import {first} from 'rxjs/operators';
import {ContactPickerDialogComponent} from '../contact-picker-dialog/contact-picker-dialog.component';



@Component({
    selector: 'app-document-editor-dialog-component',
    templateUrl: 'doc-editor-dialog.component.html',
    styleUrls: ['doc-editor-dialog.component.scss']
})
export class DocumentEditorDialogComponent implements OnInit {

    public imageSelectorOpen = false;
    public products = [{name: 'Michelangelo', templateId: '1oVrVAQPF5-h1knr2jwRoaFtDFZTrLsqEAyfCJmg5Nqw'},
        {name: 'Galileo', templateId: '1p0LFFhISweRPxo19lvoycaXeAhArP7ybOpeEnuJZdo8'},
        {name: 'Leonardo', templateId: '19XcMAg1YjM9MHBEiIPXlqGvTMbvRY6zIHFEbCIPukZY'}
    ];
    public productSelected;
    public images = [];

    public imageList = [];

    constructor(
        public dialogRef: MatDialogRef<ContactPickerDialogComponent | OpenProjectDialogComponent | DocumentEditorDialogComponent>,
        private dataService: DataService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {

        this.products = this.data.templates;
        this.dataService.getBudgetImages(this.data.budgetFolder, this.data.imageFolderPath).pipe(first()).subscribe((data) => {
            console.log('images', data);

            if (data) {
                this.images = data.files.map(img => {
                    return {
                        src: img.thumbnailLink,
                        title: img.name,
                        fileId: img.id
                    };
                });
            }
        });
    }

    public toggleImageSelector(el: HTMLElement) {

        this.imageSelectorOpen = !this.imageSelectorOpen;
        setTimeout(() => {
            el.scrollIntoView({behavior: 'smooth'});
        }, 400);

    }

    public closeImageSelector() {
        this.imageSelectorOpen = false;
    }


    public addImage(i) {
        const newImage = Object.assign({}, this.images[i]);
        this.imageList.push(newImage);
        this.closeImageSelector();
    }

    public removeImage(i) {
        this.imageList.splice(i, 1);
    }

    save() {
        console.log('Closing dialog: ', this.productSelected, this.imageList);
        this.dialogRef.close({
            productSelected: this.productSelected,
            imageList: this.imageList
        });
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

}
