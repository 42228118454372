import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialsListComponent} from './materials-list.component';
import {BalancesGuard} from './balances.guard';
import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {ActivitiesListComponent} from './activities-list.component';
import {BalanceGraphComponent} from './balance-graph.component';
import {SummaryBalanceComponent} from './summary-balance.component';
import {BudgetService} from '../services/budget.service';
import {MaterialsBalanceTableComponent} from './components/materials-balance-table/materials-balance-table.component';
import {ActivityBalanceTableComponent} from './components/activity-balance-table/activity-balance-table.component';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        RouterModule
    ],
    declarations: [MaterialsListComponent, ActivitiesListComponent, BalanceGraphComponent, SummaryBalanceComponent, MaterialsBalanceTableComponent, ActivityBalanceTableComponent],
    providers: [BalancesGuard, BudgetService]
})
export class BalancesModule {
}
