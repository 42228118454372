import {switchMap, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BudgetService, SummaryBalanceData} from '../services/budget.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {DataService} from '../services/data.service';
import {Budget, SplittedCostTableElement} from '../budget/interfaces';
import {BudgetModel} from '../budget/models/budget-model';



@Component({
    selector: 'app-summary-balance',
    templateUrl: './summary-balance.component.html',
    styleUrls: ['./summary-balance.component.scss']
})
export class SummaryBalanceComponent implements OnInit, OnDestroy {

    public splittedCostsTableData: SplittedCostTableElement[] = [];
    public budget$: Observable<Budget>;
    private subscription: Subscription;
    private unsubscribe$ = new Subject();

    private _summary: ReplaySubject<SummaryBalanceData> = new ReplaySubject(1);

    constructor(private dataService: DataService,
                private budgetService: BudgetService,
                private route: ActivatedRoute) {
    }

    private _budgetId: string;

    get budgetId() {
        return this._budgetId;
    }

    get summary$() {
        return this._summary.asObservable();
    }

    get budgetCommission(): number {
        if (!this.splittedCostsTableData || this.splittedCostsTableData.length === 0) return 0;
        const totalRow = this.splittedCostsTableData.find(i => i.id === 'total');
        return totalRow ? totalRow.commission : 0;
    }

    get consuntivoCommissions(): number { // TODO ?
        if (!this.splittedCostsTableData || this.splittedCostsTableData.length === 0) return 0;
        const totalRow = this.splittedCostsTableData.find(i => i.id === 'total');
        return totalRow ? totalRow.commission : 0;
    }

    get budgetTaxes(): number {
        if (!this.splittedCostsTableData || this.splittedCostsTableData.length === 0) return 0;
        const totalRow = this.splittedCostsTableData.find(i => i.id === 'total');
        return totalRow ? totalRow.taxes : 0;
    }

    get consuntivoTaxes(): number { // TODO ?
        if (!this.splittedCostsTableData || this.splittedCostsTableData.length === 0) return 0;
        const totalRow = this.splittedCostsTableData.find(i => i.id === 'total');
        return totalRow ? totalRow.taxes : 0;
    }


    ngOnInit() {

        this.budget$ = this.route.paramMap.pipe(
            switchMap((params) => {
                const budgetId = params.get('budgetId');
                return this.dataService.getSimpleBudget(budgetId);
            }),
            takeUntil(this.unsubscribe$));

        this.subscription = this.route.paramMap.pipe(
            switchMap((params) => {
                this._budgetId = params.get('budgetId');
                return this.budgetService.getSummaryBalanceByBudget(this._budgetId);
            }))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(this._summary);

        this.budget$.subscribe(b => {
            if (!b.export) {
                // ITA budget
                this.splittedCostsTableData = BudgetModel.recalculateItaSplittedCostsTable(b);
            } else {
                // USA budget
                this.dataService.getBudget(b.export.from.id)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((bITA) => {
                        const splittedCostsTableDataIta = BudgetModel.recalculateItaSplittedCostsTable(bITA);
                        const result = BudgetModel.recalculateUsaSplittedCostsTable(b, splittedCostsTableDataIta);
                        this.splittedCostsTableData = result.splittedCostsTableDataExport;
                    });
            }
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    compare(a: number, b: number) {
        return a > b;
    }

}
