export const calculateCustomerFromHostname = (hostname: string) => {
    let CUSTOMER_DOMAIN = '';
    if (!hostname) {
        console.warn('No hostname provided.');
        return CUSTOMER_DOMAIN;
    }

    const token = hostname.split('.');
    switch (token[0]) {
        case 'localhost':
            CUSTOMER_DOMAIN = 'extravega.com';
            // this.CUSTOMER_DOMAIN = 'wonder-sys.com';
            break;
        case 'wondersys':
            CUSTOMER_DOMAIN = 'wonder-sys.com';
            break;
        case 'extravega':
            CUSTOMER_DOMAIN = 'extravega.com';
            break;
        case 'tis':
            CUSTOMER_DOMAIN = 'theitalianspace.com';
            break;
        case 'extraglass':
            CUSTOMER_DOMAIN = 'extraglass.it';
            break;
        default:
            CUSTOMER_DOMAIN = '';
    }

    // Special cases
    if (hostname === 'evtest.wonder-sys.com' || hostname === 'tistest.wonder-sys.com') {
        CUSTOMER_DOMAIN = 'theitalianspace.com';
    } else if (hostname === 'app.extravega.com') {
        CUSTOMER_DOMAIN = 'extravega.com';
    } else if (hostname === 'extravega.wonder-sys.com') {
        CUSTOMER_DOMAIN = 'wonder-sys.com';
    } else if (hostname.includes('extraglass')) {
        CUSTOMER_DOMAIN = 'extraglass.it';
    } else if (hostname.includes('localhost')) {
        CUSTOMER_DOMAIN = 'extravega.com';
    }

    console.log('CUSTOMER_DOMAIN: ', CUSTOMER_DOMAIN);
    return CUSTOMER_DOMAIN;

}