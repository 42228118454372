<div *ngIf="canChange" class="give-margin-cell row-center">

    <div class="column">
        <p>Insert new projects:</p>
        <mat-form-field class="mat-form-field-lg">
            <input [formControl]="selectProject" [matAutocomplete]="autoBudget" matInput placeholder="Project (Budget) (Customer)"
                   type="text">
            <mat-autocomplete #autoBudget="matAutocomplete" (optionSelected)="selectedProject($event)"
                              [displayWith]="budgetDisplayFn">
                <mat-option *ngFor="let project of options" [value]="project">
                    <div>
                        <ng-template [ngIfElse]="companyName" [ngIf]="project.prosperworks">
                            <span>{{ project.prosperworks.company_name | ellipsis:20 }}-</span>
                        </ng-template>
                        <ng-template #companyName>
                            <span *ngIf="project.companyName">{{ project.companyName | ellipsis:20 }}&nbsp;-</span>
                        </ng-template>
                        <span *ngIf="project.project">{{ project | projectNr }}&nbsp;-</span>{{ project.budgetNr }}
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="column">
        <p>Insert time interval (milliseconds):</p>
        <mat-form-field>
            <input [formControl]="timeInterval" aria-label="Number" i18n-placeholder matInput placeholder="Interval"
                   type="number">
        </mat-form-field>
    </div>


</div>

<app-workers-summary-list [canChange]="canChange" [timeInterval]="timeInterval.value"></app-workers-summary-list>
