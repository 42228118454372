import {Component, OnInit} from '@angular/core';



@Component({
    selector: 'app-balance-graph',
    templateUrl: './balance-graph.component.html',
    styleUrls: ['./balance-graph.component.scss']
})
export class BalanceGraphComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
