<div class="row-center" style="margin-top:5px;">
    <button (click)="goFullscreen()" color="primary" i18n mat-raised-button type="button">Launch fullscreen</button>
</div>


<div id="container">

    <div *ngFor="let summary of displayedResumes">

        <mat-card [ngClass]="fullScreenActive ? 'custom-card-fs': 'custom-card'">
            <mat-card-subtitle>{{ summary.budgetNr }}</mat-card-subtitle>
            <mat-card-title>

                <div class="row-distance">

                    <div>
                        Commessa: <b>{{ summary.projectNr }}</b>
                        <span *ngIf="summary.client">
                 ( <i>{{ summary.client }}</i> )
               </span>

                    </div>

                    <div class="row-center">
                        <p><i>{{ summary.description }}</i></p>
                        <div *ngIf="canChange" class="ml-1">
                            <button (click)="removeItem(summary.budgetId)" style="background-color: red;">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>

                    </div>

                </div>

            </mat-card-title>
            <mat-card-content style="margin-bottom:0">

                <!-- content here -->
                <div class="give-margin-cell-reversed">

                    <table [dataSource]="summary.report" mat-table style="width:100%">

                        <ng-container matColumnDef="ppm">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Progettazione e programmi</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Progettazione e programmi macchina']?.cssClass}}">
                     {{ element['Progettazione e programmi macchina']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="comm">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Gestione</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Gestione commessa']?.cssClass}}">
                     {{ element['Gestione commessa']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ces">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Cesoiatura</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>

                                <span class="{{ element['Cesoiatura']?.cssClass}}">
                     {{ element['Cesoiatura']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="form">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Formazione</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>

                                <span class="{{ element['Formazione e spiegaz. comm.']?.cssClass}}">
                     {{ element['Formazione e spiegaz. comm.']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="pieg">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Piegatura</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Piegatura']?.cssClass}}">
                     {{ element['Piegatura']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="laser">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Laser</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Laser/punzonatrice']?.cssClass}}">
                     {{ element['Laser/punzonatrice']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="asm">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Assiemaggio, saldatura e
                                molatura
                            </th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Assiemaggio, saldatura e molatura']?.cssClass}}">
                     {{ element['Assiemaggio, saldatura e molatura']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="mon">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Montaggio</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Montaggio']?.cssClass}}">
                     {{ element['Montaggio']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="trasp">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Trasporto</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Trasporto (a mezzo interno)']?.cssClass}}">
                     {{ element['Trasporto (a mezzo interno)']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="mic">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Magazzino</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Magazzino, imballo, carico']?.cssClass}}">
                     {{ element['Magazzino, imballo, carico']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="pul">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Pulizia</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Pulizia']?.cssClass}}">
                     {{ element['Pulizia']?.value }}
                   </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="contr">
                            <th *matHeaderCellDef class="custom-cell" mat-header-cell> Controllo qualità</th>
                            <td *matCellDef="let element; let i = index;" class="custom-cell" mat-cell>
                                Ore {{ i === 0 ? 'budget' : 'utilizzate' }}:<br>
                                <span class="{{ element['Controllo qualità in/out']?.cssClass}}">
                     {{ element['Controllo qualità in/out']?.value }}
                   </span>
                            </td>
                        </ng-container>

                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                    </table>

                </div>

            </mat-card-content>
            <!-- <mat-divider inset></mat-divider> -->

        </mat-card>

    </div>

    <div *ngIf="!resumes || resumes.length === 0" class="row-center">
        <p>Nessuna commessa selezionata</p>
    </div>

</div>
