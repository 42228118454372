import {BehaviorSubject, merge, Observable, Subscription} from 'rxjs';

import {debounceTime, map, take} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {Order} from '@wondersys/wonderbudget-lib';
import {MaterialItem} from '../../interfaces/material-item';
import {OrderEntryComponent} from '../../../order/components/order-entry.component';
import {DataService} from '../../../services/data.service';
import {AngularCsv} from 'angular7-csv';
import {DataSource} from '@angular/cdk/table';



@Component({
    selector: 'app-materials-balance-table',
    templateUrl: './materials-balance-table.component.html',
    styleUrls: ['./materials-balance-table.component.scss']
})
export class MaterialsBalanceTableComponent implements OnInit, OnDestroy {

    @Input() budgetId;
    @Output() dataChanged = new EventEmitter;

    public triggerRefresh = new BehaviorSubject<boolean>(false);
    public materialList$: Observable<any[]>;
    public materialTotalCost: number;
    public currency: string = 'EUR';
    public materialTotal = 0;
    // For the mat-table
    displayedColumns = ['orderNr', 'date', 'vendor', 'author', 'unit', 'quantity', 'costEach', 'total', 'description'];
    dataSource: MaterialDataSource;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    private materialList = new BehaviorSubject<Order[]>([]);
    private subscription: Subscription;
    private renderedData: any;

    constructor(
        private dataService: DataService) {
    }

    get filteredTotal() {
        return this.dataSource.filteredTotal;
    }

    ngOnInit() {
        this.subscription = this.dataService.getOrdersByBudget(this.budgetId).pipe(
            map((orders: Order[]) => {
                return orders
                    .filter(o => o.deleted !== true);
            }))
            .subscribe(this.materialList);

        this.dataService.getBudgetCurrency(this.budgetId).pipe(take(1)).subscribe(c => this.currency = c);

        this.materialList$ = this.materialList.pipe(
            map((orders: Order[]) => {

                const flatMat = [];
                let grandTotal = 0;
                for (const order of orders) {
                    for (const item of order.items) {
                        if (item.budget.id === this.budgetId) {
                            const el: MaterialItem = {
                                item,
                                orderId: order.id,
                                orderNr: order.orderNr,
                                date: <Date>order.date,
                                deleted: order.deleted,
                                author: order.author.displayName,
                                vendor: order.vendor.name,
                                total: OrderEntryComponent.calculateEntryValue(item.costEach, item.quantity, item.discount)
                            };
                            grandTotal += el.total;
                            flatMat.push(el);
                        }

                    }
                }
                this.materialTotal = grandTotal;

                // const s3 = this.materialList$.map((list) =>  list.map(i => i.total).reduce((a, b) => a + b, 0))
                // .subscribe(c => {
                //   this.materialTotalCost = c;
                // });

                // this.subscription.add(s3);
                this.renderedData = flatMat;
                return flatMat;
            }));

        // Assign the data to the data source for the table to render
        this.dataSource = new MaterialDataSource(
            this.materialList$,
            this.paginator, this.sort);

        const s2 = this.dataSource.connect().pipe(
            map(d => d.map((row) => ({
                    orderId: row.orderId,
                    orderNr: row.orderNr,
                    budgetId: row.item.budget.id,
                    budgetNr: row.item.budget.budgetNr,
                    description: row.item.description,
                    price: +row.item.costEach,
                    quantity: +row.item.quantity,
                    total: +row.total
                }))
            ))
            .subscribe(d => {
                // console.log(d[0]);
                this.dataChanged.emit(d);
            });


        this.subscription.add(s2);


    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    exportCsv() {
        const data = this.renderedData.map(d => {
            return {
                orderNr: d.orderNr,
                description: d.item ? d.item.description : '',
                date: d.date ? new Date(d.date._seconds * 1000) : '',
                vendor: d.vendor,
                author: d.author,
                total: d.total
            }
        })

        new AngularCsv(data, 'Materials_list', {
            decimalseparator: ',',
            fieldSeparator: ';'
        });
    }

    public refresh = () => {
        this.triggerRefresh.next(true);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
class MaterialDataSource extends DataSource<MaterialItem> {

    _filterChange = new BehaviorSubject('');
    _dataChange = new BehaviorSubject([]);
    // The number of issues returned by github matching the query.
    public resultsLength = 0;
    public isLoadingResults = false;

    constructor(private observable,
                private _paginator: MatPaginator,
                private _sort: MatSort) {
        super();
        this.observable
            // .do((data) => console.log(data))
            .subscribe(this._dataChange);
    }

    _filteredTotal = 0;

    get filteredTotal(): number {
        return this._filteredTotal;
    }

    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<MaterialItem[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        const displayDataChanges = [
            this.observable,
            this._sort.sortChange,
            this._filterChange.pipe(debounceTime(500)),
            this._paginator.page
        ];

        this._paginator._changePageSize(Number(localStorage.getItem('materialBalanceListPageSize')));
        this._paginator.page.next(new PageEvent());
        this._paginator.page.subscribe(paginationData => {
            console.log(paginationData, paginationData.pageSize, paginationData.pageIndex)
            if (paginationData.pageSize) localStorage.setItem('materialBalanceListPageSize', paginationData.pageSize.toString());
        });

        const sortId = localStorage.getItem('materialBalanceListSortId');
        const sortDirection = localStorage.getItem('materialBalanceListSortDirection');

        this._sort.sort(({id: sortId, start: sortDirection}) as MatSortable);

        return merge(...displayDataChanges).pipe(map(() => {
            const data = this._dataChange.value;

            // Filter
            const fData = data.filter((matItem: MaterialItem) => {
                let srcStr;
                try {
                    srcStr = matItem.vendor + matItem.orderNr + matItem.author + matItem.item.description;
                } catch (err) {
                    srcStr = '';
                }

                const searchStr = (srcStr + matItem.vendor + matItem.orderNr).toLowerCase();
                return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });

            this._filteredTotal = fData.map((mi: MaterialItem) => mi.total).reduce((a, b) => a + b, 0);
            this.resultsLength = fData.length;
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            this.sortData(fData);
            return fData.splice(startIndex, this._paginator.pageSize);
        }));

    }

    disconnect() {
    }

    private sortData = (timereport: MaterialItem[]) => {
        localStorage.setItem('materialBalanceListSortId', this._sort.active);
        localStorage.setItem('materialBalanceListSortDirection', this._sort.direction);
        // console.log('sort ', this._sort.active, this._sort.direction);
        if (this._sort.active === undefined || this._sort.direction === undefined) {
            return;
        }
        timereport.sort((a: MaterialItem, b: MaterialItem) => {

            let propertyA: number | string | Date = '';
            let propertyB: number | string | Date = '';

            switch (this._sort.active) {
                // case 'budget':
                //   // const abid = (typeof a.budget === 'object') ? a.budget.budgetNr : a.budget;
                //   // const bbid = (typeof b.budget === 'object') ? b.budget.budgetNr : b.budget;
                //   [propertyA, propertyB] = [abid, bbid];
                //   break;
                case 'vendor':
                    [propertyA, propertyB] = [a.vendor, b.vendor];
                    break;
                case 'date':
                    [propertyA, propertyB] = [a.date, b.date];
                    break;
                case 'date':
                    [propertyA, propertyB] = [<Date>a.date, <Date>b.date];
                    break;
                case 'author':
                    [propertyA, propertyB] = [a.author, b.author];
                    break;
                case 'description':
                    [propertyA, propertyB] = [a.item.description, b.item.description];
                    break;
                case 'orderNr':
                    [propertyA, propertyB] = [a.orderNr, b.orderNr];
                    break;
                case 'costEach':
                    [propertyA, propertyB] = [a.item.costEach, b.item.costEach];
                    break;
                case 'total':
                    [propertyA, propertyB] = [a.total, b.total];
                    break;
            }

            let valueA;
            let valueB;

            if (propertyA instanceof Date) {
                valueA = propertyA;
                valueB = propertyB;
            } else {
                valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            }

            return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
        });
    }
}
